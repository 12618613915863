/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Actividade } from '../models/Actividade';
import type { ActividadeRequest } from '../models/ActividadeRequest';
import type { Desconto } from '../models/Desconto';
import type { DescontoRequest } from '../models/DescontoRequest';
import type { Pacote } from '../models/Pacote';
import type { PacoteRequest } from '../models/PacoteRequest';
import type { PatchedActividadeRequest } from '../models/PatchedActividadeRequest';
import type { PatchedDescontoRequest } from '../models/PatchedDescontoRequest';
import type { PatchedPacoteRequest } from '../models/PatchedPacoteRequest';
import type { PatchedProdutoDocsRequest } from '../models/PatchedProdutoDocsRequest';
import type { PatchedSeguroRequest } from '../models/PatchedSeguroRequest';
import type { PatchedSuplementoRequest } from '../models/PatchedSuplementoRequest';
import type { ProdutoDocs } from '../models/ProdutoDocs';
import type { ProdutoDocsRequest } from '../models/ProdutoDocsRequest';
import type { ProdutoStats } from '../models/ProdutoStats';
import type { Seguro } from '../models/Seguro';
import type { SeguroRequest } from '../models/SeguroRequest';
import type { Suplemento } from '../models/Suplemento';
import type { SuplementoRequest } from '../models/SuplementoRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProdutoService {

    /**
     * @returns Actividade
     * @throws ApiError
     */
    public static produtoActividadeList({
        activo,
        campanha,
    }: {
        activo?: boolean,
        /**
         * Identifica univocamente uma campanha
         */
        campanha?: string,
    }): CancelablePromise<Array<Actividade>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/produto/actividade',
            query: {
                'activo': activo,
                'campanha': campanha,
            },
        });
    }

    /**
     * @returns Actividade
     * @throws ApiError
     */
    public static produtoActividadeCreate({
        requestBody,
    }: {
        requestBody: ActividadeRequest,
    }): CancelablePromise<Actividade> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/produto/actividade',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Actividade
     * @throws ApiError
     */
    public static produtoActividadeRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this actividade.
         */
        id: number,
    }): CancelablePromise<Actividade> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/produto/actividade/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Actividade
     * @throws ApiError
     */
    public static produtoActividadeUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this actividade.
         */
        id: number,
        requestBody: ActividadeRequest,
    }): CancelablePromise<Actividade> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/produto/actividade/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Actividade
     * @throws ApiError
     */
    public static produtoActividadePartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this actividade.
         */
        id: number,
        requestBody?: PatchedActividadeRequest,
    }): CancelablePromise<Actividade> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/produto/actividade/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static produtoActividadeDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this actividade.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/produto/actividade/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Actividade
     * @throws ApiError
     */
    public static produtoActividadeDisponibilizarATodosOsGruposRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this actividade.
         */
        id: number,
    }): CancelablePromise<Actividade> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/produto/actividade/{id}/disponibilizar_a_todos_os_grupos',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProdutoStats
     * @throws ApiError
     */
    public static produtoActividadeStatsList({
        campanha,
        activo,
        endDate,
        grupo,
        startDate,
    }: {
        campanha: string,
        activo?: boolean,
        endDate?: string,
        grupo?: string,
        startDate?: string,
    }): CancelablePromise<Array<ProdutoStats>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/produto/actividade/stats',
            query: {
                'activo': activo,
                'campanha': campanha,
                'end_date': endDate,
                'grupo': grupo,
                'start_date': startDate,
            },
        });
    }

    /**
     * @returns Desconto
     * @throws ApiError
     */
    public static produtoDescontoList({
        campanha,
    }: {
        /**
         * Identifica univocamente uma campanha
         */
        campanha?: string,
    }): CancelablePromise<Array<Desconto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/produto/desconto',
            query: {
                'campanha': campanha,
            },
        });
    }

    /**
     * @returns Desconto
     * @throws ApiError
     */
    public static produtoDescontoCreate({
        requestBody,
    }: {
        requestBody: DescontoRequest,
    }): CancelablePromise<Desconto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/produto/desconto',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Desconto
     * @throws ApiError
     */
    public static produtoDescontoRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this desconto.
         */
        id: number,
    }): CancelablePromise<Desconto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/produto/desconto/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Desconto
     * @throws ApiError
     */
    public static produtoDescontoUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this desconto.
         */
        id: number,
        requestBody: DescontoRequest,
    }): CancelablePromise<Desconto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/produto/desconto/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Desconto
     * @throws ApiError
     */
    public static produtoDescontoPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this desconto.
         */
        id: number,
        requestBody?: PatchedDescontoRequest,
    }): CancelablePromise<Desconto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/produto/desconto/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static produtoDescontoDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this desconto.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/produto/desconto/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProdutoStats
     * @throws ApiError
     */
    public static produtoDescontoStatsList({
        campanha,
        endDate,
        grupo,
        startDate,
    }: {
        campanha: string,
        endDate?: string,
        grupo?: string,
        startDate?: string,
    }): CancelablePromise<Array<ProdutoStats>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/produto/desconto/stats',
            query: {
                'campanha': campanha,
                'end_date': endDate,
                'grupo': grupo,
                'start_date': startDate,
            },
        });
    }

    /**
     * @returns ProdutoDocs
     * @throws ApiError
     */
    public static produtoDocsList(): CancelablePromise<Array<ProdutoDocs>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/produto/docs',
        });
    }

    /**
     * @returns ProdutoDocs
     * @throws ApiError
     */
    public static produtoDocsCreate({
        formData,
    }: {
        formData: ProdutoDocsRequest,
    }): CancelablePromise<ProdutoDocs> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/produto/docs',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns ProdutoDocs
     * @throws ApiError
     */
    public static produtoDocsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this produto docs.
         */
        id: number,
    }): CancelablePromise<ProdutoDocs> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/produto/docs/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProdutoDocs
     * @throws ApiError
     */
    public static produtoDocsUpdate({
        id,
        formData,
    }: {
        /**
         * A unique integer value identifying this produto docs.
         */
        id: number,
        formData: ProdutoDocsRequest,
    }): CancelablePromise<ProdutoDocs> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/produto/docs/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns ProdutoDocs
     * @throws ApiError
     */
    public static produtoDocsPartialUpdate({
        id,
        formData,
    }: {
        /**
         * A unique integer value identifying this produto docs.
         */
        id: number,
        formData?: PatchedProdutoDocsRequest,
    }): CancelablePromise<ProdutoDocs> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/produto/docs/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static produtoDocsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this produto docs.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/produto/docs/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Pacote
     * @throws ApiError
     */
    public static produtoPacoteList({
        activo,
        campanha,
    }: {
        activo?: boolean,
        /**
         * Identifica univocamente uma campanha
         */
        campanha?: string,
    }): CancelablePromise<Array<Pacote>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/produto/pacote',
            query: {
                'activo': activo,
                'campanha': campanha,
            },
        });
    }

    /**
     * @returns Pacote
     * @throws ApiError
     */
    public static produtoPacoteCreate({
        requestBody,
    }: {
        requestBody: PacoteRequest,
    }): CancelablePromise<Pacote> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/produto/pacote',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Pacote
     * @throws ApiError
     */
    public static produtoPacoteRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this pacote.
         */
        id: number,
    }): CancelablePromise<Pacote> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/produto/pacote/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Pacote
     * @throws ApiError
     */
    public static produtoPacoteUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this pacote.
         */
        id: number,
        requestBody: PacoteRequest,
    }): CancelablePromise<Pacote> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/produto/pacote/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Pacote
     * @throws ApiError
     */
    public static produtoPacotePartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this pacote.
         */
        id: number,
        requestBody?: PatchedPacoteRequest,
    }): CancelablePromise<Pacote> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/produto/pacote/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static produtoPacoteDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this pacote.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/produto/pacote/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Pacote
     * @throws ApiError
     */
    public static produtoPacoteDisponibilizarATodosOsGruposRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this pacote.
         */
        id: number,
    }): CancelablePromise<Pacote> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/produto/pacote/{id}/disponibilizar_a_todos_os_grupos',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProdutoStats
     * @throws ApiError
     */
    public static produtoPacoteStatsList({
        campanha,
        activo,
        endDate,
        grupo,
        startDate,
    }: {
        campanha: string,
        activo?: boolean,
        endDate?: string,
        grupo?: string,
        startDate?: string,
    }): CancelablePromise<Array<ProdutoStats>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/produto/pacote/stats',
            query: {
                'activo': activo,
                'campanha': campanha,
                'end_date': endDate,
                'grupo': grupo,
                'start_date': startDate,
            },
        });
    }

    /**
     * @returns Seguro
     * @throws ApiError
     */
    public static produtoSeguroList({
        activo,
        campanha,
    }: {
        activo?: boolean,
        /**
         * Identifica univocamente uma campanha
         */
        campanha?: string,
    }): CancelablePromise<Array<Seguro>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/produto/seguro',
            query: {
                'activo': activo,
                'campanha': campanha,
            },
        });
    }

    /**
     * @returns Seguro
     * @throws ApiError
     */
    public static produtoSeguroCreate({
        requestBody,
    }: {
        requestBody: SeguroRequest,
    }): CancelablePromise<Seguro> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/produto/seguro',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Seguro
     * @throws ApiError
     */
    public static produtoSeguroRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this seguro.
         */
        id: number,
    }): CancelablePromise<Seguro> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/produto/seguro/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Seguro
     * @throws ApiError
     */
    public static produtoSeguroUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this seguro.
         */
        id: number,
        requestBody: SeguroRequest,
    }): CancelablePromise<Seguro> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/produto/seguro/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Seguro
     * @throws ApiError
     */
    public static produtoSeguroPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this seguro.
         */
        id: number,
        requestBody?: PatchedSeguroRequest,
    }): CancelablePromise<Seguro> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/produto/seguro/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static produtoSeguroDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this seguro.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/produto/seguro/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Seguro
     * @throws ApiError
     */
    public static produtoSeguroDisponibilizarATodosOsGruposRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this seguro.
         */
        id: number,
    }): CancelablePromise<Seguro> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/produto/seguro/{id}/disponibilizar_a_todos_os_grupos',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProdutoStats
     * @throws ApiError
     */
    public static produtoSeguroStatsList({
        campanha,
        activo,
        endDate,
        grupo,
        startDate,
    }: {
        campanha: string,
        activo?: boolean,
        endDate?: string,
        grupo?: string,
        startDate?: string,
    }): CancelablePromise<Array<ProdutoStats>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/produto/seguro/stats',
            query: {
                'activo': activo,
                'campanha': campanha,
                'end_date': endDate,
                'grupo': grupo,
                'start_date': startDate,
            },
        });
    }

    /**
     * @returns Suplemento
     * @throws ApiError
     */
    public static produtoSuplementoList({
        activo,
        campanha,
    }: {
        activo?: boolean,
        /**
         * Identifica univocamente uma campanha
         */
        campanha?: string,
    }): CancelablePromise<Array<Suplemento>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/produto/suplemento',
            query: {
                'activo': activo,
                'campanha': campanha,
            },
        });
    }

    /**
     * @returns Suplemento
     * @throws ApiError
     */
    public static produtoSuplementoCreate({
        requestBody,
    }: {
        requestBody: SuplementoRequest,
    }): CancelablePromise<Suplemento> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/produto/suplemento',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Suplemento
     * @throws ApiError
     */
    public static produtoSuplementoRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this suplemento.
         */
        id: number,
    }): CancelablePromise<Suplemento> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/produto/suplemento/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Suplemento
     * @throws ApiError
     */
    public static produtoSuplementoUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this suplemento.
         */
        id: number,
        requestBody: SuplementoRequest,
    }): CancelablePromise<Suplemento> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/produto/suplemento/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Suplemento
     * @throws ApiError
     */
    public static produtoSuplementoPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this suplemento.
         */
        id: number,
        requestBody?: PatchedSuplementoRequest,
    }): CancelablePromise<Suplemento> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/produto/suplemento/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static produtoSuplementoDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this suplemento.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/produto/suplemento/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Suplemento
     * @throws ApiError
     */
    public static produtoSuplementoDisponibilizarATodosOsGruposRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this suplemento.
         */
        id: number,
    }): CancelablePromise<Suplemento> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/produto/suplemento/{id}/disponibilizar_a_todos_os_grupos',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProdutoStats
     * @throws ApiError
     */
    public static produtoSuplementoStatsList({
        campanha,
        activo,
        endDate,
        grupo,
        startDate,
    }: {
        campanha: string,
        activo?: boolean,
        endDate?: string,
        grupo?: string,
        startDate?: string,
    }): CancelablePromise<Array<ProdutoStats>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/produto/suplemento/stats',
            query: {
                'activo': activo,
                'campanha': campanha,
                'end_date': endDate,
                'grupo': grupo,
                'start_date': startDate,
            },
        });
    }

}
