/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Masculino` - Masculino
 * * `Feminino` - Feminino
 * * `Outro` - Outro
 */
export enum GeneroSexualEnum {
    MASCULINO = 'Masculino',
    FEMININO = 'Feminino',
    OUTRO = 'Outro',
}
