/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Instituicao } from '../models/Instituicao';
import type { InstituicaoItem } from '../models/InstituicaoItem';
import type { InstituicaoRequest } from '../models/InstituicaoRequest';
import type { PatchedInstituicaoRequest } from '../models/PatchedInstituicaoRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InstituicaoService {

    /**
     * Gerir Instituição
     * @returns Instituicao
     * @throws ApiError
     */
    public static instituicaoList({
        campanha,
        distrito,
    }: {
        /**
         * Identifica univocamente uma campanha
         */
        campanha?: string,
        /**
         * Útil para grupar e diferenciar
         *
         * * `Aveiro` - Aveiro
         * * `Beja` - Beja
         * * `Braga` - Braga
         * * `Bragança` - Braganca
         * * `Castelo Branco` - Castelo Branco
         * * `Coimbra` - Coimbra
         * * `Évora` - Evora
         * * `Faro` - Faro
         * * `Guarda` - Guarda
         * * `Leiria` - Leiria
         * * `Lisboa` - Lisboa
         * * `Portalegre` - Portalegre
         * * `Porto` - Porto
         * * `Santarém` - Santarem
         * * `Setúbal` - Setubal
         * * `Viana do Castelo` - Viana Do Castelo
         * * `Vila Real` - Vila Real
         * * `Viseu` - Viseu
         * * `Madeira` - Madeira
         * * `Açores` - Acores
         */
        distrito?: 'Aveiro' | 'Açores' | 'Beja' | 'Braga' | 'Bragança' | 'Castelo Branco' | 'Coimbra' | 'Faro' | 'Guarda' | 'Leiria' | 'Lisboa' | 'Madeira' | 'Portalegre' | 'Porto' | 'Santarém' | 'Setúbal' | 'Viana do Castelo' | 'Vila Real' | 'Viseu' | 'Évora',
    }): CancelablePromise<Array<Instituicao>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/instituicao',
            query: {
                'campanha': campanha,
                'distrito': distrito,
            },
        });
    }

    /**
     * Gerir Instituição
     * @returns Instituicao
     * @throws ApiError
     */
    public static instituicaoCreate({
        requestBody,
    }: {
        requestBody: InstituicaoRequest,
    }): CancelablePromise<Instituicao> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/instituicao',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Gerir Instituição
     * @returns Instituicao
     * @throws ApiError
     */
    public static instituicaoRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this Instituição.
         */
        id: number,
    }): CancelablePromise<Instituicao> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/instituicao/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Gerir Instituição
     * @returns Instituicao
     * @throws ApiError
     */
    public static instituicaoUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Instituição.
         */
        id: number,
        requestBody: InstituicaoRequest,
    }): CancelablePromise<Instituicao> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/instituicao/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Gerir Instituição
     * @returns Instituicao
     * @throws ApiError
     */
    public static instituicaoPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Instituição.
         */
        id: number,
        requestBody?: PatchedInstituicaoRequest,
    }): CancelablePromise<Instituicao> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/instituicao/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Gerir Instituição
     * @returns void
     * @throws ApiError
     */
    public static instituicaoDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this Instituição.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/instituicao/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Listar Instituições
     * @returns InstituicaoItem
     * @throws ApiError
     */
    public static instituicaoItemList({
        campanha,
        distrito,
    }: {
        /**
         * Identifica univocamente uma campanha
         */
        campanha?: string,
        /**
         * Útil para grupar e diferenciar
         *
         * * `Aveiro` - Aveiro
         * * `Beja` - Beja
         * * `Braga` - Braga
         * * `Bragança` - Braganca
         * * `Castelo Branco` - Castelo Branco
         * * `Coimbra` - Coimbra
         * * `Évora` - Evora
         * * `Faro` - Faro
         * * `Guarda` - Guarda
         * * `Leiria` - Leiria
         * * `Lisboa` - Lisboa
         * * `Portalegre` - Portalegre
         * * `Porto` - Porto
         * * `Santarém` - Santarem
         * * `Setúbal` - Setubal
         * * `Viana do Castelo` - Viana Do Castelo
         * * `Vila Real` - Vila Real
         * * `Viseu` - Viseu
         * * `Madeira` - Madeira
         * * `Açores` - Acores
         */
        distrito?: 'Aveiro' | 'Açores' | 'Beja' | 'Braga' | 'Bragança' | 'Castelo Branco' | 'Coimbra' | 'Faro' | 'Guarda' | 'Leiria' | 'Lisboa' | 'Madeira' | 'Portalegre' | 'Porto' | 'Santarém' | 'Setúbal' | 'Viana do Castelo' | 'Vila Real' | 'Viseu' | 'Évora',
    }): CancelablePromise<Array<InstituicaoItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/instituicao/item',
            query: {
                'campanha': campanha,
                'distrito': distrito,
            },
        });
    }

}
