import React, { useEffect, useMemo, useState } from 'react';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from 'mantine-react-table';
import { ViagemNotificaAtraso, ViagemService } from '../../../slidein_api';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import { iconBool, msgAPIError } from '../../helpers';
import { Link, useParams } from 'react-router-dom';
import { Box, Button, LoadingOverlay, Stack } from '@mantine/core';
import {
  IconAt,
  IconMailCheck,
  IconMessageQuestion,
  IconPlane,
  IconSend,
} from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { useBread } from '../../../layout/context/BreadContext';

export function NotificaAtrasos() {
  const { campanhaID } = useParams();
  const { setBread } = useBread();
  useEffect(() => {
    setBread({
      campanha: {
        title: campanhaID,
        href: '/campanha/' + campanhaID,
        icon: <IconPlane />,
      },
      accao: {
        title: 'Notificações',
        icon: <IconMessageQuestion />,
      },
    });
  }, []);

  const [viagems, setViagems] = useState<ViagemNotificaAtraso[]>([]);
  const [isRefetching, setIsRefetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const fetchData = () => {
    setIsRefetching(true);
    setLoading(true);
    ViagemService.viagemNotificaList({
      campanha: campanhaID,
    }).then(
      (value) => {
        setViagems(value);
        setIsRefetching(false);
        setLoading(false);
      },
      (reason) => {
        msgAPIError(reason);
        setIsRefetching(false);
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    fetchData();
  }, []);

  const columns = useMemo<MRT_ColumnDef<ViagemNotificaAtraso>[]>(
    () => [
      {
        accessorKey: 'chefe_de_grupo',
        size: 50,
        id: 'chefeDeGrupo',
        header: 'Chefe',
        filterVariant: 'checkbox',
        Cell: ({ cell }) => iconBool(cell.row.original.chefe_de_grupo),
      },

      {
        accessorKey: 'vfree',
        size: 50,
        id: 'vfree',
        header: 'Free Trip',
        filterVariant: 'checkbox',
        Cell: ({ cell }) => iconBool(cell.row.original.vfree),
      },

      {
        accessorFn: (originalRow) => originalRow.nome,
        id: 'nomeCompleto',
        header: 'Nome',
        filterVariant: 'text',
        Cell: (props: { cell }) => (
          <Link to={'/campanha/' + campanhaID + '/viagem/' + props.cell.row.original.id}>
            {props.cell.row.original.nome}
          </Link>
        ),
      },
      {
        accessorFn: (vi) => vi.email,
        id: 'email',
        header: 'Email',
        filterVariant: 'text',
        Cell: (props: { cell }) => (
          <a href={'mailto:' + props.cell.row.original.email}>{props.cell.row.original.email}</a>
        ),
      },
      {
        accessorFn: (row) => row.telemovel,
        header: 'Telemovel',
        id: 'contactoTelemovel',
        filterVariant: 'text',
      },
      {
        accessorFn: (vi) => vi.email_pais,
        id: 'email_pais',
        header: 'Email(Pais)',
        filterVariant: 'text',
        Cell: (props: { cell }) => (
          <a href={'mailto:' + props.cell.row.original.email_pais}>
            {props.cell.row.original.email_pais}
          </a>
        ),
      },
      {
        accessorFn: (row) => row.telemovel_pais,
        header: 'Telemovel(Pais)',
        id: 'contactoTelemovelPais',
        filterVariant: 'text',
      },
      {
        accessorFn: (vi) => vi.grupo || '-',
        id: 'grupo',
        header: 'Grupo',
        filterVariant: 'text',
        Cell: ({ row }: { row }) => (
          <Link to={'/campanha/' + campanhaID + '/grupo/' + row.original.grupo}>
            {row.original.grupo}
          </Link>
        ),
      },
      {
        accessorFn: (vi) => vi.admin_responsavel || '-',
        id: 'admin_responsavel',
        header: 'Admin.',
        filterVariant: 'text',
      },
      {
        accessorFn: (vi) => vi.comercial_responsavel || '-',
        id: 'comercial_responsavel',
        header: 'Comercial.',
        filterVariant: 'text',
      },

      {
        accessorKey: 'prestacoes_em_atraso',
        id: 'prestacoesEmAtraso',
        size: 20,
        header: 'Prestações em Atraso',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
      },
      {
        accessorKey: 'notificacoes_email',
        header: 'Nrº Emails',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        size: 20,
      },
      {
        accessorKey: 'notificacoes_sms',
        header: 'Nrº SMS',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        size: 20,
      },
      {
        accessorKey: 'notificacoes_email_pais',
        header: 'Nrº Emails Pais',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        size: 20,
      },
      {
        accessorKey: 'notificacoes_sms_pais',
        header: 'Nrº SMS Pais',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        size: 20,
      },

      {
        accessorKey: 'valor_total',
        id: 'valorTotal',
        size: 50,
        header: 'Total',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ cell }) => {
          return cell.row.original.valor_total + '€';
        },
      },
      {
        accessorKey: 'valor_pago',
        id: 'valorPago',
        header: 'Pago',
        size: 50,
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ cell }) => {
          return cell.row.original.valor_pago + '€';
        },
      },
      {
        accessorKey: 'valor_devido',
        id: 'valorDevido',
        header: 'Devido',
        size: 50,
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ cell }) => {
          return cell.row.original.valor_devido + '€';
        },
      },

      {
        accessorFn: (vi) => vi.instituicao || '',
        id: 'instituicao',
        header: 'Instituição',
        filterVariant: 'text',
      },
    ],
    []
  );

  const tableViagens = useMantineReactTable({
    data: viagems || [],
    columns,
    enableGrouping: true,
    enableClickToCopy: true,
    enableRowActions: false,
    columnResizeMode: 'onEnd',
    positionActionsColumn: 'first',
    enableMultiSort: true,
    enablePagination: true,
    enableColumnOrdering: true,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    enableSelectAll: true,
    selectAllMode: 'all',
    getRowId: (row) => row.id.toString(),
    onRowSelectionChange: setRowSelection,
    state: {
      // isLoading: isLoading, this is crashing setState xenanings....
      showProgressBars: isRefetching || loading,
      rowSelection,
    },
    initialState: {
      density: 'xs',
      showGlobalFilter: true,
      pagination: { pageSize: 15, pageIndex: 0 },
    },

    mantineTableProps: {
      withColumnBorders: true,
    },
    enableFullScreenToggle: false,
    localization: MRT_Localization_PT,
    globalFilterFn: 'contains',
    positionToolbarAlertBanner: 'bottom',

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}>
        <Button
          variant={'outline'}
          color={'gray'}
          leftIcon={<IconAt />}
          disabled={Object.values(rowSelection).length < 1}
          onClick={() => {
            setLoading(true);
            ViagemService.viagemNotificaMailClienteCreate({
              requestBody: {
                ids: Object.keys(rowSelection).join(','),
                msg: '',
              },
            }).then(
              () => {
                fetchData();
                notifications.show({
                  title: 'Notificação Email ',
                  message: 'Emails enviados (Cliente)',
                  icon: <IconMailCheck />,
                });
              },
              (reason) => {
                msgAPIError(reason);
                setLoading(false);
              }
            );
          }}>
          Notificar via Email
        </Button>
        <Button
          variant={'outline'}
          color={'yellow'}
          leftIcon={<IconSend />}
          disabled={Object.values(rowSelection).length < 1}
          onClick={() => {
            const smsText = prompt('Texto a enviar (max 155 caracteres)', '');
            ViagemService.viagemNotificaSmsClienteCreate({
              requestBody: {
                ids: Object.keys(rowSelection).join(','),
                msg: smsText,
              },
            }).then(
              () => {
                fetchData();
                notifications.show({
                  title: 'Notificação SMS ',
                  message: 'Mensagens Enviadas(Cliente)',
                  icon: <IconMailCheck />,
                });
              },
              (reason) => {
                msgAPIError(reason);
                setLoading(false);
              }
            );
          }}>
          Notificar via SMS
        </Button>
        <Button
          variant={'outline'}
          color={'gray'}
          leftIcon={<IconAt />}
          disabled={Object.values(rowSelection).length < 1}
          onClick={() => {
            setLoading(true);
            ViagemService.viagemNotificaMailPaisCreate({
              requestBody: {
                ids: Object.keys(rowSelection).join(','),
                msg: '',
              },
            }).then(
              () => {
                fetchData();
                notifications.show({
                  title: 'Notificação Email ',
                  message: 'Emails enviados(Pais)',
                  icon: <IconMailCheck />,
                });
              },
              (reason) => {
                msgAPIError(reason);
                setLoading(false);
              }
            );
          }}>
          Notificar via Email(Pais)
        </Button>
        <Button
          variant={'outline'}
          color={'yellow'}
          leftIcon={<IconSend />}
          disabled={Object.values(rowSelection).length < 1}
          onClick={() => {
            const smsText = prompt('Texto a enviar (max 155 caracteres)', '');
            ViagemService.viagemNotificaSmsPaisCreate({
              requestBody: {
                ids: Object.keys(rowSelection).join(','),
                msg: smsText,
              },
            }).then(
              () => {
                fetchData();
                notifications.show({
                  title: 'Notificação SMS ',
                  message: 'Mensagens Enviadas (Pais)',
                  icon: <IconMailCheck />,
                });
              },
              (reason) => {
                msgAPIError(reason);
                setLoading(false);
              }
            );
          }}>
          Notificar via SMS(Pais)
        </Button>
      </Box>
    ),
  });

  return (
    <Stack>
      <LoadingOverlay
        visible={loading}
        overlayBlur={2}
      />
      <MantineReactTable table={tableViagens} />
    </Stack>
  );
}
