/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Aguardar Pagamento` - Em Pagamento
 * * `Pago NÃO TRATADO` - Pago Nao Tratado
 * * `Pago e Tratado` - Pago Tratado
 * * `Não Pagou` - Nao Pagou
 * * `Base` - Base
 */
export enum EstadoPagamentoEnum {
    AGUARDAR_PAGAMENTO = 'Aguardar Pagamento',
    PAGO_N_O_TRATADO = 'Pago NÃO TRATADO',
    PAGO_E_TRATADO = 'Pago e Tratado',
    N_O_PAGOU = 'Não Pagou',
    BASE = 'Base',
}
