import React, { useEffect, useMemo, useState } from 'react';

import {
  ActionIcon,
  Box,
  Button,
  FileInput,
  Group,
  Modal,
  Stack,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import {
  IconEdit,
  IconFiles,
  IconPlane,
  IconPlus,
  IconTrashX,
  IconUpload,
} from '@tabler/icons-react';
import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import { notifications } from '@mantine/notifications';
import {
  ApiError,
  CampanhaService,
  DocumentoCampanha,
  DocumentoCondicoesCampanha,
} from '../../../slidein_api';
import { msgAPIError } from '../../helpers';
import { useAuth } from '../../../user-auth/SlideInAuthContext';
import { checkPermission, MASTER } from '../../../components/CheckPermission';
import { useParams } from 'react-router-dom';
import { useBread } from '../../../layout/context/BreadContext';

export function CampanhaDocs() {
  const { campanhaID } = useParams();
  const { colaborador } = useAuth();
  const { setBread } = useBread();
  useEffect(() => {
    setBread({
      campanha: {
        title: campanhaID,
        href: '/campanha/' + campanhaID,
        icon: <IconPlane />,
      },
      accao: {
        title: 'Documentos',
        icon: <IconFiles />,
      },
    });
  }, []);

  const [genericos, setGenericos] = useState<DocumentoCampanha[]>([]);
  const [condicoes, setCondicoes] = useState<DocumentoCondicoesCampanha[]>([]);
  const [addDiag, setAddDiag] = useState<boolean>(false);
  const [addDiac, setAddDiac] = useState<boolean>(false);

  const [tempTitle, setTempTitle] = useState<string>('');
  const [tempFile, setTempFile] = useState<File | undefined>();
  const [updateFiles, setUpdateFiles] = useState<boolean>(false);

  useEffect(() => {
    CampanhaService.campanhaDocsList({
      campanha: campanhaID,
    }).then(
      (value) => setGenericos(value),
      (reason: ApiError) => {
        msgAPIError(reason);
      }
    );
    CampanhaService.campanhaDocsCondicoesList({
      campanha: campanhaID,
    }).then(
      (value) => setCondicoes(value),
      (reason: ApiError) => {
        msgAPIError(reason);
      }
    );
  }, [updateFiles]);

  const cleanTemp = () => {
    setTempFile(undefined);
    setTempTitle('');
    setAddDiag(false);
    setAddDiac(false);
  };

  const addp = (
    <Modal
      onClose={() => {
        cleanTemp();
      }}
      withCloseButton={false}
      opened={addDiag || addDiac}>
      <Title order={2}>Adicionar Documento</Title>
      <TextInput
        label={'Nome Documento'}
        value={tempTitle}
        onChange={(event) => setTempTitle(event.target.value)}
      />
      <FileInput
        value={tempFile}
        onChange={(payload) => setTempFile(payload)}
        label={'Ficheiro'}
        multiple={false}
        icon={<IconUpload />}
      />
      <Group
        position='right'
        mt='md'>
        <Button
          variant={'outline'}
          disabled={!(tempFile && tempTitle)}
          type='button'
          onClick={() => {
            if (addDiag) {
              CampanhaService.campanhaDocsCreate({
                formData: {
                  titulo: tempTitle,
                  file: tempFile,
                  campanha: campanhaID,
                },
              }).then(
                () => {
                  setUpdateFiles(!updateFiles);
                  cleanTemp();
                },
                (reason: ApiError) => {
                  msgAPIError(reason);
                }
              );
            } else if (addDiac) {
              CampanhaService.campanhaDocsCondicoesCreate({
                formData: {
                  titulo: tempTitle,
                  file: tempFile,
                  campanha: campanhaID,
                },
              }).then(
                () => {
                  setUpdateFiles(!updateFiles);
                  cleanTemp();
                },
                (reason: ApiError) => {
                  msgAPIError(reason);
                }
              );
            }
          }}>
          Guardar Documento
        </Button>
        <Button
          variant={'outline'}
          type='button'
          onClick={() => {
            cleanTemp();
          }}>
          Cancelar
        </Button>
      </Group>
    </Modal>
  );

  const columns = useMemo<MRT_ColumnDef<DocumentoCampanha | DocumentoCondicoesCampanha>[]>(
    () => [
      {
        accessorKey: 'titulo',
        header: 'Nome Documento',
      },
      {
        accessorKey: 'file',
        header: 'Ficheiro',
        enableEditing: false,
        Cell: (cell) => (
          <a
            href={cell.row.original.file}
            target='_blank'
            rel='noreferrer'
            download>
            {cell.row.original.titulo}
          </a>
        ),
      },
    ],
    []
  );
  const tablep = useMantineReactTable({
    columns,
    data: genericos,

    renderTopToolbarCustomActions: () => {
      return (
        <Box sx={{ display: 'flex', gap: '16px', padding: '4px', flexWrap: 'wrap' }}>
          <Button
            disabled={!checkPermission(MASTER, colaborador)}
            leftIcon={<IconPlus />}
            variant='outline'
            size='md'
            onClick={() => {
              setAddDiag(true);
            }}>
            Adicionar
          </Button>
        </Box>
      );
    },
    initialState: {
      density: 'xs',
      showGlobalFilter: true,
      pagination: { pageSize: 15, pageIndex: 0 },
    },
    enableRowActions: false,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <Tooltip label='Editar'>
          <ActionIcon
            color='blue'
            onClick={() => table.setEditingRow(row)}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label='Remover'>
          <ActionIcon
            color='red'
            onClick={() => {
              if (confirm('Confirma que deseja remover o documento ?')) {
                CampanhaService.campanhaDocsDestroy({ id: row.original.id }).then(
                  () => {
                    setGenericos(genericos.filter((obj) => obj.id !== row.original.id));
                    notifications.show({
                      title: 'Documento removido!',
                      message: `O documento ${row.original.titulo}  foi removido.`,
                      withCloseButton: true,
                      icon: <IconTrashX />,
                      color: 'red',
                    });
                  },
                  (reason) => {
                    msgAPIError(reason);
                  }
                );
              }
            }}>
            <IconTrashX />
          </ActionIcon>
        </Tooltip>
      </Box>
    ),

    // positionActionsColumn: 'first',
    enableFullScreenToggle: false,
    localization: MRT_Localization_PT,
    enableEditing: checkPermission(MASTER, colaborador),
    enablePagination: false,
    enableFilters: false,
    enableHiding: false,
    enableBottomToolbar: false,
    editDisplayMode: 'modal',
    onEditingRowSave: ({ exitEditingMode, row, values }) => {
      CampanhaService.campanhaDocsPartialUpdate({
        id: row.original.id,
        formData: { titulo: values.titulo },
      }).then(
        () => {
          genericos[row.index] = values;
          setGenericos([...genericos]);
        },
        (reason: ApiError) => {
          msgAPIError(reason);
        }
      );
      exitEditingMode();
    },
  });

  const tablec = useMantineReactTable({
    columns,
    data: condicoes,

    displayColumnDefOptions: {
      'mrt-row-actions': {
        mantineTableHeadCellProps: {
          align: 'center',
        },
        size: 120,
      },
    },
    renderTopToolbarCustomActions: () => {
      return (
        <Box sx={{ display: 'flex', gap: '16px', padding: '4px', flexWrap: 'wrap' }}>
          <Button
            disabled={!checkPermission(MASTER, colaborador)}
            leftIcon={<IconPlus />}
            variant='outline'
            size='md'
            onClick={() => {
              setAddDiac(true);
            }}>
            Adicionar
          </Button>
        </Box>
      );
    },
    initialState: {
      density: 'xs',
      showGlobalFilter: true,
      pagination: { pageSize: 15, pageIndex: 0 },
    },
    enableRowActions: checkPermission(MASTER, colaborador),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <Tooltip label='Editar'>
          <ActionIcon
            color='blue'
            onClick={() => table.setEditingRow(row)}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label='Remover'>
          <ActionIcon
            color='red'
            onClick={() => {
              if (confirm('Confirma que deseja remover o documento ?')) {
                CampanhaService.campanhaDocsCondicoesDestroy({ id: row.original.id }).then(
                  () => {
                    setCondicoes(condicoes.filter((obj) => obj.id !== row.original.id));
                    notifications.show({
                      title: 'Documento removido!',
                      message: `O documento ${row.original.titulo}  foi removido.`,
                      withCloseButton: true,
                      icon: <IconTrashX />,
                      color: 'red',
                    });
                  },
                  (reason) => {
                    msgAPIError(reason);
                  }
                );
              }
            }}>
            <IconTrashX />
          </ActionIcon>
        </Tooltip>
      </Box>
    ),

    positionActionsColumn: 'first',
    enableFullScreenToggle: false,
    localization: MRT_Localization_PT,
    enableEditing: checkPermission(MASTER, colaborador),
    enablePagination: false,
    enableFilters: false,
    enableHiding: false,
    enableBottomToolbar: false,
    editDisplayMode: 'modal',
    onEditingRowSave: ({ exitEditingMode, row, values }) => {
      CampanhaService.campanhaDocsCondicoesPartialUpdate({
        id: row.original.id,
        formData: { titulo: values.titulo },
      }).then(
        () => {
          condicoes[row.index] = values;
          setCondicoes([...condicoes]);
        },
        (reason: ApiError) => {
          msgAPIError(reason);
        }
      );
      exitEditingMode();
    },
  });

  return (
    <Stack p={'xs'}>
      {addp}
      <Box>
        <Title order={3}>Documentos Genericos Campanha</Title>
        <MantineReactTable table={tablep} />
      </Box>
      <Box>
        <Title order={3}>Documentos condições gerais venda/ Politíca de privacidade</Title>
        <MantineReactTable table={tablec} />
      </Box>
    </Stack>
  );
}
