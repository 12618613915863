import React, { useEffect, useMemo, useState } from 'react';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_GlobalFilterTextInput,
  MRT_PaginationState,
  MRT_ShowHideColumnsButton,
  MRT_SortingState,
  MRT_ToggleFiltersButton,
  useMantineReactTable,
} from 'mantine-react-table';
import { OpenAPI, PagamentoService, TransacaoSimples } from '../../../slidein_api';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import { mrtSortingToAPI, msgAPIError } from '../../helpers';
import { Button, Flex, LoadingOverlay, Stack } from '@mantine/core';
import {
  IconDownload,
  IconFileSpreadsheet,
  IconMoneybag,
  IconPlane,
  IconRefresh,
} from '@tabler/icons-react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import FileSaver from 'file-saver';
import { useBread } from '../../../layout/context/BreadContext';
import dayjs from 'dayjs';

export function Pagamentos() {
  const { campanhaID } = useParams();
  const { setBread } = useBread();
  useEffect(() => {
    setBread({
      campanha: {
        title: campanhaID,
        href: '/campanha/' + campanhaID,
        icon: <IconPlane />,
      },
      accao: {
        title: 'Pagamentos',
        icon: <IconMoneybag />,
      },
    });
  }, []);

  const [transacoes, setTransacoes] = useState<TransacaoSimples[]>([]);
  const [isRefetching, setIsRefetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  });

  const tToslideAPI: Record<string, string> = {
    nome: 'viagem__cliente__nome_proprio',
    email: 'viagem__cliente__email',
    telemovel: 'viagem__cliente__contacto_telemovel',
    grupo: 'viagem__grupo__codigo',
    instituicao: 'viagem__grupo__instituicao__nome',
    trid: 'trid',
    valor: 'valor',
    creationDate: 'creation_date',
    dataFacturacao: 'data_facturacao',
    referencia: 'referencia__referencia',
    entidade: 'referencia__entidade',
    estado: 'estado',
    banco: 'banco',
    tipo: 'tipo',
  };

  function paramsNormalize(columnFilters: MRT_ColumnFiltersState) {
    const params = {};
    columnFilters.forEach((c) => (params[c.id] = c.value));
    if (params['valor']) {
      params['valorMin'] = params['valor'][0];
      params['valorMax'] = params['valor'][1];
    }

    if (params['creationDate']) {
      const da = params['creationDate'][0];
      const db = params['creationDate'][1];

      params['creationDateAfter'] = da
        ? `${da.getFullYear()}-${(da.getMonth() + 1).toString().padStart(2, '0')}-${da
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
      params['creationDateBefore'] = db
        ? `${db.getFullYear()}-${(db.getMonth() + 1).toString().padStart(2, '0')}-${db
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
    }
    if (params['dataFacturacao']) {
      const da = params['dataFacturacao'][0];
      const db = params['dataFacturacao'][1];

      params['dataFacturacaoAfter'] = da
        ? `${da.getFullYear()}-${(da.getMonth() + 1).toString().padStart(2, '0')}-${da
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
      params['dataFacturacaoBefore'] = db
        ? `${db.getFullYear()}-${(db.getMonth() + 1).toString().padStart(2, '0')}-${db
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
    }
    return params;
  }

  const fetchData = (page: number, pageSize: number) => {
    setIsRefetching(true);
    PagamentoService.pagamentoList({
      ...paramsNormalize(columnFilters),
      viagemCampanha: campanhaID,
      page: page + 1,
      pageSize: pageSize,
      search: globalFilter ?? '',
      ordering: mrtSortingToAPI(sorting, tToslideAPI),
    }).then(
      (value) => {
        setTransacoes(value.results);
        setRowCount(value.count);
        setIsRefetching(false);
      },
      (reason) => {
        msgAPIError(reason);
      }
    );
  };
  useEffect(() => {
    fetchData(pagination.pageIndex, pagination.pageSize);
  }, [pagination, sorting]);

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: 15 });
  }, [columnFilters, globalFilter]);

  const columns = useMemo<MRT_ColumnDef<TransacaoSimples>[]>(
    () => [
      { accessorKey: 'estado', header: 'Estado' },
      {
        accessorFn: (row) => (row.viagem ? row.viagem.cliente.nome_simples : '-'),
        header: 'Nome Cliente',
        id: 'nome',
        filterVariant: 'text',
        Cell: ({ row }: { row }) => (
          <Link to={'/campanha/' + campanhaID + '/viagem/' + row.original.viagem.id}>
            {row.original.viagem.cliente.nome_simples}
          </Link>
        ),
      },
      {
        accessorFn: (row) => (
          <a href={'mailto:' + row.viagem.cliente.email}>{row.viagem.cliente.email}</a>
        ),
        header: 'Email',
        id: 'email',
        filterVariant: 'text',
      },
      {
        accessorFn: (row) => (row.viagem ? row.viagem.cliente.contacto_telemovel : '-'),
        header: 'Telemóvel',
        id: 'telemovel',
        filterVariant: 'text',
      },
      {
        accessorFn: (row) => (row.viagem ? row.viagem.grupo.codigo : '-'),
        header: 'Grupo',
        id: 'grupo',
        filterVariant: 'text',
        Cell: ({ row }: { row }) => (
          <Link to={'/campanha/' + campanhaID + '/grupo/' + row.original.viagem.grupo.codigo}>
            {row.original.viagem.grupo.codigo}
          </Link>
        ),
      },
      {
        accessorFn: (row) => (row.viagem ? row.viagem.grupo.instituicao : '-'),
        header: 'Instituição',
        id: 'instituicao',
        filterVariant: 'text',
      },

      {
        accessorKey: 'valor',
        id: 'valor',
        header: 'Valor',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ cell }) => {
          return cell.row.original.valor + '€';
        },
      },
      {
        accessorKey: 'tipo',
        header: 'Tipo',
        id: 'tipo',
        filterVariant: 'text',
      },
      {
        accessorKey: 'trid',
        header: 'Trid(EuPago)',
        id: 'trid',
        enableSorting: false,
        filterVariant: 'text',
      },
      {
        accessorKey: 'referencia.entidade',
        header: 'Entidade(EuPago)',
        id: 'entidade',
        enableSorting: false,
        filterVariant: 'text',
      },
      {
        accessorKey: 'referencia.referencia',
        header: 'Referência (EuPago)',
        id: 'referencia',
        enableSorting: false,
        filterVariant: 'text',
      },
      {
        accessorKey: 'banco',
        header: 'Banco/Local',
        id: 'banco',
        enableSorting: true,
        filterVariant: 'text',
      },
      {
        accessorKey: 'creation_date',
        header: 'Data de Criação',
        id: 'creationDate',
        filterVariant: 'date-range',
        Cell: ({ row }) => {
          return dayjs(row.original.creation_date).locale('pt').format('YYYY-MM-DD HH:mm');
        },
      },
      {
        accessorKey: 'data_facturacao',
        header: 'Data de Facturação',
        id: 'dataFacturacao',
        filterVariant: 'date-range',
      },
    ],
    []
  );

  const tablePag = useMantineReactTable({
    data: transacoes,
    columns,
    enableRowActions: false,
    positionActionsColumn: 'first',
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount: rowCount,
    state: {
      columnFilters,
      globalFilter,
      pagination,
      showProgressBars: isRefetching,
      sorting,
    },
    initialState: {
      density: 'xs',
      showGlobalFilter: true,
      pagination: { pageSize: 15, pageIndex: 0 },
    },

    mantineTableProps: {
      highlightOnHover: true,
      withColumnBorders: true,
    },
    enableFullScreenToggle: false,
    localization: MRT_Localization_PT,
    globalFilterFn: 'contains',
    positionGlobalFilter: 'none',
    positionToolbarAlertBanner: 'bottom',

    renderToolbarInternalActions: ({ table }) => (
      <Flex
        direction={'row'}
        wrap={'wrap'}
        gap={'sm'}
        justify={'start'}>
        <MRT_GlobalFilterTextInput table={table} />
        <Button
          color='lightblue'
          onClick={() => {
            setLoading(true);
            const cenas: {
              creationDateAfter?: string;
              creationDateBefore?: string;
              dataFacturacaoAfter?: string;
              dataFacturacaoBefore?: string;
              email?: string;
              banco?: string;
              estado?: 'Anulado' | 'Pago' | 'Pendente';
              grupo?: string;
              instituicao?: string;
              nome?: string;
              ordering?: string;
              page?: number;
              pageSize?: number;
              entidade?: string;
              referencia?: string;
              search?: string;
              telemovel?: string;
              tipo?: 'Devolução' | 'Manual' | 'MbWay' | 'RefMultibanco';
              trid?: string;
              valorMax?: number;
              valorMin?: number;
              viagem?: number;
              viagemCampanha?: string;
            } = {
              ...paramsNormalize(columnFilters),
              search: globalFilter ?? '',
              viagemCampanha: campanhaID,
            };
            const url = `${OpenAPI.BASE}/gestao/pagamento/mapa`;
            axios
              .get(url, {
                responseType: 'blob',
                params: {
                  creation_date_after: cenas.creationDateAfter,
                  creation_date_before: cenas.creationDateBefore,
                  data_facturacao_after: cenas.dataFacturacaoAfter,
                  data_facturacao_before: cenas.dataFacturacaoBefore,
                  email: cenas.email,
                  banco: cenas.banco,
                  estado: cenas.estado,
                  grupo: cenas.grupo,
                  instituicao: cenas.instituicao,
                  nome: cenas.nome,
                  ordering: cenas.ordering,
                  page: cenas.page,
                  page_size: cenas.pageSize,
                  referencia: cenas.referencia,
                  search: cenas.search,
                  telemovel: cenas.telemovel,
                  tipo: cenas.tipo,
                  trid: cenas.trid,
                  entidade: cenas.entidade,
                  valor_max: cenas.valorMax,
                  valor_min: cenas.valorMin,
                  viagem: cenas.viagem,
                  viagem__campanha: cenas.viagemCampanha,
                },
              })
              .then(
                (res) => {
                  const blob = new Blob([res.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
                  });
                  FileSaver.saveAs(blob, 'pagamentos.xlsx');
                  setLoading(false);
                  return res;
                },
                (reason) => {
                  msgAPIError(reason);
                  setLoading(false);
                }
              );
          }}
          rightIcon={<IconFileSpreadsheet />}
          leftIcon={<IconDownload />}
          variant='outline'>
          .xlsx
        </Button>
        <Button
          variant={'outline'}
          leftIcon={<IconRefresh />}
          rightIcon={<IconRefresh />}
          onClick={() => {
            setLoading(true);
            PagamentoService.pagamentoActualizarPagamentosRetrieve().then(
              () => {
                fetchData(pagination.pageIndex, pagination.pageSize);
                setLoading(false);
              },
              (reason) => {
                msgAPIError(reason);
                setLoading(false);
              }
            );
          }}>
          Actualizar
        </Button>
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleFiltersButton table={table} />
      </Flex>
    ),
  });

  return (
    <Stack>
      <LoadingOverlay
        visible={loading}
        overlayBlur={2}></LoadingOverlay>
      <MantineReactTable table={tablePag} />
    </Stack>
  );
}
