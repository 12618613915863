/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Em Preparação` - Em Preparacao
 * * `Suspensa` - Suspensa
 * * `Aberta` - Activa
 * * `Finalizada` - Finalizada
 * * `Inscrições Bloqueadas` - Inscricoes Bloqueadas
 */
export enum CampanhaEstadoEnum {
    EM_PREPARA_O = 'Em Preparação',
    SUSPENSA = 'Suspensa',
    ABERTA = 'Aberta',
    FINALIZADA = 'Finalizada',
    INSCRI_ES_BLOQUEADAS = 'Inscrições Bloqueadas',
}
