/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `RefMultibanco` - Ref Mult
 * * `MbWay` - Mb Way
 * * `Manual` - Manual
 * * `Devolução` - Devo
 */
export enum TipoEnum {
    REF_MULTIBANCO = 'RefMultibanco',
    MB_WAY = 'MbWay',
    MANUAL = 'Manual',
    DEVOLU_O = 'Devolução',
}
