import React, { useEffect, useState } from 'react';
import {
  CompraService,
  PagamentoService,
  PagamentosStats,
  ProdutoService,
  ProdutoStats,
  TipoEnum,
} from '../../../slidein_api';
import { Button, Flex, Stack, Table, Text, Title } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { IconCalendarDown, IconCalendarUp, IconFilter, IconTrash } from '@tabler/icons-react';
import { msgAPIError } from '../../helpers';
import { useParams } from 'react-router-dom';

export function BalancoGrupo() {
  const { campanhaID } = useParams();
  const { grupoID } = useParams();
  const [pacoteStats, setPacotesStats] = useState<ProdutoStats[]>([]);
  const [seguroStats, setSeguroStats] = useState<ProdutoStats[]>([]);
  const [actividadeStats, setActividadeStats] = useState<ProdutoStats[]>([]);
  const [suplementoStats, setSuplementoStats] = useState<ProdutoStats[]>([]);
  const [outroStats, setOutroStats] = useState<ProdutoStats[]>([]);
  const [descontoStats, setDescontoStats] = useState<ProdutoStats[]>([]);
  const [pagamentosStats, setPagamentosStats] = useState<PagamentosStats[]>([]);

  const [countPacote, setCountPacote] = useState<number>(0);
  const [countSeguro, setCountSeguro] = useState<number>(0);
  const [countActividade, setCountActividade] = useState<number>(0);
  const [countSuplemento, setCountSuplemento] = useState<number>(0);
  const [countOutro, setCountOutro] = useState<number>(0);
  const [countDesconto, setCountDesconto] = useState<number>(0);

  const [totalPacote, setTotalPacote] = useState<number>(0);
  const [totalSeguro, setTotalSeguro] = useState<number>(0);
  const [totalActividade, setTotalActividade] = useState<number>(0);
  const [totalSuplemento, setTotalSuplemento] = useState<number>(0);
  const [totalOutro, setTotalOutro] = useState<number>(0);
  const [totalDesconto, setTotalDesconto] = useState<number>(0);

  const [totalVendas, setTotalVendas] = useState<number>(0);
  const [totalPagamentos, setTotalPagamentos] = useState<number>(0);

  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);

  const [apply, toggleApply] = useState<boolean>(false);

  useEffect(() => {
    ProdutoService.produtoSeguroStatsList({
      campanha: campanhaID,
      grupo: grupoID,
      startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
      endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
    }).then(
      (sta) => {
        setSeguroStats(sta.filter((x) => x.count > 0));
      },
      (reason) => msgAPIError(reason)
    );
    ProdutoService.produtoPacoteStatsList({
      campanha: campanhaID,
      grupo: grupoID,
      startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
      endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
    }).then(
      (sta) => {
        setPacotesStats(sta.filter((x) => x.count > 0));
      },
      (reason) => msgAPIError(reason)
    );
    ProdutoService.produtoActividadeStatsList({
      campanha: campanhaID,
      grupo: grupoID,
      startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
      endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
    }).then((sta) => {
      setActividadeStats(sta.filter((x) => x.count > 0));
    });
    ProdutoService.produtoSuplementoStatsList({
      campanha: campanhaID,
      grupo: grupoID,
      startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
      endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
    }).then(
      (sta) => {
        setSuplementoStats(sta.filter((x) => x.count > 0));
      },
      (reason) => msgAPIError(reason)
    );
    CompraService.compraOutroStatsList({
      campanha: campanhaID,
      grupo: grupoID,
      startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
      endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
    }).then(
      (sta) => {
        setOutroStats((sta as ProdutoStats[]).filter((x) => x.count > 0));
      },
      (reason) => msgAPIError(reason)
    );
    ProdutoService.produtoDescontoStatsList({
      campanha: campanhaID,
      grupo: grupoID,
      startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
      endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
    }).then(
      (sta) => {
        setDescontoStats(sta.filter((x) => x.count > 0));
      },
      (reason) => msgAPIError(reason)
    );
    PagamentoService.pagamentoStatsList({
      campanha: campanhaID,
      grupo: grupoID,
      startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
      endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
    }).then(
      (pag) => {
        setPagamentosStats(pag);
      },
      (reason) => msgAPIError(reason)
    );
  }, [apply]);

  useEffect(() => {
    setCountPacote(
      pacoteStats.reduce((acc, obj) => {
        return acc + obj.count;
      }, 0)
    );
    setCountSeguro(
      seguroStats.reduce((acc, obj) => {
        return acc + obj.count;
      }, 0)
    );
    setCountActividade(
      actividadeStats.reduce((acc, obj) => {
        return acc + obj.count;
      }, 0)
    );
    setCountSuplemento(
      suplementoStats.reduce((acc, obj) => {
        return acc + obj.count;
      }, 0)
    );
    setCountOutro(
      outroStats.reduce((acc, obj) => {
        return acc + obj.count;
      }, 0)
    );
    setCountDesconto(
      descontoStats.reduce((acc, obj) => {
        return acc + obj.count;
      }, 0)
    );
    setTotalPacote(
      pacoteStats.reduce((acc, obj) => {
        return acc + obj.total;
      }, 0)
    );
    setTotalSeguro(
      seguroStats.reduce((acc, obj) => {
        return acc + obj.total;
      }, 0)
    );
    setTotalActividade(
      actividadeStats.reduce((acc, obj) => {
        return acc + obj.total;
      }, 0)
    );
    setTotalSuplemento(
      suplementoStats.reduce((acc, obj) => {
        return acc + obj.total;
      }, 0)
    );
    setTotalOutro(
      outroStats.reduce((acc, obj) => {
        return acc + obj.total;
      }, 0)
    );
    setTotalDesconto(
      descontoStats.reduce((acc, obj) => {
        return acc + obj.total;
      }, 0)
    );
  }, [seguroStats, pacoteStats, actividadeStats, suplementoStats, outroStats, descontoStats]);

  useEffect(() => {
    const pagT = pagamentosStats.reduce((acc, pag) => {
      if (pag.tipo === TipoEnum.DEVOLU_O) {
        return acc - pag.total;
      }
      return acc + pag.total;
    }, 0);
    const vedT =
      totalPacote + totalSeguro + totalActividade + totalSuplemento + totalOutro - totalDesconto;

    setTotalPagamentos(pagT);
    setTotalVendas(vedT);
  }, [
    pagamentosStats,
    totalPacote,
    totalDesconto,
    totalActividade,
    totalSuplemento,
    totalOutro,
    totalSeguro,
  ]);

  return (
    <Stack>
      <Flex
        direction={'row'}
        justify={'center'}
        align={'end'}
        wrap={'wrap'}
        gap={'md'}
        w={'100%'}>
        <DatePickerInput
          icon={<IconCalendarUp />}
          label={'Inicio'}
          valueFormat='DD/MM/YYYY'
          dropdownType={'modal'}
          w={'14rem'}
          value={startDate}
          onChange={setStartDate}
        />
        <DatePickerInput
          icon={<IconCalendarDown />}
          label={'Fim'}
          w={'10rem'}
          dropdownType={'modal'}
          valueFormat='DD/MM/YYYY'
          value={endDate}
          onChange={setEndDate}
        />
        <Button
          leftIcon={<IconFilter />}
          variant={'outline'}
          onClick={() => {
            toggleApply(!apply);
          }}>
          Filtrar
        </Button>
        <Button
          leftIcon={<IconTrash />}
          variant={'outline'}
          onClick={() => {
            setEndDate(null);
            setStartDate(null);
            toggleApply(!apply);
          }}>
          Limpar
        </Button>
      </Flex>
      <Flex
        direction={'row'}
        justify={'space-evenly'}>
        <div>
          <Title>Vendas</Title>
          <Table
            horizontalSpacing='xs'
            verticalSpacing='xs'
            striped
            withBorder
            withColumnBorders>
            <thead>
              <tr>
                <th>Produto</th>
                <th>Valor</th>
                <th>Quantidade</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  colSpan={4}
                  align={'center'}>
                  <Title order={4}>Alojamentos/Pacotes</Title>
                </td>
              </tr>
              {pacoteStats.map((ps) => (
                <tr key={ps.nome_interno}>
                  <td>{ps.nome_interno}</td>
                  <td>{ps.valor} €</td>
                  <td align={'center'}>{(ps.count || 0).toLocaleString()}</td>
                  <td>{(ps.total || 0).toLocaleString()} €</td>
                </tr>
              ))}
              <tr>
                <td
                  align={'right'}
                  colSpan={2}>
                  <Title order={4}>SubTotal:</Title>
                </td>
                <td>{(countPacote || 0).toLocaleString()}</td>
                <td>{(totalPacote || 0).toLocaleString()}€</td>
              </tr>
              <tr>
                <td
                  colSpan={4}
                  align={'center'}>
                  <Title order={4}>Seguros</Title>
                </td>
              </tr>
              {seguroStats.map((ps) => (
                <tr key={ps.nome_interno}>
                  <td>{ps.nome_interno}</td>
                  <td>{ps.valor || '-'} €</td>
                  <td align={'center'}>{(ps.count || 0).toLocaleString()}</td>
                  <td>{(ps.total || 0).toLocaleString()} €</td>
                </tr>
              ))}
              <tr>
                <td
                  align={'right'}
                  colSpan={2}>
                  <Title order={4}>SubTotal:</Title>
                </td>
                <td align={'center'}>{countSeguro}</td>
                <td>{(totalSeguro || 0).toLocaleString()}€</td>
              </tr>
              <tr>
                <td
                  colSpan={4}
                  align={'center'}>
                  <Title order={4}>Atividades/Opcionais</Title>
                </td>
              </tr>
              {actividadeStats.map((ps) => (
                <tr key={ps.nome_interno}>
                  <td>{ps.nome_interno}</td>
                  <td>{ps.valor || '-'} €</td>
                  <td align={'center'}>{(ps.count || 0).toLocaleString()}</td>
                  <td>{(ps.total || 0).toLocaleString()} €</td>
                </tr>
              ))}
              <tr>
                <td
                  align={'right'}
                  colSpan={2}>
                  <Title order={4}>SubTotal:</Title>
                </td>
                <td align={'center'}>{(countActividade || 0).toLocaleString()}</td>
                <td>{(totalActividade || 0).toLocaleString()}€</td>
              </tr>

              <tr>
                <td
                  colSpan={4}
                  align={'center'}>
                  <Title order={4}>Suplementos</Title>
                </td>
              </tr>
              {suplementoStats.map((ps) => (
                <tr key={ps.nome_interno}>
                  <td>{ps.nome_interno}</td>
                  <td>{ps.valor || '-'} €</td>
                  <td align={'center'}>{(ps.count || 0).toLocaleString()}</td>
                  <td>{(ps.total || 0).toLocaleString()} €</td>
                </tr>
              ))}
              <tr>
                <td
                  align={'right'}
                  colSpan={2}>
                  <Title order={4}>SubTotal:</Title>
                </td>
                <td align={'center'}>{(countSuplemento || 0).toLocaleString()}</td>
                <td>{(totalSuplemento || 0).toLocaleString()}€</td>
              </tr>

              <tr>
                <td
                  colSpan={4}
                  align={'center'}>
                  <Title order={4}>Outros</Title>
                </td>
              </tr>
              {outroStats.map((ps) => (
                <tr key={ps.nome_interno}>
                  <td colSpan={2}>{ps.nome_interno}</td>
                  <td align={'center'}>{(ps.count || 0).toLocaleString()}</td>
                  <td>{(ps.total || 0).toLocaleString()} €</td>
                </tr>
              ))}
              <tr>
                <td
                  align={'right'}
                  colSpan={2}>
                  <Title order={4}>SubTotal:</Title>
                </td>
                <td align={'center'}>{(countOutro || 0).toLocaleString()}</td>
                <td>{(totalOutro || 0).toLocaleString()}€</td>
              </tr>

              <tr>
                <td
                  colSpan={4}
                  align={'center'}>
                  <Title order={4}>Descontos</Title>
                </td>
              </tr>
              {descontoStats.map((ps) => (
                <tr key={ps.nome_interno}>
                  <td>{ps.nome_interno}</td>
                  <td>{ps.valor || '-'} €</td>
                  <td align={'center'}>{(ps.count || 0).toLocaleString()}</td>
                  <td>{(ps.total || 0).toLocaleString()} €</td>
                </tr>
              ))}
              <tr>
                <td
                  align={'right'}
                  colSpan={2}>
                  <Title order={4}>SubTotal:</Title>
                </td>
                <td align={'center'}>{countDesconto}</td>
                <td>{totalDesconto ? `-${(totalDesconto || 0).toLocaleString()}` : 0}€</td>
              </tr>
              <tr>
                <td
                  colSpan={3}
                  align={'right'}>
                  <Title order={2}>Total:</Title>
                </td>
                <td>
                  <Text
                    fz='lg'
                    fw={700}>
                    {(totalVendas || 0).toLocaleString()}€
                  </Text>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div>
          <Title>Pagamentos</Title>
          <Table
            horizontalSpacing='xl'
            verticalSpacing='xs'
            striped
            withBorder
            withColumnBorders>
            <thead>
              <tr>
                <th>Método</th>
                <th>Quantidade</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {pagamentosStats.map((ps) => (
                <tr key={ps.tipo}>
                  <td>{ps.tipo}</td>
                  <td align={'center'}>{(ps.count || 0).toLocaleString()}</td>
                  <td>{(ps.total || 0).toLocaleString()} €</td>
                </tr>
              ))}
              <tr>
                <td
                  colSpan={2}
                  align={'right'}>
                  <Title order={2}>Total:</Title>
                </td>
                <td>
                  <Text
                    fz='lg'
                    fw={700}>
                    {(totalPagamentos || 0).toLocaleString()}€
                  </Text>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div>
          <Title>Totais</Title>
          <Table
            horizontalSpacing='xl'
            verticalSpacing='xs'
            striped
            withBorder
            withColumnBorders>
            <tbody>
              <tr>
                <td align={'right'}>
                  <Title order={4}>Total Vendido</Title>
                </td>
                <td>{(totalVendas || 0).toLocaleString()}€</td>
              </tr>
              <tr>
                <td align={'right'}>
                  <Title order={4}>Total Pago</Title>
                </td>
                <td>{(totalPagamentos || 0).toLocaleString()}€</td>
              </tr>
              <tr>
                <td align={'right'}>
                  <Title order={4}>Total Devido</Title>
                </td>
                <td>{(totalVendas - totalPagamentos || 0).toLocaleString()}€</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Flex>
    </Stack>
  );
}
