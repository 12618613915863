import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { Grupo, GrupoService, Pacote, ProdutoService } from '../../../slidein_api';
import { msgAPIError } from '../../helpers';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import { Button, Group, Modal, Stack, Title, Tooltip } from '@mantine/core';
import {
  IconCircleCheck,
  IconCircleX,
  IconDeviceFloppy,
  IconPlus,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import {
  CheckPermission,
  checkPermission,
  MASTER_ADMIN,
} from '../../../components/CheckPermission';
import { useAuth } from '../../../user-auth/SlideInAuthContext';
import { useParams } from 'react-router-dom';

type GrupoPacotesProps = {
  grupo: Grupo;
  refreshGrupo;
};

function getRows(table) {
  if (table?.getSelectedRowModel() && table.getSelectedRowModel().rows) {
    return table.getSelectedRowModel().rows;
  } else {
    return [];
  }
}

export default function GrupoPacotes({ grupo, refreshGrupo }: GrupoPacotesProps) {
  const { colaborador } = useAuth();
  const { campanhaID } = useParams();
  const [pacotes, setPacotes] = useState<Pacote[]>([]);
  const [addPacotesModalVisible, setAddPacotesModalVisible] = useState<boolean>(false);

  const fetchData = () => {
    if (checkPermission(MASTER_ADMIN, colaborador)) {
      ProdutoService.produtoPacoteList({ campanha: campanhaID }).then(
        (pacotesall) => {
          setPacotes(pacotesall);
        },
        (reason) => msgAPIError(reason)
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = useMemo<MRT_ColumnDef<Pacote>[]>(
    () => [
      { accessorKey: 'nome_interno', header: 'Nome Interno' },
      { accessorKey: 'titulo_cliente', header: 'Nome Cliente' },
      {
        accessorKey: 'activo',
        header: 'Ativo',
        Header: (
          <Tooltip label={'Activo, disponivel para compra'}>
            <div>Act.</div>
          </Tooltip>
        ),
        size: 50,
        Cell: (cell) => {
          return cell.cell.getValue() ? (
            <IconCircleCheck color='green' />
          ) : (
            <IconCircleX color='red' />
          );
        },
      },
      {
        accessorKey: 'valor',
        header: 'Valor',
        size: 100,
        Cell: (cell) => `${cell.cell.getValue()}€`,
      },
    ],
    []
  );
  const removePacotes = () => {
    const all = grupo.pacotes.map((gp) => gp.id);
    const remove = getRows(pacotesTable).map((p) => p.id);
    GrupoService.grupoProdutosPartialUpdate({
      codigo: grupo.codigo,
      requestBody: {
        pacotes: all.filter((a) => remove.findIndex((r) => r == a) == -1),
      },
    }).then(
      () => {
        fetchData();
        refreshGrupo();
        setAddPacotesModalVisible(false);
      },
      (reason) => msgAPIError(reason)
    );
  };
  const adicionarPacotes = () => {
    const pacotesId = grupo.pacotes.map((gp) => gp.id);
    pacotesId.push(...getRows(allPacotesTable).map((p) => p.id));

    GrupoService.grupoProdutosPartialUpdate({
      codigo: grupo.codigo,
      requestBody: {
        pacotes: pacotesId,
      },
    }).then(
      () => {
        fetchData();
        refreshGrupo();
        setAddPacotesModalVisible(false);
      },
      (reason) => msgAPIError(reason)
    );
  };
  const pacotesTable = useMantineReactTable({
    columns,
    data: grupo?.pacotes || [],
    enableMultiRowSelection: checkPermission(MASTER_ADMIN, colaborador),
    enableRowSelection: checkPermission(MASTER_ADMIN, colaborador),
    enableSelectAll: checkPermission(MASTER_ADMIN, colaborador),
    selectAllMode: 'all',
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: 'pointer',
      },
      onClick: row.getToggleSelectedHandler(),
    }),
    getRowId: (originalRow) => originalRow.id.toString(),
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFilters: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enablePagination: false,
    positionToolbarAlertBanner: 'none',
    localization: MRT_Localization_PT,
    initialState: {
      density: 'xs',
    },
    renderTopToolbarCustomActions: () => (
      <Group>
        <CheckPermission
          roles={MASTER_ADMIN}
          goHome={false}>
          <Button
            leftIcon={<IconTrash />}
            variant={'outline'}
            color={'red'}
            disabled={getRows(pacotesTable).length <= 0}
            onClick={() => {
              removePacotes();
            }}>
            Remover
          </Button>
          <Button
            leftIcon={<IconPlus />}
            variant={'outline'}
            onClick={() => {
              setAddPacotesModalVisible(true);
            }}>
            Adicionar
          </Button>
        </CheckPermission>
      </Group>
    ),
  });

  const allPacotesTable = useMantineReactTable<Pacote>({
    columns,
    data: pacotes || [],
    enableSelectAll: true,
    selectAllMode: 'all',
    getRowId: (originalRow) => originalRow.id.toString(),
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    positionToolbarAlertBanner: 'none',
    enableHiding: false,
    enablePagination: false,
    localization: MRT_Localization_PT,
    initialState: {
      density: 'xs',
    },
    enableRowSelection: (row) => grupo.pacotes.findIndex((gp) => gp.id == row.original.id) == -1,
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        opacity: grupo.pacotes.findIndex((gp) => gp.id == row.original.id) == -1 ? '100%' : '35%',
        cursor: 'pointer',
      },
      onClick: row.getToggleSelectedHandler(),
    }),
  });

  return (
    <Stack>
      <MantineReactTable table={pacotesTable} />
      <Modal
        centered
        title={'Escolher Alojamentos/Pacotes:'}
        opened={addPacotesModalVisible}
        size={'auto'}
        onClose={() => {
          setAddPacotesModalVisible(false);
        }}>
        <Title order={4}>
          {getRows(allPacotesTable).length}&nbsp;Alojamentos/Pacotes Selecionados.
        </Title>
        <MantineReactTable table={allPacotesTable} />
        <Group
          position='right'
          mt='md'>
          <Button
            color={'yellow'}
            variant={'outline'}
            leftIcon={<IconX />}
            type='button'
            onClick={() => {
              setAddPacotesModalVisible(false);
            }}>
            Cancelar
          </Button>
          <Button
            variant={'outline'}
            disabled={getRows(allPacotesTable).length == 0}
            onClick={() => {
              adicionarPacotes();
              setAddPacotesModalVisible(false);
            }}
            leftIcon={<IconDeviceFloppy />}
            type='button'>
            Adicionar
          </Button>
        </Group>
      </Modal>
    </Stack>
  );
}
