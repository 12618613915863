import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import {
  ApiError,
  Grupo,
  GrupoService,
  OpenAPI,
  ProdutoService,
  Quarto,
  QuartoRequest,
  ViagemQuarto,
  ViagemQuartoItem,
  ViagemService,
} from '../../../slidein_api';
import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import {
  ActionIcon,
  Box,
  Button,
  Group,
  List,
  Modal,
  MultiSelect,
  NumberInput,
  Select,
  SelectItem,
  Stack,
  Switch,
  Text,
  TextInput,
  ThemeIcon,
  Title,
  Tooltip,
} from '@mantine/core';
import {
  IconCheck,
  IconDeviceFloppy,
  IconDownload,
  IconEdit,
  IconFileSpreadsheet,
  IconListDetails,
  IconPlus,
  IconTrash,
  IconUser,
  IconUsersGroup,
  IconX,
} from '@tabler/icons-react';
import { msgAPIError } from '../../helpers';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import { CheckPermission, MASTER } from '../../../components/CheckPermission';
import axios from 'axios';
import FileSaver from 'file-saver';

type EditQuartoProps = {
  fetchData;
  quarto: Quarto;
  semQuarto: ViagemQuarto[];
};

const EditQuarto = ({ semQuarto, quarto, fetchData }: EditQuartoProps) => {
  const [visible, setVisible] = useState<boolean>(false);

  const viaItems = [
    ...quarto.viajantes.map((q) => {
      return {
        value: q.id.toString(),
        label: `${q.cliente.nome_simples}`,
      } as SelectItem;
    }),
    ...semQuarto.map((q) => {
      return {
        value: q.id.toString(),
        label: `${q.cliente.nome_simples}`,
      } as SelectItem;
    }),
  ];

  const [temp, setTemp] = useState<string[]>(
    quarto.viajantes.map((q) => {
      return q.id.toString();
    }) || []
  );

  const guardarQuarto = () => {
    //deal with removal
    quarto.viajantes.forEach((vi) => {
      if (temp.findIndex((t) => t === vi.id.toString()) == -1) {
        ViagemService.viagemQuartoUpdate({
          id: vi.id,
          requestBody: { quarto: null },
        }).then(
          () => {
            fetchData();
            setVisible(false);
          },
          (reason) => {
            msgAPIError(reason);
          }
        );
      }
    });

    temp.forEach((tt) => {
      ViagemService.viagemQuartoUpdate({
        id: parseInt(tt),
        requestBody: { quarto: quarto.id },
      }).then(
        () => {
          fetchData();
          setVisible(false);
        },
        (reason) => {
          msgAPIError(reason);
        }
      );
    });
  };
  if (!visible) {
    return (
      <Tooltip label={'Editar Ocupantes do Quarto'}>
        <ActionIcon onClick={() => setVisible(true)}>
          <IconListDetails />
        </ActionIcon>
      </Tooltip>
    );
  }

  return (
    <Modal
      opened={true}
      onClose={() => setVisible(false)}
      centered
      closeOnClickOutside={false}
      closeOnEscape={false}
      withCloseButton={false}
      id={quarto.id.toString()}
      padding={'md'}
      size={'md'}
      withOverlay={true}>
      <Title order={3}>Editar Ocupantes Quarto: {quarto.nome}</Title>
      <Text>
        Lotação:{quarto.lotacao}
        {temp.length == quarto.lotacao && (
          <Text
            fw={'bold'}
            color={'green'}>
            Completo!
          </Text>
        )}
      </Text>
      <MultiSelect
        icon={<IconUsersGroup />}
        value={temp}
        maxSelectedValues={quarto.lotacao}
        onChange={setTemp}
        placeholder='Escolher ocupantes'
        withinPortal={true}
        data={viaItems}
        size={'md'}
      />
      <Group
        position='right'
        mt='md'>
        <Button
          type='button'
          onClick={() => {
            guardarQuarto();
          }}>
          Guardar
        </Button>
        <Button
          type='button'
          onClick={() => setVisible(false)}>
          Cancelar
        </Button>
      </Group>
    </Modal>
  );
};

type ListaOcupantesProps = {
  campanha_id: string;
  viajantes: ViagemQuartoItem[];
};

function ListaOcupantes({ campanha_id, viajantes }: ListaOcupantesProps) {
  return (
    <List
      spacing='xs'
      size='sm'
      center
      icon={
        <ThemeIcon
          color='green'
          size={24}
          radius='xl'>
          <IconUser size='1rem' />
        </ThemeIcon>
      }>
      {viajantes.map((vi) => {
        return (
          <List.Item key={vi.id}>
            <Group>
              <Link to={'/campanha/' + campanha_id + '/viagem/' + vi.id}>
                {vi.cliente.nome_simples}-{vi.alojamento}
              </Link>
            </Group>
          </List.Item>
        );
      })}
    </List>
  );
}

interface QuartoGrupoProps {
  grupo: Grupo;
  refreshGrupo;
}

export function Quartos({ grupo, refreshGrupo }: QuartoGrupoProps) {
  const { grupoID } = useParams();
  const { campanhaID } = useParams();
  const [viagensLivres, setViagenslivres] = useState<ViagemQuarto[]>([]);
  const [addPacoteModalVisible, setAddPacoteModalVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [viagens, setViagens] = useState<ViagemQuarto[]>([]);
  const [quartos, setQuartos] = useState<Quarto[]>([]);
  const [roomDialogVisible, setRoomDialogVisible] = useState<boolean>(false);
  const [newRoomDialogVisible, setNewRoomDialogVisible] = useState<boolean>(false);
  const [newQuarto, setNewQuarto] = useState<boolean>(false);

  const [pacotes, setPacotes] = useState<SelectItem[]>([]);
  const [nrCamas, setNrCamas] = useState<number>(0);

  const fetchData = () => {
    setIsLoading(true);
    ProdutoService.produtoPacoteList({ campanha: campanhaID }).then(
      (value) => {
        setPacotes(
          value.map((pa) => {
            return { label: pa.nome_interno, value: pa.id.toString() };
          })
        );
      },
      (reason) => {
        msgAPIError(reason);
      }
    );
    GrupoService.grupoQuartoList({ grupo: grupoID }).then(
      (value) => {
        setQuartos(value);

        setIsLoading(true);
        ViagemService.viagemQuartoList({ grupo: grupoID }).then(
          (viqua) => {
            setViagens(viqua);
            setViagenslivres(viqua.filter((vi) => vi.quarto === null));
            setIsLoading(false);
          },
          (reason) => {
            msgAPIError(reason);
            setIsLoading(false);
          }
        );
      },
      (reason) => {
        msgAPIError(reason);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let nrcamas = 0;
    quartos.forEach((qt) => {
      nrcamas += qt.lotacao;
    });
    setNrCamas(nrcamas);
  }, [quartos]);

  const quartoForm = useForm<Quarto | QuartoRequest>({
    initialValues: {
      nr_quarto: '',
      lotacao: undefined,
      grupo: grupoID,
      pacote: undefined,
    },
    validate: yupResolver(
      Yup.object({
        lotacao: Yup.number().required('Obrigatório'),
        pacote: Yup.string().required('Obrigatório'),
      })
    ),
  });
  const newQuartoForm = useForm({
    initialValues: {
      nr_quarto: '',
      lotacao: undefined,
      grupo: grupoID,
      pacote: undefined,
      multiplos: 1,
    },
    validate: yupResolver(
      Yup.object({
        lotacao: Yup.number().required('Obrigatório'),
        pacote: Yup.string().required('Obrigatório'),
        multiplos: Yup.number().min(1).required('Obrigatório'),
      })
    ),
  });
  const saveNewQuartos = (fvalues) => {
    for (let i = 0; i < fvalues.multiplos; i++) {
      const qua: QuartoRequest = {
        lotacao: fvalues.lotacao,
        grupo: grupoID,
        pacote: fvalues.pacote,
      };
      if (fvalues.multiplos == 1 && fvalues.nr_quarto) {
        qua.nr_quarto = fvalues.nr_quarto;
      }

      GrupoService.grupoQuartoCreate({
        requestBody: qua,
      }).then(
        () => {
          fetchData();
          setNewRoomDialogVisible(false);
        },
        (reason: ApiError) => {
          newQuartoForm.setErrors(reason.body);
        }
      );
    }
  };

  const saveQuarto = (qua) => {
    if (newQuarto) {
      GrupoService.grupoQuartoCreate({
        requestBody: qua,
      }).then(
        () => {
          fetchData();
          setRoomDialogVisible(false);
        },
        (reason: ApiError) => {
          quartoForm.setErrors(reason.body);
        }
      );
    } else {
      GrupoService.grupoQuartoUpdate({ id: qua.id, requestBody: qua }).then(
        () => {
          fetchData();
          setRoomDialogVisible(false);
        },
        (reason: ApiError) => {
          quartoForm.setErrors(reason.body);
        }
      );
    }
  };

  function deleteQuarto(quarto: Quarto) {
    if (quarto.viajantes.length > 0) {
      return;
    }

    if (confirm(`Confirma que deseja remover o Quarto: ${quarto.nome} ?`)) {
      GrupoService.grupoQuartoDestroy({ id: quarto.id }).then(
        () => {
          fetchData();
        },
        (reason) => msgAPIError(reason)
      );
    }
  }

  const columns_quartos = useMemo<MRT_ColumnDef<Quarto>[]>(
    () => [
      { accessorKey: 'nome', header: 'Quarto' },
      // { accessorKey: 'lotacao', header: 'Lotação ' },
      {
        accessorFn: (x) => (x.viajantes || []).length,
        header: 'Nrº Viajantes/Lotação',
        enableColumnFilter: false,
        size: 50,
        Cell: ({ cell }: { cell }) => {
          if ((cell.row.original.viajantes || []).length === cell.row.original.lotacao) {
            return (
              <Text
                fw={800}
                c={'green'}>
                {(cell.row.original.viajantes || []).length}/{cell.row.original.lotacao}
              </Text>
            );
          } else {
            return (
              <Text
                fw={800}
                c={'red'}>
                {(cell.row.original.viajantes || []).length}/{cell.row.original.lotacao}
              </Text>
            );
          }
        },
      },
      {
        accessorFn: (row) =>
          (row.viajantes || []).reduce((acc, x) => acc + ' ' + x.cliente.nome_simples, ''),
        filterVariant: 'text',
        filterFn: 'contains',
        header: 'Ocupantes',
        enableFilterMatchHighlighting: true,
        Cell: (c) => {
          return (c.row.original.viajantes || []).length === 0 ? (
            <b>Vazio!</b>
          ) : (
            <ListaOcupantes
              campanha_id={campanhaID}
              viajantes={c.cell.row.original.viajantes}
            />
          );
        },
      },
    ],

    []
  );

  const quartosTable = useMantineReactTable({
    data: quartos || [],
    columns: columns_quartos,
    initialState: {
      density: 'xs',
    },
    state: {
      isLoading,
    },
    enablePagination: false,
    enableRowActions: true,
    positionActionsColumn: 'last',
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <EditQuarto
          semQuarto={viagensLivres.filter(
            (vi) => vi.alojamento_id === row.original.pacote.toString()
          )}
          quarto={row.original}
          fetchData={fetchData}
        />
        <Tooltip label={'Editar Detalhes'}>
          <ActionIcon
            color='yellow'
            onClick={() => {
              quartoForm.setValues(row.original);
              setNewQuarto(false);
              setRoomDialogVisible(true);
            }}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label={'Apagar Quarto'}>
          <ActionIcon
            disabled={row.original.viajantes.length > 0}
            color='red'
            onClick={() => deleteQuarto(row.original)}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: () => (
      <Group>
        <Button
          leftIcon={<IconPlus />}
          onClick={() => {
            quartoForm.reset();
            setNewQuarto(true);
            setNewRoomDialogVisible(true);
          }}
          variant='outline'>
          Criar Quarto
        </Button>
        <Switch
          label={'Mostar aos Clientes'}
          size={'sm'}
          onLabel={<IconCheck />}
          offLabel={<IconX />}
          checked={grupo?.mostrar_quartos}
          onChange={(event) => {
            GrupoService.grupoPartialUpdate({
              codigo: grupoID,
              requestBody: {
                mostrar_quartos: event.currentTarget.checked,
              },
            }).then(
              () => {
                refreshGrupo();
              },
              (reason) => {
                msgAPIError(reason);
              }
            );
          }}></Switch>
        <Switch
          label={'Editável Chefe de Grupo'}
          size={'sm'}
          onLabel={<IconCheck />}
          offLabel={<IconX />}
          checked={grupo?.chefe_editavel}
          onChange={(event) => {
            GrupoService.grupoPartialUpdate({
              codigo: grupoID,
              requestBody: {
                chefe_editavel: event.currentTarget.checked,
              },
            }).then(
              () => {
                refreshGrupo();
              },
              (reason) => {
                msgAPIError(reason);
              }
            );
          }}></Switch>
        <CheckPermission
          roles={MASTER}
          goHome={false}>
          <Button
            color='lightblue'
            onClick={() => {
              const url = `${OpenAPI.BASE}/gestao/grupo/simples/${grupoID}/mapa_quartos`;
              axios
                .get(url, {
                  responseType: 'blob',
                  params: { codigo: grupoID },
                })
                .then(
                  (res) => {
                    const blob = new Blob([res.data], {
                      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
                    });
                    FileSaver.saveAs(blob, `quartos_${grupoID}.xlsx`);
                    return res;
                  },
                  (reason) => {
                    msgAPIError(reason);
                  }
                );
            }}
            rightIcon={<IconFileSpreadsheet />}
            leftIcon={<IconDownload />}
            variant='outline'>
            Quartos.xlsx
          </Button>
        </CheckPermission>
      </Group>
    ),
    mantineTableProps: {
      highlightOnHover: true,
      withColumnBorders: true,
    },
    enableFullScreenToggle: false,
    enableGlobalFilter: false,
    localization: MRT_Localization_PT,
  });

  const columnsPacote = useMemo<MRT_ColumnDef<SelectItem>[]>(
    () => [
      {
        accessorKey: 'nome_interno',
        header: 'Nome',
      },
    ],
    []
  );

  function getRows(table) {
    if (table?.getSelectedRowModel().rows) {
      return table.getSelectedRowModel().rows;
    } else {
      return [];
    }
  }

  const pacotesTable = useMantineReactTable({
    columns: columnsPacote,
    data: pacotes || [],
    enableRowSelection: true,
    enableMultiRowSelection: false,
    positionToolbarAlertBanner: 'none',
    enableGlobalFilter: false,
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
    }),
    enableDensityToggle: false,
    enableFilters: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enableBottomToolbar: false,
    enableTopToolbar: false,
    enablePagination: false,
    localization: MRT_Localization_PT,
    initialState: {
      density: 'xs',
      showGlobalFilter: true,
    },
  });

  return (
    <Stack>
      <Modal
        centered
        title={'Escolher Alojamento/Pacote:'}
        opened={addPacoteModalVisible}
        size={'auto'}
        onClose={() => {
          setAddPacoteModalVisible(false);
        }}>
        <MantineReactTable table={pacotesTable} />
        <Group
          position='right'
          mt='md'>
          <Button
            color={'yellow'}
            variant={'outline'}
            leftIcon={<IconX />}
            type='button'
            onClick={() => {
              setAddPacoteModalVisible(false);
              setRoomDialogVisible(true);
            }}>
            Cancelar
          </Button>
          <Button
            variant={'outline'}
            disabled={getRows(pacotesTable).length == 0}
            onClick={() => {
              quartoForm.setFieldValue(
                'pacote',
                pacotesTable.getSelectedRowModel().rows[0].original.id
              );
              setAddPacoteModalVisible(false);
              setRoomDialogVisible(true);
            }}
            leftIcon={<IconDeviceFloppy />}
            type='button'>
            Escolher
          </Button>
        </Group>
      </Modal>
      <Modal
        opened={roomDialogVisible}
        onClose={() => setRoomDialogVisible(false)}
        closeOnEscape={false}
        withCloseButton={false}
        title={'Editar Quarto'}>
        <form onSubmit={quartoForm.onSubmit(saveQuarto)}>
          <Select
            required
            disabled={true}
            label={'Alojamento/Pacote'}
            {...quartoForm.getInputProps('pacote')}
            value={quartoForm.values.pacote?.toString()}
            data={pacotes}></Select>
          <TextInput
            label={'Nrº Quarto:'}
            {...quartoForm.getInputProps('nr_quarto')}
          />
          <NumberInput
            required
            disabled={true}
            label={'Lotação Máxima*:'}
            {...quartoForm.getInputProps('lotacao')}
            min={0}></NumberInput>
          <Group
            position='right'
            mt='md'>
            <Button
              color={'yellow'}
              variant={'outline'}
              leftIcon={<IconX />}
              type='button'
              onClick={() => setRoomDialogVisible(false)}>
              Cancelar
            </Button>
            <Button
              type='submit'
              variant={'outline'}
              leftIcon={<IconDeviceFloppy />}>
              Guardar
            </Button>
          </Group>
        </form>
      </Modal>
      <Modal
        opened={newRoomDialogVisible}
        onClose={() => setNewRoomDialogVisible(false)}
        closeOnEscape={false}
        withCloseButton={false}
        title={'Criar Quarto'}>
        <form onSubmit={newQuartoForm.onSubmit(saveNewQuartos)}>
          <Select
            required
            label={'Alojamento/Pacote'}
            {...newQuartoForm.getInputProps('pacote')}
            value={newQuartoForm.values.pacote?.toString()}
            data={pacotes}></Select>
          {newQuartoForm.values.multiplos == 1 && (
            <TextInput
              label={'Nrº Quarto:'}
              disabled={newQuartoForm.values.multiplos > 1}
              {...newQuartoForm.getInputProps('nr_quarto')}
            />
          )}
          <NumberInput
            required
            label={'Lotação Máxima*:'}
            {...newQuartoForm.getInputProps('lotacao')}
            min={0}></NumberInput>
          <NumberInput
            required
            label={'Criar multiplos*:'}
            {...newQuartoForm.getInputProps('multiplos')}
            min={1}></NumberInput>
          <Group
            position='right'
            mt='md'>
            <Button
              color={'yellow'}
              variant={'outline'}
              leftIcon={<IconX />}
              type='button'
              onClick={() => setNewRoomDialogVisible(false)}>
              Cancelar
            </Button>
            <Button
              type='submit'
              variant={'outline'}
              leftIcon={<IconDeviceFloppy />}>
              Criar
            </Button>
          </Group>
        </form>
      </Modal>

      {grupo !== undefined &&
        ((viagens || []).length > nrCamas ? (
          <Title
            order={2}
            color={'red'}
            fw={700}>
            Quartos: Lotação Máxima:{nrCamas} / Nrº de Viajantes:{(viagens || []).length}
          </Title>
        ) : (
          <Title order={2}>
            Quartos: Lotação Máxima:{nrCamas} / Nrº de Viajantes:{(viagens || []).length}
          </Title>
        ))}
      <MantineReactTable table={quartosTable} />
    </Stack>
  );
}
