import {
  Button,
  Checkbox,
  Divider,
  Flex,
  LoadingOverlay,
  Modal,
  NumberInput,
  Stack,
} from '@mantine/core';
import { useParams } from 'react-router-dom';
import {
  IconLock,
  IconLockOpen,
  IconLockOpenOff,
  IconLockPlus,
  IconTrashX,
  IconUserOff,
  IconUsersPlus,
} from '@tabler/icons-react';
import React, { useState } from 'react';
import { CampanhaService } from '../../../slidein_api';
import { msgAPIError } from '../../helpers';
import { notifications } from '@mantine/notifications';

export function CampanhaEstados() {
  const { campanhaID } = useParams();
  const [valor, setValor] = useState<number | ''>('');
  const [iChefe, setIChefe] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [bVisible, setBVisible] = useState<boolean>(false);
  const [dVisible, setDVisible] = useState<boolean>(false);

  return (
    <>
      <LoadingOverlay visible={loading}></LoadingOverlay>
      <Modal
        title={'Bloquear:'}
        opened={bVisible}
        onClose={() => {
          setBVisible(false);
        }}>
        <NumberInput
          label='Bloquear viagens com pagamentos inferiores ou iguais a :'
          placeholder='<='
          value={valor}
          min={0}
          onChange={setValor}
          data-autofocus
        />
        <Checkbox
          mt={'md'}
          label='Incluir Chefe de Grupo?'
          color='green'
          checked={iChefe}
          onChange={(event) => setIChefe(event.currentTarget.checked)}
          size='md'
        />
        <Button
          fullWidth
          disabled={valor === ''}
          onClick={() => {
            setLoading(true);
            CampanhaService.campanhaBloquearFaltaPagamentosRetrieve({
              identificador: campanhaID,
              ichefe: iChefe,
              valor: valor != '' ? valor : 0,
            }).then(
              () => {
                notifications.show({
                  title: 'Bloqueadas com sucesso ',
                  message: `Viagens bloqueadas `,
                  withCloseButton: true,
                  icon: <IconTrashX />,
                  color: 'green',
                });
                setLoading(false);
                setBVisible(false);
              },
              (reason) => {
                setLoading(false);
                msgAPIError(reason);
                setBVisible(false);
              }
            );
          }}
          mt='md'>
          Bloquear
        </Button>
      </Modal>
      <Modal
        title={'Desbloquear:'}
        opened={dVisible}
        onClose={() => {
          setDVisible(false);
        }}>
        <NumberInput
          label='Desbloquear viagens com pagamentos superiores ou iguais a :'
          placeholder='<='
          value={valor}
          min={0}
          onChange={setValor}
          data-autofocus
        />
        <Checkbox
          label='Incluir Chefe de Grupo?'
          color='green'
          mt={'md'}
          checked={iChefe}
          onChange={(event) => setIChefe(event.currentTarget.checked)}
          size='md'
        />
        <Button
          fullWidth
          disabled={valor === ''}
          onClick={() => {
            setLoading(true);
            CampanhaService.campanhaDesbloquearFaltaDePagamentoRetrieve({
              identificador: campanhaID,
              ichefe: iChefe,
              valor: valor != '' ? valor : 0,
            }).then(
              () => {
                setLoading(false);
                setDVisible(false);
                notifications.show({
                  title: 'Desbloqueadas com sucesso ',
                  message: `Viagens Desbloqueadas `,
                  withCloseButton: true,
                  icon: <IconTrashX />,
                  color: 'green',
                });
              },
              (reason) => {
                setLoading(false);
                msgAPIError(reason);
                setDVisible(false);
              }
            );
          }}
          mt='md'>
          Desbloquear
        </Button>
      </Modal>
      <Flex
        mt={'md'}
        direction={'column'}
        gap={'xl'}
        justify={'center'}>
        <Stack>
          <Button
            variant={'outline'}
            leftIcon={<IconLock />}
            onClick={() => {
              setBVisible(true);
            }}>
            Bloquear viagens com pagamentos em atraso.
          </Button>
          <Button
            variant={'outline'}
            leftIcon={<IconLockOpen />}
            onClick={() => {
              setDVisible(true);
            }}>
            Desbloquear viagens com pagamentos em atraso
          </Button>
        </Stack>
        <Divider></Divider>
        <Stack>
          <Button
            variant={'outline'}
            color={'yellow'}
            leftIcon={<IconLockPlus />}
            onClick={() => {
              CampanhaService.campanhaGruposActivoToBlockinscRetrieve({
                identificador: campanhaID,
              }).then(
                () => {
                  setLoading(false);
                  notifications.show({
                    title: 'Estados alterados',
                    message: `Incrições Fechadas`,
                    withCloseButton: true,
                    icon: <IconUserOff />,
                    color: 'green',
                  });
                },
                (reason) => {
                  setLoading(false);
                  msgAPIError(reason);
                }
              );
            }}>
            Bloquear novas incrições ao nível dos grupos (Estado:Activo ⟶ Inscrições Bloqueadas)
          </Button>
          <Button
            variant={'outline'}
            leftIcon={<IconLockOpenOff />}
            color={'yellow'}
            onClick={() => {
              CampanhaService.campanhaGruposBlockinscToActivoRetrieve({
                identificador: campanhaID,
              }).then(
                () => {
                  setLoading(false);
                  notifications.show({
                    title: 'Estados alterados',
                    message: `Incrições Abertas`,
                    withCloseButton: true,
                    icon: <IconUsersPlus />,
                    color: 'green',
                  });
                },
                (reason) => {
                  setLoading(false);
                  msgAPIError(reason);
                }
              );
            }}>
            Desbloquear novas incrições ao nível dos grupos (Estado: Inscrições Bloqueadas ⟶ Activo)
          </Button>
        </Stack>
      </Flex>
    </>
  );
}
