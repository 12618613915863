import React, { useEffect } from 'react';
import { PacoteManager } from './PacoteManager';
import { ActividadeManager } from './ActividadeManager';
import { DescontoManager } from './DescontoManager';
import { SeguroManager } from './SeguroManager';
import { useBread } from '../../../layout/context/BreadContext';
import { checkPermission, MASTER_FINANCE } from '../../../components/CheckPermission';
import { useAuth } from '../../../user-auth/SlideInAuthContext';
import { Tabs } from '@mantine/core';
import {
  IconConfetti,
  IconHotelService,
  IconMap,
  IconPackage,
  IconPackages,
  IconReceipt2,
  IconShieldLock,
} from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { SuplementoManager } from './SuplementoManager';

export function ProdutosManager() {
  const { colaborador } = useAuth();
  const { setBread } = useBread();
  const { campanhaID } = useParams();

  useEffect(() => {
    setBread({
      campanha: {
        title: campanhaID,
        href: '/campanha/' + campanhaID,
        icon: <IconMap />,
      },
      accao: {
        title: 'Produtos',
        icon: <IconPackages />,
      },
    });
  }, []);

  return (
    <Tabs defaultValue='pacotes'>
      <Tabs.List>
        <Tabs.Tab
          value='pacotes'
          icon={<IconPackage />}>
          Alojamentos/Pacotes
        </Tabs.Tab>
        <Tabs.Tab
          value='seguros'
          icon={<IconShieldLock />}>
          Seguros
        </Tabs.Tab>
        <Tabs.Tab
          value='actividades'
          icon={<IconConfetti />}>
          Actividades/Opcionais
        </Tabs.Tab>
        <Tabs.Tab
          value='suplementos'
          icon={<IconHotelService />}>
          Suplementos
        </Tabs.Tab>
        {checkPermission(MASTER_FINANCE, colaborador) && (
          <Tabs.Tab
            value='descontos'
            icon={<IconReceipt2 />}>
            Descontos
          </Tabs.Tab>
        )}
      </Tabs.List>
      <Tabs.Panel value='pacotes'>
        <PacoteManager />
      </Tabs.Panel>
      <Tabs.Panel value='seguros'>
        <SeguroManager />
      </Tabs.Panel>
      <Tabs.Panel value='actividades'>
        <ActividadeManager />
      </Tabs.Panel>
      <Tabs.Panel value='suplementos'>
        <SuplementoManager />
      </Tabs.Panel>
      {checkPermission(MASTER_FINANCE, colaborador) && (
        <Tabs.Panel value='descontos'>
          <DescontoManager />
        </Tabs.Panel>
      )}
    </Tabs>
  );
}
