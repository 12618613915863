import { Button, Flex, Group, LoadingOverlay, Space, Table, Tabs, Text } from '@mantine/core';
import React, { useEffect, useMemo, useState } from 'react';
import {
  CampanhaService,
  OpenAPI,
  PacoteQuartos,
  Quarto,
  QuartosService,
} from '../../../slidein_api';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { msgAPIError } from '../../helpers';
import {
  IconDownload,
  IconFileSpreadsheet,
  IconHome2,
  IconPlane,
  IconUsersMinus,
  IconUsersPlus,
} from '@tabler/icons-react';
import { MantineReactTable, MRT_ColumnDef } from 'mantine-react-table';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import { useBread } from '../../../layout/context/BreadContext';
import { CheckPermission, MASTER } from '../../../components/CheckPermission';
import axios from 'axios';
import FileSaver from 'file-saver';

export function QuartosLista() {
  const { campanhaID } = useParams();
  const { setBread } = useBread();
  useEffect(() => {
    setBread({
      campanha: {
        title: campanhaID,
        href: '/campanha/' + campanhaID,
        icon: <IconPlane />,
      },
      accao: {
        title: 'Quartos',
        icon: <IconHome2 />,
      },
    });
  }, []);

  const [quartos, setQuartos] = useState<PacoteQuartos[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const fetchQuartos = () => {
    setLoading(true);
    QuartosService.quartosList({ campanha: campanhaID }).then(
      (value) => {
        setLoading(false);
        setQuartos(value);
      },
      (reason) => {
        setLoading(false);
        msgAPIError(reason);
      }
    );
  };

  useEffect(() => {
    fetchQuartos();
  }, []);

  const columnsQuarto = useMemo<MRT_ColumnDef<Quarto>[]>(
    () => [
      {
        accessorKey: 'grupo',
        header: 'Grupo',
        Cell: ({ row }: { row }) => (
          <Link to={'/campanha/' + campanhaID + '/grupo/' + row.original.grupo}>
            {row.original.grupo}
          </Link>
        ),
      },
      {
        accessorKey: 'instituicao_nome',
        header: 'Instituição',
      },
      {
        accessorKey: 'nome',
        header: 'Número de Quarto',
      },
      {
        accessorFn: (x) => (x.viajantes || []).length,
        header: 'Lotação',
        enableColumnFilter: false,
        size: 50,
        Cell: ({ cell }: { cell }) => {
          if (cell.row.original.viajantes.length === cell.row.original.lotacao) {
            return (
              <Text
                fw={800}
                c={'green'}>
                {cell.row.original.viajantes.length}/{cell.row.original.lotacao}
              </Text>
            );
          } else {
            return (
              <Text
                fw={800}
                c={'red'}>
                {cell.row.original.viajantes.length}/{cell.row.original.lotacao}
              </Text>
            );
          }
        },
      },
      {
        accessorKey: 'viajantes',
        header: 'Ocupantes',
        size: 50,
        filterFn: (row, id, filterValue) => {
          let ret = false;
          row.original.viajantes.forEach((vi) => {
            if (
              vi.cliente.nome_simples.toLowerCase().includes(filterValue.toLowerCase()) ||
              vi.cliente.email.toLowerCase().includes(filterValue.toLowerCase()) ||
              vi.cliente.contacto_telemovel.toLowerCase().includes(filterValue.toLowerCase())
            ) {
              ret = true;
            }
          });
          return ret;
        },
        Cell: ({ cell }: { cell }) => (
          <Table
            verticalSpacing='xs'
            fontSize='xs'
            striped
            withBorder
            withColumnBorders>
            <tbody>
              {cell.row.original.viajantes.map((vi) => (
                <tr key={vi.id}>
                  <td>
                    <Link to={'/campanha/' + campanhaID + '/viagem/' + vi.id}>
                      {vi.cliente.nome_simples}
                    </Link>
                  </td>
                  <td>{vi.cliente.email}</td>
                  <td>{vi.cliente.contacto_telemovel}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ),
      },
    ],

    []
  );

  return (
    <>
      <LoadingOverlay visible={loading}></LoadingOverlay>
      <Flex
        direction={'row'}
        mb={'xs'}
        justify={'end'}>
        <Group>
          <CheckPermission
            roles={MASTER}
            goHome={false}>
            <Button
              color='lightblue'
              size='md'
              onClick={() => {
                setLoading(true);
                const url = `${OpenAPI.BASE}/gestao/campanha/${campanhaID}/mapa_quartos`;
                axios
                  .get(url, {
                    responseType: 'blob',
                    params: { identificador: campanhaID },
                  })
                  .then(
                    (res) => {
                      const blob = new Blob([res.data], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
                      });
                      FileSaver.saveAs(blob, `quartos_${campanhaID}.xlsx`);
                      setLoading(false);
                      return res;
                    },
                    (reason) => {
                      msgAPIError(reason);
                      setLoading(false);
                    }
                  );
              }}
              rightIcon={<IconFileSpreadsheet />}
              leftIcon={<IconDownload />}
              variant='outline'>
              Quartos.xlsx
            </Button>
            <Space></Space>
            <Button.Group>
              <Button
                color='lightblue'
                size='md'
                onClick={() => {
                  setLoading(true);
                  CampanhaService.campanhaQuartosTodosRetrieve({ identificador: campanhaID }).then(
                    (res) => {
                      setLoading(false);
                    },
                    (reason) => {
                      msgAPIError(reason);
                      setLoading(false);
                    }
                  );
                }}
                rightIcon={<IconUsersPlus />}
                variant='outline'>
                Mostrar a todos o grupos
              </Button>
              <Button
                color='lightblue'
                size='md'
                onClick={() => {
                  setLoading(true);
                  CampanhaService.campanhaQuartosNenhumRetrieve({ identificador: campanhaID }).then(
                    () => {
                      setLoading(false);
                    },
                    (reason) => {
                      msgAPIError(reason);
                      setLoading(false);
                    }
                  );
                }}
                rightIcon={<IconUsersMinus />}
                variant='outline'>
                Esconder de todos o grupos
              </Button>
            </Button.Group>
            <Button.Group>
              <Button
                color='lightblue'
                size='md'
                onClick={() => {
                  setLoading(true);
                  CampanhaService.campanhaChefeEditavelTodosRetrieve({
                    identificador: campanhaID,
                  }).then(
                    (res) => {
                      setLoading(false);
                    },
                    (reason) => {
                      msgAPIError(reason);
                      setLoading(false);
                    }
                  );
                }}
                rightIcon={<IconUsersPlus />}
                variant='outline'>
                Permitir Edição Todos Chefes de Grupo
              </Button>
              <Button
                color='lightblue'
                size='md'
                onClick={() => {
                  setLoading(true);
                  CampanhaService.campanhaChefeEditavelNenhumRetrieve({
                    identificador: campanhaID,
                  }).then(
                    () => {
                      setLoading(false);
                    },
                    (reason) => {
                      msgAPIError(reason);
                      setLoading(false);
                    }
                  );
                }}
                rightIcon={<IconUsersMinus />}
                variant='outline'>
                Bloquear Edição Todos Chefes de Grupo
              </Button>
            </Button.Group>
          </CheckPermission>
        </Group>
      </Flex>
      <Tabs defaultValue={'0'}>
        <Tabs.List>
          {quartos.map((pa, i) => (
            <Tabs.Tab
              value={i.toString()}
              key={pa.nome_interno}>
              {pa.nome_interno}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {quartos.map((pa, i) => (
          <Tabs.Panel
            value={i.toString()}
            key={pa.nome_interno}>
            <MantineReactTable
              columns={columnsQuarto}
              data={pa.quartos}
              enableGlobalFilter={false}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableFilters={true}
              enableHiding={false}
              enablePagination={true}
              localization={MRT_Localization_PT}
              initialState={{
                density: 'xs',
                showGlobalFilter: false,
              }}
            />
          </Tabs.Panel>
        ))}
      </Tabs>
    </>
  );
}
