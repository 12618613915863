/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `paga` - Paga
 * * `pendente` - Pendente
 * * `expirada` - Expirada
 * * `erro` - Erro
 * * `cancelada` - Cancelada
 * * `reembolsada` - Reebolsada
 */
export enum EuPagoEstadosReferencia {
    PAGA = 'paga',
    PENDENTE = 'pendente',
    EXPIRADA = 'expirada',
    ERRO = 'erro',
    CANCELADA = 'cancelada',
    REEMBOLSADA = 'reembolsada',
}
