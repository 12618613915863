import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  ApiError,
  Grupo,
  GrupoEstadoEnum,
  GrupoService,
  Instituicao,
  InstituicaoService,
} from '../../../slidein_api';
import { useCampanha } from '../CampanhaWrapperView';
import GrupoEdit from './GrupoEdit';
import { useBread } from '../../../layout/context/BreadContext';
import { Prestacoes } from './Prestacoes';
import { Quartos } from './Quartos';
import {
  ActionIcon,
  Code,
  CopyButton,
  Divider,
  Flex,
  Select,
  Stack,
  Table,
  Tabs,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { GrupoViagem } from './GrupoViagem';
import { msgAPIError } from '../../helpers';
import {
  EmailIcon,
  EmailShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import {
  IconAdjustmentsCog,
  IconBed,
  IconBus,
  IconCheck,
  IconCopy,
  IconNotes,
  IconPackage,
  IconPdf,
  IconPlane,
  IconReceipt2,
  IconScale,
  IconTag,
  IconUsers,
  IconUsersGroup,
} from '@tabler/icons-react';
import { TransportesGrupo } from './TransportesGrupo';
import GrupoPacotes from './GrupoPacotes';
import GrupoSeguros from './GrupoSeguros';
import GrupoActividades from './GrupoActividades';
import GrupoSuplementos from './GrupoSuplementos';
import { BalancoGrupo } from './BalancoGrupo';
import {
  checkPermission,
  CheckPermission,
  MASTER,
  MASTER_ADMIN,
  MASTER_ADMIN_FINANCE,
  MASTER_FINANCE,
} from '../../../components/CheckPermission';
import { useAuth } from '../../../user-auth/SlideInAuthContext';
import { GrupoNotas } from './GrupoNotas';
import { GrupoFiles } from './GrupoFiles';
import { GrupoEstados } from './GrupoEstados';

export function GrupoDetalheView() {
  const { colaborador } = useAuth();
  const { campanhaID } = useParams();
  const { setBread } = useBread();
  const { grupoID } = useParams();
  const { campanha } = useCampanha();

  useEffect(() => {
    setBread({
      campanha: {
        title: campanhaID,
        href: '/campanha/' + campanhaID,
        icon: <IconPlane />,
      },
      grupo: {
        title: grupoID,
        href: '/campanha/' + campanhaID + '/grupo/' + grupoID,
        icon: <IconUsersGroup />,
      },
    });
  }, []);
  const [grupo, setGrupo] = useState<Grupo>(undefined);
  const [estado, setEstado] = useState<GrupoEstadoEnum>();

  const [loading, setLoading] = useState(false);
  const [instituicao, setInstituicao] = useState<Instituicao>();

  const fetchData = () => {
    setLoading(true);

    GrupoService.grupoRetrieve({ codigo: grupoID }).then(
      (grupo) => {
        setGrupo(grupo);
        setEstado(grupo.estado);
        InstituicaoService.instituicaoRetrieve({ id: grupo.instituicao }).then(
          (inst) => {
            setInstituicao(inst);
          },
          (reason) => msgAPIError(reason)
        );

        setLoading(false);
      },
      (reason) => {
        msgAPIError(reason);
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Flex
        mx={'md'}
        direction={'row'}
        wrap={'wrap'}
        justify={'space-between'}
        gap={'md'}>
        <Flex
          direction={'column'}
          align={'start'}>
          <Title order={3}>Estado</Title>
          <Select
            width={'50%'}
            disabled={!checkPermission(MASTER, colaborador)}
            icon={<IconTag />}
            radius='md'
            size='md'
            placeholder='Escolher'
            onChange={(e) => {
              GrupoService.grupoPartialUpdate({
                codigo: grupo?.codigo,
                requestBody: {
                  estado: e as GrupoEstadoEnum,
                },
              }).then(
                (value) => {
                  grupo.estado = value.estado;
                  setEstado(value.estado);
                },
                (reason: ApiError) => {
                  msgAPIError(reason);
                }
              );
            }}
            value={estado}
            data={Object.keys(GrupoEstadoEnum).map((x) => {
              return {
                label: GrupoEstadoEnum[x as keyof typeof GrupoEstadoEnum],
                value: GrupoEstadoEnum[x as keyof typeof GrupoEstadoEnum],
              };
            })}
          />
        </Flex>
        <Flex>
          <Table>
            <tbody>
              <tr>
                <td>
                  <b>Comercial:</b>
                </td>
                {grupo?.comercial_responsavel_ro ? (
                  <td>
                    {grupo?.comercial_responsavel_ro?.nome_simples}(
                    {grupo?.comercial_responsavel_ro?.contacto_telemovel},
                    {grupo?.comercial_responsavel_ro?.email})
                  </td>
                ) : (
                  <td>--</td>
                )}
              </tr>
              <tr>
                <td>
                  <b>Admin:</b>
                </td>
                {grupo?.admin_responsavel_ro ? (
                  <td>
                    {grupo?.admin_responsavel_ro?.nome_simples}(
                    {grupo?.admin_responsavel_ro?.contacto_telemovel},
                    {grupo?.admin_responsavel_ro?.email})
                  </td>
                ) : (
                  <td>--</td>
                )}
              </tr>
            </tbody>
          </Table>
        </Flex>
        <Flex direction={'column'}>
          Codigo:<Code fz={'xl'}>{grupo?.codigo}</Code>
          <Text fs='italic'>
            {instituicao ? `${instituicao.nome} (${instituicao.concelho})` : '...'}
          </Text>
        </Flex>

        <Flex
          direction={'column'}
          gap={'xs'}>
          <div>
            <Flex
              direction={'row'}
              justify={'space-between'}>
              <Title order={3}>Link para inscrição</Title>
              <Flex
                direction={'row'}
                justify={'space-between'}
                gap={'xs'}>
                <WhatsappShareButton
                  url={grupo?.convite_url}
                  title={'SLIDEIN TRAVEL : Convite ' + campanha?.nome}
                  separator={'\n'}>
                  <WhatsappIcon
                    className={'m-1'}
                    size={'30'}
                    borderRadius={10}
                  />
                </WhatsappShareButton>
                <TelegramShareButton
                  url={grupo?.convite_url}
                  title={'SLIDEIN TRAVEL: Convite ' + campanha?.nome}>
                  <TelegramIcon
                    className={'m-1'}
                    size={'30'}
                    borderRadius={10}
                  />
                </TelegramShareButton>
                <EmailShareButton
                  url={grupo?.convite_url}
                  subject={'SLIDEIN TRAVEL : Convite ' + campanha?.nome}
                  body={
                    'Olá\n ' +
                    'Este é o link para te juntares ao teu grupo da/o ' +
                    grupo?.instituicao
                  }
                  separator={'\n'}>
                  <EmailIcon
                    className={'m-1'}
                    size={'30'}
                    borderRadius={10}
                  />
                </EmailShareButton>
              </Flex>
            </Flex>

            <Flex
              direction={'row'}
              justify={'start'}
              wrap={'nowrap'}
              gap={'xs'}>
              <Link
                to={grupo?.convite_url}
                target={'_blank'}
                rel='noopener noreferrer'>
                {grupo?.convite_url}
              </Link>
              <CopyButton
                value={grupo?.convite_url}
                timeout={2000}>
                {({ copied, copy }) => (
                  <Tooltip
                    label={copied ? 'Copied' : 'Copy'}
                    withArrow
                    position='right'>
                    <ActionIcon
                      color={copied ? 'teal' : 'gray'}
                      onClick={copy}>
                      {copied ? <IconCheck size='1rem' /> : <IconCopy size='1rem' />}
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
            </Flex>
          </div>
        </Flex>
      </Flex>

      <Divider my={'md'}></Divider>

      <Tabs defaultValue={'1'}>
        <Tabs.List>
          <Tabs.Tab
            value={'1'}
            icon={<IconUsers />}>
            Viajantes
          </Tabs.Tab>
          <Tabs.Tab
            value={'2'}
            icon={<IconReceipt2 />}>
            Prestações
          </Tabs.Tab>
          <CheckPermission
            roles={MASTER_FINANCE}
            goHome={false}>
            <Tabs.Tab
              value={'3'}
              icon={<IconBed />}>
              Quartos
            </Tabs.Tab>
          </CheckPermission>
          <CheckPermission
            roles={MASTER_ADMIN_FINANCE}
            goHome={false}>
            <Tabs.Tab
              value={'4'}
              icon={<IconBus />}>
              Transporte
            </Tabs.Tab>
          </CheckPermission>
          <Tabs.Tab
            value={'5'}
            icon={<IconPackage />}>
            Produtos
          </Tabs.Tab>
          <CheckPermission
            roles={MASTER_FINANCE}
            goHome={false}>
            <Tabs.Tab
              value={'6'}
              icon={<IconScale />}>
              Balanço
            </Tabs.Tab>
          </CheckPermission>
          <CheckPermission
            roles={MASTER_ADMIN}
            goHome={false}>
            <Tabs.Tab
              value={'7'}
              icon={<IconAdjustmentsCog />}>
              Definições
            </Tabs.Tab>
          </CheckPermission>
          <CheckPermission
            roles={MASTER_FINANCE}
            goHome={false}>
            <Tabs.Tab
              value={'files'}
              icon={<IconPdf />}>
              Ficheiros
            </Tabs.Tab>
            <Tabs.Tab
              value={'8'}
              icon={<IconNotes />}>
              Notas
            </Tabs.Tab>
          </CheckPermission>
        </Tabs.List>
        <Tabs.Panel value={'1'}>
          <GrupoViagem />
        </Tabs.Panel>

        <Tabs.Panel value={'2'}>
          <Prestacoes
            loading={loading}
            grupo={grupo}
          />
        </Tabs.Panel>
        <CheckPermission
          roles={MASTER_FINANCE}
          goHome={false}>
          <Tabs.Panel value={'3'}>
            <Quartos
              grupo={grupo}
              refreshGrupo={fetchData}
            />
          </Tabs.Panel>
        </CheckPermission>
        <CheckPermission
          roles={MASTER_ADMIN_FINANCE}
          goHome={false}>
          <Tabs.Panel value={'4'}>
            <TransportesGrupo
              grupo={grupo}
              refreshGrupo={fetchData}></TransportesGrupo>
          </Tabs.Panel>
        </CheckPermission>
        <Tabs.Panel value={'5'}>
          <Stack>
            <Title
              order={2}
              mt={'1rem'}>
              Produtos Disponíveis para o Grupo
            </Title>
            <Divider></Divider>
            <Title order={3}>Alojamentos/Pacotes</Title>
            <GrupoPacotes
              grupo={grupo}
              refreshGrupo={fetchData}
            />
            <Divider></Divider>
            <Title order={3}>Seguros</Title>
            <GrupoSeguros
              grupo={grupo}
              refreshGrupo={fetchData}
            />
            <Divider></Divider>
            <Title order={3}>Actividades/Opcionais</Title>
            <GrupoActividades
              grupo={grupo}
              refreshGrupo={fetchData}
            />
            <Title order={3}>Suplementos</Title>
            <GrupoSuplementos
              grupo={grupo}
              refreshGrupo={fetchData}
            />
          </Stack>
        </Tabs.Panel>
        <CheckPermission
          roles={MASTER_FINANCE}
          goHome={false}>
          <Tabs.Panel value={'6'}>
            <BalancoGrupo />
          </Tabs.Panel>
        </CheckPermission>
        <CheckPermission
          roles={MASTER_ADMIN_FINANCE}
          goHome={false}>
          <Tabs.Panel value={'7'}>
            <Stack my={'md'}>
              <CheckPermission
                roles={MASTER}
                goHome={false}>
                <GrupoEstados />
                <Divider></Divider>
              </CheckPermission>
              <GrupoEdit creation={false} />
            </Stack>
          </Tabs.Panel>
        </CheckPermission>
        <CheckPermission
          roles={MASTER_FINANCE}
          goHome={false}>
          <Tabs.Panel value={'files'}>
            <GrupoFiles />
          </Tabs.Panel>
          <Tabs.Panel value={'8'}>
            <GrupoNotas
              notas={grupo?.notas}
              fetch={fetchData}></GrupoNotas>
          </Tabs.Panel>
        </CheckPermission>
      </Tabs>
    </>
  );
}
