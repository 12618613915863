import Layout from './layout/Layout';
import React from 'react';
import CampanhaEdit from './pages/Campanha/Definicoes/CampanhaEdit';
import { InstituicaoEdit } from './pages/Instituicao/InstituicaoEdit';
import { InstituicaoList } from './pages/Instituicao/InstituicaoList';
import { Login } from './user-auth/Login';
import { OpenAPI, SlideinRoleEnum } from './slidein_api';
import { useAuth } from './user-auth/SlideInAuthContext';
import { Navigate, useRoutes } from 'react-router-dom';
import { CampanhaGrupoView } from './pages/Campanha/Grupo/CampanhaGrupoView';
import { CampanhaViagemListView } from './pages/Campanha/Viagem/CampanhaViagemListView';
import { CampanhaWrapperView } from './pages/Campanha/CampanhaWrapperView';
import { Home } from './pages/Home';
import { MenuProvider } from './layout/context/MenuContext';
import { ColaboradorSlideIn } from './pages/Utilizadores/ColaboradorSlideIn';
import { EditColaborador } from './pages/Utilizadores/EditColaborador';
import { ClienteSlideIn } from './pages/Utilizadores/ClienteSlideIn';
import { EditCliente } from './pages/Utilizadores/EditCliente';
import { Pagamentos } from './pages/Campanha/Pagamentos/Pagamentos';
import { ProdutosManager } from './pages/Campanha/Produto/ProdutosManager';
import { Vendas } from './pages/Campanha/Vendas/Vendas';
import GrupoEdit from './pages/Campanha/Grupo/GrupoEdit';
import { GrupoDetalheView } from './pages/Campanha/Grupo/GrupoDetalheView';
import { ViagemView } from './pages/Campanha/Viagem/Detalhe/ViagemView';
import { DocumentacaoGeral } from './pages/docs';
import { CampanhaDocs } from './pages/Campanha/Documentos/CampanhaDocs';
import { BreadProvider } from './layout/context/BreadContext';
import {
  ALL_ALLOW,
  CheckPermission,
  MASTER,
  MASTER_ADMIN,
  MASTER_ADMIN_FINANCE,
  MASTER_FINANCE,
} from './components/CheckPermission';
import { CampanhaDefinicoes } from './pages/Campanha/Definicoes/CampanhaDefinicoes';
import { ColorScheme, ColorSchemeProvider, LoadingOverlay, MantineProvider } from '@mantine/core';
import { useColorScheme, useLocalStorage } from '@mantine/hooks';
import { BalancoGeral } from './pages/Campanha/Balanco/BalancoGeral';
import { TransporteLista } from './pages/Campanha/Transportes/TransporteLista';
import { TransporteManageDetalhe } from './pages/Campanha/Transportes/TransporteManageDetalhe';
import { CampanhaColaboradores } from './pages/Campanha/Colaboradores/CampanhaColaboradores';
import Marcas from './pages/Marca/Marcas';
import { NewPass } from './user-auth/NewPass';
import { SegurosTratar } from './pages/Campanha/Seguros/SegurosTratar';
import { NotificaAtrasos } from './pages/Campanha/NotificaAtrasos/NotificaAtrasos';
import { QuartosLista } from './pages/Campanha/Quartos/QuartosLista';
import { ReferenciasMultibanco } from './pages/Campanha/ReferenciasMultibanco/ReferenciasMultibanco';

export function App() {
  const { isAuthenticated, token, authLoading, colaborador } = useAuth();

  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: useColorScheme(),
    getInitialValueInEffect: true,
  });

  OpenAPI.BASE = process.env.REACT_APP_API_URL || '';
  OpenAPI.TOKEN = async () => {
    const toto = token();
    if (toto) {
      return toto.access;
    } else {
      return undefined;
    }
  };
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
  const rotas = useRoutes([
    {
      path: '/',
      element: (
        <CheckPermission
          roles={ALL_ALLOW}
          goEditPerfil={colaborador && colaborador.slidein_role == SlideinRoleEnum.TRAVEL_STAFF}>
          <Home />
        </CheckPermission>
      ),
    },
    {
      path: 'campanha/criar',
      element: (
        <CheckPermission
          roles={[SlideinRoleEnum.MASTER]}
          goHome={true}>
          <CampanhaEdit create={true} />
        </CheckPermission>
      ),
    },
    {
      path: 'campanha/:campanhaID',
      element: <CampanhaWrapperView />,
      children: [
        {
          index: true,
          element: (
            <CheckPermission
              roles={ALL_ALLOW}
              goHome={true}>
              <CampanhaGrupoView />
            </CheckPermission>
          ),
        },
        {
          path: 'viagem',

          children: [
            {
              index: true,
              element: (
                <CheckPermission
                  roles={ALL_ALLOW}
                  goHome={true}>
                  <CampanhaViagemListView />
                </CheckPermission>
              ),
            },
            {
              path: ':viagemID',
              element: (
                <CheckPermission
                  roles={ALL_ALLOW}
                  goHome={true}>
                  <ViagemView />
                </CheckPermission>
              ),
            },
          ],
        },
        {
          path: 'balanco',
          element: (
            <CheckPermission
              roles={MASTER_FINANCE}
              goHome={true}>
              <BalancoGeral />
            </CheckPermission>
          ),
        },
        {
          path: 'produtos',
          element: (
            <CheckPermission
              roles={ALL_ALLOW}
              goHome={true}>
              <ProdutosManager />
            </CheckPermission>
          ),
        },
        {
          path: 'gestao_seguros',
          element: (
            <CheckPermission
              roles={MASTER_FINANCE}
              goHome={true}>
              <SegurosTratar />
            </CheckPermission>
          ),
        },
        {
          path: 'notifica',
          element: (
            <CheckPermission
              roles={MASTER_FINANCE}
              goHome={true}>
              <NotificaAtrasos />
            </CheckPermission>
          ),
        },
        {
          path: 'pagamentos',
          element: (
            <CheckPermission
              roles={MASTER_FINANCE}
              goHome={true}>
              <Pagamentos />
            </CheckPermission>
          ),
        },
        {
          path: 'ref_mul',
          element: (
            <CheckPermission
              roles={MASTER_FINANCE}
              goHome={true}>
              <ReferenciasMultibanco />
            </CheckPermission>
          ),
        },
        {
          path: 'vendas',
          element: (
            <CheckPermission
              roles={MASTER_FINANCE}
              goHome={true}>
              <Vendas />
            </CheckPermission>
          ),
        },
        {
          path: 'docs',
          element: (
            <CheckPermission
              roles={ALL_ALLOW}
              goHome={true}>
              <CampanhaDocs />
            </CheckPermission>
          ),
        },
        {
          path: 'grupo',
          children: [
            {
              path: 'criar',
              element: (
                <CheckPermission
                  roles={ALL_ALLOW}
                  goHome={true}>
                  <GrupoEdit creation={true} />
                </CheckPermission>
              ),
            },
            {
              path: ':grupoID',
              children: [
                {
                  index: true,
                  element: (
                    <CheckPermission
                      roles={ALL_ALLOW}
                      goHome={true}>
                      <GrupoDetalheView />
                    </CheckPermission>
                  ),
                },
                {
                  path: 'editar',
                  element: (
                    <CheckPermission
                      roles={ALL_ALLOW}
                      goHome={true}>
                      <GrupoEdit creation={false} />
                    </CheckPermission>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'colaboradores',
          element: (
            <CheckPermission
              roles={MASTER_FINANCE}
              goHome={true}>
              <CampanhaColaboradores />
            </CheckPermission>
          ),
        },
        {
          path: 'transporte',
          children: [
            {
              index: true,
              element: (
                <CheckPermission
                  roles={MASTER_FINANCE}
                  goHome={true}>
                  <TransporteLista />
                </CheckPermission>
              ),
            },
            {
              path: ':transID',
              element: (
                <CheckPermission
                  roles={MASTER_FINANCE}
                  goHome={true}>
                  <TransporteManageDetalhe />
                </CheckPermission>
              ),
            },
          ],
        },
        {
          path: 'quartos',
          children: [
            {
              index: true,
              element: (
                <CheckPermission
                  roles={MASTER_FINANCE}
                  goHome={true}>
                  <QuartosLista />
                </CheckPermission>
              ),
            },
          ],
        },
        {
          path: 'definicoes',
          element: (
            <CheckPermission
              roles={MASTER}
              goHome={true}>
              <CampanhaDefinicoes />
            </CheckPermission>
          ),
        },
      ],
    },

    {
      path: 'colaborador',

      children: [
        {
          index: true,
          element: (
            <CheckPermission
              roles={MASTER_FINANCE}
              goHome={true}>
              <ColaboradorSlideIn />
            </CheckPermission>
          ),
        },
        {
          path: 'new',
          element: (
            <CheckPermission
              roles={MASTER}
              goHome={true}>
              <EditColaborador
                create={true}
                own={false}
              />
            </CheckPermission>
          ),
        },
        {
          path: 'meu_perfil',
          element: (
            <CheckPermission
              roles={[
                SlideinRoleEnum.ADMIN,
                SlideinRoleEnum.TRAVEL_STAFF,
                SlideinRoleEnum.COMERCIAL,
                SlideinRoleEnum.FINANCE,
                SlideinRoleEnum.MASTER,
              ]}
              goHome={true}>
              <EditColaborador
                create={false}
                own={true}
              />
            </CheckPermission>
          ),
        },
        {
          path: ':colaboradorID',
          element: (
            <CheckPermission
              roles={MASTER}
              goHome={true}>
              <EditColaborador
                create={false}
                own={false}
              />
            </CheckPermission>
          ),
        },
      ],
    },
    {
      path: 'cliente',

      children: [
        {
          index: true,
          element: (
            <CheckPermission
              roles={MASTER_ADMIN_FINANCE}
              goHome={true}>
              <ClienteSlideIn />
            </CheckPermission>
          ),
        },

        {
          path: ':clienteID',
          element: (
            <CheckPermission
              roles={MASTER_ADMIN_FINANCE}
              goHome={true}>
              <EditCliente />
            </CheckPermission>
          ),
        },
      ],
    },

    {
      path: 'instituicao',
      children: [
        {
          index: true,
          element: (
            <CheckPermission
              roles={MASTER_ADMIN_FINANCE}
              goHome={true}>
              <InstituicaoList />
            </CheckPermission>
          ),
        },
        {
          path: 'editar/:instID',
          element: (
            <CheckPermission
              roles={MASTER_ADMIN}
              goHome={true}>
              <InstituicaoEdit createNew={false} />
            </CheckPermission>
          ),
        },
        {
          path: 'criar',
          element: (
            <CheckPermission
              roles={MASTER_ADMIN}
              goHome={true}>
              <InstituicaoEdit createNew={true} />
            </CheckPermission>
          ),
        },
      ],
    },
    {
      path: 'marca',
      element: (
        <CheckPermission
          roles={MASTER_FINANCE}
          goHome={true}>
          <Marcas />
        </CheckPermission>
      ),
    },
    {
      path: 'docs',
      element: (
        <CheckPermission
          roles={ALL_ALLOW}
          goHome={true}>
          <DocumentacaoGeral />
        </CheckPermission>
      ),
    },
    {
      path: '*',
      element: (
        <Navigate
          replace
          to='/'
        />
      ),
    },
  ]);

  const rotas_no_auth = useRoutes([
    {
      path: '/registar_staff',
      element: (
        <EditColaborador
          create={true}
          own={true}
        />
      ),
    },
    {
      path: 'recpass/:uid/:token',
      element: <NewPass />,
    },
    {
      path: '*',
      element: <Login />,
    },
  ]);

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          // globalStyles: (theme) => ({
          //   'tbody>tr>td:last-of-type': {
          //     borderRight:
          //       theme.colorScheme === 'dark'
          //         ? '0.0625rem solid #373A40 !important'
          //         : '0.0625rem solid #dee2e6 !important',
          //     borderLeft:
          //       theme.colorScheme === 'dark'
          //         ? '0.0625rem solid #373A40 !important'
          //         : '0.0625rem solid #dee2e6 !important',
          //   },
          // }),
          loader: 'dots',
          colorScheme,
          colors: {
            slideinGreen: [
              '#60c44c',
              '#60c44c',
              '#60c44c',
              '#60c44c',
              '#60c44c',
              '#60c44c',
              '#60c44c',
              '#60c44c',
              '#60c44c',
              '#60c44c',
            ],
            slideinGreen2: [
              '#60c44c',
              '#FFFFFF',
              '#FFFFFF',
              '#FFFFFF',
              '#FFFFFF',
              '#FFFFFF',
              '#FFFFFF',
              '#FFFFFF',
              '#60c44c',
              '#60c44c',
            ],
          },
          primaryColor: 'slideinGreen',
          // primaryShade: 5,
        }}>
        <MenuProvider>
          <BreadProvider>
            <Layout>
              <LoadingOverlay
                visible={authLoading || (isAuthenticated && !colaborador)}
                overlayBlur={2}
                transitionDuration={0}
              />
              {isAuthenticated ? rotas : rotas_no_auth}
            </Layout>
          </BreadProvider>
        </MenuProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default App;
