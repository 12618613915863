import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
  ApiError,
  CondLimiteEnum,
  ProdutoDocs,
  ProdutoDocsRequest,
  ProdutoService,
  Suplemento,
  SuplementoRequest,
} from '../../../slidein_api';
import { fileLink, msgAPIError } from '../../helpers';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  useMantineReactTable,
} from 'mantine-react-table';
import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  FileInput,
  Group,
  Modal,
  NumberInput,
  Select,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from '@mantine/core';
import {
  IconCircleCheck,
  IconCircleX,
  IconCurrencyEuro,
  IconDeviceFloppy,
  IconEdit,
  IconFiles,
  IconMessageCircle,
  IconNotes,
  IconPackage,
  IconPackages,
  IconPdf,
  IconPlus,
  IconQuestionMark,
  IconTrash,
  IconTrashX,
  IconUpload,
  IconUser,
  IconUsersGroup,
  IconX,
} from '@tabler/icons-react';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import { useForm, yupResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useAuth } from '../../../user-auth/SlideInAuthContext';
import { checkPermission, MASTER } from '../../../components/CheckPermission';

const EMPTYSUPLEMENTO: SuplementoRequest = {
  descricao: '',
  nome_interno: '',
  titulo_cliente: '',
  valor: undefined,
  campanha: '',
  obrigatorio: false,
  activo: true,
  inicial: false,
  inscricao_previa: false,
  brevemente: false,
  msg_metodo_inscricao: '',
};

export function SuplementoManager() {
  const { colaborador } = useAuth();
  const params = useParams();
  const [dialogParams, setDialogParams] = useState<{
    create: boolean;
    visible: boolean;
  }>({
    create: true,
    visible: false,
  });
  const [update, setUpdate] = useState<number>(0);
  const [suplementos, setSuplementos] = useState<Suplemento[]>([]);
  const [suplementoDocsEdit, setSuplementoDocEdit] = useState<{
    suplemento: Suplemento;
    docs: ProdutoDocs[];
  }>(undefined);
  const [docsDiag, setDocsDialog] = useState<boolean>(false);
  const [docsDiagAdd, setDocsDialogAdd] = useState<boolean>(false);

  useEffect(() => {
    ProdutoService.produtoSuplementoList({ campanha: params.campanhaID }).then(
      (value) => {
        setSuplementos(value);
      },
      (reason) => msgAPIError(reason)
    );
  }, [update]);

  const suplementoForm = useForm<Suplemento | SuplementoRequest>({
    initialValues: EMPTYSUPLEMENTO,
    validate: yupResolver(
      Yup.object({
        nome_interno: Yup.string().min(4, 'Tamanho mínimo de 4 caracteres').required('Obrigatório'),
        titulo_cliente: Yup.string().required('Obrigatório'),
        descricao: Yup.string(),
        valor: Yup.number().min(0).required('Obrigatório'),
      })
    ),
  });
  useEffect(() => {
    if (suplementoForm.values.brevemente) {
      suplementoForm.setFieldValue('obrigatorio', false);
    }
  }, [suplementoForm.values.brevemente]);
  useEffect(() => {
    if (suplementoForm.values.obrigatorio) {
      suplementoForm.setFieldValue('brevemente', false);
    }
  }, [suplementoForm.values.obrigatorio]);
  const saveSuplemento = async (suplemento: Suplemento) => {
    if (dialogParams.create) {
      suplemento.campanha = params.campanhaID;
      ProdutoService.produtoSuplementoCreate({ requestBody: suplemento }).then(
        () => {
          setUpdate(update + 1);
          setDialogParams({ create: true, visible: false });
        },
        (error) => {
          suplementoForm.setErrors(error.body);
        }
      );
    } else {
      ProdutoService.produtoSuplementoUpdate({
        id: suplemento.id,
        requestBody: suplemento,
      }).then(
        () => {
          setUpdate(update + 1);
          setDialogParams({ create: true, visible: false });
          notifications.show({
            title: `Suplemento actualizado!`,
            message: `O Suplemento ${suplemento.nome_interno} foi actualizado.`,
            withCloseButton: true,
            icon: <IconCircleCheck />,
            color: 'green',
          });
        },
        (error) => {
          suplementoForm.setErrors(error.body);
        }
      );
    }
  };

  function criarSuplemento() {
    suplementoForm.reset();
    setDialogParams({
      create: true,
      visible: true,
    });
  }

  function editSuplemento(suplemento: Suplemento) {
    suplementoForm.reset();
    suplementoForm.setValues(suplemento);
    setDialogParams({
      create: false,
      visible: true,
    });
  }

  function removeSuplemento(suplemento: Suplemento) {
    if (confirm('Confirma que deseja remover o suplemento : ' + suplemento.nome_interno + ' ?')) {
      ProdutoService.produtoSuplementoDestroy({ id: suplemento.id }).then(
        () => {
          setUpdate(update + 1);
          notifications.show({
            title: 'Suplemento removido!',
            message: `O suplemento ${suplemento.nome_interno} foi removido.`,
            withCloseButton: true,
            icon: <IconTrashX />,
            color: 'red',
          });
        },
        (reason) => {
          msgAPIError(reason);
        }
      );
    }
  }

  const editSuplementoDialog = (
    <Modal
      withCloseButton={false}
      closeOnEscape={false}
      closeOnClickOutside={false}
      size={'auto'}
      opened={dialogParams.visible}
      onClose={() => {
        setDialogParams({ create: false, visible: false });
      }}
      title={dialogParams.create ? 'Criar Suplemento' : 'Editar Suplemento'}>
      <form onSubmit={suplementoForm.onSubmit(saveSuplemento)}>
        <TextInput
          label={'Nome Interno'}
          icon={<IconPackage />}
          {...suplementoForm.getInputProps('nome_interno')}
        />
        <Text color={'red'}>{suplementoForm.errors['non_field_errors']}</Text>
        <TextInput
          label={'Titulo Cliente'}
          icon={<IconUser />}
          {...suplementoForm.getInputProps('titulo_cliente')}
        />

        <NumberInput
          placeholder='Valor'
          label='Valor'
          precision={2}
          icon={<IconCurrencyEuro />}
          hideControls
          decimalSeparator=','
          thousandsSeparator='.'
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          formatter={(value) =>
            !Number.isNaN(parseFloat(value))
              ? // eslint-disable-next-line security/detect-unsafe-regex
                `${value} €`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
              : ''
          }
          {...suplementoForm.getInputProps('valor')}
        />
        {!dialogParams.create && (
          <Text color={'red'}>
            Não deve alterar o valor após criado/vendido! Criar um suplemento novo e desativar este!
          </Text>
        )}
        <NumberInput
          placeholder='Ilimitado'
          label='Unidades Disponiveis(Stock)'
          precision={0}
          description={'Deixar em branco para ser ilimitado.'}
          min={0}
          icon={<IconPackages />}
          thousandsSeparator='.'
          value={suplementoForm.values.unidades !== null ? suplementoForm.values.unidades : ''}
          onChange={(value) => {
            if (value !== '') {
              suplementoForm.setFieldValue('unidades', value);
            } else {
              suplementoForm.setFieldValue('unidades', null);
            }
          }}
        />
        <Select
          label={'Condição:'}
          icon={<IconQuestionMark />}
          data={Object.keys(CondLimiteEnum).map((x) => {
            return {
              label: CondLimiteEnum[x as keyof typeof CondLimiteEnum],
              value: CondLimiteEnum[x as keyof typeof CondLimiteEnum],
            };
          })}
          {...suplementoForm.getInputProps('cond_limite')}
        />

        <Checkbox
          mt={'lg'}
          labelPosition='right'
          label=' Activo, disponivel para compra pelos clientes'
          color='green'
          radius='md'
          size='lg'
          {...suplementoForm.getInputProps('activo', { type: 'checkbox' })}
        />
        <Checkbox
          mt={'lg'}
          labelPosition='right'
          label='Aquisição Obrigatória (exemplo:"Despesas de reserva ...")'
          color='green'
          radius='md'
          size='lg'
          {...suplementoForm.getInputProps('obrigatorio', { type: 'checkbox' })}
        />
        <Checkbox
          mt={'lg'}
          labelPosition='right'
          label='Adicionar automáticamente aos Grupos (na criação)'
          color='green'
          radius='md'
          size='lg'
          {...suplementoForm.getInputProps('inicial', { type: 'checkbox' })}
        />
        <Checkbox
          mt={'lg'}
          labelPosition='right'
          label='Modo Brevemente (Ainda não disponivel para compra)'
          color='green'
          radius='md'
          size='lg'
          {...suplementoForm.getInputProps('brevemente', { type: 'checkbox' })}
        />
        <Checkbox
          mt={'lg'}
          labelPosition='right'
          label='Inscrição via "Outro  método"'
          color='green'
          radius='md'
          size='lg'
          {...suplementoForm.getInputProps('inscricao_previa', { type: 'checkbox' })}
        />
        {suplementoForm.values.inscricao_previa && (
          <TextInput
            label={'Método de Inscrição (Mensagem cliente)'}
            icon={<IconMessageCircle />}
            {...suplementoForm.getInputProps('msg_metodo_inscricao')}
          />
        )}

        <Textarea
          placeholder='Descrição...'
          label='Descrição'
          size='md'
          icon={<IconNotes />}
          autosize={true}
          minRows={2}
          maxRows={4}
          {...suplementoForm.getInputProps('descricao')}
        />
        <NumberInput
          label={'# Ordem de aparência clientes'}
          min={0}
          placeholder={'Em branco = último.'}
          value={
            suplementoForm.values.ordem_de_aparencia !== null
              ? suplementoForm.values.ordem_de_aparencia
              : ''
          }
          onChange={(value) => {
            if (value !== '') {
              suplementoForm.setFieldValue('ordem_de_aparencia', value);
            } else {
              suplementoForm.setFieldValue('ordem_de_aparencia', null);
            }
          }}
        />
        <Group
          position='right'
          mt='md'>
          <Button
            leftIcon={<IconDeviceFloppy />}
            variant={'outline'}
            type='submit'>
            Guardar
          </Button>
          <Button
            color={'yellow'}
            variant={'outline'}
            leftIcon={<IconX />}
            type='button'
            onClick={() => setDialogParams({ create: false, visible: false })}>
            Cancelar
          </Button>
        </Group>
      </form>
    </Modal>
  );

  //--------------------------------------------------------------
  const docForm = useForm<ProdutoDocsRequest>({
    initialValues: {
      nome: '',
      produto: undefined,
      file: undefined,
    },

    validate: yupResolver(
      Yup.object({
        nome: Yup.string().min(5, 'Tamanho mínimo de 5 caracteres').required('Obrigatório'),
        file: Yup.mixed().required('Nenhum ficheiro selecionado!'),
      })
    ),
  });
  const saveSuplementoDoc = async (doc: ProdutoDocsRequest) => {
    doc.produto = suplementoDocsEdit.suplemento.id;
    ProdutoService.produtoDocsCreate({
      formData: doc,
    }).then(
      (value) => {
        setUpdate(update + 1);
        setSuplementoDocEdit({
          suplemento: suplementoDocsEdit.suplemento,
          docs: [...suplementoDocsEdit.docs, value],
        });
        setDocsDialogAdd(false);
      },
      (error) => {
        docForm.setErrors(error.body);
      }
    );
  };

  function adicionarSuplementoDoc() {
    docForm.reset();
    docForm.values.produto = suplementoDocsEdit.suplemento.id;
    setDocsDialogAdd(true);
  }

  const columnsDocs = useMemo<MRT_ColumnDef<ProdutoDocs>[]>(
    () => [
      {
        accessorKey: 'nome',
        header: 'Nome Documento',
      },
      {
        accessorFn: (row) => fileLink(row.nome, row.file),
        header: 'Ficheiro',
      },
    ],
    []
  );

  const editDocsDialog = () => {
    if (suplementoDocsEdit === undefined) {
      return <></>;
    } else {
      return (
        <>
          <Modal
            withCloseButton={true}
            closeOnEscape={false}
            closeOnClickOutside={false}
            size={'xl'}
            title={`Documentos (${suplementoDocsEdit.suplemento.nome_interno})`}
            opened={docsDiag && !docsDiagAdd}
            onClose={() => setDocsDialog(false)}>
            <MantineReactTable
              displayColumnDefOptions={{
                'mrt-row-actions': {
                  mantineTableHeadCellProps: {
                    align: 'center',
                  },
                  size: 120,
                },
              }}
              renderTopToolbarCustomActions={() => {
                return (
                  <Box sx={{ display: 'flex', gap: '16px', padding: '4px', flexWrap: 'wrap' }}>
                    <Button
                      disabled={!checkPermission(MASTER, colaborador)}
                      leftIcon={<IconPlus />}
                      variant='outline'
                      onClick={() => {
                        adicionarSuplementoDoc();
                      }}>
                      Adicionar Documento
                    </Button>
                  </Box>
                );
              }}
              initialState={{
                density: 'xs',
                showGlobalFilter: true,
                pagination: { pageSize: 15, pageIndex: 0 },
              }}
              enableRowActions={checkPermission(MASTER, colaborador)}
              renderRowActions={({ row, table }) => (
                <Box sx={{ display: 'flex', gap: '16px' }}>
                  <Tooltip label='Editar'>
                    <ActionIcon
                      color='blue'
                      onClick={() => table.setEditingRow(row)}>
                      <IconEdit />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label='Remover'>
                    <ActionIcon
                      color='red'
                      onClick={() => {
                        if (confirm('Confirma que deseja remover o documento ?')) {
                          ProdutoService.produtoDocsDestroy({ id: row.original.id }).then(
                            () => {
                              setUpdate(update + 1);
                              setSuplementoDocEdit({
                                suplemento: suplementoDocsEdit.suplemento,
                                docs: suplementoDocsEdit.docs.filter((pa) => {
                                  return pa.id !== row.original.id;
                                }),
                              });

                              notifications.show({
                                title: 'Documento removido!',
                                message: `O documento ${row.original.nome}  foi removido.`,
                                withCloseButton: true,
                                icon: <IconTrashX />,
                                color: 'red',
                              });
                            },
                            (reason) => {
                              msgAPIError(reason);
                            }
                          );
                        }
                      }}>
                      <IconTrashX />
                    </ActionIcon>
                  </Tooltip>
                </Box>
              )}
              columns={columnsDocs}
              data={suplementoDocsEdit.docs}
              positionActionsColumn='first'
              enableFullScreenToggle={false}
              localization={MRT_Localization_PT}
              enableEditing={checkPermission(MASTER, colaborador)}
              enablePagination={false}
              enableFilters={false}
              enableHiding={false}
              enableBottomToolbar={false}
              enableGlobalFilter={false}
              enableDensityToggle={false}
              editDisplayMode={'modal'}
              onEditingRowSave={({ exitEditingMode, row, values }) => {
                ProdutoService.produtoDocsPartialUpdate({
                  id: row.original.id,
                  formData: { nome: values.nome },
                }).then(
                  () => {
                    suplementoDocsEdit.docs[row.index] = values;
                    setSuplementoDocEdit({
                      suplemento: suplementoDocsEdit.suplemento,
                      docs: suplementoDocsEdit.docs,
                    });
                  },
                  (reason: ApiError) => {
                    msgAPIError(reason);
                  }
                );
                exitEditingMode();
              }}
            />
          </Modal>
          <Modal
            withCloseButton={false}
            closeOnEscape={false}
            size={'lg'}
            closeOnClickOutside={false}
            opened={docsDiagAdd}
            onClose={() => {
              setDocsDialogAdd(false);
            }}
            title={'Adicionar Documento'}>
            <form onSubmit={docForm.onSubmit(saveSuplementoDoc)}>
              <TextInput
                label={'Nome Documento'}
                icon={<IconPdf />}
                {...docForm.getInputProps('nome')}
              />
              <FileInput
                label={'Ficheiro'}
                multiple={false}
                icon={<IconUpload />}
                {...docForm.getInputProps('file')}
              />

              <Group
                position='right'
                mt='md'>
                <Button
                  variant={'outline'}
                  leftIcon={<IconDeviceFloppy />}
                  type='submit'>
                  Guardar
                </Button>
                <Button
                  color={'yellow'}
                  variant={'outline'}
                  leftIcon={<IconX />}
                  type='button'
                  onClick={() => setDocsDialogAdd(false)}>
                  Cancelar
                </Button>
              </Group>
            </form>
          </Modal>
        </>
      );
    }
  };

  //--------------------------------------------------------------

  function dispaAllGruposSuplemento(suplemento: Suplemento) {
    if (
      confirm(
        `Tem a certeza que deseja adicionar o suplemento ${suplemento.nome_interno} a TODOS os grupos da Campanha?`
      )
    ) {
      ProdutoService.produtoSuplementoDisponibilizarATodosOsGruposRetrieve({
        id: suplemento.id,
      }).then(
        () => {
          notifications.show({
            title: 'Suplemento adicionado ao grupos!',
            message: `O suplemento ${suplemento.nome_interno} foi adicionado a todo os grupos para selecção.`,
            withCloseButton: true,
            icon: <IconUsersGroup />,
          });
        },
        (reason) => {
          msgAPIError(reason);
        }
      );
    }
  }

  const columns = useMemo<MRT_ColumnDef<Suplemento>[]>(
    () => [
      { accessorKey: 'ordem_de_aparencia', header: '#', size: 50, Header: '#' },
      { accessorKey: 'nome_interno', header: 'Nome Interno', size: 200 },
      { accessorKey: 'titulo_cliente', header: 'Título Cliente', size: 200 },
      {
        accessorKey: 'valor',
        header: 'Valor',
        size: 100,
        Cell: (cell) => `${cell.cell.getValue()}€`,
      },
      {
        accessorKey: 'unidades',
        header: 'Uni. Disp.',
        size: 100,
        Cell: (cell) => (cell.cell.getValue() !== null ? `${cell.cell.getValue()}` : 'Ilimitado'),
      },
      {
        accessorKey: 'cond_limite',
        header: 'Condição',
        size: 100,
      },
      {
        accessorFn: (row) => `${row.nr_vendidos}`,
        header: 'Vendidos',
        size: 50,
      },
      {
        accessorFn: (row) => `${row.nr_vendidos_e_pagos}` || '-',
        header: 'Reservados(com Pag.)',
        size: 50,
      },

      {
        accessorKey: 'activo',
        header: 'Ativo',
        Header: (
          <Tooltip
            withinPortal={true}
            label={'Activo, disponivel para compra'}>
            <div>Act.</div>
          </Tooltip>
        ),
        size: 50,
        Cell: (cell) => {
          return cell.cell.getValue() ? (
            <IconCircleCheck color='green' />
          ) : (
            <IconCircleX color='red' />
          );
        },
      },
      {
        accessorKey: 'inicial',
        header: 'Auto',
        size: 50,
        Header: (
          <Tooltip
            withinPortal={true}
            label={'Na criação de um grupo adicionar automaticamente para escolha'}>
            <div>Auto.</div>
          </Tooltip>
        ),
        Cell: (cell) => {
          return cell.cell.getValue() ? (
            <IconCircleCheck color='green' />
          ) : (
            <IconCircleX color='red' />
          );
        },
      },
      {
        accessorKey: 'obrigatorio',
        header: 'Auto',
        size: 50,
        Header: (
          <Tooltip
            withinPortal={true}
            label={'Aquisição Obrigatória (exemplo:"Despesas de reserva ...")'}>
            <div>Obrigatório.</div>
          </Tooltip>
        ),
        Cell: (cell) => {
          return cell.cell.getValue() ? (
            <IconCircleCheck color='green' />
          ) : (
            <IconCircleX color='red' />
          );
        },
      },
      {
        accessorKey: 'brevemente',
        header: 'brevemente',
        size: 50,
        Header: (
          <Tooltip
            withinPortal={true}
            label={'Disponivel Brevemente,sem revelar preço'}>
            <div>Brevemente</div>
          </Tooltip>
        ),
        Cell: (cell) => {
          return cell.cell.getValue() ? (
            <IconCircleCheck color='green' />
          ) : (
            <IconCircleX color='red' />
          );
        },
      },
      {
        accessorKey: 'inscricao_previa',
        csv: true,
        header: 'Incrição Previa',
        Header: (
          <Tooltip
            withinPortal={true}
            label={'Incrição Previa,via "Outro  método".'}>
            <div>Inscrição</div>
          </Tooltip>
        ),
        size: 50,
        Cell: (cell) => {
          return cell.cell.getValue() ? (
            <Tooltip
              withinPortal={true}
              label={cell.row.original.msg_metodo_inscricao}>
              <IconCircleCheck color='green' />
            </Tooltip>
          ) : (
            <IconCircleX color='red' />
          );
        },
      },

      {
        accessorKey: 'docs',
        csv: false,
        header: 'Documentos',
        Header: 'Documentos',
        Cell: (cell) => (
          <Button
            leftIcon={<IconFiles />}
            onClick={() => {
              setSuplementoDocEdit({ suplemento: cell.row.original, docs: cell.row.original.docs });
              setDocsDialog(true);
            }}
            variant='outline'>
            {cell.row.original.docs.length} Docs
          </Button>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: suplementos,
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Button
          disabled={!checkPermission(MASTER, colaborador)}
          leftIcon={<IconPlus />}
          variant='outline'
          onClick={criarSuplemento}>
          Adicionar Suplemento
        </Button>
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleDensePaddingButton table={table} />
      </>
    ),
    // renderTopToolbarCustomActions: () => (
    //   <Button
    //     disabled={!suplementos.length||!checkPermission(MASTER, colaborador)}
    //     leftIcon={<IconCsv />}
    //     rightIcon={<IconDownload />}
    //     onClick={exportCSV}
    //     variant='outline'
    //   />
    // ),
    initialState: {
      density: 'xs',
    },
    enableRowActions: checkPermission(MASTER, colaborador),
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '4px' }}>
        <Tooltip label='Editar Suplemento'>
          <ActionIcon onClick={() => editSuplemento(row.original)}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label='Remover Produto'>
          <ActionIcon
            color='red'
            onClick={() => removeSuplemento(row.original)}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
        <Tooltip label='Adicionar como opção a todos Grupos da Campanha'>
          <ActionIcon
            color='yellow'
            onClick={() => dispaAllGruposSuplemento(row.original)}>
            <IconUsersGroup />
          </ActionIcon>
        </Tooltip>
      </Box>
    ),

    enablePagination: false,
    enableColumnResizing: false,
    enableColumnFilters: false,
    enableBottomToolbar: false,
    enableGlobalFilter: false,
    enableSorting: true,
    positionActionsColumn: 'first',
    enableFullScreenToggle: false,
    localization: MRT_Localization_PT,
  });

  return (
    <Box>
      <MantineReactTable table={table} />
      {editSuplementoDialog}
      {editDocsDialog()}
    </Box>
  );
}
