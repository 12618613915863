/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Vendido` - Vendido
 * * `Cancelado` - Cancelado
 */
export enum CompraEstadoEnum {
    VENDIDO = 'Vendido',
    CANCELADO = 'Cancelado',
}
