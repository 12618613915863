import React, { useEffect, useMemo, useState } from 'react';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_GlobalFilterTextInput,
  MRT_PaginationState,
  MRT_ShowHideColumnsButton,
  MRT_SortingState,
  MRT_ToggleFiltersButton,
  useMantineReactTable,
} from 'mantine-react-table';
import { CompraPacote, CompraService, OpenAPI } from '../../../slidein_api';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import { iconBool, mrtSortingToAPI, msgAPIError } from '../../helpers';
import { Link, useParams } from 'react-router-dom';
import { Button, Flex, LoadingOverlay } from '@mantine/core';
import { IconDownload, IconFileSpreadsheet } from '@tabler/icons-react';
import axios from 'axios';
import FileSaver from 'file-saver';

export function VendasPacotes() {
  const { campanhaID } = useParams();

  const [compras, setCompras] = useState<CompraPacote[]>([]);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  });

  const tToslideAPI: Record<string, string> = {
    creationDate: 'creation_date',
    email: 'viagem__cliente__email',
    estado: 'estado',
    grupo: 'viagem__grupo',
    adminResponsavel: 'viagem__grupo__admin_responsavel',
    comercialResponsavel: 'viagem__grupo__comercial_responsavel',
    instituicao: 'viagem__grupo__instituicao__nome',
    nomeCompleto: 'viagem__cliente__nome_proprio',
    produtoNomeInterno: 'produto_nome_interno',
    produtoValor: 'produto__valor',
    telemovel: 'viagem__cliente__contacto_telemovel',
    quarto: 'viagem__quarto__nr_quarto',
    valorDevido: 'viagem__valor_devido',
    valorTotal: 'viagem__valor_total',
    valorPago: 'viagem__valor_pago',
    viagemChefeDeGrupo: 'viagem__chefe_de_grupo',
    viagemCancelada: 'viagem__cancelada',
    viagemBloqueadaPagamentos: 'viagem__bloqueada_pagamentos',
    contactoUrgencia: 'viagem__cliente__contacto_urgencia',
    emailContactoUrgencia: 'viagem__cliente__email_contacto_urgencia',
  };

  function paramsNormalize(columnFilters: MRT_ColumnFiltersState) {
    const params = {};
    columnFilters.forEach((c) => (params[c.id] = c.value));
    if (params['creationDate']) {
      const da = params['creationDate'][0];
      const db = params['creationDate'][1];

      params['creationDateAfter'] = da
        ? `${da.getFullYear()}-${(da.getMonth() + 1).toString().padStart(2, '0')}-${da
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
      params['creationDateBefore'] = db
        ? `${db.getFullYear()}-${(db.getMonth() + 1).toString().padStart(2, '0')}-${db
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
    }
    if (params['valorDevido']) {
      params['valorDevidoMin'] = params['valorDevido'][0];
      params['valorDevidoMax'] = params['valorDevido'][1];
    }
    if (params['valorPago']) {
      params['valorPagoMin'] = params['valorPago'][0];
      params['valorPagoMax'] = params['valorPago'][1];
    }
    if (params['valorTotal']) {
      params['valorTotalMin'] = params['valorTotal'][0];
      params['valorTotalMax'] = params['valorTotal'][1];
    }
    return params;
  }

  const fetchData = (page: number, pageSize: number) => {
    setIsRefetching(true);
    CompraService.compraPacoteList({
      ...paramsNormalize(columnFilters),
      viagemCampanha: campanhaID,
      page: page + 1,
      pageSize: pageSize,
      search: globalFilter ?? '',
      ordering: mrtSortingToAPI(sorting, tToslideAPI),
    }).then(
      (value) => {
        setCompras(value.results);
        setRowCount(value.count);
        setIsRefetching(false);
      },
      (reason) => {
        msgAPIError(reason);
      }
    );
  };
  useEffect(() => {
    fetchData(pagination.pageIndex, pagination.pageSize);
  }, [pagination, sorting]);

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: 15 });
  }, [columnFilters, globalFilter]);

  const columns = useMemo<MRT_ColumnDef<CompraPacote>[]>(
    () => [
      { accessorKey: 'estado', header: 'Estado', id: 'estado', filterVariant: 'text' },
      {
        accessorFn: (row) => (row.viagem ? row.produto.nome_interno : '-'),
        header: 'Alojamento/Pacote',
        id: 'produtoNomeInterno',
        filterVariant: 'text',
      },
      {
        accessorFn: (row) => (row.viagem ? row.viagem.cliente.nome_simples : '-'),
        header: 'Nome Cliente',
        id: 'nomeCompleto',
        filterVariant: 'text',
        Cell: ({ cell }: { cell }) => (
          <Link to={'/campanha/' + campanhaID + '/viagem/' + cell.row.original.viagem.id}>
            {cell.row.original.viagem.cliente.nome_simples}
          </Link>
        ),
      },
      {
        accessorFn: (row) => (
          <a href={'mailto:' + row.viagem.cliente.email}>{row.viagem.cliente.email}</a>
        ),
        header: 'Email',
        id: 'email',
        filterVariant: 'text',
      },
      {
        accessorFn: (row) => (
          <a href={'mailto:' + row.viagem.cliente.email_contacto_urgencia}>
            {row.viagem.cliente.email_contacto_urgencia}
          </a>
        ),
        header: 'Email(Pais)',
        id: 'emailContactoUrgencia',
        filterVariant: 'text',
      },
      {
        accessorFn: (row) => (row.viagem ? row.viagem.cliente.contacto_urgencia : '-'),
        header: 'Telemóvel(pais)',
        id: 'contactoUrgencia',
        filterVariant: 'text',
      },
      {
        accessorFn: (row) => (row.viagem ? row.viagem.chefe_de_grupo : false),
        id: 'viagemChefeDeGrupo',
        header: 'Chefe de Grupo',
        filterVariant: 'checkbox',
        Cell: ({ cell }) => iconBool(cell.row.original.viagem.chefe_de_grupo),
      },
      {
        accessorFn: (row) => (row.viagem ? row.viagem.cancelada : false),
        id: 'viagemCancelada',
        header: 'Cancelada',
        filterVariant: 'checkbox',
        Cell: ({ cell }) => iconBool(cell.row.original.viagem.cancelada),
      },
      {
        accessorFn: (row) => (row.viagem ? row.viagem.bloqueada_pagamentos : false),
        id: 'viagemBloqueadaPagamentos',
        header: 'Bloqueada Falta Pagamentos',
        filterVariant: 'checkbox',
        Cell: ({ cell }) => iconBool(cell.row.original.viagem.bloqueada_pagamentos),
      },

      {
        accessorFn: (row) => (row.viagem ? row.viagem.valor_total : 0),
        id: 'valorTotal',
        header: 'Total',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ cell }) => {
          return cell.row.original.viagem.valor_total + '€';
        },
      },
      {
        accessorFn: (row) => (row.viagem ? row.viagem.valor_pago : 0),
        id: 'valorPago',
        header: 'Pago',

        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ cell }) => {
          return cell.row.original.viagem.valor_pago + '€';
        },
      },
      {
        accessorFn: (row) => (row.viagem ? row.viagem.valor_devido : 0),
        id: 'valorDevido',
        header: 'Devido',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ cell }) => {
          return cell.row.original.viagem.valor_devido + '€';
        },
      },

      {
        accessorFn: (row) => row.viagem.quarto,
        header: 'Quarto',
        id: 'quarto',
        filterVariant: 'text',
      },
      {
        accessorFn: (row) => (row.viagem ? row.viagem.cliente.contacto_telemovel : '-'),
        header: 'Telemóvel',
        id: 'telemovel',
        filterVariant: 'text',
      },
      {
        accessorFn: (row) => (row.viagem ? row.viagem.grupo.codigo : '-'),
        header: 'Grupo',
        id: 'grupo',
        filterVariant: 'text',
      },
      {
        accessorFn: (row) => (row.viagem ? row.viagem.grupo.admin_responsavel : '-'),
        header: 'Admin',
        id: 'adminResponsavel',
        filterVariant: 'text',
      },
      {
        accessorFn: (row) => (row.viagem ? row.viagem.grupo.comercial_responsavel : '-'),
        header: 'Comercial',
        id: 'comercialResponsavel',
        filterVariant: 'text',
      },
      {
        accessorFn: (row) => (row.viagem ? row.viagem.grupo.instituicao : '-'),
        header: 'Instituição',
        id: 'instituicao',
        filterVariant: 'text',
      },
      {
        accessorFn: (row) => (row.viagem ? row.produto.valor + '€' : '-'),
        header: 'Valor',
        id: 'produtoValor',
        filterVariant: 'text',
      },
      {
        accessorKey: 'creation_date',
        header: 'Data de Criação',
        id: 'creationDate',
        filterVariant: 'date-range',
      },
    ],
    []
  );

  const comprasTable = useMantineReactTable({
    data: compras,
    columns: columns,
    enableRowActions: false,
    positionActionsColumn: 'first',
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount: rowCount,
    state: {
      columnFilters,
      globalFilter,
      pagination,
      showProgressBars: isRefetching,
      sorting,
    },
    initialState: {
      density: 'xs',
      showGlobalFilter: true,
      pagination: { pageSize: 15, pageIndex: 0 },
      columnVisibility: {
        produtoNomeInterno: true,
        nomeCompleto: true,
        email: true,
        contactoUrgencia: false,
        emailContactoUrgencia: false,
        adminResponsavel: false,
        comercialResponsavel: false,
        viagemChefeDeGrupo: false,
        viagemCancelada: true,
        viagemBloqueadaPagamentos: true,
        valorPago: false,
        valorDevido: true,
        valorTotal: false,
        quarto: false,
        telemovel: true,
        grupo: true,
        instituicao: true,
        produtoValor: true,
        creationDate: true,
      },
    },

    mantineTableProps: {
      highlightOnHover: true,
      withColumnBorders: true,
    },
    enableFullScreenToggle: false,
    localization: MRT_Localization_PT,
    globalFilterFn: 'contains',
    positionGlobalFilter: 'none',
    renderToolbarInternalActions: ({ table }) => (
      <Flex
        direction={'row'}
        wrap={'wrap'}
        gap={'sm'}
        justify={'space-evenly'}>
        <MRT_GlobalFilterTextInput table={table} />

        <Button
          color='lightblue'
          onClick={() => {
            setIsRefetching(true);
            const cenas: {
              creationDateAfter?: string;
              creationDateBefore?: string;
              email?: string;
              contactoUrgencia?: string;
              emailContactoUrgencia?: string;
              estado?: string;
              grupo?: string;
              adminResponsavel?: string;
              comercialResponsavel?: string;
              instituicao?: string;
              nomeCompleto?: string;
              ordering?: string;
              page?: number;
              pageSize?: number;
              produtoValor?: number;
              produtoNomeInterno?: string;
              search?: string;
              telemovel?: string;
              viagemCampanha?: string;
              valorDevidoMax?: number;
              valorDevidoMin?: number;
              valorPagoMax?: number;
              valorPagoMin?: number;
              valorTotalMax?: number;
              valorTotalMin?: number;
              viagemBloqueadaPagamentos?: boolean;
              viagemCancelada?: boolean;
              viagemChefeDeGrupo?: boolean;
            } = {
              ...paramsNormalize(columnFilters),
              search: globalFilter ?? '',
              ordering: mrtSortingToAPI(sorting, tToslideAPI),
              viagemCampanha: campanhaID,
            };
            const url = `${OpenAPI.BASE}/gestao/compra/pacote/mapa`;
            axios
              .get(url, {
                responseType: 'blob',
                params: {
                  creation_date_after: cenas.creationDateAfter,
                  creation_date_before: cenas.creationDateBefore,
                  email: cenas.email,
                  email_contacto_urgencia: cenas.emailContactoUrgencia,
                  contacto_urgencia: cenas.contactoUrgencia,
                  estado: cenas.estado,
                  grupo: cenas.grupo,
                  admin_responsavel: cenas.adminResponsavel,
                  comercial_responsavel: cenas.comercialResponsavel,
                  instituicao: cenas.instituicao,
                  nome_completo: cenas.nomeCompleto,
                  ordering: cenas.ordering,
                  produto__valor: cenas.produtoValor,
                  produto_nome_interno: cenas.produtoNomeInterno,
                  search: cenas.search,
                  telemovel: cenas.telemovel,
                  viagem__campanha: cenas.viagemCampanha,
                  valor_devido_min: cenas.valorDevidoMin,
                  valor_devido_max: cenas.valorDevidoMax,
                  valor_pago_max: cenas.valorPagoMax,
                  valor_pago_min: cenas.valorPagoMin,
                  valor_total_max: cenas.valorTotalMax,
                  valor_total_min: cenas.valorTotalMin,
                  viagem__bloqueada_pagamentos: cenas.viagemBloqueadaPagamentos,
                  viagem__cancelada: cenas.viagemCancelada,
                  viagem__chefe_de_grupo: cenas.viagemChefeDeGrupo,
                },
              })
              .then(
                (res) => {
                  const blob = new Blob([res.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
                  });
                  FileSaver.saveAs(blob, 'vendas_alojamentos.xlsx');
                  setIsRefetching(false);
                  return res;
                },
                (reason) => {
                  msgAPIError(reason);
                  setIsRefetching(false);
                }
              );
          }}
          rightIcon={<IconFileSpreadsheet />}
          leftIcon={<IconDownload />}
          variant='outline'>
          .xlsx
        </Button>
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleFiltersButton table={table} />
      </Flex>
    ),
  });

  return (
    <>
      <LoadingOverlay visible={isRefetching} />
      {compras && <MantineReactTable table={comprasTable} />}
    </>
  );
}
