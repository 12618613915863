import { useParams } from 'react-router-dom';
import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  FileInput,
  Group,
  Modal,
  Stack,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { ViagemDoc, ViagemDocRequest, ViagemService } from '../../../../slidein_api';
import { fileLink, iconBoolOnlyTrue, msgAPIError } from '../../../helpers';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import {
  IconDeviceFloppy,
  IconFile,
  IconPlus,
  IconTrash,
  IconTrashX,
  IconUpload,
  IconX,
} from '@tabler/icons-react';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import { notifications } from '@mantine/notifications';

const empty_doc: ViagemDocRequest = {
  nome: '',
  declaracao_menores: false,
  viagem: null,
  file: null,
};

export function ViagemFiles() {
  const params = useParams();

  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);

  const [data, setData] = useState<ViagemDoc[]>([]);

  const fetchData = () => {
    ViagemService.viagemDocsList({ viagem: parseInt(params.viagemID) }).then(
      (vias) => {
        setData(vias);
      },
      (reason) => msgAPIError(reason)
    );
  };
  useEffect(() => {
    fetchData();
  }, []);

  const form = useForm<ViagemDocRequest>({
    initialValues: empty_doc,
    validate: yupResolver(
      Yup.object({
        nome: Yup.string().required('Obrigatório'),
        file: Yup.mixed().required('Obrigatório'),
      })
    ),
  });
  const saveFile = () => {
    const obj = form.values;
    obj.viagem = parseInt(params.viagemID);

    ViagemService.viagemDocsCreate({ formData: obj }).then(
      () => {
        fetchData();
        setEditModalVisible(false);
      },
      (reason) => form.setErrors(reason.body)
    );
  };

  function removerFicheiro(doc: ViagemDoc) {
    if (confirm('Confirma que deseja remover o ficheiro : ' + doc.nome + ' ?')) {
      ViagemService.viagemDocsDestroy({ id: doc.id }).then(
        () => {
          notifications.show({
            title: 'Ficheiro removido!',
            message: `O Ficheiro ${doc.nome} foi removido.`,
            withCloseButton: true,
            icon: <IconTrashX />,
            color: 'red',
          });
          fetchData();
        },
        (reason) => {
          msgAPIError(reason);
        }
      );
    }
  }

  const columns = useMemo<MRT_ColumnDef<ViagemDoc>[]>(
    () => [
      {
        accessorFn: (row) => fileLink(row.nome, row.file),
        header: 'Ficheiro',
      },
      {
        accessorFn: (row) => iconBoolOnlyTrue(row.declaracao_menores),
        header: 'D.Menores',
      },
    ],
    []
  );

  const table = useMantineReactTable({
    data,
    columns,
    enableFilters: false,
    enableFullScreenToggle: false,
    enablePagination: false,

    initialState: {
      density: 'xs',
    },
    enableRowActions: true,
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        leftIcon={<IconPlus />}
        variant='outline'
        onClick={() => {
          form.reset();
          setEditModalVisible(true);
        }}>
        Adicionar Ficheiro
      </Button>
    ),
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '4px' }}>
        <Tooltip label='Remover Ficheiro'>
          <ActionIcon
            color='red'
            onClick={() => removerFicheiro(row.original)}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Box>
    ),
    localization: MRT_Localization_PT,
  });

  return (
    <Stack>
      <MantineReactTable table={table} />
      <Modal
        opened={editModalVisible}
        onClose={() => setEditModalVisible(false)}>
        <Title order={2}>Adicionar Ficheiro</Title>
        <form onSubmit={form.onSubmit(saveFile)}>
          <TextInput
            label={'Nome'}
            icon={<IconFile />}
            {...form.getInputProps('nome')}
          />
          <FileInput
            {...form.getInputProps('file')}
            label={'Ficheiro'}
            multiple={false}
            icon={<IconUpload />}
          />
          <Checkbox
            mt={'lg'}
            labelPosition='left'
            label='Declaração de Menores'
            color='green'
            radius='md'
            size='lg'
            {...form.getInputProps('declaracao_menores', { type: 'checkbox' })}
          />
          <Group
            position='right'
            mt='md'>
            <Button
              variant={'outline'}
              leftIcon={<IconDeviceFloppy />}
              type='submit'>
              Guardar
            </Button>
            <Button
              color={'yellow'}
              variant={'outline'}
              leftIcon={<IconX />}
              type='button'
              onClick={() => setEditModalVisible(false)}>
              Cancelar
            </Button>
          </Group>
        </form>
      </Modal>
    </Stack>
  );
}
