import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Group,
  LoadingOverlay,
  Modal,
  NumberInput,
  Space,
  Table,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import React, { useEffect, useMemo, useState } from 'react';
import {
  ApiError,
  CampanhaService,
  OpenAPI,
  PatchedTransporteRequest,
  Transporte,
  TransporteLocalPartida,
  TransporteLocalPartidaItem,
  TransporteLocalPartidaRequest,
  TransporteRequest,
  TransporteService,
} from '../../../slidein_api';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, yupResolver } from '@mantine/form';
import { msgAPIError } from '../../helpers';
import {
  IconBuildingPavilion,
  IconBus,
  IconClock2,
  IconDeviceFloppy,
  IconDownload,
  IconEdit,
  IconFileSpreadsheet,
  IconList,
  IconMapPin,
  IconMapPinPlus,
  IconNumber,
  IconPlane,
  IconPlus,
  IconTrash,
  IconTrashX,
  IconUsersGroup,
  IconUsersMinus,
  IconUsersPlus,
  IconX,
} from '@tabler/icons-react';
import { DateTimePicker } from '@mantine/dates';
import dayjs from 'dayjs';
import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import * as Yup from 'yup';
import { notifications } from '@mantine/notifications';
import { useBread } from '../../../layout/context/BreadContext';
import { modals } from '@mantine/modals';
import axios from 'axios';
import FileSaver from 'file-saver';

export function TransporteLista() {
  const { campanhaID } = useParams();
  const { setBread } = useBread();
  useEffect(() => {
    setBread({
      campanha: {
        title: campanhaID,
        href: '/campanha/' + campanhaID,
        icon: <IconPlane />,
      },
      accao: {
        title: 'Transportes',
        icon: <IconUsersGroup />,
      },
    });
  }, []);

  const [transportes, setTransportes] = useState<Transporte[]>([]);

  const [editTransporteModalVisible, setEditTransporteModalVisible] = useState<boolean>(false);
  const [editLocalModalVisible, setEditLocalModalVisible] = useState<boolean>(false);
  const [novoTransporte, setNovoTransporte] = useState<boolean>(false);
  const [novoLocal, setNovoLocal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const transForm = useForm<Transporte | TransporteRequest | PatchedTransporteRequest>({
    initialValues: { campanha: campanhaID },

    validate: yupResolver(
      Yup.object({
        nome: Yup.string().required('Obrigatório'),
        vagas: Yup.number().min(1).max(10000).required('Obrigatório'),
      })
    ),
  });
  const localForm = useForm<TransporteLocalPartida | TransporteLocalPartidaRequest>({
    // initialValues:{},
    validate: yupResolver(
      Yup.object({
        localidade: Yup.string().required('Obrigatório'),
        local_partida: Yup.string().required('Obrigatório'),
        hora_partida: Yup.date().required('Obrigatório'),
      })
    ),
    transformValues: (values) => {
      const di = values.hora_partida ? new Date(values.hora_partida) : null;
      return {
        ...values,
        hora_partida: di ? di.toISOString() : '',
      };
    },
  });

  const saveTrans = (trans: Transporte | TransporteRequest) => {
    if (novoTransporte) {
      TransporteService.transporteCreate({
        requestBody: trans,
      }).then(
        (value) => {
          notifications.show({
            title: 'Transporte Criado!',
            message: `O Transporte ${value.nome} foi criado.`,
            withCloseButton: true,
            icon: <IconTrashX />,
            color: 'green',
          });
          fetchTransportes();
          setEditTransporteModalVisible(false);
        },
        (reason) => {
          transForm.setErrors(reason.body);
        }
      );
    } else {
      TransporteService.transporteUpdate({ id: (trans as Transporte).id, requestBody: trans }).then(
        (value) => {
          notifications.show({
            title: 'Transporte Actualizado!',
            message: `O Transporte ${value.nome} foi actualizado.`,
            withCloseButton: true,
            icon: <IconTrashX />,
            color: 'green',
          });
          fetchTransportes();
          setEditTransporteModalVisible(false);
        },
        (reason: ApiError) => {
          transForm.setErrors(reason.body);
        }
      );
    }
  };
  const saveLocal = (local: TransporteLocalPartida | TransporteLocalPartidaRequest) => {
    if (novoLocal) {
      TransporteService.transporteLocaisPartidaCreate({
        requestBody: local,
      }).then(
        (value) => {
          notifications.show({
            title: 'Local Criado!',
            message: `O Local ${value.local_partida} foi criado.`,
            withCloseButton: true,
            icon: <IconTrashX />,
            color: 'green',
          });
          fetchTransportes();
          setEditLocalModalVisible(false);
        },
        (reason) => {
          localForm.setErrors(reason.body);
        }
      );
    } else {
      TransporteService.transporteLocaisPartidaPartialUpdate({
        id: (local as TransporteLocalPartida).id,
        requestBody: local,
      }).then(
        (value) => {
          notifications.show({
            title: 'Local Actualizado!',
            message: `O Local ${value.local_partida} foi actualizado.`,
            withCloseButton: true,
            icon: <IconTrashX />,
            color: 'green',
          });
          fetchTransportes();
          setEditLocalModalVisible(false);
        },
        (reason: ApiError) => {
          localForm.setErrors(reason.body);
        }
      );
    }
  };

  const fetchTransportes = () => {
    TransporteService.transporteList({ campanha: campanhaID }).then(
      (value) => {
        setTransportes(value);
      },
      (reason) => {
        msgAPIError(reason);
      }
    );
  };

  useEffect(() => {
    fetchTransportes();
  }, []);

  function livres_vagas(cell) {
    return cell.row.original.nr_livres ? (
      <Text
        color={'yellow'}
        fw={'bold'}>
        {cell.row.original.nr_livres}/{cell.row.original.vagas}
      </Text>
    ) : (
      <Text color={'green'}>
        {cell.row.original.nr_livres}/{cell.row.original.vagas}
      </Text>
    );
  }

  const list_locais = (locais: TransporteLocalPartidaItem[]) => {
    if (locais && locais.length == 0) {
      return '--';
    }
    locais = locais.sort((a, b) => (dayjs(a.hora_partida).isBefore(dayjs(b.hora_partida)) ? 1 : 0));
    return (
      <Table
        verticalSpacing='xs'
        fontSize='xs'
        withBorder
        styles={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th>Localidade</th>
            <th>Local</th>
            <th>Hora de partida</th>
            <th>Nrº Staff</th>
            <th>Nrº Viajantes</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {locais.map((loc) => (
            <tr key={loc.id}>
              <td>{loc.localidade}</td>
              <td>{loc.local_partida}</td>
              <td>
                {loc.hora_partida
                  ? dayjs(loc.hora_partida).locale('pt').format('YYYY-MM-DD HH:mm')
                  : '-'}
              </td>
              <td>{loc.nr_staff}</td>
              <td align={'left'}>{loc.nr_viajantes}</td>
              <td align={'left'}>
                <Box sx={{ display: 'flex', gap: '4px', justifyContent: 'end' }}>
                  <Tooltip label='Editar '>
                    <ActionIcon
                      color={'yellow'}
                      onClick={() => {
                        setNovoLocal(false);
                        localForm.reset();
                        localForm.setValues(loc);
                        setEditLocalModalVisible(true);
                      }}>
                      <IconEdit />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label='Remover'>
                    <ActionIcon
                      disabled={(loc.nr_staff || 0) + (loc.nr_staff || 0) > 0}
                      color='red'
                      onClick={() =>
                        modals.openConfirmModal({
                          title: 'Remover Local (Transporte)',
                          centered: true,
                          children: (
                            <Text size='sm'>
                              Confirma que deseja remover o Local de Partida Transporte :{' '}
                              {loc.local_partida} ?
                            </Text>
                          ),
                          labels: { confirm: 'Remover', cancel: 'Cancelar' },
                          confirmProps: {
                            color: 'red',
                            variant: 'outline',
                            leftIcon: <IconTrash />,
                          },
                          cancelProps: { color: 'yellow', variant: 'outline', leftIcon: <IconX /> },
                          onConfirm: () => {
                            TransporteService.transporteLocaisPartidaDestroy({ id: loc.id }).then(
                              () => {
                                fetchTransportes();
                                notifications.show({
                                  title: 'Local de Partida removido!',
                                  message: `O Transporte ${loc.local_partida} foi removido.`,
                                  withCloseButton: true,
                                  icon: <IconTrashX />,
                                  color: 'green',
                                });
                              },
                              (reason: ApiError) => {
                                if (reason.status == 423) {
                                  notifications.show({
                                    title: 'Local de Partida!',
                                    message: `O Local  ${loc.local_partida} NÃO foi removido, existem Viagens/Grupos associados!`,
                                    withCloseButton: true,
                                    icon: <IconX />,
                                    color: 'yellow',
                                    autoClose: 5000,
                                  });
                                } else {
                                  msgAPIError(reason);
                                }
                              }
                            );
                          },
                        })
                      }>
                      <IconTrash />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label='Detalhe (Viajantes Staff)'>
                    <ActionIcon
                      color={'blue'}
                      onClick={() => {
                        navigate(loc.id.toString());
                      }}>
                      <IconList />
                    </ActionIcon>
                  </Tooltip>
                </Box>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  const columnsTrans = useMemo<MRT_ColumnDef<Transporte>[]>(
    () => [
      {
        accessorKey: 'nome',
        filterVariant: 'text',
        filterFn: 'includesString',
        header: 'Transporte',
        enableColumnFilter: true,
      },
      {
        accessorKey: 'vagas',
        header: 'Vagas Livres',
        size: 50,
        enableColumnFilter: false,
        Cell: livres_vagas,
      },
      {
        accessorFn: (row) => {
          return (row.locais_partida || []).reduce(
            (acc, lo) => acc + ' ' + lo.local_partida + ' ' + lo.localidade,
            ''
          );
        },

        enableColumnFilter: true,
        filterVariant: 'text',
        filterFn: 'includesString',
        header: 'Locais de Partida (Localidade, Local Partida, Data, Nrº Staff, Nrª Viajantes)',
        Cell: ({ row }) => {
          return list_locais(row.original.locais_partida);
        },
      },
    ],

    []
  );
  const tableTransportes = useMantineReactTable<Transporte>({
    columns: columnsTrans,
    data: transportes,
    enableGlobalFilter: false,
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: 'pointer',
      },
      onClick: row.getToggleSelectedHandler(),
    }),
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableFilters: true,
    enableHiding: false,
    enablePagination: false,
    localization: MRT_Localization_PT,
    renderTopToolbarCustomActions: ({ table }) => (
      <Group>
        <Button
          leftIcon={<IconPlus />}
          variant='outline'
          onClick={() => {
            setNovoTransporte(true);
            transForm.reset();
            transForm.setValues({ campanha: campanhaID });
            transForm.setFieldValue('hora_partida', null);
            setEditTransporteModalVisible(true);
          }}>
          Criar Transporte
        </Button>
        <Button
          color='lightblue'
          onClick={() => {
            setLoading(true);
            const url = `${OpenAPI.BASE}/gestao/campanha/${campanhaID}/mapa_transportes`;
            axios
              .get(url, {
                responseType: 'blob',
                params: { identificador: campanhaID },
              })
              .then(
                (res) => {
                  const blob = new Blob([res.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
                  });
                  FileSaver.saveAs(blob, `mapa_transportes_${campanhaID}.xlsx`);
                  setLoading(false);
                  return res;
                },
                (reason) => {
                  msgAPIError(reason);
                  setLoading(false);
                }
              );
          }}
          rightIcon={<IconFileSpreadsheet />}
          leftIcon={<IconDownload />}
          variant='outline'>
          Mapa Transportes
        </Button>
        <Space></Space>
        <Button.Group>
          <Button
            color='lightblue'
            size='md'
            onClick={() => {
              setLoading(true);
              CampanhaService.campanhaTransportesTodosRetrieve({ identificador: campanhaID }).then(
                (res) => {
                  setLoading(false);
                },
                (reason) => {
                  msgAPIError(reason);
                  setLoading(false);
                }
              );
            }}
            rightIcon={<IconUsersPlus />}
            variant='outline'>
            Mostrar a todos o grupos
          </Button>
          <Button
            color='lightblue'
            size='md'
            onClick={() => {
              setLoading(true);
              CampanhaService.campanhaTransportesNenhumRetrieve({ identificador: campanhaID }).then(
                () => {
                  setLoading(false);
                },
                (reason) => {
                  msgAPIError(reason);
                  setLoading(false);
                }
              );
            }}
            rightIcon={<IconUsersMinus />}
            variant='outline'>
            Esconder de todos o grupos
          </Button>
        </Button.Group>
      </Group>
    ),
    enableRowActions: true,
    positionActionsColumn: 'first',
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '4px' }}>
        <Tooltip label='Editar '>
          <ActionIcon
            color={'yellow'}
            onClick={() => {
              setNovoTransporte(false);
              transForm.reset();
              transForm.setValues(row.original);
              setEditTransporteModalVisible(true);
            }}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label='Remover'>
          <ActionIcon
            disabled={(row.original?.locais_partida?.length || 0) > 0}
            color='red'
            onClick={() =>
              modals.openConfirmModal({
                title: 'Remover Transporte',
                centered: true,
                children: (
                  <Text size='sm'>
                    Confirma que deseja remover o Transporte : {row.original.nome} ?
                  </Text>
                ),
                labels: { confirm: 'Remover', cancel: 'Cancelar' },
                confirmProps: { color: 'red', variant: 'outline', leftIcon: <IconTrash /> },
                cancelProps: { color: 'yellow', variant: 'outline', leftIcon: <IconX /> },
                onConfirm: () => {
                  TransporteService.transporteDestroy({ id: row.original.id }).then(
                    () => {
                      fetchTransportes();
                      notifications.show({
                        title: 'Transporte removido!',
                        message: `O Transporte ${row.original.nome} foi removido.`,
                        withCloseButton: true,
                        icon: <IconTrashX />,
                        color: 'green',
                      });
                    },
                    (reason: ApiError) => {
                      if (reason.status == 423) {
                        notifications.show({
                          title: 'Transporte!',
                          message: `O Transporte  ${row.original.nome} NÃO foi removido, existem Viagens/Grupos associados!`,
                          withCloseButton: true,
                          icon: <IconX />,
                          color: 'yellow',
                          autoClose: 5000,
                        });
                      } else {
                        msgAPIError(reason);
                      }
                    }
                  );
                },
              })
            }>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
        <Space w={'xl'}></Space>
        <Tooltip label='Adicionar Local de partida'>
          <ActionIcon
            color={'blue'}
            onClick={() => {
              setNovoLocal(true);
              localForm.reset();
              localForm.setValues({ transporte: row.original.id });
              setEditLocalModalVisible(true);
            }}>
            <IconMapPinPlus />
          </ActionIcon>
        </Tooltip>
      </Box>
    ),
    initialState: {
      density: 'xs',
      showGlobalFilter: false,
    },
  });

  return (
    <>
      <LoadingOverlay visible={loading}></LoadingOverlay>
      <Modal
        opened={editTransporteModalVisible}
        onClose={() => setEditTransporteModalVisible(false)}
        closeOnEscape={false}
        closeOnClickOutside={false}
        withCloseButton={false}
        title={novoTransporte ? 'Criar Transporte' : 'Editar Transporte'}
        centered>
        <form onSubmit={transForm.onSubmit(saveTrans)}>
          <TextInput
            label={'Nome'}
            icon={<IconBus />}
            {...transForm.getInputProps('nome')}
          />
          <NumberInput
            min={1}
            w={{ base: '100%', xs: '49%' }}
            icon={<IconNumber />}
            label={'Vagas'}
            {...transForm.getInputProps('vagas')}
          />
          <Group
            position='right'
            mt='md'>
            <Button
              color={'yellow'}
              variant={'outline'}
              leftIcon={<IconX />}
              type='button'
              onClick={() => setEditTransporteModalVisible(false)}>
              Cancelar
            </Button>
            <Button
              type='submit'
              variant={'outline'}
              leftIcon={<IconDeviceFloppy />}>
              Guardar Alterações
            </Button>
          </Group>
        </form>
      </Modal>
      <MantineReactTable table={tableTransportes} />
      <Modal
        opened={editLocalModalVisible}
        onClose={() => setEditLocalModalVisible(false)}
        closeOnEscape={false}
        closeOnClickOutside={false}
        withCloseButton={false}
        title={novoLocal ? 'Criar Local Partida' : 'Editar Local Partida'}
        centered>
        <form onSubmit={localForm.onSubmit(saveLocal)}>
          <TextInput
            label={'Localidade'}
            icon={<IconBuildingPavilion />}
            {...localForm.getInputProps('localidade')}
          />
          <TextInput
            label={'Local de Partida'}
            icon={<IconMapPin />}
            {...localForm.getInputProps('local_partida')}
          />
          <Flex
            direction='row'
            gap={'xs'}
            justify={'space-between'}
            wrap={'wrap'}>
            <DateTimePicker
              label='Data/Hora Partida'
              id={'hora_partida'}
              w={{ base: '100%', xs: '49%' }}
              locale={'pt'}
              firstDayOfWeek={1}
              dropdownType={'modal'}
              placeholder='Escolher Data e Hora'
              minDate={new Date()}
              icon={<IconClock2 stroke={1.5} />}
              valueFormat='DD/MM/YYYY hh:mm'
              {...localForm.getInputProps('hora_partida')}
              value={
                localForm.values.hora_partida ? dayjs(localForm.values.hora_partida).toDate() : null
              }
            />
          </Flex>
          <Group
            position='right'
            mt='md'>
            <Button
              color={'yellow'}
              variant={'outline'}
              leftIcon={<IconX />}
              type='button'
              onClick={() => setEditLocalModalVisible(false)}>
              Cancelar
            </Button>
            <Button
              type='submit'
              variant={'outline'}
              leftIcon={<IconDeviceFloppy />}>
              Guardar Alterações
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
}
