import React, { useEffect, useMemo, useState } from 'react';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_ShowHideColumnsButton,
  MRT_SortingState,
  MRT_ToggleFiltersButton,
  useMantineReactTable,
} from 'mantine-react-table';
import { PagamentoService, RefsMultibanco } from '../../../slidein_api';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import { mrtSortingToAPI, msgAPIError } from '../../helpers';
import { Flex, LoadingOverlay, Stack } from '@mantine/core';
import { IconPlane, IconReportMoney } from '@tabler/icons-react';
import { Link, useParams } from 'react-router-dom';
import { useBread } from '../../../layout/context/BreadContext';
import dayjs from 'dayjs';

export function ReferenciasMultibanco() {
  const { campanhaID } = useParams();
  const { setBread } = useBread();
  useEffect(() => {
    setBread({
      campanha: {
        title: campanhaID,
        href: '/campanha/' + campanhaID,
        icon: <IconPlane />,
      },
      accao: {
        title: 'Referências Multibanco',
        icon: <IconReportMoney />,
      },
    });
  }, []);

  const [refmul, setRefMul] = useState<RefsMultibanco[]>([]);
  const [isRefetching, setIsRefetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  });

  const tToslideAPI: Record<string, string> = {
    estado: 'estado',
    referencia: 'referencia',
    entidade: 'entidade',
    nomeCliente: 'viagem__cliente__nome_proprio',
    email: 'viagem__cliente__email',
    telemovel: 'viagem__cliente__contacto_telemovel',
    grupo: 'viagem__grupo__codigo',
    instituicao: 'viagem__grupo__instituicao__nome',
    nome: 'nome',
    valor: 'valor',
    dataInicio: 'data_inicio',
    dataFim: 'data_fim',
  };

  function paramsNormalize(columnFilters: MRT_ColumnFiltersState) {
    const params = {};
    columnFilters.forEach((c) => (params[c.id] = c.value));
    if (params['valor']) {
      params['valorMin'] = params['valor'][0];
      params['valorMax'] = params['valor'][1];
    }

    if (params['dataInicio']) {
      const da = params['dataInicio'][0];
      const db = params['dataInicio'][1];

      params['dataInicioAfter'] = da
        ? `${da.getFullYear()}-${(da.getMonth() + 1).toString().padStart(2, '0')}-${da
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
      params['dataInicioBefore'] = db
        ? `${db.getFullYear()}-${(db.getMonth() + 1).toString().padStart(2, '0')}-${db
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
    }
    if (params['dataFim']) {
      const da = params['dataFim'][0];
      const db = params['dataFim'][1];

      params['dataFimAfter'] = da
        ? `${da.getFullYear()}-${(da.getMonth() + 1).toString().padStart(2, '0')}-${da
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
      params['dataFimBefore'] = db
        ? `${db.getFullYear()}-${(db.getMonth() + 1).toString().padStart(2, '0')}-${db
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
    }
    return params;
  }

  const fetchData = (page: number, pageSize: number) => {
    setIsRefetching(true);
    PagamentoService.pagamentoRefList({
      ...paramsNormalize(columnFilters),
      viagemCampanha: campanhaID,
      page: page + 1,
      pageSize: pageSize,
      ordering: mrtSortingToAPI(sorting, tToslideAPI),
    }).then(
      (value) => {
        setRefMul(value.results);
        setRowCount(value.count);
        setIsRefetching(false);
      },
      (reason) => {
        msgAPIError(reason);
      }
    );
  };
  useEffect(() => {
    fetchData(pagination.pageIndex, pagination.pageSize);
  }, [pagination, sorting]);

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: 15 });
  }, [columnFilters]);

  const columns = useMemo<MRT_ColumnDef<RefsMultibanco>[]>(
    () => [
      {
        accessorKey: 'entidade',
        header: 'Entidade(EuPago)',
        id: 'entidade',
        filterVariant: 'text',
      },
      {
        accessorKey: 'referencia',
        header: 'Referência (EuPago)',
        id: 'referencia',
        filterVariant: 'text',
      },
      {
        accessorKey: 'identificador',
        header: 'Identificador (EuPago)',
        enableHiding: true,
        enableSorting: false,
        enableColumnFilter: false,
        id: 'identificador',
      },
      { accessorKey: 'estado', header: 'Estado', filterVariant: 'text' },
      { accessorFn: (row) => row.nome, header: 'Tipo', filterVariant: 'text', id: 'nome' },
      {
        accessorKey: 'nome_cliente',
        header: 'Nome Cliente',
        id: 'nomeCliente',
        filterVariant: 'text',
        Cell: ({ row }: { row }) => (
          <Link to={'/campanha/' + campanhaID + '/viagem/' + row.original.viagem}>
            {row.original.nome_cliente}
          </Link>
        ),
      },
      {
        accessorFn: (row) => <a href={'mailto:' + row.email}>{row.email}</a>,
        header: 'Email',
        id: 'email',
        filterVariant: 'text',
      },
      {
        accessorKey: 'telemovel',
        header: 'Telemóvel',
        id: 'telemovel',
        filterVariant: 'text',
      },
      {
        accessorKey: 'grupo',
        header: 'Grupo',
        id: 'grupo',
        filterVariant: 'text',
      },
      {
        accessorKey: 'valor',
        id: 'valor',
        header: 'Valor',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ cell }) => {
          return cell.row.original.valor + '€';
        },
      },

      {
        accessorKey: 'data_inicio',
        header: 'Data de Inicio',
        id: 'dataInicio',
        filterVariant: 'date-range',
        Cell: ({ row }) => {
          return dayjs(row.original.data_inicio).locale('pt').format('YYYY-MM-DD');
        },
      },
      {
        accessorKey: 'data_fim',
        header: 'Data de Fim',
        id: 'dataFim',
        filterVariant: 'date-range',
        Cell: ({ row }) => {
          return dayjs(row.original.data_fim).locale('pt').format('YYYY-MM-DD');
        },
      },
    ],
    []
  );

  const tablePag = useMantineReactTable({
    data: refmul,
    columns,
    enableRowActions: false,
    positionActionsColumn: 'first',
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount: rowCount,
    state: {
      columnFilters,
      pagination,
      showProgressBars: isRefetching,
      sorting,
    },
    initialState: {
      density: 'xs',
      showGlobalFilter: false,
      pagination: { pageSize: 15, pageIndex: 0 },
      columnVisibility: {
        identificador: false,
      },
    },

    mantineTableProps: {
      highlightOnHover: true,
      withColumnBorders: true,
    },
    enableFullScreenToggle: false,
    localization: MRT_Localization_PT,
    positionToolbarAlertBanner: 'bottom',

    renderToolbarInternalActions: ({ table }) => (
      <Flex
        direction={'row'}
        wrap={'wrap'}
        gap={'sm'}
        justify={'start'}>
        {/*<Button*/}
        {/*  color='lightblue'*/}
        {/*  onClick={() => {*/}
        {/*    setLoading(true);*/}
        {/*    const cenas: {*/}
        {/*      creationDateAfter?: string;*/}
        {/*      creationDateBefore?: string;*/}
        {/*      dataFacturacaoAfter?: string;*/}
        {/*      dataFacturacaoBefore?: string;*/}
        {/*      email?: string;*/}
        {/*      banco?: string;*/}
        {/*      estado?: 'Anulado' | 'Pago' | 'Pendente';*/}
        {/*      grupo?: string;*/}
        {/*      instituicao?: string;*/}
        {/*      nome?: string;*/}
        {/*      ordering?: string;*/}
        {/*      page?: number;*/}
        {/*      pageSize?: number;*/}
        {/*      entidade?: string;*/}
        {/*      referencia?: string;*/}
        {/*      search?: string;*/}
        {/*      telemovel?: string;*/}
        {/*      tipo?: 'Devolução' | 'Manual' | 'MbWay' | 'RefMultibanco';*/}
        {/*      trid?: string;*/}
        {/*      valorMax?: number;*/}
        {/*      valorMin?: number;*/}
        {/*      viagem?: number;*/}
        {/*      viagemCampanha?: string;*/}
        {/*    } = {*/}
        {/*      ...paramsNormalize(columnFilters),*/}
        {/*      search: globalFilter ?? '',*/}
        {/*      viagemCampanha: campanhaID,*/}
        {/*    };*/}
        {/*    const url = `${OpenAPI.BASE}/gestao/pagamento/mapa`;*/}
        {/*    axios*/}
        {/*      .get(url, {*/}
        {/*        responseType: 'blob',*/}
        {/*        params: {*/}
        {/*          creation_date_after: cenas.creationDateAfter,*/}
        {/*          creation_date_before: cenas.creationDateBefore,*/}
        {/*          data_facturacao_after: cenas.dataFacturacaoAfter,*/}
        {/*          data_facturacao_before: cenas.dataFacturacaoBefore,*/}
        {/*          email: cenas.email,*/}
        {/*          banco: cenas.banco,*/}
        {/*          estado: cenas.estado,*/}
        {/*          grupo: cenas.grupo,*/}
        {/*          instituicao: cenas.instituicao,*/}
        {/*          nome: cenas.nome,*/}
        {/*          ordering: cenas.ordering,*/}
        {/*          page: cenas.page,*/}
        {/*          page_size: cenas.pageSize,*/}
        {/*          referencia: cenas.referencia,*/}
        {/*          search: cenas.search,*/}
        {/*          telemovel: cenas.telemovel,*/}
        {/*          tipo: cenas.tipo,*/}
        {/*          trid: cenas.trid,*/}
        {/*          entidade: cenas.entidade,*/}
        {/*          valor_max: cenas.valorMax,*/}
        {/*          valor_min: cenas.valorMin,*/}
        {/*          viagem: cenas.viagem,*/}
        {/*          viagem__campanha: cenas.viagemCampanha,*/}
        {/*        },*/}
        {/*      })*/}
        {/*      .then(*/}
        {/*        (res) => {*/}
        {/*          const blob = new Blob([res.data], {*/}
        {/*            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',*/}
        {/*          });*/}
        {/*          FileSaver.saveAs(blob, 'pagamentos.xlsx');*/}
        {/*          setLoading(false);*/}
        {/*          return res;*/}
        {/*        },*/}
        {/*        (reason) => {*/}
        {/*          msgAPIError(reason);*/}
        {/*          setLoading(false);*/}
        {/*        }*/}
        {/*      );*/}
        {/*  }}*/}
        {/*  rightIcon={<IconFileSpreadsheet />}*/}
        {/*  leftIcon={<IconDownload />}*/}
        {/*  variant='outline'>*/}
        {/*  .xlsx*/}
        {/*</Button>*/}
        {/*<Button*/}
        {/*  variant={'outline'}*/}
        {/*  leftIcon={<IconRefresh />}*/}
        {/*  rightIcon={<IconRefresh />}*/}
        {/*  onClick={() => {*/}
        {/*    setLoading(true);*/}
        {/*    PagamentoService.pagamentoActualizarPagamentosRetrieve().then(*/}
        {/*      () => {*/}
        {/*        fetchData(pagination.pageIndex, pagination.pageSize);*/}
        {/*        setLoading(false);*/}
        {/*      },*/}
        {/*      (reason) => {*/}
        {/*        msgAPIError(reason);*/}
        {/*        setLoading(false);*/}
        {/*      }*/}
        {/*    );*/}
        {/*  }}>*/}
        {/*  Actualizar*/}
        {/*</Button>*/}
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleFiltersButton table={table} />
      </Flex>
    ),
  });

  return (
    <Stack>
      <LoadingOverlay
        visible={loading}
        overlayBlur={2}></LoadingOverlay>
      <MantineReactTable table={tablePag} />
    </Stack>
  );
}
