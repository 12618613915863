/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Devolucao } from '../models/Devolucao';
import type { DevolucaoRequest } from '../models/DevolucaoRequest';
import type { PagamentoManual } from '../models/PagamentoManual';
import type { PagamentoManualRequest } from '../models/PagamentoManualRequest';
import type { PagamentosInstituicaoStats } from '../models/PagamentosInstituicaoStats';
import type { PagamentosStats } from '../models/PagamentosStats';
import type { PaginatedRefsMultibancoList } from '../models/PaginatedRefsMultibancoList';
import type { PaginatedTransacaoSimplesList } from '../models/PaginatedTransacaoSimplesList';
import type { PatchedDevolucaoRequest } from '../models/PatchedDevolucaoRequest';
import type { PatchedPagamentoManualRequest } from '../models/PatchedPagamentoManualRequest';
import type { RefsMultibanco } from '../models/RefsMultibanco';
import type { RefsMultibancoRequest } from '../models/RefsMultibancoRequest';
import type { TransacaoSimples } from '../models/TransacaoSimples';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PagamentoService {

    /**
     * @returns PaginatedTransacaoSimplesList
     * @throws ApiError
     */
    public static pagamentoList({
        banco,
        creationDateAfter,
        creationDateBefore,
        dataFacturacaoAfter,
        dataFacturacaoBefore,
        email,
        entidade,
        estado,
        grupo,
        instituicao,
        nome,
        ordering,
        page,
        pageSize,
        referencia,
        search,
        telemovel,
        tipo,
        trid,
        valorMax,
        valorMin,
        viagem,
        viagemCampanha,
    }: {
        banco?: string,
        creationDateAfter?: string,
        creationDateBefore?: string,
        dataFacturacaoAfter?: string,
        dataFacturacaoBefore?: string,
        email?: string,
        entidade?: string,
        /**
         * * `Pendente` - Pedente
         * * `Pago` - Pago
         * * `Anulado` - Anulado
         */
        estado?: 'Anulado' | 'Pago' | 'Pendente',
        grupo?: string,
        instituicao?: string,
        nome?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        referencia?: string,
        /**
         * A search term.
         */
        search?: string,
        telemovel?: string,
        tipo?: string,
        trid?: string,
        valorMax?: number,
        valorMin?: number,
        viagem?: number,
        /**
         * Identifica univocamente uma campanha
         */
        viagemCampanha?: string,
    }): CancelablePromise<PaginatedTransacaoSimplesList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/pagamento',
            query: {
                'banco': banco,
                'creation_date_after': creationDateAfter,
                'creation_date_before': creationDateBefore,
                'data_facturacao_after': dataFacturacaoAfter,
                'data_facturacao_before': dataFacturacaoBefore,
                'email': email,
                'entidade': entidade,
                'estado': estado,
                'grupo': grupo,
                'instituicao': instituicao,
                'nome': nome,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'referencia': referencia,
                'search': search,
                'telemovel': telemovel,
                'tipo': tipo,
                'trid': trid,
                'valor_max': valorMax,
                'valor_min': valorMin,
                'viagem': viagem,
                'viagem__campanha': viagemCampanha,
            },
        });
    }

    /**
     * @returns TransacaoSimples
     * @throws ApiError
     */
    public static pagamentoRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this transacao.
         */
        id: number,
    }): CancelablePromise<TransacaoSimples> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/pagamento/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns TransacaoSimples
     * @throws ApiError
     */
    public static pagamentoActualizarPagamentosRetrieve(): CancelablePromise<TransacaoSimples> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/pagamento/actualizar_pagamentos',
        });
    }

    /**
     * @returns Devolucao
     * @throws ApiError
     */
    public static pagamentoDevolucaoList(): CancelablePromise<Array<Devolucao>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/pagamento/devolucao',
        });
    }

    /**
     * @returns Devolucao
     * @throws ApiError
     */
    public static pagamentoDevolucaoCreate({
        formData,
    }: {
        formData: DevolucaoRequest,
    }): CancelablePromise<Devolucao> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/pagamento/devolucao',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns Devolucao
     * @throws ApiError
     */
    public static pagamentoDevolucaoRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this devolucao.
         */
        id: number,
    }): CancelablePromise<Devolucao> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/pagamento/devolucao/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Devolucao
     * @throws ApiError
     */
    public static pagamentoDevolucaoUpdate({
        id,
        formData,
    }: {
        /**
         * A unique integer value identifying this devolucao.
         */
        id: number,
        formData: DevolucaoRequest,
    }): CancelablePromise<Devolucao> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/pagamento/devolucao/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns Devolucao
     * @throws ApiError
     */
    public static pagamentoDevolucaoPartialUpdate({
        id,
        formData,
    }: {
        /**
         * A unique integer value identifying this devolucao.
         */
        id: number,
        formData?: PatchedDevolucaoRequest,
    }): CancelablePromise<Devolucao> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/pagamento/devolucao/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static pagamentoDevolucaoDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this devolucao.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/pagamento/devolucao/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PagamentoManual
     * @throws ApiError
     */
    public static pagamentoManualList(): CancelablePromise<Array<PagamentoManual>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/pagamento/manual',
        });
    }

    /**
     * @returns PagamentoManual
     * @throws ApiError
     */
    public static pagamentoManualCreate({
        formData,
    }: {
        formData: PagamentoManualRequest,
    }): CancelablePromise<PagamentoManual> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/pagamento/manual',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns PagamentoManual
     * @throws ApiError
     */
    public static pagamentoManualRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this pagamento manual.
         */
        id: number,
    }): CancelablePromise<PagamentoManual> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/pagamento/manual/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PagamentoManual
     * @throws ApiError
     */
    public static pagamentoManualUpdate({
        id,
        formData,
    }: {
        /**
         * A unique integer value identifying this pagamento manual.
         */
        id: number,
        formData: PagamentoManualRequest,
    }): CancelablePromise<PagamentoManual> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/pagamento/manual/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns PagamentoManual
     * @throws ApiError
     */
    public static pagamentoManualPartialUpdate({
        id,
        formData,
    }: {
        /**
         * A unique integer value identifying this pagamento manual.
         */
        id: number,
        formData?: PatchedPagamentoManualRequest,
    }): CancelablePromise<PagamentoManual> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/pagamento/manual/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static pagamentoManualDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this pagamento manual.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/pagamento/manual/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns TransacaoSimples
     * @throws ApiError
     */
    public static pagamentoMapaRetrieve(): CancelablePromise<TransacaoSimples> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/pagamento/mapa',
        });
    }

    /**
     * @returns PaginatedRefsMultibancoList
     * @throws ApiError
     */
    public static pagamentoRefList({
        dataFimAfter,
        dataFimBefore,
        dataInicioAfter,
        dataInicioBefore,
        email,
        entidade,
        estado,
        grupo,
        identificador,
        instituicao,
        nome,
        nomeCliente,
        ordering,
        page,
        pageSize,
        referencia,
        telemovel,
        valorMax,
        valorMin,
        viagemCampanha,
    }: {
        dataFimAfter?: string,
        dataFimBefore?: string,
        dataInicioAfter?: string,
        dataInicioBefore?: string,
        email?: string,
        entidade?: string,
        estado?: string,
        grupo?: string,
        identificador?: string,
        instituicao?: string,
        nome?: string,
        nomeCliente?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        referencia?: string,
        telemovel?: string,
        valorMax?: number,
        valorMin?: number,
        /**
         * Identifica univocamente uma campanha
         */
        viagemCampanha?: string,
    }): CancelablePromise<PaginatedRefsMultibancoList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/pagamento/ref',
            query: {
                'data_fim_after': dataFimAfter,
                'data_fim_before': dataFimBefore,
                'data_inicio_after': dataInicioAfter,
                'data_inicio_before': dataInicioBefore,
                'email': email,
                'entidade': entidade,
                'estado': estado,
                'grupo': grupo,
                'identificador': identificador,
                'instituicao': instituicao,
                'nome': nome,
                'nome_cliente': nomeCliente,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'referencia': referencia,
                'telemovel': telemovel,
                'valor_max': valorMax,
                'valor_min': valorMin,
                'viagem__campanha': viagemCampanha,
            },
        });
    }

    /**
     * @returns RefsMultibanco
     * @throws ApiError
     */
    public static pagamentoRefRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this referencia multibanco.
         */
        id: number,
    }): CancelablePromise<RefsMultibanco> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/pagamento/ref/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns RefsMultibanco
     * @throws ApiError
     */
    public static pagamentoRefInvalidateRefRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this referencia multibanco.
         */
        id: number,
    }): CancelablePromise<RefsMultibanco> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/pagamento/ref/{id}/invalidate_ref',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns RefsMultibanco
     * @throws ApiError
     */
    public static pagamentoRefRefreshStateRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this referencia multibanco.
         */
        id: number,
    }): CancelablePromise<RefsMultibanco> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/pagamento/ref/{id}/refresh_state',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns RefsMultibanco
     * @throws ApiError
     */
    public static pagamentoRefNewCloseCreate({
        requestBody,
    }: {
        requestBody: RefsMultibancoRequest,
    }): CancelablePromise<RefsMultibanco> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/pagamento/ref/new_close',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns RefsMultibanco
     * @throws ApiError
     */
    public static pagamentoRefNewCloseUpdate({
        requestBody,
    }: {
        requestBody: RefsMultibancoRequest,
    }): CancelablePromise<RefsMultibanco> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/pagamento/ref/new_close',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PagamentosStats
     * @throws ApiError
     */
    public static pagamentoStatsList({
        campanha,
        banco,
        creationDateAfter,
        creationDateBefore,
        dataFacturacaoAfter,
        dataFacturacaoBefore,
        email,
        endDate,
        entidade,
        estado,
        grupo,
        instituicao,
        nome,
        ordering,
        referencia,
        search,
        startDate,
        telemovel,
        tipo,
        trid,
        valorMax,
        valorMin,
        viagem,
        viagemCampanha,
    }: {
        campanha: string,
        banco?: string,
        creationDateAfter?: string,
        creationDateBefore?: string,
        dataFacturacaoAfter?: string,
        dataFacturacaoBefore?: string,
        email?: string,
        endDate?: string,
        entidade?: string,
        /**
         * * `Pendente` - Pedente
         * * `Pago` - Pago
         * * `Anulado` - Anulado
         */
        estado?: 'Anulado' | 'Pago' | 'Pendente',
        grupo?: string,
        instituicao?: string,
        nome?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        referencia?: string,
        /**
         * A search term.
         */
        search?: string,
        startDate?: string,
        telemovel?: string,
        tipo?: string,
        trid?: string,
        valorMax?: number,
        valorMin?: number,
        viagem?: number,
        /**
         * Identifica univocamente uma campanha
         */
        viagemCampanha?: string,
    }): CancelablePromise<Array<PagamentosStats>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/pagamento/stats',
            query: {
                'banco': banco,
                'campanha': campanha,
                'creation_date_after': creationDateAfter,
                'creation_date_before': creationDateBefore,
                'data_facturacao_after': dataFacturacaoAfter,
                'data_facturacao_before': dataFacturacaoBefore,
                'email': email,
                'end_date': endDate,
                'entidade': entidade,
                'estado': estado,
                'grupo': grupo,
                'instituicao': instituicao,
                'nome': nome,
                'ordering': ordering,
                'referencia': referencia,
                'search': search,
                'start_date': startDate,
                'telemovel': telemovel,
                'tipo': tipo,
                'trid': trid,
                'valor_max': valorMax,
                'valor_min': valorMin,
                'viagem': viagem,
                'viagem__campanha': viagemCampanha,
            },
        });
    }

    /**
     * @returns PagamentosInstituicaoStats
     * @throws ApiError
     */
    public static pagamentoStatsGruposList({
        campanha,
        banco,
        creationDateAfter,
        creationDateBefore,
        dataFacturacaoAfter,
        dataFacturacaoBefore,
        email,
        endDate,
        entidade,
        estado,
        grupo,
        instituicao,
        nome,
        ordering,
        referencia,
        search,
        startDate,
        telemovel,
        tipo,
        trid,
        valorMax,
        valorMin,
        viagem,
        viagemCampanha,
    }: {
        campanha: string,
        banco?: string,
        creationDateAfter?: string,
        creationDateBefore?: string,
        dataFacturacaoAfter?: string,
        dataFacturacaoBefore?: string,
        email?: string,
        endDate?: string,
        entidade?: string,
        /**
         * * `Pendente` - Pedente
         * * `Pago` - Pago
         * * `Anulado` - Anulado
         */
        estado?: 'Anulado' | 'Pago' | 'Pendente',
        grupo?: string,
        instituicao?: string,
        nome?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        referencia?: string,
        /**
         * A search term.
         */
        search?: string,
        startDate?: string,
        telemovel?: string,
        tipo?: string,
        trid?: string,
        valorMax?: number,
        valorMin?: number,
        viagem?: number,
        /**
         * Identifica univocamente uma campanha
         */
        viagemCampanha?: string,
    }): CancelablePromise<Array<PagamentosInstituicaoStats>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/pagamento/stats_grupos',
            query: {
                'banco': banco,
                'campanha': campanha,
                'creation_date_after': creationDateAfter,
                'creation_date_before': creationDateBefore,
                'data_facturacao_after': dataFacturacaoAfter,
                'data_facturacao_before': dataFacturacaoBefore,
                'email': email,
                'end_date': endDate,
                'entidade': entidade,
                'estado': estado,
                'grupo': grupo,
                'instituicao': instituicao,
                'nome': nome,
                'ordering': ordering,
                'referencia': referencia,
                'search': search,
                'start_date': startDate,
                'telemovel': telemovel,
                'tipo': tipo,
                'trid': trid,
                'valor_max': valorMax,
                'valor_min': valorMin,
                'viagem': viagem,
                'viagem__campanha': viagemCampanha,
            },
        });
    }

}
