import CampanhaEdit from './CampanhaEdit';
import { CheckPermission } from '../../../components/CheckPermission';
import { SlideinRoleEnum } from '../../../slidein_api';
import { PrestacoesTipo } from './PrestacoesTipo';
import { Tabs } from '@mantine/core';
import { IconLock, IconManualGearbox, IconReceipt2 } from '@tabler/icons-react';
import React from 'react';
import { CampanhaEstados } from './CampanhaEstados';

export function CampanhaDefinicoes() {
  return (
    <CheckPermission
      roles={[SlideinRoleEnum.ADMIN, SlideinRoleEnum.MASTER]}
      goHome={true}>
      <Tabs defaultValue={'defs'}>
        <Tabs.List>
          <Tabs.Tab
            icon={<IconManualGearbox />}
            value={'defs'}>
            Definições
          </Tabs.Tab>
          <Tabs.Tab
            icon={<IconReceipt2 />}
            value={'presta'}>
            Prestações Por Omissão
          </Tabs.Tab>
          <CheckPermission roles={[SlideinRoleEnum.MASTER]}>
            <Tabs.Tab
              value={'estados'}
              icon={<IconLock />}>
              Bloquear...
            </Tabs.Tab>
          </CheckPermission>
        </Tabs.List>
        <Tabs.Panel value={'defs'}>
          <CampanhaEdit create={false} />
        </Tabs.Panel>
        <Tabs.Panel value={'presta'}>
          <PrestacoesTipo />
        </Tabs.Panel>
        <CheckPermission roles={[SlideinRoleEnum.MASTER]}>
          <Tabs.Panel value={'estados'}>
            <CampanhaEstados />
          </Tabs.Panel>
        </CheckPermission>
      </Tabs>
    </CheckPermission>
  );
}
