import React, { useEffect, useMemo, useState } from 'react';
import {
  Actividade,
  ApiError,
  CompraService,
  Desconto,
  GrupoItem,
  GrupoService,
  OpenAPI,
  Pacote,
  ProdutoService,
  SlideinRoleEnum,
  Suplemento,
  ViagemService,
  ViagemSimples,
} from '../../../slidein_api';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { iconBool, iconBoolOnlyTrue, msgAPIError } from '../../helpers';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_GlobalFilterTextInput,
  MRT_SelectCheckbox,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  useMantineReactTable,
} from 'mantine-react-table';
import {
  Button,
  Divider,
  Flex,
  Group,
  LoadingOverlay,
  Modal,
  Stack,
  Switch,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import {
  IconCheck,
  IconCircleCheck,
  IconCircleX,
  IconDeviceFloppy,
  IconDownload,
  IconFileSpreadsheet,
  IconLock,
  IconLockOpen,
  IconSend,
  IconTrash,
  IconTrashX,
  IconUserCancel,
  IconUserEdit,
  IconX,
} from '@tabler/icons-react';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import {
  CheckPermission,
  checkPermission,
  MASTER,
  MASTER_ADMIN,
  MASTER_FINANCE,
} from '../../../components/CheckPermission';
import { useAuth } from '../../../user-auth/SlideInAuthContext';
import dayjs from 'dayjs';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import axios from 'axios';
import FileSaver from 'file-saver';

function getRows(table) {
  if (table?.getSelectedRowModel() && table.getSelectedRowModel().rows) {
    return table.getSelectedRowModel().rows;
  } else {
    return [];
  }
}

export function GrupoViagem() {
  const theme = useMantineTheme();
  const { grupoID } = useParams();
  const { colaborador } = useAuth();
  const { campanhaID } = useParams();
  const [pacotes, setPacotes] = useState<Pacote[]>([]);
  const [actividades, setActividades] = useState<Actividade[]>([]);
  const [suplementos, setSuplementos] = useState<Suplemento[]>([]);
  const [descontos, setDescontos] = useState<Desconto[]>([]);
  const [removeMode, setRemoveMode] = useState<boolean>(false);
  const [pacotesModalVisible, setPacotesModalVisible] = useState<boolean>(false);
  const [actividadesModalVisible, setActividadesModalVisible] = useState<boolean>(false);
  const [suplementosModalVisible, setSuplementosModalVisible] = useState<boolean>(false);
  const [descontosModalVisible, setDescontosModalVisible] = useState<boolean>(false);
  useNavigate();
  const [viagens, setViagens] = useState<ViagemSimples[]>([]);
  const [isRefetching, setIsRefetching] = useState(false);
  const [loadingCenas, setLoadingCenas] = useState(false);
  const [grupos, setGrupos] = useState<GrupoItem[]>([]);
  const [moveGrupoVisible, setMoveGrupoVisible] = useState<boolean>(false);

  const fetchData = () => {
    setIsRefetching(true);
    if (checkPermission(MASTER, colaborador)) {
      GrupoService.grupoItemList({ campanha: campanhaID }).then((value) => {
        setGrupos(value);
      });
      ProdutoService.produtoPacoteList({ campanha: campanhaID }).then(
        (pacotesall) => {
          setPacotes(pacotesall);
        },
        (reason) => msgAPIError(reason)
      );
      ProdutoService.produtoActividadeList({ campanha: campanhaID }).then(
        (pacotesall) => {
          setActividades(pacotesall);
        },
        (reason) => msgAPIError(reason)
      );
      ProdutoService.produtoSuplementoList({ campanha: campanhaID }).then(
        (pacotesall) => {
          setSuplementos(pacotesall);
        },
        (reason) => msgAPIError(reason)
      );
      ProdutoService.produtoDescontoList({ campanha: campanhaID }).then(
        (pacotesall) => {
          setDescontos(pacotesall);
        },
        (reason) => msgAPIError(reason)
      );
    }
    ViagemService.viagemSimplesList({ grupo: grupoID }).then(
      (vis) => {
        setViagens(vis);
        setIsRefetching(false);
      },
      (reason) => {
        setIsRefetching(false);
        msgAPIError(reason);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, []);
  const columnsComercial = useMemo<MRT_ColumnDef<ViagemSimples>[]>(
    () => [
      {
        accessorFn: (vi) => vi.chefe_de_grupo,
        id: 'chefeDeGrupo',
        enableGlobalFilter: false,
        header: 'Chefe de Grupo',
        filterVariant: 'checkbox',
        Cell: (props: { cell }) => {
          return (
            <Switch
              size={'sm'}
              onLabel={<IconCheck />}
              offLabel={<IconX />}
              disabled={props.cell.row.original.cancelada}
              checked={props.cell.row.original.chefe_de_grupo}
              onChange={(event) => {
                ViagemService.viagemChefePartialUpdate({
                  id: props.cell.row.original.id,
                  requestBody: {
                    chefe_de_grupo: event.currentTarget.checked,
                  },
                }).then(
                  () => {
                    fetchData();
                  },
                  (reason) => {
                    msgAPIError(reason);
                  }
                );
              }}></Switch>
          );
        },
      },
      {
        accessorKey: 'cancelada',
        enableGlobalFilter: false,
        id: 'cancelada',
        header: 'Cancelada',
        Cell: ({ cell }) => iconBool(cell.row.original.cancelada),
        filterVariant: 'checkbox',
        Footer: (props: { table }) => {
          return (
            <b>
              {props.table
                .getFilteredRowModel()
                .rows.reduce((acu, rr) => (rr.original.cancelada ? ++acu : acu), 0)}
              &nbsp; Cancelados
            </b>
          );
        },
      },
      {
        accessorKey: 'bloqueada_pagamentos',
        enableGlobalFilter: false,
        id: 'bloqueadaPagamentos',
        header: 'Bloqueada Falta Pagamentos',
        filterVariant: 'checkbox',
        Cell: ({ cell }) => iconBool(cell.row.original.bloqueada_pagamentos),
        Footer: (props: { table }) => {
          return (
            <b>
              {props.table
                .getFilteredRowModel()
                .rows.reduce((acu, rr) => (rr.original.bloqueada_pagamentos ? ++acu : acu), 0)}
              &nbsp; Bloqueada Falta Pagamentos
            </b>
          );
        },
      },
      {
        accessorFn: (vi) => vi.idade_no_fim,
        enableGlobalFilter: false,
        header: 'Idade(Fim da Viagem)',
        id: 'idadeNoFim',
        filterVariant: 'range',
      },

      {
        accessorKey: 'tem_deca_menores',
        enableGlobalFilter: false,
        id: 'temDecaMenores',
        header: 'Tem Dec.Menores',
        filterVariant: 'checkbox',
        Cell: ({ cell }) => iconBool(cell.row.original.tem_deca_menores),
        Footer: (props: { table }) => {
          return (
            <b>
              {props.table
                .getFilteredRowModel()
                .rows.reduce(
                  (acu, rr) =>
                    !rr.original.tem_deca_menores && rr.original.idade_no_fim < 18 ? ++acu : acu,
                  0
                )}
              &nbsp; Dec. em falta
            </b>
          );
        },
      },
      {
        accessorFn: (originalRow) => originalRow.cliente.nome_simples,
        id: 'nomeCompleto',
        header: 'Nome',
        filterVariant: 'text',
        filterFn: 'contains',
      },
      {
        accessorFn: (vi) => vi.cliente.email,
        id: 'email',
        header: 'Email',
        filterVariant: 'text',
        filterFn: 'contains',
        Cell: (props: { renderedCellValue }) => (
          <a href={`mailto:${props.renderedCellValue}`}>{props.renderedCellValue}</a>
        ),
      },
      {
        accessorFn: (vi) => vi.cliente.contacto_telemovel,
        id: 'contactoTelemovel',
        header: 'Telemovel',
        enableColumnFilter: false,
      },
      {
        accessorFn: (vi) => vi.cliente.genero_sexual,
        enableGlobalFilter: false,
        header: 'Género',
        id: 'generoSexual',
        filterVariant: 'text',
      },

      {
        accessorFn: (vi) => dayjs(vi.cliente.data_nascimento).format('YYYY-MM-DD'),
        enableGlobalFilter: false,
        id: 'dataNascimento',
        header: 'Data Nascimento',
        filterVariant: 'date-range',
      },

      {
        accessorFn: (row) => row.alojamento,
        id: 'alojamento',
        header: 'Alojamento',
        filterVariant: 'text',
        filterFn: 'contains',
      },

      {
        accessorKey: 'valor_total',
        enableGlobalFilter: false,
        id: 'valorTotal',
        header: 'Total',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ cell }) => {
          return cell.row.original.valor_total + '€';
        },
        Footer: (props: { table }) => {
          return (
            <b>
              {props.table
                .getFilteredRowModel()
                .rows.reduce((acu, rr) => acu + rr.original.valor_total || 0, 0)}
              €
            </b>
          );
        },
      },
      {
        accessorKey: 'valor_pago',
        enableGlobalFilter: false,
        id: 'valorPago',
        header: 'Pago',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ cell }) => {
          return cell.row.original.valor_pago + '€';
        },
        Footer: (props: { table }) => {
          return (
            <b>
              {props.table
                .getFilteredRowModel()
                .rows.reduce((acu, rr) => acu + rr.original.valor_pago || 0, 0)}
              €
            </b>
          );
        },
      },
      {
        accessorKey: 'valor_devido',
        enableGlobalFilter: false,
        id: 'valorDevido',
        header: 'Devido',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ cell }) => {
          return cell.row.original.valor_devido + '€';
        },
        Footer: (props: { table }) => {
          return (
            <b>
              {props.table
                .getFilteredRowModel()
                .rows.reduce((acu, rr) => acu + rr.original.valor_devido || 0, 0)}
              €
            </b>
          );
        },
      },
      {
        accessorFn: (vi) => vi.quarto || '-',
        id: 'quarto',
        header: 'Quarto',
        enableColumnFilter: false,
      },
      {
        accessorFn: (vi) => vi.transporte || '-',
        id: 'transporte',
        header: 'Transporte',
        enableColumnFilter: false,
      },

      {
        accessorFn: (vi) => vi.grupo.instituicao || '-',
        id: 'instituicao',
        header: 'Instituição',
        filterVariant: 'text',
      },
    ],
    []
  );
  const columnsAdmin = useMemo<MRT_ColumnDef<ViagemSimples>[]>(
    () => [
      {
        accessorFn: (vi) => vi.chefe_de_grupo,
        id: 'chefeDeGrupo',
        header: 'Chefe de Grupo',
        enableGlobalFilter: false,
        filterVariant: 'checkbox',
        Cell: (props: { cell }) => {
          return (
            <Switch
              size={'sm'}
              onLabel={<IconCheck />}
              offLabel={<IconX />}
              disabled={props.cell.row.original.cancelada}
              checked={props.cell.row.original.chefe_de_grupo}
              onChange={(event) => {
                ViagemService.viagemChefePartialUpdate({
                  id: props.cell.row.original.id,
                  requestBody: {
                    chefe_de_grupo: event.currentTarget.checked,
                  },
                }).then(
                  () => {
                    fetchData();
                  },
                  (reason) => {
                    msgAPIError(reason);
                  }
                );
              }}></Switch>
          );
        },
      },
      {
        accessorFn: (vi) => vi.cancelada,
        id: 'cancelada',
        header: 'Cancelada',
        enableGlobalFilter: false,
        filterVariant: 'checkbox',
        Cell: (cell) => {
          if (cell.row.original.cancelada) {
            return iconBoolOnlyTrue(cell.row.original.cancelada);
          } else {
            return (
              <Button
                variant='outline'
                size={'xs'}
                color='cyan'
                leftIcon={<IconUserCancel />}
                onClick={() => {
                  modals.openConfirmModal({
                    title: 'Cancelar Viagem!',
                    centered: true,
                    children: (
                      <Text size='sm'>
                        Confirma que deseja cancelar a viagem de{' '}
                        {cell.row.original.cliente.nome_simples}?
                      </Text>
                    ),
                    labels: { confirm: 'Cancelar Viagem', cancel: 'sair' },
                    confirmProps: { color: 'red', variant: 'outline', leftIcon: <IconTrash /> },
                    cancelProps: { color: 'yellow', variant: 'outline', leftIcon: <IconX /> },
                    onConfirm: () => {
                      ViagemService.viagemCancelViagemRetrieve({ id: cell.row.original?.id }).then(
                        () => {
                          // fetchData();
                          notifications.show({
                            title: 'Cancelamento com sucesso ',
                            message: `A Viagem ${cell.row.original?.cliente.nome_simples} foi cancelada.`,
                            withCloseButton: true,
                            icon: <IconTrashX />,
                            color: 'green',
                          });
                          window.location.reload();
                        },
                        (reason: ApiError) => {
                          msgAPIError(reason);
                        }
                      );
                    },
                  });
                }}>
                Cancelar
              </Button>
            );
          }
        },
        Footer: (props: { table }) => {
          return (
            <b>
              {props.table
                .getFilteredRowModel()
                .rows.reduce((acu, rr) => (rr.original.cancelada ? ++acu : acu), 0)}
              &nbsp; Cancelados
            </b>
          );
        },
      },
      {
        accessorKey: 'bloqueada_pagamentos',
        id: 'bloqueadaPagamentos',
        enableGlobalFilter: false,
        header: 'Bloqueada Falta Pagamentos',
        filterVariant: 'checkbox',
        Cell: ({ cell }) => iconBool(cell.row.original.bloqueada_pagamentos),
        Footer: (props: { table }) => {
          return (
            <b>
              {props.table
                .getFilteredRowModel()
                .rows.reduce((acu, rr) => (rr.original.bloqueada_pagamentos ? ++acu : acu), 0)}
              &nbsp; Bloqueada Falta Pagamentos
            </b>
          );
        },
      },
      {
        accessorFn: (vi) => vi.idade_no_fim,
        header: 'Idade(Fim da Viagem)',
        enableGlobalFilter: false,
        id: 'idadeNoFim',
        filterVariant: 'range',
      },

      {
        accessorKey: 'tem_deca_menores',
        id: 'temDecaMenores',
        header: 'Dec.Menores',
        enableGlobalFilter: false,
        filterVariant: 'checkbox',
        Cell: ({ cell }) => iconBool(cell.row.original.tem_deca_menores),
        Footer: (props: { table }) => {
          return (
            <b>
              {props.table
                .getFilteredRowModel()
                .rows.reduce(
                  (acu, rr) =>
                    !rr.original.tem_deca_menores && rr.original.idade_no_fim < 18 ? ++acu : acu,
                  0
                )}
              &nbsp; Dec. em falta
            </b>
          );
        },
      },
      {
        accessorFn: (originalRow) => originalRow.cliente.nome_simples || '-',
        id: 'nomeCompleto',
        header: 'Nome',
        filterVariant: 'text',
        filterFn: 'contains',
      },
      {
        accessorFn: (vi) => vi.cliente.email,
        id: 'email',
        header: 'Email',
        filterVariant: 'text',
        filterFn: 'contains',
        Cell: (props: { renderedCellValue }) => (
          <a href={`mailto:${props.renderedCellValue}`}>{props.renderedCellValue}</a>
        ),
      },
      {
        accessorFn: (vi) => vi.cliente.contacto_telemovel,
        id: 'contactoTelemovel',
        header: 'Telemovel',
        enableColumnFilter: false,
      },
      {
        accessorFn: (vi) => vi.cliente.genero_sexual,
        header: 'Género',
        enableGlobalFilter: false,
        id: 'generoSexual',
        filterVariant: 'text',
      },

      {
        accessorFn: (vi) => dayjs(vi.cliente.data_nascimento).format('YYYY-MM-DD'),
        id: 'dataNascimento',
        header: 'Data Nascimento',
        enableGlobalFilter: false,
        filterVariant: 'date-range',
      },

      {
        accessorFn: (row) => row.alojamento,
        id: 'alojamento',
        header: 'Alojamento',
        filterVariant: 'text',
        filterFn: 'contains',
      },

      {
        accessorKey: 'valor_total',
        id: 'valorTotal',
        header: 'Total',
        enableGlobalFilter: false,
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ cell }) => {
          return cell.row.original.valor_total + '€';
        },
        Footer: (props: { table }) => {
          return (
            <b>
              {props.table
                .getFilteredRowModel()
                .rows.reduce((acu, rr) => acu + rr.original.valor_total || 0, 0)}
              €
            </b>
          );
        },
      },
      {
        accessorKey: 'valor_pago',
        id: 'valorPago',
        enableGlobalFilter: false,
        header: 'Pago',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ cell }) => {
          return cell.row.original.valor_pago + '€';
        },
        Footer: (props: { table }) => {
          return (
            <b>
              {props.table
                .getFilteredRowModel()
                .rows.reduce((acu, rr) => acu + rr.original.valor_pago || 0, 0)}
              €
            </b>
          );
        },
      },
      {
        accessorKey: 'valor_devido',
        id: 'valorDevido',
        enableGlobalFilter: false,
        header: 'Devido',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ cell }) => {
          return cell.row.original.valor_devido + '€';
        },
        Footer: (props: { table }) => {
          return (
            <b>
              {props.table
                .getFilteredRowModel()
                .rows.reduce((acu, rr) => acu + rr.original.valor_devido || 0, 0)}
              €
            </b>
          );
        },
      },
      {
        accessorFn: (vi) => vi.quarto || '-',
        id: 'quarto',
        header: 'Quarto',
        enableColumnFilter: false,
      },
      {
        accessorFn: (vi) => vi.transporte || '-',
        id: 'transporte',
        header: 'Transporte',
        enableColumnFilter: false,
      },

      {
        accessorFn: (vi) => vi.grupo.instituicao,
        id: 'instituicao',
        header: 'Instituição',
        filterVariant: 'text',
      },
    ],
    []
  );

  const columns = useMemo<MRT_ColumnDef<ViagemSimples>[]>(
    () => [
      {
        accessorKey: 'chefe_de_grupo',
        id: 'chefeDeGrupo',
        header: 'Chefe de Grupo',
        enableGlobalFilter: false,
        filterVariant: 'checkbox',
        Cell: (props: { cell }) => {
          return (
            <Switch
              size={'sm'}
              onLabel={<IconCheck />}
              offLabel={<IconX />}
              disabled={props.cell.row.original.cancelada}
              checked={props.cell.row.original.chefe_de_grupo}
              onChange={(event) => {
                ViagemService.viagemChefePartialUpdate({
                  id: props.cell.row.original.id,
                  requestBody: {
                    chefe_de_grupo: event.currentTarget.checked,
                  },
                }).then(
                  () => {
                    fetchData();
                  },
                  (reason) => {
                    msgAPIError(reason);
                  }
                );
              }}></Switch>
          );
        },
      },
      {
        accessorKey: 'nao_notificar',
        id: 'naoNotificar',
        header: 'Não Notificar',
        enableGlobalFilter: false,
        filterVariant: 'checkbox',
        Cell: (props: { cell }) => {
          return (
            <Switch
              size={'sm'}
              onLabel={<IconCheck />}
              offLabel={<IconX />}
              disabled={props.cell.row.original.cancelada}
              checked={props.cell.row.original.nao_notificar}
              onChange={(event) => {
                ViagemService.viagemPartialUpdate({
                  id: props.cell.row.original.id,
                  requestBody: {
                    nao_notificar: event.currentTarget.checked,
                  },
                }).then(
                  () => {
                    fetchData();
                  },
                  (reason) => {
                    msgAPIError(reason);
                  }
                );
              }}></Switch>
          );
        },
      },
      {
        accessorKey: 'vfree',
        id: 'vfree',
        header: 'Free Trip',
        enableGlobalFilter: false,
        filterVariant: 'checkbox',
        Cell: (props: { cell }) => {
          return (
            <Switch
              size={'sm'}
              onLabel={<IconCheck />}
              offLabel={<IconX />}
              disabled={props.cell.row.original.cancelada}
              checked={props.cell.row.original.vfree}
              onChange={(event) => {
                ViagemService.viagemPartialUpdate({
                  id: props.cell.row.original.id,
                  requestBody: {
                    vfree: event.currentTarget.checked,
                  },
                }).then(
                  () => {
                    fetchData();
                  },
                  (reason) => {
                    msgAPIError(reason);
                  }
                );
              }}></Switch>
          );
        },
      },
      {
        accessorFn: (vi) => vi.cancelada,
        id: 'cancelada',
        header: 'Cancelada',
        enableGlobalFilter: false,
        filterVariant: 'checkbox',
        Cell: (cell) => {
          if (cell.row.original.cancelada) {
            return iconBoolOnlyTrue(cell.row.original.cancelada);
          } else {
            return (
              <Button
                variant='outline'
                size={'xs'}
                color='cyan'
                leftIcon={<IconUserCancel />}
                onClick={() => {
                  modals.openConfirmModal({
                    title: 'Cancelar Viagem!',
                    centered: true,
                    children: (
                      <Text size='sm'>
                        Confirma que deseja cancelar a viagem de{' '}
                        {cell.row.original.cliente.nome_simples}?
                      </Text>
                    ),
                    labels: { confirm: 'Cancelar Viagem', cancel: 'sair' },
                    confirmProps: { color: 'red', variant: 'outline', leftIcon: <IconTrash /> },
                    cancelProps: { color: 'yellow', variant: 'outline', leftIcon: <IconX /> },
                    onConfirm: () => {
                      ViagemService.viagemCancelViagemRetrieve({ id: cell.row.original?.id }).then(
                        () => {
                          // fetchData();
                          notifications.show({
                            title: 'Cancelamento com sucesso ',
                            message: `A Viagem ${cell.row.original?.cliente.nome_simples} foi cancelada.`,
                            withCloseButton: true,
                            icon: <IconTrashX />,
                            color: 'green',
                          });
                          window.location.reload();
                        },
                        (reason: ApiError) => {
                          msgAPIError(reason);
                        }
                      );
                    },
                  });
                }}>
                Cancelar
              </Button>
            );
          }
        },
        Footer: (props: { table }) => {
          return (
            <b>
              {props.table
                .getFilteredRowModel()
                .rows.reduce((acu, rr) => (rr.original.cancelada ? ++acu : acu), 0)}
              &nbsp; Cancelados
            </b>
          );
        },
      },
      {
        accessorFn: (vi) => vi.bloqueada_pagamentos,
        id: 'bloqueadaPagamentos',
        header: 'Bloqueada pagamentos',
        enableGlobalFilter: false,
        filterVariant: 'checkbox',
        Cell: (props: { cell }) => {
          return (
            <Switch
              size={'sm'}
              onLabel={<IconCheck />}
              offLabel={<IconX />}
              disabled={props.cell.row.original.cancelada}
              checked={props.cell.row.original.bloqueada_pagamentos}
              onChange={(event) => {
                if (event.currentTarget.checked) {
                  ViagemService.viagemBloquearFaltaPagamentoRetrieve({
                    id: props.cell.row.original.id,
                  }).then(
                    () => {
                      fetchData();
                    },
                    (reason) => {
                      msgAPIError(reason);
                    }
                  );
                } else {
                  ViagemService.viagemDesbloquearFaltaPagamentoRetrieve({
                    id: props.cell.row.original.id,
                  }).then(
                    () => {
                      fetchData();
                    },
                    (reason) => {
                      msgAPIError(reason);
                    }
                  );
                }
              }}></Switch>
          );
        },
        Footer: (props: { table }) => {
          return (
            <b>
              {props.table
                .getFilteredRowModel()
                .rows.reduce((acu, rr) => (rr.original.bloqueada_pagamentos ? ++acu : acu), 0)}
              &nbsp; Bloqueadas
            </b>
          );
        },
      },
      {
        accessorFn: (row) => row.nr_factura,
        id: 'nrFactura',
        header: 'Nr factura',
        filterVariant: 'text',
        filterFn: 'contains',
      },
      {
        accessorFn: (vi) => vi.idade_no_fim,
        header: 'Idade(Fim da Viagem)',
        enableGlobalFilter: false,
        id: 'idadeNoFim',
        filterVariant: 'range',
      },

      {
        accessorKey: 'tem_deca_menores',
        id: 'temDecaMenores',
        enableGlobalFilter: false,
        header: 'Dec.Menores',
        filterVariant: 'checkbox',
        Cell: ({ cell }) => iconBool(cell.row.original.tem_deca_menores),
        Footer: (props: { table }) => {
          return (
            <b>
              {props.table
                .getFilteredRowModel()
                .rows.reduce(
                  (acu, rr) =>
                    !rr.original.tem_deca_menores && rr.original.idade_no_fim < 18 ? ++acu : acu,
                  0
                )}
              &nbsp; Dec. em falta
            </b>
          );
        },
      },
      {
        accessorFn: (originalRow) => originalRow.cliente.nome_simples,
        id: 'nomeCompleto',
        header: 'Nome',
        filterVariant: 'text',
        filterFn: 'contains',
        Cell: (props: { renderedCellValue; row }) => {
          if (checkPermission(MASTER_FINANCE, colaborador)) {
            return (
              <Link to={'/campanha/' + campanhaID + '/viagem/' + props.row.original.id}>
                {props.renderedCellValue}
              </Link>
            );
          } else {
            return props.renderedCellValue;
          }
        },
      },
      {
        accessorFn: (vi) => vi.cliente.email,
        id: 'email',
        header: 'Email',
        filterVariant: 'text',
        filterFn: 'contains',
        Cell: (props: { renderedCellValue }) => (
          <a href={`mailto:${props.renderedCellValue}`}>{props.renderedCellValue}</a>
        ),
      },
      {
        accessorFn: (vi) => vi.cliente.contacto_telemovel,
        id: 'contactoTelemovel',
        header: 'Telemovel',
        filterVariant: 'text',
        filterFn: 'contains',
      },
      {
        accessorFn: (vi) => vi.cliente.genero_sexual,
        header: 'Género',
        id: 'generoSexual',
        enableGlobalFilter: false,
        filterVariant: 'text',
      },
      {
        accessorFn: (vi) => vi.cliente.identificacao,
        id: 'nrid',
        header: 'Nr ID',
        filterVariant: 'text',
        filterFn: 'contains',
      },
      {
        accessorFn: (vi) => vi.cliente.tipo_id,
        id: 'tipoId',
        header: 'Tipo ID',
        filterVariant: 'text',
        filterFn: 'contains',
        enableGlobalFilter: false,
      },
      {
        accessorFn: (vi) => dayjs(vi.cliente.data_nascimento).format('YYYY-MM-DD'),
        id: 'dataNascimento',
        enableGlobalFilter: false,
        header: 'Data Nascimento',
        filterVariant: 'date-range',
      },
      {
        accessorFn: (vi) => vi.cliente.email_pais || '-',
        id: 'emailPais',
        header: 'Email Pais',
        filterVariant: 'text',
        filterFn: 'contains',
      },
      {
        accessorFn: (vi) => vi.cliente.email_contacto_urgencia,
        id: 'emailContactoUrgencia',
        header: 'Email Urgência',
        filterVariant: 'text',
        filterFn: 'contains',
      },

      {
        accessorFn: (vi) => vi.cliente.nome_contacto_urgencia,
        id: 'nomeContactoUrgencia',
        header: 'Nome Contacto Urgência',
        filterVariant: 'text',
        filterFn: 'contains',
      },
      {
        accessorFn: (vi) => vi.cliente.contacto_urgencia,
        id: 'contactoUrgencia',
        header: 'Contacto Urgência',
        filterVariant: 'text',
        filterFn: 'contains',
      },
      {
        accessorFn: (row) => row.alojamento,
        id: 'alojamento',
        header: 'Alojamento',
        filterVariant: 'text',
        filterFn: 'contains',
      },
      {
        accessorKey: 'prestacoes_em_atraso',
        id: 'prestacoesEmAtraso',
        enableGlobalFilter: false,
        header: 'Prestações Atrasadas',
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Footer: (props: { table }) => {
          return (
            <b>
              {props.table
                .getFilteredRowModel()
                .rows.reduce((acu, rr) => acu + rr.original.prestacoes_em_atraso || 0, 0)}
              &nbsp; Pag. Atrasados
            </b>
          );
        },
      },

      {
        accessorKey: 'valor_total',
        id: 'valorTotal',
        header: 'Total',
        enableGlobalFilter: false,
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ cell }) => {
          return cell.row.original.valor_total + '€';
        },
        Footer: (props: { table }) => {
          return (
            <b>
              {props.table
                .getFilteredRowModel()
                .rows.reduce((acu, rr) => acu + rr.original.valor_total || 0, 0)}
              €
            </b>
          );
        },
      },
      {
        accessorKey: 'valor_pago',
        id: 'valorPago',
        header: 'Pago',
        enableGlobalFilter: false,
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ cell }) => {
          return cell.row.original.valor_pago + '€';
        },
        Footer: (props: { table }) => {
          return (
            <b>
              {props.table
                .getFilteredRowModel()
                .rows.reduce((acu, rr) => acu + rr.original.valor_pago || 0, 0)}
              €
            </b>
          );
        },
      },
      {
        accessorKey: 'valor_devido',
        id: 'valorDevido',
        header: 'Devido',
        enableGlobalFilter: false,
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ cell }) => {
          return cell.row.original.valor_devido + '€';
        },
        Footer: (props: { table }) => {
          return (
            <b>
              {props.table
                .getFilteredRowModel()
                .rows.reduce((acu, rr) => acu + rr.original.valor_devido || 0, 0)}
              €
            </b>
          );
        },
      },
      {
        accessorFn: (vi) => vi.quarto || '-',
        id: 'quarto',
        header: 'Quarto',
        filterVariant: 'text',
        filterFn: 'contains',
      },
      {
        accessorFn: (vi) => vi.transporte || '-',
        id: 'transporte',
        header: 'Transporte',
        filterVariant: 'text',
        filterFn: 'contains',
      },
      {
        accessorFn: (vi) => vi.grupo.comercial_responsavel,
        id: 'comercialResponsavel',
        header: 'Comercial',
        filterVariant: 'text',
      },
      {
        accessorFn: (vi) => vi.grupo.admin_responsavel,
        id: 'adminResponsavel',
        header: 'Administrador',
        filterVariant: 'text',
      },
      {
        accessorFn: (vi) => vi.grupo.instituicao,
        id: 'instituicao',
        header: 'Instituição',
        filterVariant: 'text',
      },
      {
        accessorKey: 'tamanho_tshirt',
        id: 'tamanhoTshirt',
        header: 'T-shirt',
        enableGlobalFilter: false,
        filterVariant: 'text',
      },
      {
        accessorKey: 'peso',
        id: 'peso',
        header: 'Peso',
        enableGlobalFilter: false,
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
      },
      {
        accessorKey: 'altura',
        id: 'altura',
        header: 'Altura',
        enableGlobalFilter: false,
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
      },
      {
        accessorKey: 'calcado',
        id: 'calcado',
        enableGlobalFilter: false,
        header: 'Calçado',
        filterVariant: 'range',
        filterFn: 'text',
      },
    ],

    []
  );

  const get_columns = () => {
    switch (colaborador.slidein_role) {
      case SlideinRoleEnum.MASTER:
      case SlideinRoleEnum.FINANCE:
        return columns;
      case SlideinRoleEnum.ADMIN:
        return columnsAdmin;
      case SlideinRoleEnum.COMERCIAL:
        return columnsComercial;
      default:
        return [];
    }
  };

  function lockSelected() {
    setLoadingCenas(true);
    let count = 0;
    const viagensChange = getRows(viagemTable);
    viagensChange.forEach((vi) => {
      ViagemService.viagemBloquearFaltaPagamentoRetrieve({
        id: parseInt(vi.original.id),
      }).then(
        () => {
          if (++count >= viagensChange.length) {
            fetchData();
            setLoadingCenas(false);
          }
        },
        (reason) => msgAPIError(reason)
      );
    });
  }

  function cancelSelected() {
    setLoadingCenas(true);
    let count = 0;
    const viagensChange = getRows(viagemTable);
    viagensChange.forEach((vi) => {
      ViagemService.viagemCancelViagemRetrieve({ id: parseInt(vi.original.id) }).then(
        () => {
          if (++count >= viagensChange.length) {
            fetchData();
            setLoadingCenas(false);
          }
        },
        (reason) => msgAPIError(reason)
      );
    });
  }

  function unlockSelected() {
    setLoadingCenas(true);
    let count = 0;
    const viagensChange = getRows(viagemTable);
    viagensChange.forEach((vi) => {
      ViagemService.viagemDesbloquearFaltaPagamentoRetrieve({
        id: parseInt(vi.original.id),
      }).then(
        () => {
          if (++count >= viagensChange.length) {
            fetchData();
            setLoadingCenas(false);
          }
        },
        (reason) => msgAPIError(reason)
      );
    });
  }

  const viagemTable = useMantineReactTable({
    data: viagens,
    columns: get_columns(),
    enableColumnOrdering: true,
    getRowId: (originalRow) => originalRow.id.toString(),
    enableGrouping: true,
    enableMultiRowSelection: checkPermission(MASTER, colaborador),
    enableRowSelection: checkPermission(MASTER, colaborador),
    enableSelectAll: checkPermission(MASTER, colaborador),
    selectAllMode: 'all',
    positionGlobalFilter: 'none',
    renderToolbarAlertBannerContent: ({ selectedAlert, table }) => (
      <Flex justify='space-between'>
        <Flex
          p='6px'
          gap='xl'>
          <MRT_SelectCheckbox
            selectAll
            table={table}
          />
          {selectedAlert}
        </Flex>
        <Flex gap='md'>
          <Button
            variant={'outline'}
            onClick={() => setMoveGrupoVisible(true)}
            leftIcon={<IconUserCancel />}
            color='green'>
            Mover para outro Grupo
          </Button>
          <Divider orientation={'vertical'}></Divider>
          <Button
            variant={'outline'}
            onClick={() => cancelSelected()}
            leftIcon={<IconUserCancel />}
            color='red'>
            Cancelar
          </Button>
          <Divider orientation={'vertical'}></Divider>
          <Stack>
            <Button
              variant={'outline'}
              onClick={() => lockSelected()}
              leftIcon={<IconLock />}
              color='red'>
              Bloquear (Falta de Pagamento)
            </Button>

            <Button
              variant={'outline'}
              onClick={() => unlockSelected()}
              leftIcon={<IconLockOpen />}
              color='green'>
              Desbloquear (Falta de Pagamento)
            </Button>
          </Stack>
          <Divider orientation={'vertical'}></Divider>
          <Button
            variant={'outline'}
            onClick={() => setPacotesModalVisible(true)}
            leftIcon={<IconSend />}
            color='green'>
            Alterar Pacote / Alojamento
          </Button>
          <Divider orientation={'vertical'}></Divider>
          <Stack>
            <Button
              variant={'outline'}
              onClick={() => {
                setRemoveMode(true);
                setActividadesModalVisible(true);
              }}
              leftIcon={<IconLock />}
              color='red'>
              Remover Actividade
            </Button>

            <Button
              variant={'outline'}
              onClick={() => {
                setRemoveMode(false);
                setActividadesModalVisible(true);
              }}
              leftIcon={<IconLockOpen />}
              color='green'>
              Adicionar Actividade
            </Button>
          </Stack>
          <Divider orientation={'vertical'}></Divider>
          <Stack>
            <Button
              variant={'outline'}
              onClick={() => {
                setRemoveMode(true);
                setSuplementosModalVisible(true);
              }}
              leftIcon={<IconLock />}
              color='red'>
              Remover Suplemento
            </Button>

            <Button
              variant={'outline'}
              onClick={() => {
                setRemoveMode(false);
                setSuplementosModalVisible(true);
              }}
              leftIcon={<IconLockOpen />}
              color='green'>
              Adicionar Suplemento
            </Button>
          </Stack>
          <Divider orientation={'vertical'}></Divider>
          <Stack>
            <Button
              variant={'outline'}
              onClick={() => {
                setRemoveMode(true);
                setDescontosModalVisible(true);
              }}
              leftIcon={<IconLock />}
              color='red'>
              Remover Desconto
            </Button>

            <Button
              variant={'outline'}
              onClick={() => {
                setRemoveMode(false);
                setDescontosModalVisible(true);
              }}
              leftIcon={<IconLockOpen />}
              color='green'>
              Adicionar Descontos
            </Button>
          </Stack>
        </Flex>
      </Flex>
    ),
    initialState: {
      density: 'xs',
      showGlobalFilter: true,
      pagination: { pageSize: 15, pageIndex: 0 },
      columnOrder: [
        'mrt-row-select',
        'chefeDeGrupo',
        'naoNotificar',
        'vfree',
        'cancelada',
        'bloqueadaPagamentos',
        'nrFactura',
        'alojamento',
        'idadeNoFim',
        'temDecaMenores',
        'nomeCompleto',
        'nomeSimples',
        'email',
        'contactoTelemovel',
        'generoSexual',
        'comercialResponsavel',
        'prestacoesEmAtraso',
        'valorDevido',
        'valorPago',
        'valorTotal',
        'nrid',
        'dataNascimento',
        'emailPais',
        'emailContactoUrgencia',
        'nomeContactoUrgencia',
        'contactoUrgencia',
        'quarto',
        'transporte',
        'instituicao',
        'tamanhoTshirt',
        'peso',
        'altura',
        'calcado',
      ],
      columnVisibility: {
        'mrt-row-select': true,
        chefeDeGrupo: true,
        naoNotificar: true,
        vfree:true,
        cancelada: true,
        bloqueadaPagamentos: true,
        nrFactura: false,
        idadeNoFim: false,
        temDecaMenores: false,
        nomeSimples: true,
        nomeCompleto: true,
        email: true,
        contactoTelemovel: true,
        generoSexual: false,
        nrid: false,
        alojamento: true,
        dataNascimento: false,
        emailPais: false,
        emailContactoUrgencia: false,
        nomeContactoUrgencia: false,
        contactoUrgencia: false,
        valorTotal: true,
        valorPago: true,
        valorDevido: true,
        quarto: false,
        transporte: false,
        comercialResponsavel: checkPermission(MASTER_ADMIN, colaborador),
        adminResponsavel: checkPermission(MASTER, colaborador),
        instituicao: false,
        tamanhoTshirt: false,
        peso: false,
        altura: false,
        calcado: false,
        prestacoesEmAtraso: true,
      },
    },
    state: {
      showProgressBars: isRefetching,
    },

    mantineTableProps: {
      withColumnBorders: true,
    },

    enableFullScreenToggle: false,
    localization: MRT_Localization_PT,
    globalFilterFn: 'contains',

    mantineTableBodyCellProps: ({ row }) => ({
      sx: {
        textDecoration: row.original.cancelada ? 'line-through' : '',
        fontStyle: row.original.cancelada ? 'italic' : '',
        backgroundColor:
          theme.colorScheme == 'dark'
            ? row.original.prestacoes_em_atraso === 0
              ? 'rgba(0,100,0,0.1)'
              : 'rgba(139,0,0,0.2)'
            : row.original.prestacoes_em_atraso === 0
            ? 'lightgreen'
            : 'palevioletred',
      },
    }),
    renderToolbarInternalActions: ({ table }) => (
      <Flex
        direction={'row'}
        wrap={'wrap'}
        gap={'sm'}
        justify={'space-evenly'}>
        <MRT_GlobalFilterTextInput table={viagemTable} />
        <CheckPermission
          roles={MASTER_FINANCE}
          goHome={false}>
          <Button
            color='lightblue'
            onClick={() => {
              setIsRefetching(true);

              const url = `${OpenAPI.BASE}/gestao/viagem/mapa`;
              axios
                .get(url, {
                  responseType: 'blob',
                  params: {
                    grupo: grupoID,
                  },
                })
                .then(
                  (res) => {
                    const blob = new Blob([res.data], {
                      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
                    });
                    FileSaver.saveAs(blob, `grupo_${grupoID}.xlsx`);
                    setIsRefetching(false);
                    return res;
                  },
                  (reason) => {
                    msgAPIError(reason);
                    setIsRefetching(false);
                  }
                );
            }}
            rightIcon={<IconFileSpreadsheet />}
            leftIcon={<IconDownload />}
            variant='outline'>
            .xlsx
          </Button>
        </CheckPermission>
        <MRT_ShowHideColumnsButton table={viagemTable} />
        <MRT_ToggleFiltersButton table={viagemTable} />
      </Flex>
    ),
  });

  const columnsPacotes = useMemo<MRT_ColumnDef<Pacote>[]>(
    () => [
      { accessorKey: 'nome_interno', header: 'Nome Interno' },
      { accessorKey: 'titulo_cliente', header: 'Nome Cliente' },
      {
        accessorKey: 'activo',
        header: 'Ativo',
        Header: (
          <Tooltip label={'Activo, disponivel para compra'}>
            <div>Act.</div>
          </Tooltip>
        ),
        size: 50,
        Cell: (cell) => {
          return cell.cell.getValue() ? (
            <IconCircleCheck color='green' />
          ) : (
            <IconCircleX color='red' />
          );
        },
      },
      {
        accessorKey: 'valor',
        header: 'Valor',
        size: 100,
        Cell: (cell) => `${cell.cell.getValue()}€`,
      },
    ],
    []
  );
  const columnsActividades = useMemo<MRT_ColumnDef<Actividade>[]>(
    () => [
      { accessorKey: 'nome_interno', header: 'Nome Interno' },
      { accessorKey: 'titulo_cliente', header: 'Nome Cliente' },
      {
        accessorKey: 'activo',
        header: 'Ativo',
        Header: (
          <Tooltip label={'Activo, disponivel para compra'}>
            <div>Act.</div>
          </Tooltip>
        ),
        size: 50,
        Cell: (cell) => {
          return cell.cell.getValue() ? (
            <IconCircleCheck color='green' />
          ) : (
            <IconCircleX color='red' />
          );
        },
      },
      {
        accessorKey: 'valor',
        header: 'Valor',
        size: 100,
        Cell: (cell) => `${cell.cell.getValue()}€`,
      },
    ],
    []
  );
  const columnsSuplementos = useMemo<MRT_ColumnDef<Suplemento>[]>(
    () => [
      { accessorKey: 'nome_interno', header: 'Nome Interno' },
      { accessorKey: 'titulo_cliente', header: 'Nome Cliente' },
      {
        accessorKey: 'activo',
        header: 'Ativo',
        Header: (
          <Tooltip label={'Activo, disponivel para compra'}>
            <div>Act.</div>
          </Tooltip>
        ),
        size: 50,
        Cell: (cell) => {
          return cell.cell.getValue() ? (
            <IconCircleCheck color='green' />
          ) : (
            <IconCircleX color='red' />
          );
        },
      },
      {
        accessorKey: 'valor',
        header: 'Valor',
        size: 100,
        Cell: (cell) => `${cell.cell.getValue()}€`,
      },
    ],
    []
  );
  const columnsDescontos = useMemo<MRT_ColumnDef<Desconto>[]>(
    () => [
      { accessorKey: 'nome_interno', header: 'Nome Interno' },
      { accessorKey: 'titulo_cliente', header: 'Nome Cliente' },
      {
        accessorKey: 'activo',
        header: 'Ativo',
        Header: (
          <Tooltip label={'Activo, disponivel para compra'}>
            <div>Act.</div>
          </Tooltip>
        ),
        size: 50,
        Cell: (cell) => {
          return cell.cell.getValue() ? (
            <IconCircleCheck color='green' />
          ) : (
            <IconCircleX color='red' />
          );
        },
      },
      {
        accessorKey: 'valor',
        header: 'Valor',
        size: 100,
        Cell: (cell) => `${cell.cell.getValue()}€`,
      },
    ],
    []
  );
  const allPacotesTable = useMantineReactTable<Pacote>({
    columns: columnsPacotes,
    data: pacotes || [],
    enableSelectAll: false,
    getRowId: (originalRow) => originalRow.id.toString(),
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    positionToolbarAlertBanner: 'none',
    enableHiding: false,
    enablePagination: false,
    localization: MRT_Localization_PT,
    initialState: {
      density: 'xs',
    },
    enableRowSelection: true,
    enableMultiRowSelection: false,
  });
  const allActividadesTable = useMantineReactTable<Actividade>({
    columns: columnsActividades,
    data: actividades || [],
    enableSelectAll: false,
    getRowId: (originalRow) => originalRow.id.toString(),
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    positionToolbarAlertBanner: 'none',
    enableHiding: false,
    enablePagination: false,
    localization: MRT_Localization_PT,
    initialState: {
      density: 'xs',
    },
    enableRowSelection: true,
    enableMultiRowSelection: false,
  });
  const allSuplementosTable = useMantineReactTable<Suplemento>({
    columns: columnsSuplementos,
    data: suplementos || [],
    enableSelectAll: false,
    getRowId: (originalRow) => originalRow.id.toString(),
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    positionToolbarAlertBanner: 'none',
    enableHiding: false,
    enablePagination: false,
    localization: MRT_Localization_PT,
    initialState: {
      density: 'xs',
    },
    enableRowSelection: true,
    enableMultiRowSelection: false,
  });
  const allDescontosTable = useMantineReactTable<Desconto>({
    columns: columnsDescontos,
    data: descontos || [],
    enableSelectAll: false,
    getRowId: (originalRow) => originalRow.id.toString(),
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    positionToolbarAlertBanner: 'none',
    enableHiding: false,
    enablePagination: false,
    localization: MRT_Localization_PT,
    initialState: {
      density: 'xs',
    },
    enableRowSelection: true,
    enableMultiRowSelection: false,
  });
  const columnsGrupos = useMemo<MRT_ColumnDef<GrupoItem>[]>(
    () => [
      {
        accessorKey: 'codigo',
        header: 'Grupo',
      },
      {
        accessorKey: 'instituicao',
        header: 'Instituição',
        size: 100,
      },
    ],
    []
  );
  const gruposTable = useMantineReactTable({
    columns: columnsGrupos,
    data: grupos || [],
    enableRowSelection: (row) => grupoID != row.original.codigo,
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        opacity: grupoID != row.original.codigo ? '100%' : '35%',
        cursor: 'pointer',
      },
      onClick: row.getToggleSelectedHandler(),
    }),
    enableMultiRowSelection: false,
    positionToolbarAlertBanner: 'none',
    enableGlobalFilter: true,
    enableDensityToggle: false,
    enableFilters: true,
    enableFullScreenToggle: false,
    enableHiding: false,
    enablePagination: true,
    localization: MRT_Localization_PT,
    initialState: {
      density: 'xs',
      showGlobalFilter: true,
    },
  });

  return (
    <Stack>
      <Modal
        centered
        title={'Mover Viagem:'}
        opened={moveGrupoVisible}
        size={'auto'}
        onClose={() => {
          setMoveGrupoVisible(false);
        }}>
        <LoadingOverlay visible={loadingCenas}></LoadingOverlay>
        <MantineReactTable table={gruposTable} />
        <Group
          position='right'
          mt='md'>
          <Button
            color={'yellow'}
            variant={'outline'}
            leftIcon={<IconX />}
            type='button'
            onClick={() => {
              setMoveGrupoVisible(false);
            }}>
            Cancelar
          </Button>
          <Button
            variant={'outline'}
            disabled={gruposTable.getSelectedRowModel().rows.length === 0}
            onClick={() => {
              setLoadingCenas(true);
              let count = 0;
              const viagensChange = getRows(viagemTable);
              viagensChange.forEach((vi) => {
                ViagemService.viagemMoverViajanteRetrieve({
                  grupoDst: gruposTable.getSelectedRowModel().rows[0].original.codigo,
                  id: vi.id,
                }).then(
                  () => {
                    if (++count >= viagensChange.length) {
                      fetchData();
                      setMoveGrupoVisible(false);
                      setLoadingCenas(false);
                    }
                  },
                  (reason) => {
                    msgAPIError(reason);
                    fetchData();
                    setMoveGrupoVisible(false);
                  }
                );
              });
            }}
            leftIcon={<IconUserEdit />}
            type='button'>
            Mover Viagens
          </Button>
        </Group>
      </Modal>
      <Modal
        centered
        title={'Alterar Pacote/Alojamento:'}
        opened={pacotesModalVisible}
        size={'auto'}
        onClose={() => {
          setPacotesModalVisible(false);
        }}>
        <LoadingOverlay visible={loadingCenas}></LoadingOverlay>
        <MantineReactTable table={allPacotesTable} />
        <Group
          position='right'
          mt='md'>
          <Button
            color={'yellow'}
            variant={'outline'}
            leftIcon={<IconX />}
            type='button'
            onClick={() => {
              setPacotesModalVisible(false);
            }}>
            Cancelar
          </Button>
          <Button
            variant={'outline'}
            disabled={allPacotesTable.getSelectedRowModel().rows.length === 0}
            onClick={() => {
              setLoadingCenas(true);
              let count = 0;
              const viagensChange = getRows(viagemTable);
              viagensChange.forEach((vi) => {
                CompraService.compraTrocarPacoteUpdate({
                  id: parseInt(vi.original.compra_pacote_id),
                  requestBody: {
                    produto: allPacotesTable.getSelectedRowModel().rows[0].original.id,
                  },
                }).then(
                  () => {
                    if (++count >= viagensChange.length) {
                      fetchData();
                      setPacotesModalVisible(false);
                      setLoadingCenas(false);
                    }
                  },
                  (reason) => msgAPIError(reason)
                );
              });
            }}
            leftIcon={<IconDeviceFloppy />}
            type='button'>
            Alterar
          </Button>
        </Group>
      </Modal>
      <Modal
        centered
        title={removeMode ? 'Remover Actividade:' : 'Adicionar Actividade'}
        opened={actividadesModalVisible}
        size={'auto'}
        onClose={() => {
          setActividadesModalVisible(false);
        }}>
        <LoadingOverlay visible={loadingCenas}></LoadingOverlay>
        <MantineReactTable table={allActividadesTable} />
        <Group
          position='right'
          mt='md'>
          <Button
            color={'yellow'}
            variant={'outline'}
            leftIcon={<IconX />}
            type='button'
            onClick={() => {
              setActividadesModalVisible(false);
            }}>
            Cancelar
          </Button>
          <Button
            variant={'outline'}
            disabled={allActividadesTable.getSelectedRowModel().rows.length === 0}
            onClick={() => {
              setLoadingCenas(true);
              let count = 0;
              const viagensChange = getRows(viagemTable);
              viagensChange.forEach((vi) => {
                if (removeMode) {
                  CompraService.compraRemoverActividadeCreate({
                    requestBody: {
                      produto: allActividadesTable.getSelectedRowModel().rows[0].original.id,
                      viagem: parseInt(vi.original.id),
                    },
                  }).then(
                    () => {
                      if (++count >= viagensChange.length) {
                        fetchData();
                        setActividadesModalVisible(false);
                        setLoadingCenas(false);
                      }
                    },
                    (reason) => msgAPIError(reason)
                  );
                } else {
                  CompraService.compraAdicionarActividadeCreate({
                    requestBody: {
                      produto: allActividadesTable.getSelectedRowModel().rows[0].original.id,
                      viagem: parseInt(vi.original.id),
                    },
                  }).then(
                    () => {
                      if (++count >= viagensChange.length) {
                        fetchData();
                        setActividadesModalVisible(false);
                        setLoadingCenas(false);
                      }
                    },
                    (reason) => msgAPIError(reason)
                  );
                }
              });
            }}
            leftIcon={<IconDeviceFloppy />}
            type='button'>
            {removeMode ? 'Remover Actividade' : 'Adicionar Actividade'}
          </Button>
        </Group>
      </Modal>
      <Modal
        centered
        title={removeMode ? 'Remover Suplemento:' : 'Adicionar Suplemento'}
        opened={suplementosModalVisible}
        size={'auto'}
        onClose={() => {
          setSuplementosModalVisible(false);
        }}>
        <LoadingOverlay visible={loadingCenas}></LoadingOverlay>
        <MantineReactTable table={allSuplementosTable} />
        <Group
          position='right'
          mt='md'>
          <Button
            color={'yellow'}
            variant={'outline'}
            leftIcon={<IconX />}
            type='button'
            onClick={() => {
              setSuplementosModalVisible(false);
            }}>
            Cancelar
          </Button>
          <Button
            variant={'outline'}
            disabled={allSuplementosTable.getSelectedRowModel().rows.length === 0}
            onClick={() => {
              setLoadingCenas(true);
              let count = 0;
              const viagensChange = getRows(viagemTable);
              viagensChange.forEach((vi) => {
                if (removeMode) {
                  CompraService.compraRemoverSuplementoCreate({
                    requestBody: {
                      produto: allSuplementosTable.getSelectedRowModel().rows[0].original.id,
                      viagem: parseInt(vi.original.id),
                    },
                  }).then(
                    () => {
                      if (++count >= viagensChange.length) {
                        fetchData();
                        setSuplementosModalVisible(false);
                        setLoadingCenas(false);
                      }
                    },
                    (reason) => msgAPIError(reason)
                  );
                } else {
                  CompraService.compraAdicionarSuplementoCreate({
                    requestBody: {
                      produto: allSuplementosTable.getSelectedRowModel().rows[0].original.id,
                      viagem: parseInt(vi.original.id),
                    },
                  }).then(
                    () => {
                      if (++count >= viagensChange.length) {
                        fetchData();
                        setSuplementosModalVisible(false);
                        setLoadingCenas(false);
                      }
                    },
                    (reason) => msgAPIError(reason)
                  );
                }
              });
            }}
            leftIcon={<IconDeviceFloppy />}
            type='button'>
            {removeMode ? 'Remover Suplemento' : 'Adicionar Suplemento'}
          </Button>
        </Group>
      </Modal>
      <Modal
        centered
        title={removeMode ? 'Remover Desconto:' : 'Adicionar Desconto'}
        opened={descontosModalVisible}
        size={'auto'}
        onClose={() => {
          setDescontosModalVisible(false);
        }}>
        <LoadingOverlay visible={loadingCenas}></LoadingOverlay>
        <MantineReactTable table={allDescontosTable} />
        <Group
          position='right'
          mt='md'>
          <Button
            color={'yellow'}
            variant={'outline'}
            leftIcon={<IconX />}
            type='button'
            onClick={() => {
              setDescontosModalVisible(false);
            }}>
            Cancelar
          </Button>
          <Button
            variant={'outline'}
            disabled={allDescontosTable.getSelectedRowModel().rows.length === 0}
            onClick={() => {
              setLoadingCenas(true);
              let count = 0;
              const viagensChange = getRows(viagemTable);
              viagensChange.forEach((vi) => {
                if (removeMode) {
                  CompraService.compraRemoverDescontoCreate({
                    requestBody: {
                      produto: allDescontosTable.getSelectedRowModel().rows[0].original.id,
                      viagem: parseInt(vi.original.id),
                    },
                  }).then(
                    () => {
                      if (++count >= viagensChange.length) {
                        fetchData();
                        setDescontosModalVisible(false);
                        setLoadingCenas(false);
                      }
                    },
                    (reason) => msgAPIError(reason)
                  );
                } else {
                  CompraService.compraAdicionarDescontoCreate({
                    requestBody: {
                      produto: allDescontosTable.getSelectedRowModel().rows[0].original.id,
                      viagem: parseInt(vi.original.id),
                    },
                  }).then(
                    () => {
                      if (++count >= viagensChange.length) {
                        fetchData();
                        setDescontosModalVisible(false);
                        setLoadingCenas(false);
                      }
                    },
                    (reason) => msgAPIError(reason)
                  );
                }
              });
            }}
            leftIcon={<IconDeviceFloppy />}
            type='button'>
            {removeMode ? 'Remover Desconto' : 'Adicionar Desconto'}
          </Button>
        </Group>
      </Modal>

      <MantineReactTable table={viagemTable} />
    </Stack>
  );
}
