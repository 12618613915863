import React, { useContext } from 'react';
import { MenuContext } from './context/MenuContext';
import { useAuth } from '../user-auth/SlideInAuthContext';
import { checkPermission } from '../components/CheckPermission';
import { Divider, Image, Indicator, NavLink, Stack, Title } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { GestaoMenuItem } from './Layout';
import { IconQuestionMark } from '@tabler/icons-react';

interface GestaoNavbarProps {
  toggle: () => void;
}

function GestaoNavbar({ toggle }: GestaoNavbarProps) {
  const { menuCampanha, menuItems } = useContext(MenuContext);
  const { colaborador, isAuthenticated } = useAuth();
  const local = useLocation();

  if (!menuItems || !isAuthenticated) {
    return <></>;
  }
  return (
    <Stack>
      {menuCampanha && (
        <Stack>
          <Title order={4}>{menuCampanha.identificador}&nbsp;By:</Title>
          <Image
            sx={{ backgroundColor: 'whitesmoke', padding: '0.5rem' }}
            src={menuCampanha.marca_ro.logo}
            alt={menuCampanha.marca_ro.nome}
            placeholder={<IconQuestionMark />}
          />
          <Divider></Divider>
        </Stack>
      )}
      <Stack
        justify='top'
        spacing='0'>
        {menuItems
          .filter((item: GestaoMenuItem) => {
            return checkPermission(item.roles, colaborador);
          })
          .map((item: GestaoMenuItem, index0: number) => {
            return item.separator ? (
              <Divider></Divider>
            ) : (
              <NavLink
                fw={'bold'}
                key={parseInt(index0.toString())}
                label={item.label}
                icon={item.icon}
                variant={'light'}
                color={'white'}
                defaultOpened
                childrenOffset={28}>
                <Stack
                  justify='top'
                  spacing='0'>
                  {item.items
                    ? item.items
                        .filter((item) => {
                          return checkPermission(item.roles, colaborador);
                        })
                        .map((itemleaf: GestaoMenuItem, index1) => {
                          return itemleaf.separator ? (
                            <Divider
                              key={parseInt(index0.toString() + index1.toString())}></Divider>
                          ) : (
                            <Indicator
                              key={parseInt(index0.toString() + index1.toString())}
                              disabled={itemleaf.indicator === undefined || itemleaf.indicator <= 0}
                              label={itemleaf.indicator?.toString()}
                              position={'top-end'}
                              offset={30}
                              radius={'md'}
                              inline
                              size={20}>
                              <NavLink
                                component={Link}
                                active={itemleaf.to === local.pathname}
                                variant='light'
                                label={itemleaf.label}
                                color={'slideinGreen2'}
                                onClick={toggle}
                                icon={itemleaf.icon}
                                to={itemleaf.to}></NavLink>
                            </Indicator>
                          );
                        })
                    : ''}
                </Stack>
              </NavLink>
            );
          })}
      </Stack>
    </Stack>
  );
}

export default GestaoNavbar;
