import { Button, Divider, Stack, Text, Textarea, Title } from '@mantine/core';
import { IconDeviceFloppy, IconNotes } from '@tabler/icons-react';
import React, { useEffect } from 'react';
import { useForm } from '@mantine/form';
import { useParams } from 'react-router-dom';
import { PatchedViagemRequest, ViagemService } from '../../../../slidein_api';
import { msgAPIError } from '../../../helpers';

export function ViagemNotas({
  notas,
  fetch,
  notas_cliente,
}: {
  notas: string;
  fetch;
  notas_cliente: string;
}) {
  const { viagemID } = useParams();
  const saveNotas = (notasF: PatchedViagemRequest) => {
    ViagemService.viagemPartialUpdate({
      id: parseInt(viagemID),
      requestBody: notasF,
    }).then(
      () => {
        fetch();
        notas = notasF.notas;
        notasForm.reset;
      },
      (reason) => msgAPIError(reason)
    );
  };
  useEffect(() => {
    notasForm.setFieldValue('notas', notas);
  }, [notas]);
  const notasForm = useForm<PatchedViagemRequest>({
    initialValues: {
      notas: notas,
    },
  });
  return (
    <Stack>
      <form onSubmit={notasForm.onSubmit(saveNotas)}>
        <Textarea
          placeholder='Notas...'
          label='Notas:'
          size='md'
          minRows={5}
          icon={<IconNotes />}
          {...notasForm.getInputProps('notas')}></Textarea>
        <Button
          w={'100%'}
          disabled={notas == notasForm.values.notas}
          type={'submit'}
          rightIcon={<IconDeviceFloppy />}
          variant={'outline'}>
          Guardar Notas
        </Button>
      </form>
      <Divider></Divider>
      <Title order={5}>Notas importantes (Doenças, Alergias, etc...):</Title>
      <Text>{notas_cliente}</Text>
    </Stack>
  );
}
