import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { Grupo, GrupoService, ProdutoService, Suplemento } from '../../../slidein_api';
import { iconBool, msgAPIError } from '../../helpers';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import { Button, Group, Modal, Stack, Title, Tooltip } from '@mantine/core';
import {
  IconCircleCheck,
  IconCircleX,
  IconDeviceFloppy,
  IconPlus,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import { useAuth } from '../../../user-auth/SlideInAuthContext';
import {
  CheckPermission,
  checkPermission,
  MASTER_ADMIN,
} from '../../../components/CheckPermission';
import { useParams } from 'react-router-dom';

type GrupoSuplementosProps = {
  grupo: Grupo;
  refreshGrupo;
};

function getRows(table) {
  if (table && table.getSelectedRowModel() && table.getSelectedRowModel().rows) {
    return table.getSelectedRowModel().rows;
  } else {
    return [];
  }
}

export default function GrupoSuplementos({ grupo, refreshGrupo }: GrupoSuplementosProps) {
  const { colaborador } = useAuth();
  const { campanhaID } = useParams();
  const [suplementos, setSuplementos] = useState<Suplemento[]>([]);
  const [addSuplementosModalVisible, setAddSuplementosModalVisible] = useState<boolean>(false);

  const fetchData = () => {
    if (checkPermission(MASTER_ADMIN, colaborador)) {
      ProdutoService.produtoSuplementoList({ campanha: campanhaID }).then(
        (suplementosall) => {
          setSuplementos(suplementosall);
        },
        (reason) => msgAPIError(reason)
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = useMemo<MRT_ColumnDef<Suplemento>[]>(
    () => [
      { accessorKey: 'nome_interno', header: 'Nome Interno' },
      { accessorKey: 'titulo_cliente', header: 'Nome Cliente' },
      {
        accessorKey: 'activo',
        header: 'Ativo',
        Header: (
          <Tooltip label={'Activo, disponivel para compra'}>
            <div>Act.</div>
          </Tooltip>
        ),
        size: 50,
        Cell: (cell) => {
          return cell.cell.getValue() ? (
            <IconCircleCheck color='green' />
          ) : (
            <IconCircleX color='red' />
          );
        },
      },
      {
        accessorKey: 'obrigatorio',
        header: 'Obrigatorio',
        Header: (
          <Tooltip label={'Aquisição Obrigatória (exemplo:"Despesas de reserva ...")'}>
            <div>Obrigatório.</div>
          </Tooltip>
        ),
        Cell: ({ cell }) => iconBool(cell.row.original.activo),
      },
      {
        accessorKey: 'valor',
        header: 'Valor',
        size: 100,
        Cell: (cell) => `${cell.cell.getValue()}€`,
      },
    ],
    []
  );
  const removeSuplementos = () => {
    const all = grupo.suplementos.map((gp) => gp.id);
    const remove = getRows(suplementosTable).map((p) => p.id);
    GrupoService.grupoProdutosPartialUpdate({
      codigo: grupo.codigo,
      requestBody: {
        suplementos: all.filter((a) => remove.findIndex((r) => r == a) == -1),
      },
    }).then(
      () => {
        fetchData();
        refreshGrupo();
        setAddSuplementosModalVisible(false);
      },
      (reason) => msgAPIError(reason)
    );
  };
  const adicionarSuplementos = () => {
    const suplementosId = grupo.suplementos.map((gp) => gp.id);
    suplementosId.push(...getRows(allSuplementosTable).map((p) => p.id));

    GrupoService.grupoProdutosPartialUpdate({
      codigo: grupo.codigo,
      requestBody: {
        suplementos: suplementosId,
      },
    }).then(
      () => {
        fetchData();
        refreshGrupo();
        setAddSuplementosModalVisible(false);
      },
      (reason) => msgAPIError(reason)
    );
  };
  const suplementosTable = useMantineReactTable({
    columns,
    data: grupo?.suplementos || [],
    enableMultiRowSelection: checkPermission(MASTER_ADMIN, colaborador),
    enableRowSelection: (row) => {
      return !row.original.obrigatorio && checkPermission(MASTER_ADMIN, colaborador);
    },
    enableSelectAll: checkPermission(MASTER_ADMIN, colaborador),
    selectAllMode: 'all',
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: 'pointer',
      },
      onClick: row.getToggleSelectedHandler(),
    }),
    getRowId: (originalRow) => originalRow.id.toString(),
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFilters: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enablePagination: false,
    positionToolbarAlertBanner: 'none',
    localization: MRT_Localization_PT,
    initialState: {
      density: 'xs',
    },
    renderTopToolbarCustomActions: () => (
      <Group>
        <CheckPermission
          roles={MASTER_ADMIN}
          goHome={false}>
          <Button
            leftIcon={<IconTrash />}
            variant={'outline'}
            color={'red'}
            disabled={getRows(suplementosTable).length <= 0}
            onClick={() => {
              removeSuplementos();
            }}>
            Remover
          </Button>
          <Button
            leftIcon={<IconPlus />}
            variant={'outline'}
            onClick={() => {
              setAddSuplementosModalVisible(true);
            }}>
            Adicionar
          </Button>
        </CheckPermission>
      </Group>
    ),
  });

  const allSuplementosTable = useMantineReactTable<Suplemento>({
    columns,
    data: suplementos || [],
    enableSelectAll: true,
    selectAllMode: 'all',
    getRowId: (originalRow) => originalRow.id.toString(),
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    positionToolbarAlertBanner: 'none',
    enableHiding: false,
    enablePagination: false,
    localization: MRT_Localization_PT,
    initialState: {
      density: 'xs',
    },
    enableRowSelection: (row) =>
      grupo.suplementos.findIndex((gp) => gp.id == row.original.id) == -1,
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        opacity:
          grupo.suplementos.findIndex((gp) => gp.id == row.original.id) == -1 ? '100%' : '35%',
        cursor: 'pointer',
      },
      onClick: row.getToggleSelectedHandler(),
    }),
  });

  return (
    <Stack>
      <MantineReactTable table={suplementosTable} />
      <Modal
        centered
        title={'Escolher Suplementos:'}
        opened={addSuplementosModalVisible}
        size={'auto'}
        onClose={() => {
          setAddSuplementosModalVisible(false);
        }}>
        <Title order={4}>
          {getRows(allSuplementosTable).length}&nbsp;Suplementos Selecionados.
        </Title>
        <MantineReactTable table={allSuplementosTable} />
        <Group
          position='right'
          mt='md'>
          <Button
            color={'yellow'}
            variant={'outline'}
            leftIcon={<IconX />}
            type='button'
            onClick={() => {
              setAddSuplementosModalVisible(false);
            }}>
            Cancelar
          </Button>
          <Button
            variant={'outline'}
            disabled={getRows(allSuplementosTable).length == 0}
            onClick={() => {
              adicionarSuplementos();
              setAddSuplementosModalVisible(false);
            }}
            leftIcon={<IconDeviceFloppy />}
            type='button'>
            Adicionar
          </Button>
        </Group>
      </Modal>
    </Stack>
  );
}
