import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  ApiError,
  Marca,
  MarcaRequest,
  MarcaService,
  PatchedMarcaRequest,
} from '../../slidein_api';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import {
  ActionIcon,
  Box,
  Button,
  FileInput,
  Group,
  Image,
  Modal,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import {
  IconAt,
  IconBrandInstagram,
  IconBrandTiktok,
  IconBrandX,
  IconDeviceFloppy,
  IconEdit,
  IconPhone,
  IconPictureInPicture,
  IconPlus,
  IconSquareRoundedLetterS,
  IconTrash,
  IconTrashX,
  IconWorldWww,
  IconX,
} from '@tabler/icons-react';
import { msgAPIError } from '../helpers';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import { notifications } from '@mantine/notifications';
import { MenuContext } from '../../layout/context/MenuContext';
import { useBread } from '../../layout/context/BreadContext';
import { homeMenu } from '../Home';
import { modals } from '@mantine/modals';

export default function Marcas() {
  const { setMenuItems } = useContext(MenuContext);
  const { setBread } = useBread();
  useEffect(() => {
    setMenuItems(homeMenu);
    setBread({ accao: { title: 'Marcas', icon: <IconBrandX /> } });
  }, []);

  const [marcas, setMarcas] = useState<Marca[]>([]);

  const [novaMarca, setNovaMarca] = useState(false);
  const [marcaModalVisible, setModalMarcaVisible] = useState(false);

  const fetchData = () => {
    MarcaService.marcaList().then(
      (mm) => {
        setMarcas(mm);
      },
      (reason) => msgAPIError(reason)
    );
  };
  useEffect(() => {
    fetchData();
  }, []);

  const marcaForm = useForm<Marca | MarcaRequest | PatchedMarcaRequest>({
    initialValues: {
      nome: '',
      logo: null,
      website: 'https://',
      email: '',
      telefone: '',
      instagram_url: 'https://',
      tiktok_url: 'https://',
    } as MarcaRequest,

    validate: yupResolver(
      Yup.object({
        nome: Yup.string().required('Obrigatório'),
      })
    ),
  });

  const saveMarca = (marca: MarcaRequest | Marca | PatchedMarcaRequest) => {
    if (novaMarca) {
      MarcaService.marcaCreate({ formData: marca as MarcaRequest }).then(
        () => {
          fetchData();
          setModalMarcaVisible(false);
        },
        (reason: ApiError) => {
          marcaForm.setErrors(reason.body);
        }
      );
    } else {
      const cleanmarca =
        typeof marca.logo === 'string'
          ? {
              nome: marca.nome,
              website: marca.website,
              email: marca.email,
              telefone: marca.telefone,
              instagram_url: marca.instagram_url,
              tiktok_url: marca.tiktok_url,
            }
          : marca;
      MarcaService.marcaPartialUpdate({
        id: (marca as Marca).id,
        formData: cleanmarca as PatchedMarcaRequest,
      }).then(
        () => {
          fetchData();
          setModalMarcaVisible(false);
        },
        (reason) => {
          marcaForm.setErrors(reason.body);
        }
      );
    }
  };

  const collumnsMarcas = useMemo<MRT_ColumnDef<Marca>[]>(
    () => [
      {
        accessorFn: (row) => (
          <Image
            mah={'2rem'}
            height={'2rem'}
            fit='contain'
            src={row.logo}
          />
        ),
        header: 'Logo',
      },
      { accessorKey: 'nome', header: 'Nome' },
      { accessorKey: 'website', header: 'Website' },
      { accessorKey: 'telefone', header: 'Telefone' },
      { accessorKey: 'tiktok_url', header: 'Tiktok URL' },
      { accessorKey: 'instagram_url', header: 'Instagram URL' },
      { accessorKey: 'email', header: 'Email' },
    ],

    []
  );

  const marcasTable = useMantineReactTable({
    columns: collumnsMarcas,
    data: marcas,
    enableGlobalFilter: false,
    enableFilters: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: true,
    enablePagination: false,
    localization: MRT_Localization_PT,
    renderTopToolbarCustomActions: () => (
      <Button
        leftIcon={<IconPlus />}
        variant='outline'
        onClick={() => {
          setNovaMarca(true);
          marcaForm.reset();
          marcaForm.setValues({} as MarcaRequest);
          setModalMarcaVisible(true);
        }}>
        Criar Marca
      </Button>
    ),
    enableRowActions: true,
    positionActionsColumn: 'first',
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '4px' }}>
        <Tooltip label='Editar '>
          <ActionIcon
            color={'yellow'}
            onClick={() => {
              setNovaMarca(false);
              marcaForm.reset();
              marcaForm.setValues(row.original);
              setModalMarcaVisible(true);
            }}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label='Remover'>
          <ActionIcon
            disabled={row.original.nr_campanhas > 0}
            color='red'
            onClick={() =>
              modals.openConfirmModal({
                title: 'Remover Marca',
                centered: true,
                children: (
                  <Text size='sm'>Confirma que deseja remover a MArca : {row.original.nome} ?</Text>
                ),
                labels: { confirm: 'Remover', cancel: 'Cancelar' },
                confirmProps: { color: 'red', variant: 'outline', leftIcon: <IconTrash /> },
                cancelProps: { color: 'yellow', variant: 'outline', leftIcon: <IconX /> },
                onConfirm: () => {
                  MarcaService.marcaDestroy({ id: row.original.id }).then(
                    () => {
                      fetchData();
                      notifications.show({
                        title: 'Marca removida!',
                        message: `A Marca ${row.original.nome} foi removida.`,
                        withCloseButton: true,
                        icon: <IconTrashX />,
                        color: 'green',
                      });
                    },
                    (reason: ApiError) => {
                      if (reason.status == 423) {
                        notifications.show({
                          title: 'Marca!',
                          message: `A Marca ${row.original.nome} NÃO foi removida, existem campanhas associados!`,
                          withCloseButton: true,
                          icon: <IconX />,
                          color: 'yellow',
                          autoClose: 5000,
                        });
                      } else {
                        msgAPIError(reason);
                      }
                    }
                  );
                },
              })
            }>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Box>
    ),
    initialState: {
      density: 'xs',
      showGlobalFilter: false,
    },
  });

  return (
    <Stack>
      <MantineReactTable table={marcasTable} />
      <Modal
        opened={marcaModalVisible}
        onClose={() => setModalMarcaVisible(false)}
        closeOnEscape={false}
        closeOnClickOutside={false}
        withCloseButton={false}
        title={novaMarca ? 'Criar Marca' : 'Editar Marca'}
        centered>
        <form onSubmit={marcaForm.onSubmit(saveMarca)}>
          <TextInput
            label={'Nome'}
            required
            icon={<IconSquareRoundedLetterS />}
            {...marcaForm.getInputProps('nome')}
          />
          <TextInput
            label={'Email'}
            type={'email'}
            icon={<IconAt />}
            {...marcaForm.getInputProps('email')}
          />
          <TextInput
            label={'Telefone'}
            icon={<IconPhone />}
            {...marcaForm.getInputProps('telefone')}
          />
          <TextInput
            label={'Website'}
            type={'url'}
            icon={<IconWorldWww />}
            {...marcaForm.getInputProps('website')}
          />
          <TextInput
            label={'Instagram URL'}
            icon={<IconBrandInstagram />}
            {...marcaForm.getInputProps('instagram_url')}
          />
          <TextInput
            label={'TikTokURL'}
            icon={<IconBrandTiktok />}
            {...marcaForm.getInputProps('tiktok_url')}
          />

          <FileInput
            icon={<IconPictureInPicture />}
            label={'Logo'}
            required
            description={'Só ficheiros PNG! '}
            accept={'image/png'}
            {...marcaForm.getInputProps('logo')}></FileInput>

          <Group
            position='right'
            mt='md'>
            <Button
              color={'yellow'}
              variant={'outline'}
              leftIcon={<IconX />}
              type='button'
              onClick={() => setModalMarcaVisible(false)}>
              Cancelar
            </Button>
            <Button
              type='submit'
              variant={'outline'}
              leftIcon={<IconDeviceFloppy />}>
              Guardar
            </Button>
          </Group>
        </form>
      </Modal>
    </Stack>
  );
}
