/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddCompraActividade } from '../models/AddCompraActividade';
import type { AddCompraActividadeRequest } from '../models/AddCompraActividadeRequest';
import type { AddCompraDesconto } from '../models/AddCompraDesconto';
import type { AddCompraDescontoRequest } from '../models/AddCompraDescontoRequest';
import type { AddCompraOutros } from '../models/AddCompraOutros';
import type { AddCompraOutrosRequest } from '../models/AddCompraOutrosRequest';
import type { AddCompraSuplemento } from '../models/AddCompraSuplemento';
import type { AddCompraSuplementoRequest } from '../models/AddCompraSuplementoRequest';
import type { CompraActividade } from '../models/CompraActividade';
import type { CompraActividadeRequest } from '../models/CompraActividadeRequest';
import type { CompraDesconto } from '../models/CompraDesconto';
import type { CompraDescontoRequest } from '../models/CompraDescontoRequest';
import type { CompraOutro } from '../models/CompraOutro';
import type { CompraOutroRequest } from '../models/CompraOutroRequest';
import type { CompraPacote } from '../models/CompraPacote';
import type { CompraPacoteRequest } from '../models/CompraPacoteRequest';
import type { CompraSeguro } from '../models/CompraSeguro';
import type { CompraSeguroRequest } from '../models/CompraSeguroRequest';
import type { CompraSuplemento } from '../models/CompraSuplemento';
import type { CompraSuplementoRequest } from '../models/CompraSuplementoRequest';
import type { ComprasViagem } from '../models/ComprasViagem';
import type { PaginatedCompraActividadeList } from '../models/PaginatedCompraActividadeList';
import type { PaginatedCompraDescontoList } from '../models/PaginatedCompraDescontoList';
import type { PaginatedCompraOutroList } from '../models/PaginatedCompraOutroList';
import type { PaginatedCompraPacoteList } from '../models/PaginatedCompraPacoteList';
import type { PaginatedCompraSeguroList } from '../models/PaginatedCompraSeguroList';
import type { PaginatedCompraSuplementoList } from '../models/PaginatedCompraSuplementoList';
import type { PaginatedProdutoStatsList } from '../models/PaginatedProdutoStatsList';
import type { PatchedCompraActividadeRequest } from '../models/PatchedCompraActividadeRequest';
import type { PatchedCompraDescontoRequest } from '../models/PatchedCompraDescontoRequest';
import type { PatchedCompraOutroRequest } from '../models/PatchedCompraOutroRequest';
import type { PatchedCompraPacoteRequest } from '../models/PatchedCompraPacoteRequest';
import type { PatchedCompraSeguroRequest } from '../models/PatchedCompraSeguroRequest';
import type { PatchedCompraSuplementoRequest } from '../models/PatchedCompraSuplementoRequest';
import type { TrocaPacote } from '../models/TrocaPacote';
import type { TrocaPacoteRequest } from '../models/TrocaPacoteRequest';
import type { TrocaSeguro } from '../models/TrocaSeguro';
import type { TrocaSeguroRequest } from '../models/TrocaSeguroRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CompraService {

    /**
     * @returns void
     * @throws ApiError
     */
    public static compraDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this compra pacote.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/compra/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns TrocaPacote
     * @throws ApiError
     */
    public static compraTrocarPacoteUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this compra pacote.
         */
        id: number,
        requestBody: TrocaPacoteRequest,
    }): CancelablePromise<TrocaPacote> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/compra/{id}/trocar_pacote',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TrocaSeguro
     * @throws ApiError
     */
    public static compraTrocarSeguroUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this compra seguro.
         */
        id: number,
        requestBody: TrocaSeguroRequest,
    }): CancelablePromise<TrocaSeguro> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/compra/{id}/trocar_seguro',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PaginatedCompraActividadeList
     * @throws ApiError
     */
    public static compraActividadeList({
        adminResponsavel,
        comercialResponsavel,
        contactoUrgencia,
        creationDateAfter,
        creationDateBefore,
        email,
        emailContactoUrgencia,
        estado,
        grupo,
        instituicao,
        nomeCompleto,
        ordering,
        page,
        pageSize,
        produtoValor,
        produtoNomeInterno,
        quarto,
        search,
        telemovel,
        valorDevidoMax,
        valorDevidoMin,
        valorPagoMax,
        valorPagoMin,
        valorTotalMax,
        valorTotalMin,
        viagemBloqueadaPagamentos,
        viagemCampanha,
        viagemCancelada,
        viagemChefeDeGrupo,
    }: {
        adminResponsavel?: string,
        comercialResponsavel?: string,
        contactoUrgencia?: string,
        creationDateAfter?: string,
        creationDateBefore?: string,
        email?: string,
        emailContactoUrgencia?: string,
        estado?: string,
        grupo?: string,
        instituicao?: string,
        nomeCompleto?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        produtoValor?: number,
        produtoNomeInterno?: string,
        quarto?: string,
        /**
         * A search term.
         */
        search?: string,
        telemovel?: string,
        valorDevidoMax?: number,
        valorDevidoMin?: number,
        valorPagoMax?: number,
        valorPagoMin?: number,
        valorTotalMax?: number,
        valorTotalMin?: number,
        viagemBloqueadaPagamentos?: boolean,
        /**
         * Identifica univocamente uma campanha
         */
        viagemCampanha?: string,
        viagemCancelada?: boolean,
        viagemChefeDeGrupo?: boolean,
    }): CancelablePromise<PaginatedCompraActividadeList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/compra/actividade',
            query: {
                'admin_responsavel': adminResponsavel,
                'comercial_responsavel': comercialResponsavel,
                'contacto_urgencia': contactoUrgencia,
                'creation_date_after': creationDateAfter,
                'creation_date_before': creationDateBefore,
                'email': email,
                'email_contacto_urgencia': emailContactoUrgencia,
                'estado': estado,
                'grupo': grupo,
                'instituicao': instituicao,
                'nome_completo': nomeCompleto,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'produto__valor': produtoValor,
                'produto_nome_interno': produtoNomeInterno,
                'quarto': quarto,
                'search': search,
                'telemovel': telemovel,
                'valor_devido_max': valorDevidoMax,
                'valor_devido_min': valorDevidoMin,
                'valor_pago_max': valorPagoMax,
                'valor_pago_min': valorPagoMin,
                'valor_total_max': valorTotalMax,
                'valor_total_min': valorTotalMin,
                'viagem__bloqueada_pagamentos': viagemBloqueadaPagamentos,
                'viagem__campanha': viagemCampanha,
                'viagem__cancelada': viagemCancelada,
                'viagem__chefe_de_grupo': viagemChefeDeGrupo,
            },
        });
    }

    /**
     * @returns CompraActividade
     * @throws ApiError
     */
    public static compraActividadeCreate({
        requestBody,
    }: {
        requestBody?: CompraActividadeRequest,
    }): CancelablePromise<CompraActividade> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/compra/actividade',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CompraActividade
     * @throws ApiError
     */
    public static compraActividadeRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this compra actividade.
         */
        id: number,
    }): CancelablePromise<CompraActividade> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/compra/actividade/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CompraActividade
     * @throws ApiError
     */
    public static compraActividadeUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this compra actividade.
         */
        id: number,
        requestBody?: CompraActividadeRequest,
    }): CancelablePromise<CompraActividade> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/compra/actividade/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CompraActividade
     * @throws ApiError
     */
    public static compraActividadePartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this compra actividade.
         */
        id: number,
        requestBody?: PatchedCompraActividadeRequest,
    }): CancelablePromise<CompraActividade> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/compra/actividade/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static compraActividadeDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this compra actividade.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/compra/actividade/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CompraActividade
     * @throws ApiError
     */
    public static compraActividadeMapaRetrieve(): CancelablePromise<CompraActividade> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/compra/actividade/mapa',
        });
    }

    /**
     * @returns AddCompraActividade
     * @throws ApiError
     */
    public static compraAdicionarActividadeCreate({
        requestBody,
    }: {
        requestBody: AddCompraActividadeRequest,
    }): CancelablePromise<AddCompraActividade> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/compra/adicionar_actividade',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AddCompraDesconto
     * @throws ApiError
     */
    public static compraAdicionarDescontoCreate({
        requestBody,
    }: {
        requestBody: AddCompraDescontoRequest,
    }): CancelablePromise<AddCompraDesconto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/compra/adicionar_desconto',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AddCompraOutros
     * @throws ApiError
     */
    public static compraAdicionarOutroCreate({
        requestBody,
    }: {
        requestBody: AddCompraOutrosRequest,
    }): CancelablePromise<AddCompraOutros> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/compra/adicionar_outro',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AddCompraSuplemento
     * @throws ApiError
     */
    public static compraAdicionarSuplementoCreate({
        requestBody,
    }: {
        requestBody: AddCompraSuplementoRequest,
    }): CancelablePromise<AddCompraSuplemento> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/compra/adicionar_suplemento',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PaginatedCompraDescontoList
     * @throws ApiError
     */
    public static compraDescontoList({
        adminResponsavel,
        comercialResponsavel,
        contactoUrgencia,
        creationDateAfter,
        creationDateBefore,
        email,
        emailContactoUrgencia,
        estado,
        grupo,
        instituicao,
        nomeCompleto,
        ordering,
        page,
        pageSize,
        produtoValor,
        produtoNomeInterno,
        quarto,
        search,
        telemovel,
        valorDevidoMax,
        valorDevidoMin,
        valorPagoMax,
        valorPagoMin,
        valorTotalMax,
        valorTotalMin,
        viagemBloqueadaPagamentos,
        viagemCampanha,
        viagemCancelada,
        viagemChefeDeGrupo,
    }: {
        adminResponsavel?: string,
        comercialResponsavel?: string,
        contactoUrgencia?: string,
        creationDateAfter?: string,
        creationDateBefore?: string,
        email?: string,
        emailContactoUrgencia?: string,
        estado?: string,
        grupo?: string,
        instituicao?: string,
        nomeCompleto?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        produtoValor?: number,
        produtoNomeInterno?: string,
        quarto?: string,
        /**
         * A search term.
         */
        search?: string,
        telemovel?: string,
        valorDevidoMax?: number,
        valorDevidoMin?: number,
        valorPagoMax?: number,
        valorPagoMin?: number,
        valorTotalMax?: number,
        valorTotalMin?: number,
        viagemBloqueadaPagamentos?: boolean,
        /**
         * Identifica univocamente uma campanha
         */
        viagemCampanha?: string,
        viagemCancelada?: boolean,
        viagemChefeDeGrupo?: boolean,
    }): CancelablePromise<PaginatedCompraDescontoList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/compra/desconto',
            query: {
                'admin_responsavel': adminResponsavel,
                'comercial_responsavel': comercialResponsavel,
                'contacto_urgencia': contactoUrgencia,
                'creation_date_after': creationDateAfter,
                'creation_date_before': creationDateBefore,
                'email': email,
                'email_contacto_urgencia': emailContactoUrgencia,
                'estado': estado,
                'grupo': grupo,
                'instituicao': instituicao,
                'nome_completo': nomeCompleto,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'produto__valor': produtoValor,
                'produto_nome_interno': produtoNomeInterno,
                'quarto': quarto,
                'search': search,
                'telemovel': telemovel,
                'valor_devido_max': valorDevidoMax,
                'valor_devido_min': valorDevidoMin,
                'valor_pago_max': valorPagoMax,
                'valor_pago_min': valorPagoMin,
                'valor_total_max': valorTotalMax,
                'valor_total_min': valorTotalMin,
                'viagem__bloqueada_pagamentos': viagemBloqueadaPagamentos,
                'viagem__campanha': viagemCampanha,
                'viagem__cancelada': viagemCancelada,
                'viagem__chefe_de_grupo': viagemChefeDeGrupo,
            },
        });
    }

    /**
     * @returns CompraDesconto
     * @throws ApiError
     */
    public static compraDescontoCreate({
        requestBody,
    }: {
        requestBody?: CompraDescontoRequest,
    }): CancelablePromise<CompraDesconto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/compra/desconto',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CompraDesconto
     * @throws ApiError
     */
    public static compraDescontoRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this compra desconto.
         */
        id: number,
    }): CancelablePromise<CompraDesconto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/compra/desconto/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CompraDesconto
     * @throws ApiError
     */
    public static compraDescontoUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this compra desconto.
         */
        id: number,
        requestBody?: CompraDescontoRequest,
    }): CancelablePromise<CompraDesconto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/compra/desconto/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CompraDesconto
     * @throws ApiError
     */
    public static compraDescontoPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this compra desconto.
         */
        id: number,
        requestBody?: PatchedCompraDescontoRequest,
    }): CancelablePromise<CompraDesconto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/compra/desconto/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static compraDescontoDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this compra desconto.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/compra/desconto/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CompraDesconto
     * @throws ApiError
     */
    public static compraDescontoMapaRetrieve(): CancelablePromise<CompraDesconto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/compra/desconto/mapa',
        });
    }

    /**
     * @returns PaginatedCompraOutroList
     * @throws ApiError
     */
    public static compraOutroList({
        adminResponsavel,
        comercialResponsavel,
        contactoUrgencia,
        creationDateAfter,
        creationDateBefore,
        descricao,
        email,
        emailContactoUrgencia,
        estado,
        grupo,
        instituicao,
        nome,
        nomeCompleto,
        ordering,
        page,
        pageSize,
        quarto,
        search,
        telemovel,
        valor,
        valorDevidoMax,
        valorDevidoMin,
        valorPagoMax,
        valorPagoMin,
        valorTotalMax,
        valorTotalMin,
        viagemBloqueadaPagamentos,
        viagemCampanha,
        viagemCancelada,
        viagemChefeDeGrupo,
    }: {
        adminResponsavel?: string,
        comercialResponsavel?: string,
        contactoUrgencia?: string,
        creationDateAfter?: string,
        creationDateBefore?: string,
        descricao?: string,
        email?: string,
        emailContactoUrgencia?: string,
        estado?: string,
        grupo?: string,
        instituicao?: string,
        nome?: string,
        nomeCompleto?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        quarto?: string,
        /**
         * A search term.
         */
        search?: string,
        telemovel?: string,
        valor?: number,
        valorDevidoMax?: number,
        valorDevidoMin?: number,
        valorPagoMax?: number,
        valorPagoMin?: number,
        valorTotalMax?: number,
        valorTotalMin?: number,
        viagemBloqueadaPagamentos?: boolean,
        /**
         * Identifica univocamente uma campanha
         */
        viagemCampanha?: string,
        viagemCancelada?: boolean,
        viagemChefeDeGrupo?: boolean,
    }): CancelablePromise<PaginatedCompraOutroList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/compra/outro',
            query: {
                'admin_responsavel': adminResponsavel,
                'comercial_responsavel': comercialResponsavel,
                'contacto_urgencia': contactoUrgencia,
                'creation_date_after': creationDateAfter,
                'creation_date_before': creationDateBefore,
                'descricao': descricao,
                'email': email,
                'email_contacto_urgencia': emailContactoUrgencia,
                'estado': estado,
                'grupo': grupo,
                'instituicao': instituicao,
                'nome': nome,
                'nome_completo': nomeCompleto,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'quarto': quarto,
                'search': search,
                'telemovel': telemovel,
                'valor': valor,
                'valor_devido_max': valorDevidoMax,
                'valor_devido_min': valorDevidoMin,
                'valor_pago_max': valorPagoMax,
                'valor_pago_min': valorPagoMin,
                'valor_total_max': valorTotalMax,
                'valor_total_min': valorTotalMin,
                'viagem__bloqueada_pagamentos': viagemBloqueadaPagamentos,
                'viagem__campanha': viagemCampanha,
                'viagem__cancelada': viagemCancelada,
                'viagem__chefe_de_grupo': viagemChefeDeGrupo,
            },
        });
    }

    /**
     * @returns CompraOutro
     * @throws ApiError
     */
    public static compraOutroCreate({
        requestBody,
    }: {
        requestBody: CompraOutroRequest,
    }): CancelablePromise<CompraOutro> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/compra/outro',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CompraOutro
     * @throws ApiError
     */
    public static compraOutroRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this compra outro.
         */
        id: number,
    }): CancelablePromise<CompraOutro> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/compra/outro/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CompraOutro
     * @throws ApiError
     */
    public static compraOutroUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this compra outro.
         */
        id: number,
        requestBody: CompraOutroRequest,
    }): CancelablePromise<CompraOutro> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/compra/outro/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CompraOutro
     * @throws ApiError
     */
    public static compraOutroPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this compra outro.
         */
        id: number,
        requestBody?: PatchedCompraOutroRequest,
    }): CancelablePromise<CompraOutro> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/compra/outro/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static compraOutroDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this compra outro.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/compra/outro/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CompraOutro
     * @throws ApiError
     */
    public static compraOutroMapaRetrieve(): CancelablePromise<CompraOutro> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/compra/outro/mapa',
        });
    }

    /**
     * @returns PaginatedProdutoStatsList
     * @throws ApiError
     */
    public static compraOutroStatsList({
        campanha,
        adminResponsavel,
        comercialResponsavel,
        contactoUrgencia,
        creationDateAfter,
        creationDateBefore,
        descricao,
        email,
        emailContactoUrgencia,
        endDate,
        estado,
        grupo,
        instituicao,
        nome,
        nomeCompleto,
        ordering,
        page,
        pageSize,
        quarto,
        search,
        startDate,
        telemovel,
        valor,
        valorDevidoMax,
        valorDevidoMin,
        valorPagoMax,
        valorPagoMin,
        valorTotalMax,
        valorTotalMin,
        viagemBloqueadaPagamentos,
        viagemCampanha,
        viagemCancelada,
        viagemChefeDeGrupo,
    }: {
        campanha: string,
        adminResponsavel?: string,
        comercialResponsavel?: string,
        contactoUrgencia?: string,
        creationDateAfter?: string,
        creationDateBefore?: string,
        descricao?: string,
        email?: string,
        emailContactoUrgencia?: string,
        endDate?: string,
        estado?: string,
        grupo?: string,
        instituicao?: string,
        nome?: string,
        nomeCompleto?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        quarto?: string,
        /**
         * A search term.
         */
        search?: string,
        startDate?: string,
        telemovel?: string,
        valor?: number,
        valorDevidoMax?: number,
        valorDevidoMin?: number,
        valorPagoMax?: number,
        valorPagoMin?: number,
        valorTotalMax?: number,
        valorTotalMin?: number,
        viagemBloqueadaPagamentos?: boolean,
        /**
         * Identifica univocamente uma campanha
         */
        viagemCampanha?: string,
        viagemCancelada?: boolean,
        viagemChefeDeGrupo?: boolean,
    }): CancelablePromise<PaginatedProdutoStatsList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/compra/outro/stats',
            query: {
                'admin_responsavel': adminResponsavel,
                'campanha': campanha,
                'comercial_responsavel': comercialResponsavel,
                'contacto_urgencia': contactoUrgencia,
                'creation_date_after': creationDateAfter,
                'creation_date_before': creationDateBefore,
                'descricao': descricao,
                'email': email,
                'email_contacto_urgencia': emailContactoUrgencia,
                'end_date': endDate,
                'estado': estado,
                'grupo': grupo,
                'instituicao': instituicao,
                'nome': nome,
                'nome_completo': nomeCompleto,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'quarto': quarto,
                'search': search,
                'start_date': startDate,
                'telemovel': telemovel,
                'valor': valor,
                'valor_devido_max': valorDevidoMax,
                'valor_devido_min': valorDevidoMin,
                'valor_pago_max': valorPagoMax,
                'valor_pago_min': valorPagoMin,
                'valor_total_max': valorTotalMax,
                'valor_total_min': valorTotalMin,
                'viagem__bloqueada_pagamentos': viagemBloqueadaPagamentos,
                'viagem__campanha': viagemCampanha,
                'viagem__cancelada': viagemCancelada,
                'viagem__chefe_de_grupo': viagemChefeDeGrupo,
            },
        });
    }

    /**
     * @returns PaginatedCompraPacoteList
     * @throws ApiError
     */
    public static compraPacoteList({
        adminResponsavel,
        comercialResponsavel,
        contactoUrgencia,
        creationDateAfter,
        creationDateBefore,
        email,
        emailContactoUrgencia,
        estado,
        grupo,
        instituicao,
        nomeCompleto,
        ordering,
        page,
        pageSize,
        produtoValor,
        produtoNomeInterno,
        quarto,
        search,
        telemovel,
        valorDevidoMax,
        valorDevidoMin,
        valorPagoMax,
        valorPagoMin,
        valorTotalMax,
        valorTotalMin,
        viagemBloqueadaPagamentos,
        viagemCampanha,
        viagemCancelada,
        viagemChefeDeGrupo,
    }: {
        adminResponsavel?: string,
        comercialResponsavel?: string,
        contactoUrgencia?: string,
        creationDateAfter?: string,
        creationDateBefore?: string,
        email?: string,
        emailContactoUrgencia?: string,
        estado?: string,
        grupo?: string,
        instituicao?: string,
        nomeCompleto?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        produtoValor?: number,
        produtoNomeInterno?: string,
        quarto?: string,
        /**
         * A search term.
         */
        search?: string,
        telemovel?: string,
        valorDevidoMax?: number,
        valorDevidoMin?: number,
        valorPagoMax?: number,
        valorPagoMin?: number,
        valorTotalMax?: number,
        valorTotalMin?: number,
        viagemBloqueadaPagamentos?: boolean,
        /**
         * Identifica univocamente uma campanha
         */
        viagemCampanha?: string,
        viagemCancelada?: boolean,
        viagemChefeDeGrupo?: boolean,
    }): CancelablePromise<PaginatedCompraPacoteList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/compra/pacote',
            query: {
                'admin_responsavel': adminResponsavel,
                'comercial_responsavel': comercialResponsavel,
                'contacto_urgencia': contactoUrgencia,
                'creation_date_after': creationDateAfter,
                'creation_date_before': creationDateBefore,
                'email': email,
                'email_contacto_urgencia': emailContactoUrgencia,
                'estado': estado,
                'grupo': grupo,
                'instituicao': instituicao,
                'nome_completo': nomeCompleto,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'produto__valor': produtoValor,
                'produto_nome_interno': produtoNomeInterno,
                'quarto': quarto,
                'search': search,
                'telemovel': telemovel,
                'valor_devido_max': valorDevidoMax,
                'valor_devido_min': valorDevidoMin,
                'valor_pago_max': valorPagoMax,
                'valor_pago_min': valorPagoMin,
                'valor_total_max': valorTotalMax,
                'valor_total_min': valorTotalMin,
                'viagem__bloqueada_pagamentos': viagemBloqueadaPagamentos,
                'viagem__campanha': viagemCampanha,
                'viagem__cancelada': viagemCancelada,
                'viagem__chefe_de_grupo': viagemChefeDeGrupo,
            },
        });
    }

    /**
     * @returns CompraPacote
     * @throws ApiError
     */
    public static compraPacoteCreate({
        requestBody,
    }: {
        requestBody?: CompraPacoteRequest,
    }): CancelablePromise<CompraPacote> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/compra/pacote',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CompraPacote
     * @throws ApiError
     */
    public static compraPacoteRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this compra pacote.
         */
        id: number,
    }): CancelablePromise<CompraPacote> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/compra/pacote/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CompraPacote
     * @throws ApiError
     */
    public static compraPacoteUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this compra pacote.
         */
        id: number,
        requestBody?: CompraPacoteRequest,
    }): CancelablePromise<CompraPacote> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/compra/pacote/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CompraPacote
     * @throws ApiError
     */
    public static compraPacotePartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this compra pacote.
         */
        id: number,
        requestBody?: PatchedCompraPacoteRequest,
    }): CancelablePromise<CompraPacote> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/compra/pacote/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static compraPacoteDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this compra pacote.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/compra/pacote/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CompraPacote
     * @throws ApiError
     */
    public static compraPacoteMapaRetrieve(): CancelablePromise<CompraPacote> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/compra/pacote/mapa',
        });
    }

    /**
     * @returns AddCompraActividade
     * @throws ApiError
     */
    public static compraRemoverActividadeCreate({
        requestBody,
    }: {
        requestBody: AddCompraActividadeRequest,
    }): CancelablePromise<AddCompraActividade> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/compra/remover_actividade',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AddCompraDesconto
     * @throws ApiError
     */
    public static compraRemoverDescontoCreate({
        requestBody,
    }: {
        requestBody: AddCompraDescontoRequest,
    }): CancelablePromise<AddCompraDesconto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/compra/remover_desconto',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AddCompraOutros
     * @throws ApiError
     */
    public static compraRemoverOutroCreate({
        requestBody,
    }: {
        requestBody: AddCompraOutrosRequest,
    }): CancelablePromise<AddCompraOutros> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/compra/remover_outro',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AddCompraSuplemento
     * @throws ApiError
     */
    public static compraRemoverSuplementoCreate({
        requestBody,
    }: {
        requestBody: AddCompraSuplementoRequest,
    }): CancelablePromise<AddCompraSuplemento> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/compra/remover_suplemento',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PaginatedCompraSeguroList
     * @throws ApiError
     */
    public static compraSeguroList({
        adminResponsavel,
        comercialResponsavel,
        contactoUrgencia,
        creationDateAfter,
        creationDateBefore,
        dataSubscricaoAfter,
        dataSubscricaoBefore,
        email,
        emailContactoUrgencia,
        estadoPagamento,
        grupo,
        instituicao,
        nomeCompleto,
        numeroApolice,
        ordering,
        page,
        pageSize,
        produtoBase,
        produtoValor,
        produtoNomeInterno,
        quarto,
        search,
        telemovel,
        valorDevidoMax,
        valorDevidoMin,
        valorPagoMax,
        valorPagoMin,
        valorTotalMax,
        valorTotalMin,
        viagemBloqueadaPagamentos,
        viagemCampanha,
        viagemCancelada,
        viagemChefeDeGrupo,
    }: {
        adminResponsavel?: string,
        comercialResponsavel?: string,
        contactoUrgencia?: string,
        creationDateAfter?: string,
        creationDateBefore?: string,
        dataSubscricaoAfter?: string,
        dataSubscricaoBefore?: string,
        email?: string,
        emailContactoUrgencia?: string,
        estadoPagamento?: string,
        grupo?: string,
        instituicao?: string,
        nomeCompleto?: string,
        numeroApolice?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        produtoBase?: boolean,
        produtoValor?: number,
        produtoNomeInterno?: string,
        quarto?: string,
        /**
         * A search term.
         */
        search?: string,
        telemovel?: string,
        valorDevidoMax?: number,
        valorDevidoMin?: number,
        valorPagoMax?: number,
        valorPagoMin?: number,
        valorTotalMax?: number,
        valorTotalMin?: number,
        viagemBloqueadaPagamentos?: boolean,
        /**
         * Identifica univocamente uma campanha
         */
        viagemCampanha?: string,
        viagemCancelada?: boolean,
        viagemChefeDeGrupo?: boolean,
    }): CancelablePromise<PaginatedCompraSeguroList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/compra/seguro',
            query: {
                'admin_responsavel': adminResponsavel,
                'comercial_responsavel': comercialResponsavel,
                'contacto_urgencia': contactoUrgencia,
                'creation_date_after': creationDateAfter,
                'creation_date_before': creationDateBefore,
                'data_subscricao_after': dataSubscricaoAfter,
                'data_subscricao_before': dataSubscricaoBefore,
                'email': email,
                'email_contacto_urgencia': emailContactoUrgencia,
                'estado_pagamento': estadoPagamento,
                'grupo': grupo,
                'instituicao': instituicao,
                'nome_completo': nomeCompleto,
                'numero_apolice': numeroApolice,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'produto__base': produtoBase,
                'produto__valor': produtoValor,
                'produto_nome_interno': produtoNomeInterno,
                'quarto': quarto,
                'search': search,
                'telemovel': telemovel,
                'valor_devido_max': valorDevidoMax,
                'valor_devido_min': valorDevidoMin,
                'valor_pago_max': valorPagoMax,
                'valor_pago_min': valorPagoMin,
                'valor_total_max': valorTotalMax,
                'valor_total_min': valorTotalMin,
                'viagem__bloqueada_pagamentos': viagemBloqueadaPagamentos,
                'viagem__campanha': viagemCampanha,
                'viagem__cancelada': viagemCancelada,
                'viagem__chefe_de_grupo': viagemChefeDeGrupo,
            },
        });
    }

    /**
     * @returns CompraSeguro
     * @throws ApiError
     */
    public static compraSeguroCreate({
        requestBody,
    }: {
        requestBody?: CompraSeguroRequest,
    }): CancelablePromise<CompraSeguro> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/compra/seguro',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CompraSeguro
     * @throws ApiError
     */
    public static compraSeguroRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this compra seguro.
         */
        id: number,
    }): CancelablePromise<CompraSeguro> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/compra/seguro/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CompraSeguro
     * @throws ApiError
     */
    public static compraSeguroUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this compra seguro.
         */
        id: number,
        requestBody?: CompraSeguroRequest,
    }): CancelablePromise<CompraSeguro> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/compra/seguro/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CompraSeguro
     * @throws ApiError
     */
    public static compraSeguroPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this compra seguro.
         */
        id: number,
        requestBody?: PatchedCompraSeguroRequest,
    }): CancelablePromise<CompraSeguro> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/compra/seguro/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static compraSeguroDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this compra seguro.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/compra/seguro/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CompraSeguro
     * @throws ApiError
     */
    public static compraSeguroRemoverEBaseRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this compra seguro.
         */
        id: number,
    }): CancelablePromise<CompraSeguro> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/compra/seguro/{id}/remover_e_base',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CompraSeguro
     * @throws ApiError
     */
    public static compraSeguroActualizarEstadosRetrieve({
        campanha,
    }: {
        campanha: string,
    }): CancelablePromise<CompraSeguro> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/compra/seguro/actualizar_estados',
            query: {
                'campanha': campanha,
            },
        });
    }

    /**
     * @returns CompraSeguro
     * @throws ApiError
     */
    public static compraSeguroMapaRetrieve(): CancelablePromise<CompraSeguro> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/compra/seguro/mapa',
        });
    }

    /**
     * @returns CompraSeguro
     * @throws ApiError
     */
    public static compraSeguroMapaSeguradoraRetrieve(): CancelablePromise<CompraSeguro> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/compra/seguro/mapa_seguradora',
        });
    }

    /**
     * @returns CompraSeguro
     * @throws ApiError
     */
    public static compraSeguroRemoverEBaseAllNPagosRetrieve({
        campanha,
    }: {
        campanha: string,
    }): CancelablePromise<CompraSeguro> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/compra/seguro/remover_e_base_all_n_pagos',
            query: {
                'campanha': campanha,
            },
        });
    }

    /**
     * @returns PaginatedCompraSuplementoList
     * @throws ApiError
     */
    public static compraSuplementoList({
        adminResponsavel,
        comercialResponsavel,
        contactoUrgencia,
        creationDateAfter,
        creationDateBefore,
        email,
        emailContactoUrgencia,
        estado,
        grupo,
        instituicao,
        nomeCompleto,
        ordering,
        page,
        pageSize,
        produtoValor,
        produtoNomeInterno,
        quarto,
        search,
        telemovel,
        valorDevidoMax,
        valorDevidoMin,
        valorPagoMax,
        valorPagoMin,
        valorTotalMax,
        valorTotalMin,
        viagemBloqueadaPagamentos,
        viagemCampanha,
        viagemCancelada,
        viagemChefeDeGrupo,
    }: {
        adminResponsavel?: string,
        comercialResponsavel?: string,
        contactoUrgencia?: string,
        creationDateAfter?: string,
        creationDateBefore?: string,
        email?: string,
        emailContactoUrgencia?: string,
        estado?: string,
        grupo?: string,
        instituicao?: string,
        nomeCompleto?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        produtoValor?: number,
        produtoNomeInterno?: string,
        quarto?: string,
        /**
         * A search term.
         */
        search?: string,
        telemovel?: string,
        valorDevidoMax?: number,
        valorDevidoMin?: number,
        valorPagoMax?: number,
        valorPagoMin?: number,
        valorTotalMax?: number,
        valorTotalMin?: number,
        viagemBloqueadaPagamentos?: boolean,
        /**
         * Identifica univocamente uma campanha
         */
        viagemCampanha?: string,
        viagemCancelada?: boolean,
        viagemChefeDeGrupo?: boolean,
    }): CancelablePromise<PaginatedCompraSuplementoList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/compra/suplemento',
            query: {
                'admin_responsavel': adminResponsavel,
                'comercial_responsavel': comercialResponsavel,
                'contacto_urgencia': contactoUrgencia,
                'creation_date_after': creationDateAfter,
                'creation_date_before': creationDateBefore,
                'email': email,
                'email_contacto_urgencia': emailContactoUrgencia,
                'estado': estado,
                'grupo': grupo,
                'instituicao': instituicao,
                'nome_completo': nomeCompleto,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'produto__valor': produtoValor,
                'produto_nome_interno': produtoNomeInterno,
                'quarto': quarto,
                'search': search,
                'telemovel': telemovel,
                'valor_devido_max': valorDevidoMax,
                'valor_devido_min': valorDevidoMin,
                'valor_pago_max': valorPagoMax,
                'valor_pago_min': valorPagoMin,
                'valor_total_max': valorTotalMax,
                'valor_total_min': valorTotalMin,
                'viagem__bloqueada_pagamentos': viagemBloqueadaPagamentos,
                'viagem__campanha': viagemCampanha,
                'viagem__cancelada': viagemCancelada,
                'viagem__chefe_de_grupo': viagemChefeDeGrupo,
            },
        });
    }

    /**
     * @returns CompraSuplemento
     * @throws ApiError
     */
    public static compraSuplementoCreate({
        requestBody,
    }: {
        requestBody?: CompraSuplementoRequest,
    }): CancelablePromise<CompraSuplemento> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/compra/suplemento',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CompraSuplemento
     * @throws ApiError
     */
    public static compraSuplementoRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this compra suplemento.
         */
        id: number,
    }): CancelablePromise<CompraSuplemento> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/compra/suplemento/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CompraSuplemento
     * @throws ApiError
     */
    public static compraSuplementoUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this compra suplemento.
         */
        id: number,
        requestBody?: CompraSuplementoRequest,
    }): CancelablePromise<CompraSuplemento> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/compra/suplemento/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CompraSuplemento
     * @throws ApiError
     */
    public static compraSuplementoPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this compra suplemento.
         */
        id: number,
        requestBody?: PatchedCompraSuplementoRequest,
    }): CancelablePromise<CompraSuplemento> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/compra/suplemento/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static compraSuplementoDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this compra suplemento.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/compra/suplemento/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CompraSuplemento
     * @throws ApiError
     */
    public static compraSuplementoMapaRetrieve(): CancelablePromise<CompraSuplemento> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/compra/suplemento/mapa',
        });
    }

    /**
     * @returns ComprasViagem
     * @throws ApiError
     */
    public static compraViagemRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
    }): CancelablePromise<ComprasViagem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/compra/viagem/{id}',
            path: {
                'id': id,
            },
        });
    }

}
