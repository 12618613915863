/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Marca } from '../models/Marca';
import type { MarcaRequest } from '../models/MarcaRequest';
import type { PatchedMarcaRequest } from '../models/PatchedMarcaRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MarcaService {

    /**
     * @returns Marca
     * @throws ApiError
     */
    public static marcaList(): CancelablePromise<Array<Marca>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/marca',
        });
    }

    /**
     * @returns Marca
     * @throws ApiError
     */
    public static marcaCreate({
        formData,
    }: {
        formData: MarcaRequest,
    }): CancelablePromise<Marca> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/marca',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns Marca
     * @throws ApiError
     */
    public static marcaRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this marca.
         */
        id: number,
    }): CancelablePromise<Marca> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/marca/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Marca
     * @throws ApiError
     */
    public static marcaUpdate({
        id,
        formData,
    }: {
        /**
         * A unique integer value identifying this marca.
         */
        id: number,
        formData: MarcaRequest,
    }): CancelablePromise<Marca> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/marca/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns Marca
     * @throws ApiError
     */
    public static marcaPartialUpdate({
        id,
        formData,
    }: {
        /**
         * A unique integer value identifying this marca.
         */
        id: number,
        formData?: PatchedMarcaRequest,
    }): CancelablePromise<Marca> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/marca/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static marcaDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this marca.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/marca/{id}',
            path: {
                'id': id,
            },
        });
    }

}
