import { Button, Checkbox, Flex, Modal, NumberInput } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { IconLock, IconLockOpen, IconTrashX } from '@tabler/icons-react';
import React, { useState } from 'react';
import { GrupoService } from '../../../slidein_api';
import { msgAPIError } from '../../helpers';
import { notifications } from '@mantine/notifications';


export function GrupoEstados() {
  const { grupoID } = useParams();
  const [iChefe, setIChefe] = useState<boolean>(false);
  const [valor, setValor] = useState<number | ''>('');
  const [bVisible, setBVisible] = useState<boolean>(false);
  const [dVisible, setDVisible] = useState<boolean>(false);

  return (
    <>
      <Modal
        title={'Bloquear:'}
        opened={bVisible}
        onClose={() => {
          setBVisible(false);
        }}>
        <NumberInput
          label='Bloquear viagens com pagamentos inferiores ou iguais a :'
          placeholder='<='
          value={valor}
          min={0}
          onChange={setValor}
          data-autofocus
        />
        <Checkbox
          mt={'md'}
          label='Incluir Chefe de Grupo?'
          color='green'
          checked={iChefe}
          onChange={(event) => setIChefe(event.currentTarget.checked)}
          size='md'
        />
        <Button
          fullWidth
          disabled={valor === ''}
          onClick={() => {
            GrupoService.grupoBloquearFaltaPagamentosRetrieve({
              codigo: grupoID,
              ichefe: iChefe,
              valor: valor != '' ? valor : 0,
            }).then(
              () => {
                notifications.show({
                  title: 'Bloqueadas com sucesso ',
                  message: `Viagens bloqueadas `,
                  withCloseButton: true,
                  icon: <IconTrashX />,
                  color: 'green',
                });
                setBVisible(false);
                window.location.reload();
              },
              (reason) => {
                msgAPIError(reason);
                setBVisible(false);
              }
            );
          }}
          mt='md'>
          Bloquear
        </Button>
      </Modal>
      <Modal
        title={'Desbloquear:'}
        opened={dVisible}
        onClose={() => {
          setDVisible(false);
        }}>
        <NumberInput
          label='Desbloquear viagens com pagamentos superiores ou iguais a :'
          placeholder='<='
          value={valor}
          min={0}
          onChange={setValor}
          data-autofocus
        />
        <Checkbox
          label='Incluir Chefe de Grupo?'
          color='green'
          mt={'md'}
          checked={iChefe}
          onChange={(event) => setIChefe(event.currentTarget.checked)}
          size='md'
        />
        <Button
          fullWidth
          disabled={valor === ''}
          onClick={() => {
            GrupoService.grupoDesbloquearFaltaDePagamentoRetrieve({
              codigo: grupoID,
              ichefe: iChefe,
              valor: valor != '' ? valor : 0,
            }).then(
              () => {
                setDVisible(false);
                notifications.show({
                  title: 'Desbloqueadas com sucesso ',
                  message: `Viagens Desbloqueadas `,
                  withCloseButton: true,
                  icon: <IconTrashX />,
                  color: 'green',
                });
                window.location.reload();
              },
              (reason) => {
                msgAPIError(reason);
                setDVisible(false);
              }
            );
          }}
          mt='md'>
          Desbloquear
        </Button>
      </Modal>
      <Flex
        direction={'row'}
        gap={'xs'}
        justify={'center'}>
        <Button
          variant={'outline'}
          leftIcon={<IconLock />}
          onClick={() => {
            setBVisible(true);
          }}>
          Bloquear viagens com pagamentos em atraso.
        </Button>
        <Button
          variant={'outline'}
          leftIcon={<IconLockOpen />}
          onClick={() => {
            setDVisible(true);
          }}>
          Desbloquear viagens com pagamentos em atraso
        </Button>
      </Flex>
    </>
  );
}
