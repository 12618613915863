import React, { useEffect, useMemo, useState } from 'react';
import {
  Actividade,
  AddCompraOutrosRequest,
  CompraEstadoEnum,
  CompraService,
  ComprasViagem,
  Desconto,
  EstadoPagamentoEnum,
  Pacote,
  ProdutoService,
  Seguro,
  Suplemento,
} from '../../../../slidein_api';
import {
  Button,
  Checkbox,
  Group,
  LoadingOverlay,
  Modal,
  NumberInput,
  Select,
  Stack,
  Table,
  Text,
  Textarea,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import {
  IconCheck,
  IconConfetti,
  IconCurrencyEuro,
  IconCurrencyEuroOff,
  IconDeviceFloppy,
  IconHotelService,
  IconNotes,
  IconPackage,
  IconPlus,
  IconSwitch,
  IconTrash,
  IconUser,
  IconX,
} from '@tabler/icons-react';
import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import { iconBool, msgAPIError } from '../../../helpers';
import { notifications } from '@mantine/notifications';
import { useParams } from 'react-router-dom';
import { useForm } from '@mantine/form';

interface ComprasProps {
  viagem: ComprasViagem;
  refresh;
}

function getRows(table) {
  if (table?.getSelectedRowModel() && table.getSelectedRowModel().rows) {
    return table.getSelectedRowModel().rows;
  } else {
    return [];
  }
}

export function ComprasViagemView({ viagem, refresh }: ComprasProps) {
  const { campanhaID } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [addPacoteModalVisible, setAddPacoteModalVisible] = useState<boolean>(false);
  const [addSeguroModalVisible, setAddSeguroModalVisible] = useState<boolean>(false);
  const [addActividadesModalVisible, setAddActividadesModalVisible] = useState<boolean>(false);
  const [addSuplementosModalVisible, setAddSuplementosModalVisible] = useState<boolean>(false);
  const [addOutroModalVisible, setAddOutroModalVisible] = useState<boolean>(false);
  const [addDescontosModalVisible, setAddDescontosModalVisible] = useState<boolean>(false);

  const [descontos, setDescontos] = useState<Desconto[]>([]);
  const [pacotes, setPacotes] = useState<Pacote[]>([]);
  const [seguros, setSeguros] = useState<Seguro[]>([]);
  const [actividades, setActividades] = useState<Actividade[]>([]);
  const [suplementos, setSuplementos] = useState<Suplemento[]>([]);

  const fetchData = () => {
    ProdutoService.produtoDescontoList({ campanha: campanhaID }).then(
      (value) => {
        setDescontos(value);
      },
      (reason) => {
        msgAPIError(reason);
      }
    );
    ProdutoService.produtoPacoteList({ campanha: campanhaID }).then(
      (value) => {
        setPacotes(value);
      },
      (reason) => {
        msgAPIError(reason);
      }
    );
    ProdutoService.produtoSeguroList({ campanha: campanhaID }).then(
      (value) => {
        setSeguros(value);
      },
      (reason) => {
        msgAPIError(reason);
      }
    );
    ProdutoService.produtoActividadeList({ campanha: campanhaID }).then(
      (value) => {
        setActividades(value);
      },
      (reason) => {
        msgAPIError(reason);
      }
    );
    ProdutoService.produtoSuplementoList({ campanha: campanhaID }).then(
      (value) => {
        setSuplementos(value);
      },
      (reason) => {
        msgAPIError(reason);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columnsPacote = useMemo<MRT_ColumnDef<Pacote>[]>(
    () => [
      {
        accessorKey: 'nome_interno',
        header: 'Nome',
      },
      {
        accessorKey: 'valor',
        header: 'Valor',
        size: 100,
        Cell: ({ cell }) => cell.row.original.valor + '€',
      },
      {
        accessorFn: (originalRow) => originalRow.nr_vendidos,
        header: 'Vendidos/Reservados/Disponíveis',
        size: 100,
        Cell: ({ cell }) => `${cell.row.original.nr_vendidos}/${cell.row.original.nr_vendidos_e_pagos}/${cell.row.original.unidades ?? '∞'}`,
      },
      {
        accessorKey: 'activo',
        header: 'Ativo',
        Header: (
          <Tooltip label={'Activo, disponivel para compra'}>
            <div>Act.</div>
          </Tooltip>
        ),
        Cell: ({ cell }) => iconBool(cell.row.original.activo),
      },
    ],
    []
  );
  const columnsSeguro = useMemo<MRT_ColumnDef<Seguro>[]>(
    () => [
      {
        accessorKey: 'nome_interno',
        header: 'Nome',
      },
      {
        accessorKey: 'valor',
        header: 'Valor',
        size: 100,
        Cell: ({ cell }) => cell.row.original.valor + '€',
      },
      {
        accessorFn: (originalRow) => originalRow.nr_vendidos,
        header: 'Vendidos/Reservados/Disponíveis',
        size: 100,
        Cell: ({ cell }) => `${cell.row.original.nr_vendidos}/${cell.row.original.nr_vendidos_e_pagos}/${cell.row.original.unidades ?? '∞'}`,
      },
      {
        accessorKey: 'activo',
        header: 'Ativo',
        Header: (
          <Tooltip label={'Activo, disponivel para compra'}>
            <div>Act.</div>
          </Tooltip>
        ),
        Cell: ({ cell }) => iconBool(cell.row.original.activo),
      },
    ],
    []
  );

  const columnsSuplemento = useMemo<MRT_ColumnDef<Suplemento>[]>(
    () => [
      {
        accessorKey: 'nome_interno',
        header: 'Nome',
      },
      {
        accessorKey: 'valor',
        header: 'Valor',
        size: 100,
        Cell: ({ cell }) => cell.row.original.valor + '€',
      },
      {
        accessorKey: 'activo',
        header: 'Ativo',
        Header: (
          <Tooltip label={'Activo, disponivel para compra'}>
            <div>Act.</div>
          </Tooltip>
        ),
        Cell: ({ cell }) => iconBool(cell.row.original.activo),
      },
      {
        accessorFn: (originalRow) => originalRow.nr_vendidos,
        header: 'Vendidos/Reservados/Disponíveis',
        size: 100,
        Cell: ({ cell }) => `${cell.row.original.nr_vendidos}/${cell.row.original.nr_vendidos_e_pagos}/${cell.row.original.unidades ?? '∞'}`,
      },
      {
        accessorKey: 'obrigatorio',
        header: 'Obrigatório',
        Header: (
          <Tooltip label={'Aquisição Obrigatória (exemplo:"Despesas de reserva ...")'}>
            <div>Obrigatorio.</div>
          </Tooltip>
        ),
        Cell: ({ cell }) => iconBool(cell.row.original.obrigatorio),
      },
      {
        accessorKey: 'brevemente',
        header: 'Brevemente',
        Header: (
          <Tooltip label={'Brevemente, indisponível para compra'}>
            <div>Brev.</div>
          </Tooltip>
        ),
        Cell: ({ cell }) => iconBool(cell.row.original.brevemente),
      },
    ],
    []
  );
  const columnsActividade = useMemo<MRT_ColumnDef<Actividade>[]>(
    () => [
      {
        accessorKey: 'nome_interno',
        header: 'Nome',
      },
      {
        accessorKey: 'valor',
        header: 'Valor',
        size: 100,
        Cell: ({ cell }) => cell.row.original.valor + '€',
      },
      {
        accessorFn: (originalRow) => originalRow.nr_vendidos,
        header: 'Vendidos/Reservados/Disponíveis',
        size: 100,
        Cell: ({ cell }) => `${cell.row.original.nr_vendidos}/${cell.row.original.nr_vendidos_e_pagos}/${cell.row.original.unidades ?? '∞'}`,
      },
      {
        accessorKey: 'activo',
        header: 'Ativo',
        Header: (
          <Tooltip label={'Activo, disponivel para compra'}>
            <div>Act.</div>
          </Tooltip>
        ),
        Cell: ({ cell }) => iconBool(cell.row.original.activo),
      },
      {
        accessorKey: 'brevemente',
        header: 'Brevemente',
        Header: (
          <Tooltip label={'Brevemente, indisponivel para compra'}>
            <div>Brev.</div>
          </Tooltip>
        ),
        Cell: ({ cell }) => iconBool(cell.row.original.brevemente),
      },
    ],
    []
  );
  const columnsDesconto = useMemo<MRT_ColumnDef<Desconto>[]>(
    () => [
      {
        accessorKey: 'nome_interno',
        header: 'Nome',
      },
      {
        accessorKey: 'valor',
        header: 'Valor',
        size: 100,
        Cell: ({ cell }) => cell.row.original.valor + '€',
      },
    ],
    []
  );

  const outroForm = useForm<AddCompraOutrosRequest>();

  const pacotesTable = useMantineReactTable({
    columns: columnsPacote,
    data: pacotes || [],
    enableRowSelection: (row) => viagem?.compra_pacote.produto.id != row.original.id,
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        opacity: viagem?.compra_pacote.produto.id != row.original.id ? '100%' : '35%',
        cursor: 'pointer',
      },
      onClick: row.getToggleSelectedHandler(),
    }),
    enableMultiRowSelection: false,
    positionToolbarAlertBanner: 'none',
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFilters: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enablePagination: true,
    localization: MRT_Localization_PT,
    initialState: {
      density: 'xs',
      showGlobalFilter: true,
    },
  });
  const segurosTable = useMantineReactTable({
    columns: columnsSeguro,
    data: seguros || [],
    enableRowSelection: (row) => viagem?.compra_seguro.produto.id != row.original.id,
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        opacity: viagem?.compra_seguro.produto.id != row.original.id ? '100%' : '35%',
        cursor: 'pointer',
      },
      onClick: row.getToggleSelectedHandler(),
    }),
    enableMultiRowSelection: false,
    positionToolbarAlertBanner: 'none',
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFilters: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enablePagination: true,
    localization: MRT_Localization_PT,
    initialState: {
      density: 'xs',
      showGlobalFilter: true,
    },
  });
  const actividadesTable = useMantineReactTable({
    columns: columnsActividade,
    data: actividades || [],
    enableSelectAll: true,
    selectAllMode: 'all',
    getRowId: (originalRow) => originalRow.id.toString(),
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    positionToolbarAlertBanner: 'none',
    enableHiding: false,
    enablePagination: false,
    localization: MRT_Localization_PT,
    initialState: {
      density: 'xs',
    },
    enableRowSelection: (row) =>
      viagem?.compras_actividade.findIndex((gp) => gp.produto.id == row.original.id) == -1 &&
      !row.original.brevemente,
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        opacity:
          viagem?.compras_actividade.findIndex((gp) => gp.produto.id == row.original.id) == -1 &&
          !row.original.brevemente
            ? '100%'
            : '35%',
        cursor: 'pointer',
      },
      onClick: row.getToggleSelectedHandler(),
    }),
  });
  const suplementosTable = useMantineReactTable({
    columns: columnsSuplemento,
    data: suplementos || [],
    enableSelectAll: true,
    selectAllMode: 'all',
    getRowId: (originalRow) => originalRow.id.toString(),
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    positionToolbarAlertBanner: 'none',
    enableHiding: false,
    enablePagination: false,
    localization: MRT_Localization_PT,
    initialState: {
      density: 'xs',
    },
    enableRowSelection: (row) =>
      viagem?.compras_suplemento.findIndex((gp) => gp.produto.id == row.original.id) == -1 &&
      !row.original.brevemente,
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        opacity:
          viagem?.compras_suplemento.findIndex((gp) => gp.produto.id == row.original.id) == -1 &&
          !row.original.brevemente
            ? '100%'
            : '35%',
        cursor: 'pointer',
      },
      onClick: row.getToggleSelectedHandler(),
    }),
  });

  const descontosTable = useMantineReactTable({
    columns: columnsDesconto,
    data: descontos || [],
    enableSelectAll: true,
    selectAllMode: 'all',
    getRowId: (originalRow) => originalRow.id.toString(),
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    positionToolbarAlertBanner: 'none',
    enableHiding: false,
    enablePagination: false,
    localization: MRT_Localization_PT,
    initialState: {
      density: 'xs',
    },
    enableRowSelection: (row) =>
      viagem?.compras_desconto.findIndex((gp) => gp.produto.id == row.original.id) == -1,
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        opacity:
          viagem?.compras_desconto.findIndex((gp) => gp.produto.id == row.original.id) == -1
            ? '100%'
            : '35%',
        cursor: 'pointer',
      },
      onClick: row.getToggleSelectedHandler(),
    }),
  });

  const createOutro = async (outro: AddCompraOutrosRequest) => {
    outro.viagem = viagem.id;
    CompraService.compraAdicionarOutroCreate({ requestBody: outro }).then(
      () => {
        refresh();
        fetchData();

        outroForm.reset();
        setAddOutroModalVisible(false);
      },
      (error) => {
        outroForm.setErrors(error.body);
      }
    );
  };

  return (
    <Stack>
      <LoadingOverlay
        visible={loading}
        overlayBlur={2}></LoadingOverlay>
      <Table
        verticalSpacing='xs'
        fontSize='sm'
        horizontalSpacing={'xs'}
        mx={'lg'}
        style={{ tableLayout: 'fixed', width: 'auto' }}>
        <thead>
          <tr>
            <th>Produto</th>
            <th>Valor</th>
            <th>Estado</th>
            <th>Acções</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Group ml={'xl'}>
                <IconPackage />
                <Text
                  fw={700}
                  fz={'lg'}
                  ta={'center'}
                  fs={'italic'}>
                  Pacote:
                </Text>
                {viagem?.compra_pacote.produto.nome_interno}
              </Group>
            </td>
            <td>{viagem?.compra_pacote?.produto.valor} €</td>
            <td>
              <Group>
                <Select
                  disabled={true}
                  data={Object.values(CompraEstadoEnum)}
                  onChange={(value: CompraEstadoEnum) => {
                    setLoading(true);
                    CompraService.compraPacotePartialUpdate({
                      id: viagem?.compra_pacote.id,
                      requestBody: { estado: value },
                    }).then(
                      () => {
                        refresh();
                        setLoading(false);
                      },
                      (reason) => {
                        setLoading(false);
                        msgAPIError(reason);
                      }
                    );
                  }}
                  value={viagem?.compra_pacote.estado}></Select>
              </Group>
            </td>

            <td>
              <Group>
                <Button
                  variant={'outline'}
                  color={'indigo'}
                  onClick={() => setAddPacoteModalVisible(true)}
                  leftIcon={<IconSwitch />}>
                  Trocar Pacote
                </Button>
              </Group>
            </td>
          </tr>
          <tr>
            <td>
              <Group ml={'xl'}>
                <IconPackage />
                <Text
                  fw={700}
                  fz={'lg'}
                  ta={'center'}
                  fs={'italic'}>
                  Seguro:
                </Text>
                {viagem?.compra_seguro.produto.nome_interno}
              </Group>
            </td>
            <td>{viagem?.compra_seguro?.produto.valor} €</td>
            <td>
              <Group>
                <Select
                  label={'  '}
                  data={Object.values(CompraEstadoEnum)}
                  onChange={(value: CompraEstadoEnum) => {
                    setLoading(true);
                    CompraService.compraSeguroPartialUpdate({
                      id: viagem?.compra_seguro.id,
                      requestBody: { estado: value },
                    }).then(
                      () => {
                        refresh();
                        setLoading(false);
                      },
                      (reason) => {
                        setLoading(false);
                        msgAPIError(reason);
                      }
                    );
                  }}
                  value={viagem?.compra_seguro.estado}></Select>
                <Select
                  label={'Estado de Pagamento'}
                  data={Object.values(EstadoPagamentoEnum)}
                  onChange={(value: EstadoPagamentoEnum) => {
                    setLoading(true);
                    CompraService.compraSeguroPartialUpdate({
                      id: viagem?.compra_seguro.id,
                      requestBody: { estado_pagamento: value },
                    }).then(
                      () => {
                        refresh();
                        setLoading(false);
                      },
                      (reason) => {
                        setLoading(false);
                        msgAPIError(reason);
                      }
                    );
                  }}
                  value={viagem?.compra_seguro.estado_pagamento}></Select>
              </Group>
            </td>
            <td>
              <Group>
                <Button
                  variant={'outline'}
                  color={'indigo'}
                  onClick={() => setAddSeguroModalVisible(true)}
                  leftIcon={<IconSwitch />}>
                  Trocar Seguro
                </Button>
              </Group>
            </td>
          </tr>
          <tr>
            <td colSpan={5}>
              <Group ml={'xl'}>
                <IconConfetti />
                <Text
                  fw={700}
                  fz={'lg'}
                  ta={'center'}
                  fs={'italic'}>
                  Actividades:
                </Text>
              </Group>
            </td>
          </tr>
          {viagem?.compras_actividade.map((cativ) => (
            <tr key={cativ.id}>
              <td>{cativ.produto.nome_interno}</td>
              <td>{cativ.produto.valor} €</td>
              <td>
                <Group>
                  <Select
                    data={Object.values(CompraEstadoEnum)}
                    onChange={(value: CompraEstadoEnum) => {
                      setLoading(true);
                      CompraService.compraActividadePartialUpdate({
                        id: cativ.id,
                        requestBody: { estado: value },
                      }).then(
                        () => {
                          refresh();
                          setLoading(false);
                        },
                        (reason) => {
                          setLoading(false);
                          msgAPIError(reason);
                        }
                      );
                    }}
                    value={cativ.estado}
                  />
                </Group>
              </td>
              <td>
                <Group>
                  <Button
                    variant={'outline'}
                    color={'red'}
                    onClick={() => {
                      if (
                        confirm(
                          'Confirma que deseja remover a Actividade : ' +
                            cativ.produto.nome_interno +
                            ' ?'
                        )
                      ) {
                        CompraService.compraActividadeDestroy({ id: cativ.id }).then(
                          () => {
                            refresh();
                            notifications.show({
                              title: 'Removido com sucesso ',
                              message: 'removido com sucesso',
                              icon: <IconCheck />,
                            });
                          },
                          (reason) => msgAPIError(reason)
                        );
                      }
                    }}
                    leftIcon={<IconTrash />}>
                    Remover
                  </Button>
                </Group>
              </td>
            </tr>
          ))}
          <tr>
            <td
              colSpan={5}
              style={{ textAlign: 'left' }}>
              <Button
                variant={'outline'}
                onClick={() => setAddActividadesModalVisible(true)}
                leftIcon={<IconPlus />}>
                Adicionar Atividade
              </Button>
            </td>
          </tr>
          {/*suplementos*/}
          <tr>
            <td colSpan={5}>
              <Group ml={'xl'}>
                <IconHotelService />
                <Text
                  fw={700}
                  fz={'lg'}
                  ta={'center'}
                  fs={'italic'}>
                  Suplementos:
                </Text>
              </Group>
            </td>
          </tr>
          {viagem?.compras_suplemento.map((csup) => (
            <tr key={csup.id}>
              <td>{csup.produto.nome_interno}</td>
              <td>{csup.produto.valor} €</td>
              <td>
                <Group>
                  <Select
                    data={Object.values(CompraEstadoEnum)}
                    onChange={(value: CompraEstadoEnum) => {
                      setLoading(true);
                      CompraService.compraSuplementoPartialUpdate({
                        id: csup.id,
                        requestBody: { estado: value },
                      }).then(
                        () => {
                          refresh();
                          setLoading(false);
                        },
                        (reason) => {
                          setLoading(false);
                          msgAPIError(reason);
                        }
                      );
                    }}
                    value={csup.estado}
                  />
                </Group>
              </td>
              <td>
                <Group>
                  <Button
                    variant={'outline'}
                    color={'red'}
                    onClick={() => {
                      if (
                        confirm(
                          'Confirma que deseja remover o Suplemento : ' +
                            csup.produto.nome_interno +
                            ' ?'
                        )
                      ) {
                        CompraService.compraSuplementoDestroy({ id: csup.id }).then(
                          () => {
                            refresh();
                            notifications.show({
                              title: 'Removido com sucesso ',
                              message: 'removido com sucesso',
                              icon: <IconCheck />,
                            });
                          },
                          (reason) => msgAPIError(reason)
                        );
                      }
                    }}
                    leftIcon={<IconTrash />}>
                    Remover
                  </Button>
                </Group>
              </td>
            </tr>
          ))}

          <tr>
            <td
              colSpan={5}
              style={{ textAlign: 'left' }}>
              <Button
                mr={'md'}
                variant={'outline'}
                onClick={() => setAddSuplementosModalVisible(true)}
                leftIcon={<IconPlus />}>
                Adicionar Suplemento
              </Button>
            </td>
          </tr>
          <tr>
            <td colSpan={5}>
              <Group ml={'xl'}>
                <IconHotelService />
                <Text
                  fw={700}
                  fz={'lg'}
                  ta={'center'}
                  fs={'italic'}>
                  Outros (Custos de operação, items/serviços únicos,...):
                </Text>
              </Group>
            </td>
          </tr>

          {viagem?.compras_outro.map((ctx) => (
            <tr key={ctx.id}>
              <td>
                {ctx.nome}
                {ctx.custos_de_operacao ? '(Custo de operação)' : ''}
              </td>
              <td>{ctx.valor} €</td>
              <td>
                <Group>
                  <Select
                    data={Object.values(CompraEstadoEnum)}
                    onChange={(value: CompraEstadoEnum) => {
                      setLoading(true);
                      CompraService.compraOutroPartialUpdate({
                        id: ctx.id,
                        requestBody: { estado: value },
                      }).then(
                        () => {
                          refresh();
                          setLoading(false);
                        },
                        (reason) => {
                          setLoading(false);
                          msgAPIError(reason);
                        }
                      );
                    }}
                    value={ctx.estado}
                  />
                </Group>
              </td>
              <td>
                <Group>
                  <Button
                    variant={'outline'}
                    color={'red'}
                    onClick={() => {
                      if (confirm('Confirma que deseja remover : ' + ctx.nome + ' ?')) {
                        CompraService.compraOutroDestroy({ id: ctx.id }).then(
                          () => {
                            refresh();
                            notifications.show({
                              title: 'Removido com sucesso ',
                              message: 'removido com sucesso',
                              icon: <IconCheck />,
                            });
                          },
                          (reason) => msgAPIError(reason)
                        );
                      }
                    }}
                    leftIcon={<IconTrash />}>
                    Remover
                  </Button>
                </Group>
              </td>
            </tr>
          ))}
          <tr>
            <td
              colSpan={5}
              style={{ textAlign: 'left' }}>
              <Button
                variant={'outline'}
                ml={'md'}
                onClick={() => setAddOutroModalVisible(true)}
                leftIcon={<IconPlus />}>
                Adicionar outro
              </Button>
            </td>
          </tr>
          <tr>
            <td colSpan={5}>
              <Group ml={'xl'}>
                <IconCurrencyEuroOff />
                <Text
                  fw={700}
                  fz={'lg'}
                  ta={'center'}
                  fs={'italic'}>
                  Descontos
                </Text>
              </Group>
            </td>
          </tr>
          {viagem?.compras_desconto.map((cdesc) => (
            <tr key={cdesc.id}>
              <td>{cdesc.produto.nome_interno}</td>
              <td>-{cdesc.produto.valor} €</td>
              <td>
                <Group>
                  <Select
                    data={Object.values(CompraEstadoEnum)}
                    onChange={(value: CompraEstadoEnum) => {
                      setLoading(true);
                      CompraService.compraDescontoPartialUpdate({
                        id: cdesc.id,
                        requestBody: { estado: value },
                      }).then(
                        () => {
                          refresh();
                          setLoading(false);
                        },
                        (reason) => {
                          setLoading(false);
                          msgAPIError(reason);
                        }
                      );
                    }}
                    value={cdesc.estado}
                  />
                </Group>
              </td>
              <td>
                <Group>
                  <Button
                    variant={'outline'}
                    color={'red'}
                    onClick={() => {
                      if (
                        confirm(
                          'Confirma que deseja remover o Desconto : ' +
                            cdesc.produto.nome_interno +
                            ' ?'
                        )
                      ) {
                        CompraService.compraDescontoDestroy({ id: cdesc.id }).then(
                          () => {
                            refresh();
                            notifications.show({
                              title: 'Removido com sucesso ',
                              message: 'removido com sucesso',
                              icon: <IconCheck />,
                            });
                          },
                          (reason) => msgAPIError(reason)
                        );
                      }
                    }}
                    leftIcon={<IconTrash />}>
                    Remover
                  </Button>
                </Group>
              </td>
            </tr>
          ))}
          <tr>
            <td
              colSpan={5}
              style={{ textAlign: 'left' }}>
              <Button
                variant={'outline'}
                onClick={() => setAddDescontosModalVisible(true)}
                leftIcon={<IconPlus />}>
                Adicionar Desconto
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>

      <Modal
        centered
        title={'Escolher Alojamento/Pacote:'}
        opened={addPacoteModalVisible}
        size={'auto'}
        onClose={() => {
          setAddPacoteModalVisible(false);
        }}>
        <MantineReactTable table={pacotesTable} />
        <Group
          position='right'
          mt='md'>
          <Button
            color={'yellow'}
            variant={'outline'}
            leftIcon={<IconX />}
            type='button'
            onClick={() => {
              setAddPacoteModalVisible(false);
            }}>
            Cancelar
          </Button>
          <Button
            variant={'outline'}
            disabled={getRows(pacotesTable).length == 0}
            onClick={() => {
              CompraService.compraTrocarPacoteUpdate({
                id: viagem?.compra_pacote.id,
                requestBody: { produto: pacotesTable.getSelectedRowModel().rows[0].original.id },
              }).then(
                () => {
                  refresh();
                  fetchData();
                },
                (reason) => msgAPIError(reason)
              );
              setAddPacoteModalVisible(false);
            }}
            leftIcon={<IconDeviceFloppy />}
            type='button'>
            Adicionar
          </Button>
        </Group>
      </Modal>

      <Modal
        centered
        title={'Escolher Seguro:'}
        opened={addSeguroModalVisible}
        size={'auto'}
        onClose={() => {
          setAddSeguroModalVisible(false);
        }}>
        <MantineReactTable table={segurosTable} />
        <Group
          position='right'
          mt='md'>
          <Button
            color={'yellow'}
            variant={'outline'}
            leftIcon={<IconX />}
            type='button'
            onClick={() => {
              setAddSeguroModalVisible(false);
            }}>
            Cancelar
          </Button>
          <Button
            variant={'outline'}
            disabled={getRows(segurosTable).length == 0}
            onClick={() => {
              CompraService.compraTrocarSeguroUpdate({
                id: viagem?.compra_pacote.id,
                requestBody: { produto: segurosTable.getSelectedRowModel().rows[0].original.id },
              }).then(
                () => {
                  refresh();
                  fetchData();
                },
                (reason) => msgAPIError(reason)
              );
              setAddSeguroModalVisible(false);
            }}
            leftIcon={<IconDeviceFloppy />}
            type='button'>
            Adicionar
          </Button>
        </Group>
      </Modal>
      <Modal
        centered
        title={'Escolher Actividades/Opcionais:'}
        opened={addActividadesModalVisible}
        size={'auto'}
        onClose={() => {
          setAddActividadesModalVisible(false);
        }}>
        <Title order={4}>
          {getRows(actividadesTable).length}&nbsp;Actividades/Opcionais Selecionadas.
        </Title>
        <MantineReactTable table={actividadesTable} />
        <Group
          position='right'
          mt='md'>
          <Button
            color={'yellow'}
            variant={'outline'}
            leftIcon={<IconX />}
            type='button'
            onClick={() => {
              setAddActividadesModalVisible(false);
            }}>
            Cancelar
          </Button>
          <Button
            variant={'outline'}
            disabled={getRows(actividadesTable).length == 0}
            onClick={() => {
              actividadesTable.getSelectedRowModel().rows.forEach((row) => {
                CompraService.compraAdicionarActividadeCreate({
                  requestBody: { produto: row.original.id, viagem: viagem?.id },
                }).then(
                  () => {
                    refresh();
                    fetchData();
                  },
                  (reason) => msgAPIError(reason)
                );
              });

              setAddActividadesModalVisible(false);
            }}
            leftIcon={<IconDeviceFloppy />}
            type='button'>
            Adicionar
          </Button>
        </Group>
      </Modal>
      <Modal
        centered
        title={'Escolher Suplementos:'}
        opened={addSuplementosModalVisible}
        size={'auto'}
        onClose={() => {
          setAddSuplementosModalVisible(false);
        }}>
        <Title order={4}>{getRows(suplementosTable).length}&nbsp;Suplementos Selecionados.</Title>
        <MantineReactTable table={suplementosTable} />
        <Group
          position='right'
          mt='md'>
          <Button
            color={'yellow'}
            variant={'outline'}
            leftIcon={<IconX />}
            type='button'
            onClick={() => {
              setAddSuplementosModalVisible(false);
            }}>
            Cancelar
          </Button>
          <Button
            variant={'outline'}
            disabled={getRows(suplementosTable).length == 0}
            onClick={() => {
              suplementosTable.getSelectedRowModel().rows.forEach((row) => {
                CompraService.compraAdicionarSuplementoCreate({
                  requestBody: { produto: row.original.id, viagem: viagem?.id },
                }).then(
                  () => {
                    refresh();
                    fetchData();
                  },
                  (reason) => msgAPIError(reason)
                );
              });
              setAddSuplementosModalVisible(false);
            }}
            leftIcon={<IconDeviceFloppy />}
            type='button'>
            Adicionar
          </Button>
        </Group>
      </Modal>
      <Modal
        centered
        title={'Criar outro:'}
        opened={addOutroModalVisible}
        size={'xl'}
        onClose={() => {
          setAddOutroModalVisible(false);
        }}>
        <form onSubmit={outroForm.onSubmit(createOutro)}>
          <TextInput
            label={'Titulo Cliente'}
            required={true}
            icon={<IconUser />}
            {...outroForm.getInputProps('nome')}
          />
          <NumberInput
            placeholder='Valor'
            label='Valor'
            precision={2}
            required={true}
            icon={<IconCurrencyEuro />}
            hideControls
            min={0}
            decimalSeparator=','
            thousandsSeparator='.'
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            formatter={(value) =>
              !Number.isNaN(parseFloat(value))
                ? // eslint-disable-next-line security/detect-unsafe-regex
                  `${value} €`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                : ''
            }
            {...outroForm.getInputProps('valor')}
          />
          <Checkbox
            my={'lg'}
            labelPosition='left'
            label='Custo de Operação?'
            color='green'
            radius='md'
            size='lg'
            {...outroForm.getInputProps('custos_de_operacao', { type: 'checkbox' })}
          />
          <Textarea
            placeholder='Descrição...'
            label='Descrição Cliente'
            size='md'
            icon={<IconNotes />}
            autosize={true}
            minRows={2}
            maxRows={4}
            {...outroForm.getInputProps('descricao')}
          />
          <Group
            position='right'
            mt='md'>
            <Button
              color={'yellow'}
              variant={'outline'}
              leftIcon={<IconX />}
              type='button'
              onClick={() => {
                setAddOutroModalVisible(false);
                outroForm.reset();
              }}>
              Cancelar
            </Button>
            <Button
              variant={'outline'}
              leftIcon={<IconDeviceFloppy />}
              type='submit'>
              Adicionar
            </Button>
          </Group>
        </form>
      </Modal>

      <Modal
        centered
        title={'Escolher Descontos:'}
        opened={addDescontosModalVisible}
        size={'auto'}
        onClose={() => {
          setAddDescontosModalVisible(false);
        }}>
        <Title order={4}>{getRows(descontosTable).length}&nbsp;Descontos Selecionados.</Title>
        <MantineReactTable table={descontosTable} />
        <Group
          position='right'
          mt='md'>
          <Button
            color={'yellow'}
            variant={'outline'}
            leftIcon={<IconX />}
            type='button'
            onClick={() => {
              setAddDescontosModalVisible(false);
            }}>
            Cancelar
          </Button>
          <Button
            variant={'outline'}
            disabled={getRows(descontosTable).length == 0}
            onClick={() => {
              descontosTable.getSelectedRowModel().rows.forEach((row) => {
                CompraService.compraAdicionarDescontoCreate({
                  requestBody: { produto: row.original.id, viagem: viagem?.id },
                }).then(
                  () => {
                    refresh();
                    fetchData();
                  },
                  (reason) => msgAPIError(reason)
                );
              });
              setAddDescontosModalVisible(false);
            }}
            leftIcon={<IconDeviceFloppy />}
            type='button'>
            Adicionar
          </Button>
        </Group>
      </Modal>
    </Stack>
  );
}
