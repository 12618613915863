import { useMantineTheme } from '@mantine/core';

export function SlideinLogo() {
  const theme = useMantineTheme();
  return (
    <svg
      width='150px'
      height='25px'
      viewBox='0 0 149.78555 25.519012'
      version='1.1'
      id='svg954'
      xmlns='http://www.w3.org/2000/svg'>
      <defs id='defs951'>
        <clipPath
          transform='matrix(2.8346458,0,0,-2.8346458,-393.46711,115.79758)'
          clipPathUnits='userSpaceOnUse'
          id='clipPath913'>
          <path
            d='M -46.927797,11.087199 H 250.07228 V 221.08875 H -46.927797 Z'
            id='path915'
            strokeWidth={0.352778}
          />
        </clipPath>
        <clipPath
          transform='matrix(2.8346458,0,0,-2.8346458,-436.16113,159.92218)'
          clipPathUnits='userSpaceOnUse'
          id='clipPath907'>
          <path
            d='M -46.927797,11.087199 H 250.07228 V 221.08875 H -46.927797 Z'
            id='path909'
            strokeWidth={0.352778}
          />
        </clipPath>
      </defs>
      <g
        id='layer1'
        transform='translate(-14.405661,-150.29283)'>
        <g
          id='g43'
          transform='matrix(0.35277777,0,0,-0.35277777,125.24632,157.0924)'
          clipPath='url(#clipPath913)'>
          <path
            d='M 0,0 V 18.788 H -49.519 -68.068 V 0 -9.36 -24.793 -31.117 c -0.178,-1.031 -0.26,-2.072 -0.26,-3.136 0,-1.082 0.082,-2.105 0.26,-3.139 v -0.016 c 1.501,-8.887 9.241,-15.655 18.549,-15.655 H 0 v 18.81 h -30.712 -18.807 v 9.46 H -33.26 -4.145 V -9.36 H -49.519 V 0 Z m -90.117,-9.422 h -20.341 -9.887 -2.468 c -1.953,-0.467 -3.468,-1.994 -3.936,-3.943 v -2.461 -4.404 -3.53 -4.388 -2.467 c 0.468,-1.954 1.983,-3.486 3.936,-3.937 h 2.468 9.887 6.63 13.711 v 14.111 z m 18.984,-27.211 v -0.024 -0.009 c 0,-9.048 -7.357,-16.397 -16.403,-16.397 h -0.017 -2.564 -36.632 c -9.082,0.01 -16.42,7.365 -16.42,16.406 0,0.725 0.048,1.411 0.129,2.105 h -0.146 v 25.13 h 0.146 c -0.081,0.678 -0.129,1.387 -0.129,2.081 0,9.063 7.338,16.419 16.42,16.419 h 36.632 v 9.71 h 18.984 v -9.71 -18.5 -25.13 z m -93.554,-16.147 h 18.743 v 71.568 h -18.743 z m -51.405,25.147 v 46.421 h -18.776 v -50.631 c -0.048,-0.547 -0.064,-1.115 -0.064,-1.66 0,-0.566 0.016,-1.114 0.064,-1.662 0.839,-9.71 8.871,-17.371 18.776,-17.615 h 0.436 48.114 v 18.414 h -41.84 c -3.711,0 -6.71,3.017 -6.71,6.733 m -41.536,18.338 h -8.016 -4.677 -24.808 v 28.083 c -11.211,0 -19.065,-10.66 -19.065,-21.66 0,-12.533 9.113,-21.633 18.162,-21.778 3.081,-0.049 9.485,0 9.485,0 h 5.08 17.372 c 3.436,0 6.226,-2.791 6.226,-6.24 v -3.614 h -24.226 -32.067 v -8.838 -0.162 c 0,0 0.016,-0.551 0.049,-0.832 0.419,-4.733 4.387,-8.444 9.225,-8.444 h 39.842 9.436 c 6.887,0 14.129,7.115 16.678,14.123 0.887,2.413 1.371,4.992 1.371,7.702 0,4.95 -1.614,9.548 -4.356,13.256 -2.999,4.083 -7.789,8.404 -15.711,8.404 m -11.935,9.197 h -22.372 v 18.886 h 5.855 39.325 c 3.21,0 5.806,-2.596 5.806,-5.775 V -0.098 Z'
            fill={theme.colorScheme === 'light' ? 'black' : 'white'}
            fillOpacity={1}
            fillRule={'nonzero'}
            stroke={'none'}
            id='path45'
          />
        </g>
        <g
          id='g47'
          transform='matrix(0.35277777,0,0,-0.35277777,140.30782,172.65858)'
          clipPath='url(#clipPath907)'>
          <path
            d='M 0,0 C 0,0.385 -0.302,0.702 -0.691,0.702 H -3.093 -3.837 -6.969 V -2.38 H -6.661 -0.286 0 Z M 3.487,0.879 V 0.702 -8.938 H 0 v 3.146 h -6.969 v -3.146 h -3.481 v 9.64 0.177 c 0,1.839 1.486,3.304 3.288,3.304 H -6.969 0 0.178 c 1.825,0 3.309,-1.465 3.309,-3.304 m -17.985,-1.273 c 0,0.594 -0.485,1.084 -1.095,1.084 h -2.278 -0.516 -3.114 v -3.07 h 0.308 5.293 0.307 c 0.107,0 0.205,0.016 0.302,0.032 0.453,0.13 0.793,0.556 0.793,1.047 v 0.599 z m 1.9,-4.55 1.727,-3.994 h -3.643 l -1.613,3.734 h -5.387 v -3.734 h -3.487 v 3.734 2.824 3.082 h 0.016 c -0.016,0.053 -0.016,0.113 -0.016,0.177 0,1.839 1.468,3.304 3.308,3.304 h 0.179 6.902 0.098 c 1.77,-0.048 3.222,-1.514 3.222,-3.304 v -3.734 c 0,-0.929 -0.545,-1.701 -1.306,-2.089 m -20.467,9.127 h -6.242 V 0.702 h 0.985 4.177 c 0.594,0 1.08,-0.502 1.08,-1.096 v -4.162 -4.382 h 3.468 v 9.64 h 3.999 v 3.481 h -3.999 z m 72.196,-9.521 c -0.529,0 -0.966,0.442 -0.966,0.966 v 2.154 6.401 h -3.469 v -9.521 -0.189 c 0,-1.814 1.451,-3.288 3.275,-3.288 h 0.194 8.874 v 3.477 H 41.281 Z M 21.552,4.183 v -3.481 -6.04 -0.189 c 0,-1.814 1.468,-3.288 3.287,-3.288 h 0.178 8.906 v 3.477 h -5.775 -3.131 v 1.546 h 2.661 5.582 v 2.822 h -8.243 v 1.672 h 8.906 V 4.183 H 25.017 Z M 11.712,-5.219 6.904,4.183 H 2.936 L 9.662,-8.938 h 0.145 3.827 0.14 l 6.715,13.121 h -3.956 z m 55.989,49.92 c 0,10.326 -8.372,18.698 -18.698,18.698 H 48.987 1.582 -17.113 V 9.91 H 1.582 v 34.791 h 28.714 11.853 c 3.773,0 6.854,-3.079 6.854,-6.87 V 26.01 -8.938 H 67.701 Z M -20.597,9.91 h -18.71 v 53.489 h 18.71 z'
            fill={'#60c44c'}
            fillOpacity={1}
            fillRule={'nonzero'}
            stroke={'none'}
            id='path49'
          />
        </g>
      </g>
    </svg>
  );
}
