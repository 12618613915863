/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Pagamento Efetuado` - Pagos
 * * `Inscrito` - Inscrito
 */
export enum CondLimiteEnum {
    PAGAMENTO_EFETUADO = 'Pagamento Efetuado',
    INSCRITO = 'Inscrito',
}
