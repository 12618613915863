import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import React, { useEffect } from 'react';
import { Button, Container, Paper, PasswordInput, Title } from '@mantine/core';
import { SlideinLogo } from '../components/logos';
import { useNavigate, useParams } from 'react-router-dom';
import { useBread } from '../layout/context/BreadContext';
import { IconLogin } from '@tabler/icons-react';
import { useSlideInMenu } from '../layout/context/MenuContext';
import { ApiError, ColaboradorService, PasswordResetConfirmRetypeRequest } from '../slidein_api';

export function NewPass() {
  //bread
  const { setBread } = useBread();
  const { uid, token } = useParams();
  const { setMenuItems } = useSlideInMenu();
  useEffect(() => {
    setBread({
      accao: {
        title: 'Recuperar Password',
        href: '/',
        icon: <IconLogin />,
      },
    });
    //menu
    setMenuItems([]);
  }, []);

  const form = useForm<PasswordResetConfirmRetypeRequest>({
    initialValues: {
      uid: uid,
      token: token,
      new_password: '',
      re_new_password: '',
    },
    validate: yupResolver(
      Yup.object({
        new_password: Yup.string()
          .min(8, 'Tamanho mínimo 8 caracteres')
          .max(20, 'Tamanho máximo 20 caracteres')
          .required('Obrigatório'),
        re_new_password: Yup.string()
          .oneOf([Yup.ref('new_password'), null], 'As passwords não coincidem!')
          .min(8, 'Tamanho mínimo 8 caracteres')
          .max(20, 'Tamanho máximo 20 caracteres')
          .required('Obrigatório'),
      })
    ),
  });
  const navigate = useNavigate();

  function sumitForm(values: PasswordResetConfirmRetypeRequest) {
    ColaboradorService.colaboradorResetPasswordConfirmCreate({ requestBody: values }).then(
      () => {
        // msgSuccess('A password foi alterada com sucesso!');
        navigate('/');
      },
      (reason: ApiError) => {
        if (reason.body['uid'] !== undefined || reason.body['token'] !== undefined) {
          // msgWarn('O teu link deve ter expirado! Tenta de novo.');
          navigate('/');
        } else {
          // msgError('Algo correu mal, verificar a informação submetida! Tenta de Novo!');
          form.setErrors(reason.body);
        }
      }
    );
  }

  return (
    <Container
      size={520}
      my={160}>
      <Title
        align='center'
        order={1}>
        <SlideinLogo />
      </Title>

      <form onSubmit={form.onSubmit(sumitForm)}>
        <Paper
          withBorder
          shadow='md'
          p={30}
          mt={30}
          radius='md'>
          <PasswordInput
            label='Nova password'
            placeholder='...'
            required
            mt='md'
            {...form.getInputProps('new_password')}
          />
          <PasswordInput
            label='Repetir password'
            placeholder='...'
            required
            mt='md'
            {...form.getInputProps('re_new_password')}
          />

          <Button
            type={'submit'}
            variant={'outline'}
            fullWidth
            mt='xl'>
            Recuperar Password
          </Button>
        </Paper>
      </form>
    </Container>
  );
}
