/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Colaborador } from '../models/Colaborador';
import type { ColaboradorOwn } from '../models/ColaboradorOwn';
import type { ColaboradorOwnRequest } from '../models/ColaboradorOwnRequest';
import type { ColaboradorRequest } from '../models/ColaboradorRequest';
import type { PasswordResetConfirmRetype } from '../models/PasswordResetConfirmRetype';
import type { PasswordResetConfirmRetypeRequest } from '../models/PasswordResetConfirmRetypeRequest';
import type { PatchedColaboradorOwnRequest } from '../models/PatchedColaboradorOwnRequest';
import type { PatchedColaboradorRequest } from '../models/PatchedColaboradorRequest';
import type { SendEmailReset } from '../models/SendEmailReset';
import type { SendEmailResetRequest } from '../models/SendEmailResetRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ColaboradorService {

    /**
     * @returns Colaborador
     * @throws ApiError
     */
    public static colaboradorList({
        slideinRole,
    }: {
        /**
         * * `Master` - ♚
         * * `Admin` - ♜
         * * `Finance` - ♛
         * * `Comercial` - ♞
         * * `TravelStaff` - ♟
         */
        slideinRole?: 'Admin' | 'Comercial' | 'Finance' | 'Master' | 'TravelStaff',
    }): CancelablePromise<Array<Colaborador>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/colaborador',
            query: {
                'slidein_role': slideinRole,
            },
        });
    }

    /**
     * @returns Colaborador
     * @throws ApiError
     */
    public static colaboradorCreate({
        requestBody,
    }: {
        requestBody: ColaboradorRequest,
    }): CancelablePromise<Colaborador> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/colaborador',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Colaborador
     * @throws ApiError
     */
    public static colaboradorRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this Utilizador.
         */
        id: number,
    }): CancelablePromise<Colaborador> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/colaborador/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Colaborador
     * @throws ApiError
     */
    public static colaboradorUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Utilizador.
         */
        id: number,
        requestBody: ColaboradorRequest,
    }): CancelablePromise<Colaborador> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/colaborador/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Colaborador
     * @throws ApiError
     */
    public static colaboradorPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Utilizador.
         */
        id: number,
        requestBody?: PatchedColaboradorRequest,
    }): CancelablePromise<Colaborador> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/colaborador/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static colaboradorDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this Utilizador.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/colaborador/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Colaborador
     * @throws ApiError
     */
    public static colaboradorGdprUserUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Utilizador.
         */
        id: number,
        requestBody: ColaboradorRequest,
    }): CancelablePromise<Colaborador> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/colaborador/{id}/gdpr_user',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Colaborador
     * @throws ApiError
     */
    public static colaboradorMapaRetrieve(): CancelablePromise<Colaborador> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/colaborador/mapa',
        });
    }

    /**
     * @returns ColaboradorOwn
     * @throws ApiError
     */
    public static colaboradorOwnCreate({
        requestBody,
    }: {
        requestBody: ColaboradorOwnRequest,
    }): CancelablePromise<ColaboradorOwn> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/colaborador/own',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ColaboradorOwn
     * @throws ApiError
     */
    public static colaboradorOwnRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this Utilizador.
         */
        id: number,
    }): CancelablePromise<ColaboradorOwn> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/colaborador/own/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ColaboradorOwn
     * @throws ApiError
     */
    public static colaboradorOwnUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Utilizador.
         */
        id: number,
        requestBody: ColaboradorOwnRequest,
    }): CancelablePromise<ColaboradorOwn> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/colaborador/own/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ColaboradorOwn
     * @throws ApiError
     */
    public static colaboradorOwnPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Utilizador.
         */
        id: number,
        requestBody?: PatchedColaboradorOwnRequest,
    }): CancelablePromise<ColaboradorOwn> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/colaborador/own/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static colaboradorOwnDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this Utilizador.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/colaborador/own/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ColaboradorOwn
     * @throws ApiError
     */
    public static colaboradorOwnMeRetrieve(): CancelablePromise<ColaboradorOwn> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/colaborador/own/me',
        });
    }

    /**
     * @returns SendEmailReset
     * @throws ApiError
     */
    public static colaboradorResetPasswordCreate({
        requestBody,
    }: {
        requestBody: SendEmailResetRequest,
    }): CancelablePromise<SendEmailReset> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/colaborador/reset_password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PasswordResetConfirmRetype
     * @throws ApiError
     */
    public static colaboradorResetPasswordConfirmCreate({
        requestBody,
    }: {
        requestBody: PasswordResetConfirmRetypeRequest,
    }): CancelablePromise<PasswordResetConfirmRetype> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/colaborador/reset_password_confirm',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
