import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ApiError, Campanha, CampanhaEstadoEnum, CampanhaService } from '../slidein_api';
import { useNavigate } from 'react-router-dom';
import { MenuContext } from '../layout/context/MenuContext';
import { useBread } from '../layout/context/BreadContext';
import {
  IconBuilding,
  IconFiles,
  IconHome,
  IconList,
  IconPlus,
  IconSquareRoundedLetterS,
  IconUser,
  IconUsers,
} from '@tabler/icons-react';
import { GestaoMenuItem } from '../layout/Layout';
import { msgAPIError } from './helpers';
import { Badge, Button, Code, Flex, Tooltip } from '@mantine/core';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_GlobalFilterTextInput,
  MRT_ShowHideColumnsButton,
  useMantineReactTable,
} from 'mantine-react-table';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import {
  ALL_ALLOW,
  CheckPermission,
  MASTER,
  MASTER_ADMIN_FINANCE,
  MASTER_FINANCE,
} from '../components/CheckPermission';

export const homeMenu: GestaoMenuItem[] = [
  {
    label: 'Geral',
    roles: ALL_ALLOW,
    items: [
      {
        label: 'Campanhas',
        to: '/',
        icon: <IconHome />,
        roles: ALL_ALLOW,
      },
      {
        label: 'Instituições',
        to: '/instituicao',
        icon: <IconBuilding />,
        roles: MASTER_ADMIN_FINANCE,
      },
      {
        label: 'Marcas',
        to: '/marca',
        icon: <IconSquareRoundedLetterS />,
        roles: MASTER_FINANCE,
      },
      {
        label: 'Documentos Gerais',
        to: '/docs',
        icon: <IconFiles />,
        roles: MASTER_ADMIN_FINANCE,
      },
    ],
  },
  {
    label: 'Utilizadores',
    roles: MASTER_FINANCE,
    items: [
      { label: 'Clientes', to: 'cliente', icon: <IconUsers />, roles: MASTER_FINANCE },
      {
        label: 'Colaboradores ',
        to: 'colaborador',
        icon: <IconUser />,
        roles: MASTER_FINANCE,
      },
    ],
  },
];

export function Home() {
  const { setBread } = useBread();
  const navigate = useNavigate();
  const { setMenuItems, setMenuCampanha } = useContext(MenuContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [campanhas, setCampanhas] = useState<Campanha[]>([]);

  useEffect(() => {
    setMenuItems(homeMenu);
    setMenuCampanha(undefined);
    setBread({ accao: { title: 'Campanhas', href: '/', icon: <IconList /> } });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    CampanhaService.campanhaList({}).then(
      (value) => {
        setCampanhas(value);
        setIsLoading(false);
      },
      (reason: ApiError) => {
        msgAPIError(reason);
        setCampanhas([]);
        setIsLoading(false);
      }
    );
  }, []);

  const columnsCampanhas = useMemo<MRT_ColumnDef<Campanha>[]>(
    () => [
      {
        header: 'Estado',
        size: 65,
        accessorFn: (row) => {
          const status: CampanhaEstadoEnum = row.estado;
          let color: string;
          if (status === CampanhaEstadoEnum.EM_PREPARA_O) {
            color = 'yellow';
          } else if (status === CampanhaEstadoEnum.ABERTA) {
            color = 'green';
          } else if (status === CampanhaEstadoEnum.SUSPENSA) {
            color = 'red';
          } else if (status === CampanhaEstadoEnum.FINALIZADA) {
            color = 'white';
          } else {
            color = 'gray';
          }
          return (
            <Badge
              color={color}
              size='xl'
              radius='md'
              variant='filled'>
              {status}
            </Badge>
          );
        },
      },
      {
        accessorFn: (row) => <Code>{row.identificador}</Code>,
        header: 'ID',
        size: 65,
      },
      {
        accessorKey: 'nome',
        header: 'Nome',
      },
      {
        accessorKey: 'destino',
        header: 'Destino',
      },
      {
        accessorFn: (row) => (row.marca_ro ? row.marca_ro.nome : '-'),
        header: 'Marca',
      },
      {
        accessorFn: (row) => `${row.data_inicio} a ${row.data_fim}`,
        header: 'Data Campanha',
        size: 10,
      },
      {
        accessorFn: (row) => `${row.data_inicio_viagem} a ${row.data_fim_viagem}`,
        header: 'Data Viagem',
        size: 10,
      },
      {
        accessorKey: 'data_limite_ultima_prestacao',
        header: 'Data lim.Prest.',
        Header: (
          <Tooltip label='Data Limite para a última Prestação'>
            <b>Data Lim. Prest.</b>
          </Tooltip>
        ),
        size: 10,
      },
    ],
    []
  );
  const tableCampanhas = useMantineReactTable({
    data: campanhas,
    columns: columnsCampanhas,
    positionGlobalFilter: 'none',
    renderToolbarInternalActions: ({ table }) => (
      <Flex
        direction={'row'}
        wrap={'wrap'}
        gap={'sm'}
        justify={'space-evenly'}>
        <MRT_GlobalFilterTextInput table={table} />
        <CheckPermission roles={MASTER}>
          <Button
            leftIcon={<IconPlus />}
            variant='outline'
            onClick={() => {
              navigate('campanha/criar');
            }}>
            Criar Campanha
          </Button>

          <MRT_ShowHideColumnsButton table={table} />
        </CheckPermission>
      </Flex>
    ),
    initialState: {
      density: 'md',
      showGlobalFilter: true,
    },
    state: {
      isLoading,
    },

    localization: MRT_Localization_PT,
    enableColumnActions: false,
    enablePagination: false,
    enableSorting: true,
    enableTopToolbar: true,
    mantineTableProps: {
      highlightOnHover: true,
      withColumnBorders: true,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        navigate('campanha/' + row.original.identificador);
      },
    }),
  });

  return <MantineReactTable table={tableCampanhas} />;
}
