/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `AF` - Afeganistão
 * * `ZA` - África do Sul
 * * `AL` - Albânia
 * * `DE` - Alemanha
 * * `AD` - Andorra
 * * `AO` - Angola
 * * `AG` - Antiga e Barbuda
 * * `SA` - Arábia Saudita
 * * `DZ` - Argélia
 * * `AR` - Argentina
 * * `AM` - Arménia
 * * `AU` - Austrália
 * * `AT` - Áustria
 * * `AZ` - Azerbaijão
 * * `BS` - Bahamas
 * * `BD` - Bangladexe
 * * `BB` - Barbados
 * * `BH` - Barém
 * * `BE` - Bélgica
 * * `BZ` - Belize
 * * `BJ` - Benim
 * * `BY` - Bielorrússia
 * * `BO` - Bolívia
 * * `BA` - Bósnia e Herzegovina
 * * `BW` - Botsuana
 * * `BR` - Brasil
 * * `BN` - Brunei
 * * `BG` - Bulgária
 * * `BF` - Burquina Faso
 * * `BI` - Burúndi
 * * `BT` - Butão
 * * `CV` - Cabo Verde
 * * `CM` - Camarões
 * * `KH` - Camboja
 * * `CA` - Canadá
 * * `QA` - Catar
 * * `KZ` - Cazaquistão
 * * `TD` - Chade
 * * `CL` - Chile
 * * `CN` - China
 * * `CY` - Chipre
 * * `CO` - Colômbia
 * * `KM` - Comores
 * * `CG` - Congo-Brazzaville
 * * `KP` - Coreia do Norte
 * * `KR` - Coreia do Sul
 * * `CI` - Costa do Marfim
 * * `CR` - Costa Rica
 * * `HR` - Croácia
 * * `KW` - Cuaite
 * * `CU` - Cuba
 * * `DK` - Dinamarca
 * * `DM` - Dominica
 * * `EG` - Egito
 * * `AE` - Emirados Árabes Unidos
 * * `EC` - Equador
 * * `ER` - Eritreia
 * * `SK` - Eslováquia
 * * `SI` - Eslovénia
 * * `ES` - Espanha
 * * `SZ` - Essuatíni
 * * `PS` - Estado da Palestina
 * * `US` - Estados Unidos da America
 * * `EE` - Estónia
 * * `ET` - Etiópia
 * * `FJ` - Fiji
 * * `PH` - Filipinas
 * * `FI` - Finlândia
 * * `FR` - França
 * * `GA` - Gabão
 * * `GM` - Gâmbia
 * * `GH` - Gana
 * * `GE` - Geórgia
 * * `GD` - Granada
 * * `GR` - Grécia
 * * `GT` - Guatemala
 * * `GY` - Guiana
 * * `GN` - Guiné
 * * `GQ` - Guiné Equatorial
 * * `GW` - Guiné-Bissau
 * * `HT` - Haiti
 * * `HN` - Honduras
 * * `HU` - Hungria
 * * `YE` - Iémen
 * * `MH` - Ilhas Marechal
 * * `IN` - Índia
 * * `ID` - Indonésia
 * * `IR` - Irão
 * * `IQ` - Iraque
 * * `IE` - Irlanda
 * * `IS` - Islândia
 * * `IL` - Israel
 * * `IT` - Itália
 * * `JM` - Jamaica
 * * `JP` - Japão
 * * `DJ` - Jibuti
 * * `JO` - Jordânia
 * * `LA` - Laus
 * * `LS` - Lesoto
 * * `LV` - Letónia
 * * `LB` - Líbano
 * * `LR` - Libéria
 * * `LY` - Líbia
 * * `LI` - Listenstaine
 * * `LT` - Lituânia
 * * `LU` - Luxemburgo
 * * `MK` - Macedónia do Norte
 * * `MG` - Madagáscar
 * * `MY` - Malásia
 * * `MW` - Maláui
 * * `MV` - Maldivas
 * * `ML` - Mali
 * * `MT` - Malta
 * * `MA` - Marrocos
 * * `MU` - Maurícia
 * * `MR` - Mauritânia
 * * `MX` - México
 * * `MM` - Mianmar
 * * `FM` - Micronésia
 * * `MZ` - Moçambique
 * * `MD` - Moldávia
 * * `MC` - Mónaco
 * * `MN` - Mongólia
 * * `ME` - Montenegro
 * * `NA` - Namíbia
 * * `NR` - Nauru
 * * `NP` - Nepal
 * * `NI` - Nicarágua
 * * `NE` - Níger
 * * `NG` - Nigéria
 * * `NO` - Noruega
 * * `NZ` - Nova Zelândia
 * * `OM` - Omã
 * * `NL` - Países Baixos
 * * `PW` - Palau
 * * `PA` - Panamá
 * * `PG` - Papua Nova Guiné
 * * `PK` - Paquistão
 * * `PY` - Paraguai
 * * `PE` - Peru
 * * `PL` - Polónia
 * * `PT` - Portugal
 * * `KE` - Quénia
 * * `KG` - Quirguistão
 * * `KI` - Quiribáti
 * * `GB` - Reino Unido
 * * `CF` - República Centro-Africana
 * * `CZ` - República Checa
 * * `CD` - República Democrática do Congo
 * * `DO` - República Dominicana
 * * `RO` - Roménia
 * * `RW` - Ruanda
 * * `RU` - Rússia
 * * `SB` - Salomão
 * * `SV` - Salvador
 * * `WS` - Samoa
 * * `LC` - Santa Lúcia
 * * `KN` - São Cristóvão e Neves
 * * `SM` - São Marinho
 * * `ST` - São Tomé e Príncipe
 * * `VC` - São Vicente e Granadinas
 * * `SC` - Seicheles
 * * `SN` - Senegal
 * * `SL` - Serra Leoa
 * * `RS` - Sérvia
 * * `SG` - Singapura
 * * `SY` - Síria
 * * `SO` - Somália
 * * `LK` - Sri Lanca
 * * `SD` - Sudão
 * * `SS` - Sudão do Sul
 * * `SE` - Suécia
 * * `CH` - Suíça
 * * `SR` - Suriname
 * * `TH` - Tailândia
 * * `TW` - Taiuã
 * * `TJ` - Tajiquistão
 * * `TZ` - Tanzânia
 * * `TL` - Timor-Leste
 * * `TG` - Togo
 * * `TO` - Tonga
 * * `TT` - Trindade e Tobago
 * * `TN` - Tunísia
 * * `TM` - Turcomenistão
 * * `TR` - Turquia
 * * `TV` - Tuvalu
 * * `UA` - Ucrânia
 * * `UG` - Uganda
 * * `UY` - Uruguai
 * * `UZ` - Usbequistão
 * * `VU` - Vanuatu
 * * `VA` - Vaticano
 * * `VE` - Venezuela
 * * `VN` - Vietname
 * * `ZM` - Zâmbia
 * * `ZW` - Zimbábue
 */
export enum PaisCodeEnum {
    AF = 'AF',
    ZA = 'ZA',
    AL = 'AL',
    DE = 'DE',
    AD = 'AD',
    AO = 'AO',
    AG = 'AG',
    SA = 'SA',
    DZ = 'DZ',
    AR = 'AR',
    AM = 'AM',
    AU = 'AU',
    AT = 'AT',
    AZ = 'AZ',
    BS = 'BS',
    BD = 'BD',
    BB = 'BB',
    BH = 'BH',
    BE = 'BE',
    BZ = 'BZ',
    BJ = 'BJ',
    BY = 'BY',
    BO = 'BO',
    BA = 'BA',
    BW = 'BW',
    BR = 'BR',
    BN = 'BN',
    BG = 'BG',
    BF = 'BF',
    BI = 'BI',
    BT = 'BT',
    CV = 'CV',
    CM = 'CM',
    KH = 'KH',
    CA = 'CA',
    QA = 'QA',
    KZ = 'KZ',
    TD = 'TD',
    CL = 'CL',
    CN = 'CN',
    CY = 'CY',
    CO = 'CO',
    KM = 'KM',
    CG = 'CG',
    KP = 'KP',
    KR = 'KR',
    CI = 'CI',
    CR = 'CR',
    HR = 'HR',
    KW = 'KW',
    CU = 'CU',
    DK = 'DK',
    DM = 'DM',
    EG = 'EG',
    AE = 'AE',
    EC = 'EC',
    ER = 'ER',
    SK = 'SK',
    SI = 'SI',
    ES = 'ES',
    SZ = 'SZ',
    PS = 'PS',
    US = 'US',
    EE = 'EE',
    ET = 'ET',
    FJ = 'FJ',
    PH = 'PH',
    FI = 'FI',
    FR = 'FR',
    GA = 'GA',
    GM = 'GM',
    GH = 'GH',
    GE = 'GE',
    GD = 'GD',
    GR = 'GR',
    GT = 'GT',
    GY = 'GY',
    GN = 'GN',
    GQ = 'GQ',
    GW = 'GW',
    HT = 'HT',
    HN = 'HN',
    HU = 'HU',
    YE = 'YE',
    MH = 'MH',
    IN = 'IN',
    ID = 'ID',
    IR = 'IR',
    IQ = 'IQ',
    IE = 'IE',
    IS = 'IS',
    IL = 'IL',
    IT = 'IT',
    JM = 'JM',
    JP = 'JP',
    DJ = 'DJ',
    JO = 'JO',
    LA = 'LA',
    LS = 'LS',
    LV = 'LV',
    LB = 'LB',
    LR = 'LR',
    LY = 'LY',
    LI = 'LI',
    LT = 'LT',
    LU = 'LU',
    MK = 'MK',
    MG = 'MG',
    MY = 'MY',
    MW = 'MW',
    MV = 'MV',
    ML = 'ML',
    MT = 'MT',
    MA = 'MA',
    MU = 'MU',
    MR = 'MR',
    MX = 'MX',
    MM = 'MM',
    FM = 'FM',
    MZ = 'MZ',
    MD = 'MD',
    MC = 'MC',
    MN = 'MN',
    ME = 'ME',
    NA = 'NA',
    NR = 'NR',
    NP = 'NP',
    NI = 'NI',
    NE = 'NE',
    NG = 'NG',
    NO = 'NO',
    NZ = 'NZ',
    OM = 'OM',
    NL = 'NL',
    PW = 'PW',
    PA = 'PA',
    PG = 'PG',
    PK = 'PK',
    PY = 'PY',
    PE = 'PE',
    PL = 'PL',
    PT = 'PT',
    KE = 'KE',
    KG = 'KG',
    KI = 'KI',
    GB = 'GB',
    CF = 'CF',
    CZ = 'CZ',
    CD = 'CD',
    DO = 'DO',
    RO = 'RO',
    RW = 'RW',
    RU = 'RU',
    SB = 'SB',
    SV = 'SV',
    WS = 'WS',
    LC = 'LC',
    KN = 'KN',
    SM = 'SM',
    ST = 'ST',
    VC = 'VC',
    SC = 'SC',
    SN = 'SN',
    SL = 'SL',
    RS = 'RS',
    SG = 'SG',
    SY = 'SY',
    SO = 'SO',
    LK = 'LK',
    SD = 'SD',
    SS = 'SS',
    SE = 'SE',
    CH = 'CH',
    SR = 'SR',
    TH = 'TH',
    TW = 'TW',
    TJ = 'TJ',
    TZ = 'TZ',
    TL = 'TL',
    TG = 'TG',
    TO = 'TO',
    TT = 'TT',
    TN = 'TN',
    TM = 'TM',
    TR = 'TR',
    TV = 'TV',
    UA = 'UA',
    UG = 'UG',
    UY = 'UY',
    UZ = 'UZ',
    VU = 'VU',
    VA = 'VA',
    VE = 'VE',
    VN = 'VN',
    ZM = 'ZM',
    ZW = 'ZW',
}
