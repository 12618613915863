/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Aveiro` - Aveiro
 * * `Beja` - Beja
 * * `Braga` - Braga
 * * `Bragança` - Braganca
 * * `Castelo Branco` - Castelo Branco
 * * `Coimbra` - Coimbra
 * * `Évora` - Evora
 * * `Faro` - Faro
 * * `Guarda` - Guarda
 * * `Leiria` - Leiria
 * * `Lisboa` - Lisboa
 * * `Portalegre` - Portalegre
 * * `Porto` - Porto
 * * `Santarém` - Santarem
 * * `Setúbal` - Setubal
 * * `Viana do Castelo` - Viana Do Castelo
 * * `Vila Real` - Vila Real
 * * `Viseu` - Viseu
 * * `Madeira` - Madeira
 * * `Açores` - Acores
 */
export enum DistritoEnum {
    AVEIRO = 'Aveiro',
    BEJA = 'Beja',
    BRAGA = 'Braga',
    BRAGAN_A = 'Bragança',
    CASTELO_BRANCO = 'Castelo Branco',
    COIMBRA = 'Coimbra',
    _VORA = 'Évora',
    FARO = 'Faro',
    GUARDA = 'Guarda',
    LEIRIA = 'Leiria',
    LISBOA = 'Lisboa',
    PORTALEGRE = 'Portalegre',
    PORTO = 'Porto',
    SANTAR_M = 'Santarém',
    SET_BAL = 'Setúbal',
    VIANA_DO_CASTELO = 'Viana do Castelo',
    VILA_REAL = 'Vila Real',
    VISEU = 'Viseu',
    MADEIRA = 'Madeira',
    A_ORES = 'Açores',
}
