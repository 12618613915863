import React, { useContext, useState } from 'react';
import { GestaoMenuItem } from '../Layout';
import { Campanha } from '../../slidein_api';

type MenuContextCenas = { menuItems; setMenuItems; menuCampanha; setMenuCampanha };
export const MenuContext = React.createContext<Partial<MenuContextCenas>>({});

export function MenuProvider(props: { children: React.ReactNode }) {
  const [menuItems, setMenuItems] = useState<GestaoMenuItem[]>([]);
  const [menuCampanha, setMenuCampanha] = useState<Campanha | undefined>(undefined);

  const value = {
    menuCampanha,
    setMenuCampanha,
    menuItems,
    setMenuItems,
  };

  return <MenuContext.Provider value={value}>{props.children}</MenuContext.Provider>;
}

export const useSlideInMenu = () => useContext(MenuContext);
