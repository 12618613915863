import {
  Grupo,
  GrupoService,
  OpenAPI,
  TransporteLocalPartidaItem,
  TransporteService,
  ViagemService,
  ViagemTransporte,
} from '../../../slidein_api';
import { msgAPIError } from '../../helpers';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Group, Modal, Switch, Text, Title } from '@mantine/core';
import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import {
  IconCheck,
  IconDeviceFloppy,
  IconDownload,
  IconFileSpreadsheet,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import {
  CheckPermission,
  MASTER_ADMIN_FINANCE,
  MASTER_FINANCE,
} from '../../../components/CheckPermission';
import axios from 'axios';
import FileSaver from 'file-saver';

interface TransportesGrupoProps {
  grupo: Grupo;
  refreshGrupo;
}

export function TransportesGrupo({ grupo, refreshGrupo }: TransportesGrupoProps) {
  const [viagens, setViagens] = useState<ViagemTransporte[]>([]);
  const [partidas, setPartidas] = useState<TransporteLocalPartidaItem[]>([]);
  const [transDialogVisivel, setTrasnDialogVisible] = useState<boolean>(false);

  const fetchData = () => {
    if (!grupo) return;
    ViagemService.viagemTransporteList({ grupo: grupo.codigo }).then(
      (value) => {
        setViagens(value);
      },
      (reason) => {
        msgAPIError(reason);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, [grupo]);

  const columnsViajantes = useMemo<MRT_ColumnDef<ViagemTransporte>[]>(
    () => [
      {
        accessorFn: (or) => or.cliente.nome_simples,
        header: 'Cliente',
        filterVariant: 'text',
        filterFn: 'contains',
      },
      {
        accessorFn: (or) =>
          or.transporte_nome ? (
            `${or.transporte_nome} - ${or.transporte_localidade} - ${
              or.transporte_local_partida
            } - ${dayjs(or.transporte_hora_partida).locale('pt').format('YYYY-MM-DD HH:mm')}`
          ) : (
            <Text fs={'italic'}>Sem Transporte Atribuido</Text>
          ),
        header: 'Transporte',
        filterVariant: 'text',
        filterFn: 'contains',
      },
    ],
    []
  );
  const tableViajantes = useMantineReactTable<ViagemTransporte>({
    columns: columnsViajantes,
    data: viagens,
    enableMultiRowSelection: true,
    enableRowSelection: true,
    enableSelectAll: true,
    selectAllMode: 'all',
    getRowId: (originalRow) => originalRow.id.toString(),
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFilters: true,
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: 'pointer',
      },
      onClick: row.getToggleSelectedHandler(),
    }),
    enableFullScreenToggle: false,
    enableHiding: false,
    enablePagination: true,
    localization: MRT_Localization_PT,
    initialState: {
      density: 'xs',
    },

    renderTopToolbarCustomActions: () => (
      <Group>
        <CheckPermission roles={MASTER_FINANCE}>
          <Button
            disabled={tableViajantes.getSelectedRowModel().rows.length <= 0}
            onClick={() => {
              openTransDialog();
            }}>
            Atribuir Transporte aos Selecionados
          </Button>
          <Switch
            label={'Mostar aos Clientes'}
            size={'sm'}
            onLabel={<IconCheck />}
            offLabel={<IconX />}
            checked={grupo?.mostrar_transporte}
            onChange={(event) => {
              GrupoService.grupoPartialUpdate({
                codigo: grupo.codigo,
                requestBody: {
                  mostrar_transporte: event.currentTarget.checked,
                },
              }).then(
                () => {
                  refreshGrupo();
                },
                (reason) => {
                  msgAPIError(reason);
                }
              );
            }}></Switch>
        </CheckPermission>
        <CheckPermission
          roles={MASTER_ADMIN_FINANCE}
          goHome={false}>
          <Button
            color='lightblue'
            onClick={() => {
              const url = `${OpenAPI.BASE}/gestao/grupo/simples/${grupo.codigo}/mapa_transportes`;
              axios
                .get(url, {
                  responseType: 'blob',
                  params: { codigo: grupo.codigo },
                })
                .then(
                  (res) => {
                    const blob = new Blob([res.data], {
                      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
                    });
                    FileSaver.saveAs(blob, `transporte_${grupo.codigo}.xlsx`);
                    return res;
                  },
                  (reason) => {
                    msgAPIError(reason);
                  }
                );
            }}
            rightIcon={<IconFileSpreadsheet />}
            leftIcon={<IconDownload />}
            variant='outline'>
            .xlsx
          </Button>
        </CheckPermission>
      </Group>
    ),
  });

  function livres(cell) {
    return cell.row.original.transporte_ro.nr_livres &&
      cell.row.original.transporte_ro.nr_livres > 0 &&
      cell.row.original.transporte_ro.nr_livres >=
        tableViajantes.getSelectedRowModel().rows.length ? (
      <Text>
        {cell.row.original.transporte_ro.nr_livres}/{cell.row.original.transporte_ro.vagas}
      </Text>
    ) : (
      <Text
        color={'red'}
        fw={'bold'}>
        {cell.row.original.transporte_ro.nr_livres}/{cell.row.original.transporte_ro.vagas}
      </Text>
    );
  }

  const columnsTrans = useMemo<MRT_ColumnDef<TransporteLocalPartidaItem>[]>(
    () => [
      {
        accessorKey: 'localidade',
        header: 'Localidade',
      },
      { accessorKey: 'local_partida', header: 'Local de Partida' },
      {
        accessorKey: 'hora_partida',
        header: 'Data/hora de Partida',
        Cell: ({ row }) => {
          return dayjs(row.original.hora_partida).locale('pt').format('YYYY-MM-DD HH:mm');
        },
      },
      {
        accessorKey: 'transporte_ro.nome',
        header: 'Transporte',
      },

      {
        accessorKey: 'transporte_ro.vagas',
        header: 'Vagas (Livres/Total)',
        Cell: livres,
      },
    ],

    []
  );

  function check_livres(row) {
    return (
      row.original.transporte_ro.nr_livres > 0 &&
      row.original.transporte_ro.nr_livres >= tableViajantes.getSelectedRowModel().rows.length
    );
  }

  const tableTransportes = useMantineReactTable<TransporteLocalPartidaItem>({
    columns: columnsTrans,
    data: partidas,
    enableRowSelection: check_livres,
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        opacity: check_livres(row) ? '100%' : '35%',
        cursor: 'pointer',
      },
      onClick: row.getToggleSelectedHandler(),
    }),
    enableMultiRowSelection: false,
    positionToolbarAlertBanner: 'none',
    enableGlobalFilter: true,
    enableDensityToggle: false,
    // enableFilters: false,
    enableFullScreenToggle: false,

    enableHiding: false,
    enablePagination: true,
    localization: MRT_Localization_PT,
    positionGlobalFilter: 'left',
    globalFilterFn: 'contains',
    initialState: {
      density: 'xs',
      showGlobalFilter: true,
    },
  });

  const openTransDialog = () => {
    TransporteService.transporteList({ campanha: grupo.campanha }).then(
      (value) => {
        const locs = [];
        value.forEach((trans) => {
          locs.push(...trans.locais_partida);
        });

        setPartidas(locs);
        setTrasnDialogVisible(true);
      },
      (reason) => msgAPIError(reason)
    );
  };
  const removerTransporte = () => {
    tableViajantes.getSelectedRowModel().rows.forEach((via) => {
      ViagemService.viagemTransportePartialUpdate({
        id: via.original.id,
        requestBody: { transporte: null },
      }).then(
        () => fetchData(),
        (reason) => msgAPIError(reason)
      );
    });
  };
  const adicionarTransporte = () => {
    tableViajantes.getSelectedRowModel().rows.forEach((via) => {
      ViagemService.viagemTransportePartialUpdate({
        id: via.original.id,
        requestBody: { transporte: tableTransportes.getSelectedRowModel().rows[0].original.id },
      }).then(
        () => fetchData(),
        (reason) => msgAPIError(reason)
      );
    });
  };

  return (
    <>
      <MantineReactTable table={tableViajantes} />
      <Modal
        centered
        title={'Escolher Transporte:'}
        opened={transDialogVisivel}
        size={'auto'}
        onClose={() => {
          setTrasnDialogVisible(false);
        }}>
        <Title order={4}>
          {tableViajantes.getSelectedRowModel().rows.length} Viajantes Selecionados.
        </Title>

        {partidas && <MantineReactTable table={tableTransportes} />}
        <Group
          position='right'
          mt='md'>
          <Button
            color={'red'}
            variant={'outline'}
            leftIcon={<IconTrash />}
            type='button'
            onClick={() => {
              removerTransporte();
              setTrasnDialogVisible(false);
            }}>
            Remover Atribuição
          </Button>
          <Button
            color={'yellow'}
            variant={'outline'}
            leftIcon={<IconX />}
            type='button'
            onClick={() => {
              setTrasnDialogVisible(false);
            }}>
            Cancelar
          </Button>
          <Button
            variant={'outline'}
            leftIcon={<IconDeviceFloppy />}
            onClick={() => {
              adicionarTransporte();
              setTrasnDialogVisible(false);
            }}
            type='button'>
            Atribuir Transporte
          </Button>
        </Group>
      </Modal>
    </>
  );
}
