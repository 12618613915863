import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ApiError, Instituicao, InstituicaoService } from '../../slidein_api';
import { useNavigate } from 'react-router-dom';
import { MenuContext } from '../../layout/context/MenuContext';
import { homeMenu } from '../Home';
import { useBread } from '../../layout/context/BreadContext';
import { ActionIcon, Box, Button, Container, Flex, Text, Title, Tooltip } from '@mantine/core';
import {
  IconCsv,
  IconDownload,
  IconEdit,
  IconPlus,
  IconSchool,
  IconTrash,
  IconTrashX,
  IconX,
} from '@tabler/icons-react';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_GlobalFilterTextInput,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
} from 'mantine-react-table';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import { notifications } from '@mantine/notifications';
import { msgAPIError } from '../helpers';
import { CSVLink } from 'react-csv';
import { modals } from '@mantine/modals';

export function InstituicaoList() {
  const { setMenuItems } = useContext(MenuContext);
  const { setBread } = useBread();
  useEffect(() => {
    setMenuItems(homeMenu);
    setBread({ accao: { title: 'Instituições', icon: <IconSchool /> } });
  }, []);
  const navigate = useNavigate();

  const [instituicoes, setInstituicoes] = useState<Instituicao[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    InstituicaoService.instituicaoList({}).then(
      (value) => {
        setInstituicoes(value);
        setIsLoading(false);
      },
      (reason) => {
        setIsLoading(false);
        msgAPIError(reason);
      }
    );
  }, []);

  const columns = useMemo<MRT_ColumnDef<Instituicao>[]>(
    () => [
      {
        accessorKey: 'nome',
        header: 'Nome',
      },
      {
        accessorKey: 'sigla',
        header: 'Sigla',
      },
      {
        accessorKey: 'morada',
        header: 'Morada',
      },
      {
        accessorKey: 'distrito',
        header: 'Distrito',
      },
      {
        accessorKey: 'concelho',
        header: 'Concelho',
      },
      {
        accessorKey: 'notas',
        header: 'Notas',
      },
    ],

    []
  );

  return (
    <Container
      p={'xs'}
      fluid>
      <Title order={2}>Instituições (Escolas, Empresas, Clubes, ...):</Title>

      <MantineReactTable
        data={instituicoes}
        columns={columns}
        enableColumnOrdering
        enableRowActions
        positionActionsColumn='first'
        positionGlobalFilter={'none'}
        renderRowActions={({ row }) => (
          <Box sx={{ display: 'flex', gap: '16px' }}>
            <Tooltip
              withArrow
              position='left'
              label='Edit'>
              <ActionIcon onClick={() => navigate(`editar/${row.original.id}`)}>
                <IconEdit />
              </ActionIcon>
            </Tooltip>

            <Tooltip
              withArrow
              position='right'
              label='Delete'>
              <ActionIcon
                color='red'
                onClick={() =>
                  modals.openConfirmModal({
                    title: 'Remover Instituição',
                    centered: true,
                    children: (
                      <Text size='sm'>
                        Confirma que deseja remover a Instituição : {row.original.nome} ?
                      </Text>
                    ),
                    labels: { confirm: 'Remover', cancel: 'Cancelar' },
                    confirmProps: { color: 'red', variant: 'outline', leftIcon: <IconTrash /> },
                    cancelProps: { color: 'yellow', variant: 'outline', leftIcon: <IconX /> },
                    onConfirm: () => {
                      InstituicaoService.instituicaoDestroy({ id: row.original.id }).then(
                        () => {
                          setInstituicoes(instituicoes.filter((obj) => obj.id !== row.original.id));
                          notifications.show({
                            title: 'Instituição removida!',
                            message: `A instituição ${row.original.nome} foi removida.`,
                            withCloseButton: true,
                            icon: <IconTrashX />,
                            color: 'green',
                          });
                        },
                        (reason: ApiError) => {
                          if (reason.status == 423) {
                            notifications.show({
                              title: 'Instituição!',
                              message: `A instituição ${row.original.nome} NÃO foi removida, existem grupos associados!`,
                              withCloseButton: true,
                              icon: <IconX />,
                              color: 'yellow',
                              autoClose: 5000,
                            });
                          } else {
                            msgAPIError(reason);
                          }
                        }
                      );
                    },
                  })
                }>
                <IconTrash />
              </ActionIcon>
            </Tooltip>
          </Box>
        )}
        renderToolbarInternalActions={({ table }) => (
          <Flex
            direction={'row'}
            wrap={'wrap'}
            gap={'sm'}
            justify={'space-evenly'}>
            <MRT_GlobalFilterTextInput table={table} />
            <Button
              leftIcon={<IconPlus />}
              onClick={() => navigate('/instituicao/criar')}
              variant='outline'>
              Nova Instituição
            </Button>
            <CSVLink
              data={table.getPrePaginationRowModel().rows.map((r) => r.original)}
              filename={'instituicoes.csv'}
              headers={columns.map((c) => {
                return { label: c.header, key: c.id };
              })}>
              <Button
                leftIcon={<IconCsv />}
                rightIcon={<IconDownload />}
                variant='outline'
              />
            </CSVLink>

            <MRT_ShowHideColumnsButton table={table} />
            <MRT_ToggleFiltersButton table={table} />
          </Flex>
        )}
        initialState={{
          density: 'xs',
          showGlobalFilter: true,
          pagination: { pageSize: 15, pageIndex: 0 },
        }}
        state={{
          isLoading,
        }}
        mantineTableProps={{
          highlightOnHover: true,
          withColumnBorders: true,
        }}
        enableFullScreenToggle={false}
        localization={MRT_Localization_PT}
        globalFilterFn='contains'
      />
    </Container>
  );
}
