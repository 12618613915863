import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { Actividade, Grupo, GrupoService, ProdutoService } from '../../../slidein_api';
import { msgAPIError } from '../../helpers';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import { Button, Group, Modal, Stack, Title, Tooltip } from '@mantine/core';
import {
  IconCircleCheck,
  IconCircleX,
  IconDeviceFloppy,
  IconPlus,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import { useAuth } from '../../../user-auth/SlideInAuthContext';
import {
  CheckPermission,
  checkPermission,
  MASTER_ADMIN,
  MASTER_ADMIN_FINANCE,
} from '../../../components/CheckPermission';
import { useParams } from 'react-router-dom';

type GrupoActividadesProps = {
  grupo: Grupo;
  refreshGrupo;
};

function getRows(table) {
  if (table?.getSelectedRowModel() && table.getSelectedRowModel().rows) {
    return table.getSelectedRowModel().rows;
  } else {
    return [];
  }
}

export default function GrupoActividades({ grupo, refreshGrupo }: GrupoActividadesProps) {
  const { colaborador } = useAuth();
  const { campanhaID } = useParams();
  const [actividades, setActividades] = useState<Actividade[]>([]);
  const [addActividadesModalVisible, setAddActividadesModalVisible] = useState<boolean>(false);

  const fetchData = () => {
    if (checkPermission(MASTER_ADMIN_FINANCE, colaborador)) {
      ProdutoService.produtoActividadeList({ campanha: campanhaID }).then(
        (actividadesall) => {
          setActividades(actividadesall);
        },
        (reason) => msgAPIError(reason)
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = useMemo<MRT_ColumnDef<Actividade>[]>(
    () => [
      { accessorKey: 'nome_interno', header: 'Nome Interno' },
      { accessorKey: 'titulo_cliente', header: 'Nome Cliente' },
      {
        accessorKey: 'activo',
        header: 'Ativo',
        Header: (
          <Tooltip label={'Activo, disponivel para compra'}>
            <div>Act.</div>
          </Tooltip>
        ),
        size: 50,
        Cell: (cell) => {
          return cell.cell.getValue() ? (
            <IconCircleCheck color='green' />
          ) : (
            <IconCircleX color='red' />
          );
        },
      },
      {
        accessorKey: 'valor',
        header: 'Valor',
        size: 100,
        Cell: (cell) => `${cell.cell.getValue()}€`,
      },
    ],
    []
  );
  const removeActividades = () => {
    const all = grupo.actividades.map((gp) => gp.id);
    const remove = getRows(actividadesTable).map((p) => p.id);
    GrupoService.grupoProdutosPartialUpdate({
      codigo: grupo.codigo,
      requestBody: {
        actividades: all.filter((a) => remove.findIndex((r) => r == a) == -1),
      },
    }).then(
      () => {
        fetchData();
        refreshGrupo();
        setAddActividadesModalVisible(false);
      },
      (reason) => msgAPIError(reason)
    );
  };
  const adicionarActividades = () => {
    const actividadesId = grupo.actividades.map((gp) => gp.id);
    actividadesId.push(...getRows(allActividadesTable).map((p) => p.id));

    GrupoService.grupoProdutosPartialUpdate({
      codigo: grupo.codigo,
      requestBody: {
        actividades: actividadesId,
      },
    }).then(
      () => {
        fetchData();
        refreshGrupo();
        setAddActividadesModalVisible(false);
      },
      (reason) => msgAPIError(reason)
    );
  };
  const actividadesTable = useMantineReactTable({
    columns,
    data: grupo?.actividades || [],
    enableMultiRowSelection: checkPermission(MASTER_ADMIN, colaborador),
    enableRowSelection: checkPermission(MASTER_ADMIN, colaborador),
    enableSelectAll: checkPermission(MASTER_ADMIN, colaborador),
    selectAllMode: 'all',
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: 'pointer',
      },
      onClick: row.getToggleSelectedHandler(),
    }),
    getRowId: (originalRow) => originalRow.id.toString(),
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFilters: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enablePagination: false,
    positionToolbarAlertBanner: 'none',
    localization: MRT_Localization_PT,
    initialState: {
      density: 'xs',
    },
    renderTopToolbarCustomActions: () => (
      <Group>
        <CheckPermission
          roles={MASTER_ADMIN}
          goHome={false}>
          <Button
            leftIcon={<IconTrash />}
            variant={'outline'}
            color={'red'}
            disabled={getRows(actividadesTable).length <= 0}
            onClick={() => {
              removeActividades();
            }}>
            Remover
          </Button>
          <Button
            leftIcon={<IconPlus />}
            variant={'outline'}
            onClick={() => {
              setAddActividadesModalVisible(true);
            }}>
            Adicionar
          </Button>
        </CheckPermission>
      </Group>
    ),
  });

  const allActividadesTable = useMantineReactTable<Actividade>({
    columns,
    data: actividades || [],
    enableSelectAll: true,
    selectAllMode: 'all',
    getRowId: (originalRow) => originalRow.id.toString(),
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    positionToolbarAlertBanner: 'none',
    enableHiding: false,
    enablePagination: false,
    localization: MRT_Localization_PT,
    initialState: {
      density: 'xs',
    },
    enableRowSelection: (row) =>
      grupo.actividades.findIndex((gp) => gp.id == row.original.id) == -1,
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        opacity:
          grupo.actividades.findIndex((gp) => gp.id == row.original.id) == -1 ? '100%' : '35%',
        cursor: 'pointer',
      },
      onClick: row.getToggleSelectedHandler(),
    }),
  });

  return (
    <Stack>
      <MantineReactTable table={actividadesTable} />
      <Modal
        centered
        title={'Escolher Actividades/Opcionais:'}
        opened={addActividadesModalVisible}
        size={'auto'}
        onClose={() => {
          setAddActividadesModalVisible(false);
        }}>
        <Title order={4}>
          {getRows(allActividadesTable).length}&nbsp;Actividades/Opcionais Selecionados.
        </Title>
        <MantineReactTable table={allActividadesTable} />
        <Group
          position='right'
          mt='md'>
          <Button
            color={'yellow'}
            variant={'outline'}
            leftIcon={<IconX />}
            type='button'
            onClick={() => {
              setAddActividadesModalVisible(false);
            }}>
            Cancelar
          </Button>
          <Button
            variant={'outline'}
            disabled={getRows(allActividadesTable).length == 0}
            onClick={() => {
              adicionarActividades();
              setAddActividadesModalVisible(false);
            }}
            leftIcon={<IconDeviceFloppy />}
            type='button'>
            Adicionar
          </Button>
        </Group>
      </Modal>
    </Stack>
  );
}
