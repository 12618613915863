import React, { useContext, useEffect, useMemo, useState } from 'react';

import { MenuContext } from '../layout/context/MenuContext';
import { homeMenu } from './Home';
import { useBread } from '../layout/context/BreadContext';
import {
  ApiError,
  DocsService,
  DocumentosCondicoesGeraisViagem,
  DocumentosPrivacidade,
  OutrosDocumentos,
  SlideinRoleEnum,
} from '../slidein_api';
import { msgAPIError } from './helpers';
import {
  ActionIcon,
  Box,
  Button,
  FileInput,
  Flex,
  Group,
  Modal,
  Stack,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import { IconEdit, IconPdf, IconPlus, IconTrashX, IconUpload } from '@tabler/icons-react';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_GlobalFilterTextInput,
  MRT_ShowHideColumnsButton,
} from 'mantine-react-table';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import { notifications } from '@mantine/notifications';
import { checkPermission, CheckPermission, MASTER } from '../components/CheckPermission';
import { useAuth } from '../user-auth/SlideInAuthContext';

export function DocumentacaoGeral() {
  const { setMenuItems } = useContext(MenuContext);
  const { colaborador } = useAuth();
  const { setBread } = useBread();
  useEffect(() => {
    setMenuItems(homeMenu);
    setBread({ accao: { title: 'Documentos Gerais', icon: <IconPdf /> } });
  }, []);
  const [privacidade, setPrivacidade] = useState<DocumentosPrivacidade[]>([]);
  // const [condicoes, setCondicoes] = useState<DocumentosCondicoesGeraisViagem[]>([]);
  const [outros, setOutros] = useState<OutrosDocumentos[]>([]);
  const [addDiaP, setAddDiap] = useState<boolean>(false);
  const [addDiac, setAddDiac] = useState<boolean>(false);
  const [addDiao, setAddDiao] = useState<boolean>(false);
  const [tempTitle, setTempTitle] = useState<string>('');
  const [tempFile, setTempFile] = useState<File | undefined>();
  const [updateFiles, setUpdateFiles] = useState<boolean>(false);

  useEffect(() => {
    DocsService.docsPrivacidadeList().then(
      (value) => setPrivacidade(value),
      (reason: ApiError) => {
        msgAPIError(reason);
      }
    );
    // DocsService.docsCondgeraisList().then(
    //   (value) => setCondicoes(value),
    //   (reason: ApiError) => {
    //     msgAPIError(reason);
    //   }
    // );
    DocsService.docsOutrosList().then(
      (value) => {
        setOutros(value);
      },
      (reason: ApiError) => {
        msgAPIError(reason);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateFiles]);

  const cleanTemp = () => {
    setTempFile(undefined);
    setTempTitle('');
    setAddDiap(false);
    setAddDiac(false);
    setAddDiao(false);
  };

  const addp = (
    <Modal
      onClose={() => {
        cleanTemp();
      }}
      withCloseButton={false}
      opened={addDiaP || addDiac || addDiao}>
      <Title order={2}>Adicionar Documento</Title>
      <TextInput
        label={'Nome Documento'}
        value={tempTitle}
        onChange={(event) => setTempTitle(event.target.value)}
      />
      <FileInput
        value={tempFile}
        onChange={(payload) => setTempFile(payload)}
        label={'Ficheiro'}
        multiple={false}
        icon={<IconUpload />}
      />
      <Group
        position='right'
        variant={'outline'}
        mt='md'>
        <Button
          disabled={!(tempFile && tempTitle)}
          type='button'
          onClick={() => {
            if (addDiaP) {
              DocsService.docsPrivacidadeCreate({
                formData: {
                  titulo: tempTitle,
                  file: tempFile,
                },
              }).then(
                () => {
                  setUpdateFiles(!updateFiles);
                  cleanTemp();
                },
                (reason: ApiError) => {
                  msgAPIError(reason);
                }
              );
            } else if (addDiac) {
              DocsService.docsCondgeraisCreate({
                formData: {
                  titulo: tempTitle,
                  file: tempFile,
                },
              }).then(
                () => {
                  setUpdateFiles(!updateFiles);
                  cleanTemp();
                },
                (reason: ApiError) => {
                  msgAPIError(reason);
                }
              );
            } else if (addDiao) {
              DocsService.docsOutrosCreate({
                formData: {
                  titulo: tempTitle,
                  file: tempFile,
                },
              }).then(
                () => {
                  setUpdateFiles(!updateFiles);
                  cleanTemp();
                },
                (reason: ApiError) => {
                  msgAPIError(reason);
                }
              );
            }
          }}>
          Guardar Documento
        </Button>
        <Button
          variant={'outline'}
          type='button'
          onClick={() => {
            cleanTemp();
          }}>
          Cancelar
        </Button>
      </Group>
    </Modal>
  );

  const columns = useMemo<MRT_ColumnDef<DocumentosPrivacidade | DocumentosCondicoesGeraisViagem>[]>(
    () => [
      {
        accessorKey: 'titulo',
        header: 'Nome Documento',
      },
      {
        accessorKey: 'file',
        header: 'Ficheiro',
        enableEditing: false,
        Cell: (cell) => (
          <a
            href={cell.row.original.file}
            target='_blank'
            rel='noreferrer'
            download>
            {cell.row.original.titulo}
          </a>
        ),
      },
    ],
    []
  );

  return (
    <Stack>
      {addp}
      <Box>
        <Title order={3}>Documentos Privacidade/GDPR</Title>
        <MantineReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              mantineTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
          positionGlobalFilter={'none'}
          renderToolbarInternalActions={({ table }) => (
            <Flex
              direction={'row'}
              wrap={'wrap'}
              gap={'sm'}
              justify={'space-evenly'}>
              <MRT_GlobalFilterTextInput table={table} />
              <CheckPermission
                roles={[SlideinRoleEnum.MASTER]}
                goHome={false}>
                <Button
                  leftIcon={<IconPlus />}
                  variant='outline'
                  size='md'
                  onClick={() => {
                    setAddDiap(true);
                  }}>
                  Adicionar
                </Button>
              </CheckPermission>
              <MRT_ShowHideColumnsButton table={table} />
            </Flex>
          )}
          initialState={{
            density: 'xs',
            showGlobalFilter: true,
            pagination: { pageSize: 15, pageIndex: 0 },
          }}
          enableRowActions={checkPermission(MASTER, colaborador)}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '16px' }}>
              <Tooltip label='Editar'>
                <ActionIcon
                  color='blue'
                  onClick={() => table.setEditingRow(row)}>
                  <IconEdit />
                </ActionIcon>
              </Tooltip>
              <Tooltip label='Remover'>
                <ActionIcon
                  color='red'
                  onClick={() => {
                    if (confirm('Confirma que deseja remover o documento?')) {
                      DocsService.docsPrivacidadeDestroy({ id: row.original.id }).then(
                        () => {
                          setPrivacidade(privacidade.filter((obj) => obj.id !== row.original.id));
                          notifications.show({
                            title: 'Documento removido!',
                            message: `O documento ${row.original.titulo}  foi removido.`,
                            withCloseButton: true,
                            icon: <IconTrashX />,
                            color: 'red',
                          });
                        },
                        (reason) => {
                          msgAPIError(reason);
                        }
                      );
                    }
                  }}>
                  <IconTrashX />
                </ActionIcon>
              </Tooltip>
            </Box>
          )}
          columns={columns}
          data={privacidade}
          positionActionsColumn='first'
          enableFullScreenToggle={false}
          localization={MRT_Localization_PT}
          enableEditing={checkPermission(MASTER, colaborador)}
          enablePagination={false}
          enableFilters={false}
          enableHiding={false}
          enableBottomToolbar={false}
          editDisplayMode={'modal'}
          onEditingRowSave={({ exitEditingMode, row, values }) => {
            DocsService.docsPrivacidadePartialUpdate({
              id: row.original.id,
              formData: { titulo: values.titulo },
            }).then(
              () => {
                privacidade[row.index] = values;
                setPrivacidade([...privacidade]);
              },
              (reason: ApiError) => {
                msgAPIError(reason);
              }
            );
            exitEditingMode();
          }}
        />
      </Box>
      {/*<Box>*/}
      {/*  <Title order={3}>Documentos Condições Gerais Viagem/Venda</Title>*/}
      {/*  <MantineReactTable*/}
      {/*    displayColumnDefOptions={{*/}
      {/*      'mrt-row-actions': {*/}
      {/*        mantineTableHeadCellProps: {*/}
      {/*          align: 'center',*/}
      {/*        },*/}
      {/*        size: 120,*/}
      {/*      },*/}
      {/*    }}*/}
      {/*    positionGlobalFilter={'none'}*/}
      {/*    renderToolbarInternalActions={({ table }) => (*/}
      {/*      <Flex*/}
      {/*        direction={'row'}*/}
      {/*        wrap={'wrap'}*/}
      {/*        gap={'sm'}*/}
      {/*        justify={'space-evenly'}>*/}
      {/*        <MRT_GlobalFilterTextInput table={table} />*/}
      {/*        <CheckPermission*/}
      {/*          roles={[SlideinRoleEnum.MASTER]}*/}
      {/*          goHome={false}>*/}
      {/*          <Button*/}
      {/*            leftIcon={<IconPlus />}*/}
      {/*            variant='outline'*/}
      {/*            size='md'*/}
      {/*            onClick={() => {*/}
      {/*              setAddDiac(true);*/}
      {/*            }}>*/}
      {/*            Adicionar*/}
      {/*          </Button>*/}
      {/*        </CheckPermission>*/}
      {/*        <MRT_ShowHideColumnsButton table={table} />*/}
      {/*      </Flex>*/}
      {/*    )}*/}
      {/*    initialState={{*/}
      {/*      density: 'xs',*/}
      {/*      showGlobalFilter: true,*/}
      {/*      pagination: { pageSize: 15, pageIndex: 0 },*/}
      {/*    }}*/}
      {/*    enableRowActions={checkPermission(MASTER, colaborador)}*/}
      {/*    renderRowActions={({ row, table }) => (*/}
      {/*      <Box sx={{ display: 'flex', gap: '16px' }}>*/}
      {/*        <Tooltip label='Editar'>*/}
      {/*          <ActionIcon*/}
      {/*            color='blue'*/}
      {/*            onClick={() => table.setEditingRow(row)}>*/}
      {/*            <IconEdit />*/}
      {/*          </ActionIcon>*/}
      {/*        </Tooltip>*/}
      {/*        <Tooltip label='Remover'>*/}
      {/*          <ActionIcon*/}
      {/*            color='red'*/}
      {/*            onClick={() => {*/}
      {/*              if (confirm('Confirma que deseja remover o documento ?')) {*/}
      {/*                DocsService.docsCondgeraisDestroy({ id: row.original.id }).then(*/}
      {/*                  () => {*/}
      {/*                    setCondicoes(condicoes.filter((obj) => obj.id !== row.original.id));*/}
      {/*                    notifications.show({*/}
      {/*                      title: 'Documento removido!',*/}
      {/*                      message: `O documento ${row.original.titulo}  foi removido.`,*/}
      {/*                      withCloseButton: true,*/}
      {/*                      icon: <IconTrashX />,*/}
      {/*                      color: 'red',*/}
      {/*                    });*/}
      {/*                  },*/}
      {/*                  (reason) => {*/}
      {/*                    msgAPIError(reason);*/}
      {/*                  }*/}
      {/*                );*/}
      {/*              }*/}
      {/*            }}>*/}
      {/*            <IconTrashX />*/}
      {/*          </ActionIcon>*/}
      {/*        </Tooltip>*/}
      {/*      </Box>*/}
      {/*    )}*/}
      {/*    columns={columns}*/}
      {/*    data={condicoes}*/}
      {/*    positionActionsColumn='first'*/}
      {/*    enableFullScreenToggle={false}*/}
      {/*    localization={MRT_Localization_PT}*/}
      {/*    enableEditing={checkPermission(MASTER, colaborador)}*/}
      {/*    editDisplayMode={'modal'}*/}
      {/*    enablePagination={false}*/}
      {/*    enableFilters={false}*/}
      {/*    enableHiding={false}*/}
      {/*    enableBottomToolbar={false}*/}
      {/*    onEditingRowSave={({ exitEditingMode, row, values }) => {*/}
      {/*      DocsService.docsCondgeraisPartialUpdate({*/}
      {/*        id: row.original.id,*/}
      {/*        formData: { titulo: values.titulo },*/}
      {/*      }).then(*/}
      {/*        () => {*/}
      {/*          condicoes[row.index] = values;*/}
      {/*          setCondicoes([...condicoes]);*/}
      {/*        },*/}
      {/*        (reason: ApiError) => {*/}
      {/*          msgAPIError(reason);*/}
      {/*        }*/}
      {/*      );*/}
      {/*      exitEditingMode();*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</Box>*/}
      <Box>
        <Title order={3}>Outros Documentos</Title>
        <MantineReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              mantineTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
          positionGlobalFilter={'none'}
          renderToolbarInternalActions={({ table }) => (
            <Flex
              direction={'row'}
              wrap={'wrap'}
              gap={'sm'}
              justify={'space-evenly'}>
              <MRT_GlobalFilterTextInput table={table} />
              <CheckPermission
                roles={[SlideinRoleEnum.MASTER]}
                goHome={false}>
                <Button
                  leftIcon={<IconPlus />}
                  variant='outline'
                  size='md'
                  onClick={() => {
                    setAddDiao(true);
                  }}>
                  Adicionar
                </Button>
                {addp}
              </CheckPermission>
              <MRT_ShowHideColumnsButton table={table} />
            </Flex>
          )}
          initialState={{
            density: 'xs',
            showGlobalFilter: true,
            pagination: { pageSize: 15, pageIndex: 0 },
          }}
          enableRowActions={checkPermission(MASTER, colaborador)}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '16px' }}>
              <Tooltip label='Editar'>
                <ActionIcon
                  color='blue'
                  onClick={() => table.setEditingRow(row)}>
                  <IconEdit />
                </ActionIcon>
              </Tooltip>
              <Tooltip label='Remover'>
                <ActionIcon
                  color='red'
                  onClick={() => {
                    if (confirm('Confirma que deseja remover o documento ?')) {
                      DocsService.docsOutrosDestroy({ id: row.original.id }).then(
                        () => {
                          setOutros(outros.filter((obj) => obj.id !== row.original.id));
                          notifications.show({
                            title: 'Documento removido!',
                            message: `O documento ${row.original.titulo} foi removido.`,
                            withCloseButton: true,
                            icon: <IconTrashX />,
                            color: 'red',
                          });
                        },
                        (reason) => {
                          msgAPIError(reason);
                        }
                      );
                    }
                  }}>
                  <IconTrashX />
                </ActionIcon>
              </Tooltip>
            </Box>
          )}
          columns={columns}
          data={outros}
          positionActionsColumn='first'
          enableFullScreenToggle={false}
          localization={MRT_Localization_PT}
          enableEditing={checkPermission(MASTER, colaborador)}
          editDisplayMode={'modal'}
          enablePagination={false}
          enableFilters={false}
          enableHiding={false}
          enableBottomToolbar={false}
          onEditingRowSave={({ exitEditingMode, row, values }) => {
            DocsService.docsOutrosPartialUpdate({
              id: row.original.id,
              formData: { titulo: values.titulo },
            }).then(
              () => {
                outros[row.index] = values;
                setOutros([...outros]);
              },
              (reason: ApiError) => {
                msgAPIError(reason);
              }
            );
            exitEditingMode();
          }}
        />
      </Box>
    </Stack>
  );
}
