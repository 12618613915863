import React, { useEffect, useMemo, useState } from 'react';
import {
  ApiError,
  CompraEstadoEnum,
  CompraSeguro,
  CompraService,
  EstadoPagamentoEnum,
  OpenAPI,
} from '../../../slidein_api';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_GlobalFilterTextInput,
  MRT_PaginationState,
  MRT_ShowHideColumnsButton,
  MRT_SortingState,
  MRT_ToggleFiltersButton,
  useMantineReactTable,
} from 'mantine-react-table';
import { mrtSortingToAPI, msgAPIError } from '../../helpers';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import { Link, useParams } from 'react-router-dom';
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Group,
  LoadingOverlay,
  Modal,
  Select,
  SelectItem,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import {
  IconAlertTriangle,
  IconCheck,
  IconDeviceFloppy,
  IconDownload,
  IconEdit,
  IconLockBolt,
  IconPlane,
  IconRefresh,
  IconTrash,
  IconTrashX,
  IconUserOff,
  IconX,
} from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { modals } from '@mantine/modals';
import FileSaver from 'file-saver';
import axios from 'axios';
import { useBread } from '../../../layout/context/BreadContext';

export function SegurosTratar() {
  const { campanhaID } = useParams();
  const { setBread } = useBread();
  useEffect(() => {
    setBread({
      campanha: {
        title: campanhaID,
        href: '/campanha/' + campanhaID,
        icon: <IconPlane />,
      },
      accao: {
        title: 'Gestão Seguros',
        icon: <IconLockBolt />,
      },
    });
  }, []);

  const [seguros, setSeguros] = useState<CompraSeguro[]>([]);
  const [editCompra, setEditCompra] = useState<CompraSeguro>(null);
  const [estadopagamentoFilter, setEstadoPagamentoFilter] = useState<string | null>(null);
  const [tratarSeguroModalVisible, setTratarSeguroModalVisible] = useState<boolean>(false);
  const [apoliceEdit, setApoliceEdit] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([
    {
      id: 'estadoPagamento',
      desc: true,
    },
  ]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  });

  const tToslideAPI: Record<string, string> = {
    creationDate: 'creation_date',
    dataSubscricao: 'data_subscricao',
    email: 'viagem__cliente__email',
    estado: 'estado',
    estadoPagamento: 'estado_pagamento',
    grupo: 'viagem__grupo',
    instituicao: 'viagem__grupo__instituicao__nome',
    nomeCompleto: 'viagem__cliente__nome_proprio',
    produtoNomeInterno: 'produto__nome_interno',
    produtoValor: 'produto__valor',
    telemovel: 'viagem__cliente__contacto_telemovel',
  };

  function paramsNormalize(columnFilters: MRT_ColumnFiltersState) {
    const params = {};
    columnFilters.forEach((c) => (params[c.id] = c.value));
    if (params['creationDate']) {
      const da = params['creationDate'][0];
      const db = params['creationDate'][1];

      params['creationDateAfter'] = da
        ? `${da.getFullYear()}-${(da.getMonth() + 1).toString().padStart(2, '0')}-${da
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
      params['creationDateBefore'] = db
        ? `${db.getFullYear()}-${(db.getMonth() + 1).toString().padStart(2, '0')}-${db
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
    }
    if (params['dataSubscricao']) {
      const da = params['dataSubscricao'][0];
      const db = params['dataSubscricao'][1];

      params['dataSubscricaoAfter'] = da
        ? `${da.getFullYear()}-${(da.getMonth() + 1).toString().padStart(2, '0')}-${da
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
      params['dataSubscricaoBefore'] = db
        ? `${db.getFullYear()}-${(db.getMonth() + 1).toString().padStart(2, '0')}-${db
            .getDate()
            .toString()
            .padStart(2, '0')}`
        : null;
    }
    return params;
  }

  const fetchData = (page: number, pageSize: number) => {
    setIsRefetching(true);
    CompraService.compraSeguroList({
      ...paramsNormalize(columnFilters),
      estadoPagamento: estadopagamentoFilter,
      viagemCampanha: campanhaID,
      page: page + 1,
      pageSize: pageSize,
      search: globalFilter ?? '',
      ordering: mrtSortingToAPI(sorting, tToslideAPI),
    }).then(
      (value) => {
        setSeguros(value.results);
        setRowCount(value.count);
        setIsRefetching(false);
      },
      (reason) => {
        msgAPIError(reason);
      }
    );
  };
  useEffect(() => {
    fetchData(pagination.pageIndex, pagination.pageSize);
  }, [pagination, sorting]);

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: 15 });
  }, [columnFilters, globalFilter, estadopagamentoFilter]);

  const trans = (state: EstadoPagamentoEnum | CompraEstadoEnum) => {
    switch (state) {
      case EstadoPagamentoEnum.AGUARDAR_PAGAMENTO:
        return 'gray';
      case EstadoPagamentoEnum.PAGO_E_TRATADO:
        return 'green';
      case EstadoPagamentoEnum.BASE:
        return 'green';
      case EstadoPagamentoEnum.PAGO_N_O_TRATADO:
        return 'yellow';
      case EstadoPagamentoEnum.N_O_PAGOU:
        return 'red';
      case CompraEstadoEnum.CANCELADO:
        return 'red';
      case CompraEstadoEnum.VENDIDO:
        return 'green';
      default:
        return 'blue';
    }
  };

  const columns = useMemo<MRT_ColumnDef<CompraSeguro>[]>(
    () => [
      {
        accessorFn: (row) => (
          <Badge
            color={trans(row.estado)}
            variant='filled'>
            {row.estado}
          </Badge>
        ),
        header: 'Estado',
        id: 'estado',
        filterVariant: 'text',
      },
      {
        accessorFn: (row) => (
          <Badge
            color={trans(row.estado_pagamento)}
            variant='filled'>
            {row.estado_pagamento}
          </Badge>
        ),
        header: 'Pagamento',
        id: 'estadoPagamento',
        enableColumnFilter: false,
      },

      {
        accessorFn: (row) => (row.viagem ? row.produto.nome_interno : '-'),
        header: 'Seguro',
        id: 'produtoNomeInterno',
        filterVariant: 'text',
      },

      {
        accessorFn: (row) => (
          <Link to={'/campanha/' + campanhaID + '/viagem/' + row.viagem.id}>
            {row.viagem.cliente.nome_simples}
          </Link>
        ),
        header: 'Cliente',
        id: 'cliente',
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => row.viagem.grupo.codigo,
        id: 'grupo',
        header: 'Grupo',
        filterVariant: 'text',
        Cell: ({ row }: { row }) => (
          <Link to={'/campanha/' + campanhaID + '/grupo/' + row.original.viagem.grupo.codigo}>
            {row.original.viagem.grupo.codigo}
          </Link>
        ),
      },
      {
        accessorFn: (row) => row.produto.valor,
        header: 'Valor',
        id: 'valor',
        size: 50,
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          return cell.row.original.produto.valor + '€';
        },
      },

      {
        accessorFn: (row) => row.numero_apolice,
        header: 'Nrº Apolice',
        id: 'numeroApolice',
        filterVariant: 'text',
      },
      {
        accessorFn: (row) => row.data_subscricao,
        header: 'Data Subscrição',
        id: 'dataSubscricao',
        filterVariant: 'date-range',
      },
    ],
    []
  );
  const tableSeguros = useMantineReactTable({
    data: seguros || [],
    columns,
    enableClickToCopy: true,
    columnResizeMode: 'onEnd',
    positionActionsColumn: 'first',
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableMultiSort: true,
    enableColumnOrdering: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount: rowCount,
    state: {
      columnFilters: columnFilters,
      globalFilter: globalFilter,
      // isLoading: isLoading, this is crashing setState xenanings....
      pagination: pagination,
      showProgressBars: isRefetching,
      sorting: sorting,
    },
    initialState: {
      density: 'xs',
      showGlobalFilter: true,
      pagination: { pageSize: 15, pageIndex: 0 },
    },

    mantineTableProps: {
      highlightOnHover: true,
      withColumnBorders: true,
    },
    enableFullScreenToggle: false,
    localization: MRT_Localization_PT,
    globalFilterFn: 'contains',
    positionToolbarAlertBanner: 'bottom',
    positionGlobalFilter: 'none',
    renderToolbarInternalActions: ({ table }) => (
      <Flex
        direction={'row'}
        wrap={'wrap'}
        gap={'sm'}
        justify={'start'}>
        <MRT_GlobalFilterTextInput table={table} />

        <Select
          data={Object.values(EstadoPagamentoEnum)
            .map((ep) => {
              return { label: ep, value: ep } as SelectItem;
            })
            .concat({ label: '---', value: null })}
          value={estadopagamentoFilter}
          placeholder={'Filtrar Estado Pagamento'}
          onChange={setEstadoPagamentoFilter}
        />
        {estadopagamentoFilter == EstadoPagamentoEnum.N_O_PAGOU && (
          <Button
            onClick={() => {
              modals.openConfirmModal({
                title: 'Seguros não pagos',
                centered: true,
                children: (
                  <Text size='sm'>
                    Confirma que deseja remover e atribuir o Seguro Base a TODOS os Não Pagos (ACÇÃO
                    IRREVERSÍVEL !!!) ?
                  </Text>
                ),
                labels: { confirm: 'Aplicar', cancel: 'Cancelar' },
                confirmProps: { color: 'red', variant: 'outline', leftIcon: <IconUserOff /> },
                cancelProps: { color: 'yellow', variant: 'outline', leftIcon: <IconX /> },
                onConfirm: () => {
                  CompraService.compraSeguroRemoverEBaseAllNPagosRetrieve({
                    campanha: campanhaID,
                  }).then(
                    () => {
                      fetchData(pagination.pageIndex, pagination.pageSize);
                      notifications.show({
                        title: 'Removidos com sucesso ',
                        message: 'Removidos com sucesso',
                        icon: <IconCheck />,
                      });
                    },
                    (reason) => msgAPIError(reason)
                  );
                },
              });
            }}
            variant={'outline'}
            color={'red'}
            leftIcon={<IconAlertTriangle />}
            rightIcon={<IconTrash />}>
            Remover e atribuir o Base a TODOS os Não Pagos
          </Button>
        )}
        <Flex direction={'column'}>
          <Button
            color='lightblue'
            onClick={() => {
              setLoading(true);
              const cenas: {
                creationDateAfter?: string;
                creationDateBefore?: string;
                dataSubscricaoAfter?: string;
                dataSubscricaoBefore?: string;
                email?: string;
                estadoPagamento?: string;
                grupo?: string;
                instituicao?: string;
                nomeCompleto?: string;
                numeroApolice?: string;
                produtoBase?: boolean;
                produtoValor?: number;
                produtoNomeInterno?: string;
                search?: string;
                telemovel?: string;
                viagemCampanha?: string;
              } = {
                ...paramsNormalize(columnFilters),
                estadoPagamento: estadopagamentoFilter,
                viagemCampanha: campanhaID,
                search: globalFilter ?? '',
              };
              const url = `${OpenAPI.BASE}/gestao/compra/seguro/mapa_seguradora`;
              axios
                .get(url, {
                  responseType: 'blob',
                  params: {
                    creation_date_after: cenas.creationDateAfter || '',
                    creation_date_before: cenas.creationDateBefore || '',
                    data_subscricao_after: cenas.dataSubscricaoAfter || '',
                    data_subscricao_before: cenas.dataSubscricaoBefore || '',
                    email: cenas.email || '',
                    estado_pagamento: cenas.estadoPagamento || '',
                    grupo: cenas.grupo || '',
                    instituicao: cenas.instituicao || '',
                    nome_completo: cenas.nomeCompleto || '',
                    numero_apolice: cenas.numeroApolice || '',
                    produto__base: cenas.produtoBase || '',
                    produto__valor: cenas.produtoValor || '',
                    produto_nome_interno: cenas.produtoNomeInterno || '',
                    search: cenas.search || '',
                    telemovel: cenas.telemovel || '',
                    viagem__campanha: cenas.viagemCampanha || '',
                  },
                })
                .then(
                  (res) => {
                    const blob = new Blob([res.data], {
                      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
                    });
                    FileSaver.saveAs(blob, 'mapa_seguradora.xlsx');
                    setLoading(false);
                    return res;
                  },
                  (reason) => {
                    msgAPIError(reason);
                    setLoading(false);
                  }
                );
            }}
            leftIcon={<IconDownload />}
            variant='outline'>
            Mapa Seguradora
          </Button>
        </Flex>
        <Button
          variant={'outline'}
          leftIcon={<IconRefresh />}
          rightIcon={<IconRefresh />}
          onClick={() => {
            setLoading(true);
            CompraService.compraSeguroActualizarEstadosRetrieve({ campanha: campanhaID }).then(
              () => {
                fetchData(pagination.pageIndex, pagination.pageSize);
                setLoading(false);
              },
              (reason) => {
                msgAPIError(reason);
                setLoading(false);
              }
            );
          }}>
          Actualizar
        </Button>
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleFiltersButton table={table} />
      </Flex>
    ),
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '4px' }}>
        {row.original.estado !== CompraEstadoEnum.CANCELADO && (
          <Tooltip label='Tratar Seguro'>
            <ActionIcon
              onClick={() => {
                setApoliceEdit(row.original.numero_apolice);
                setEditCompra(row.original);
                setTratarSeguroModalVisible(true);
              }}>
              <IconEdit />
            </ActionIcon>
          </Tooltip>
        )}
        {row.original.estado_pagamento !== EstadoPagamentoEnum.PAGO_E_TRATADO && (
          <Tooltip label='Passar seguros para Base'>
            <ActionIcon
              color='red'
              onClick={() => {
                modals.openConfirmModal({
                  title: 'Cancelar Seguro!',
                  centered: true,
                  children: <Text size='sm'>Confirma que deseja passar para seguro base ?</Text>,
                  labels: { confirm: 'Passar para Seguro Base', cancel: 'Cancelar' },
                  confirmProps: { color: 'red', variant: 'outline', leftIcon: <IconTrash /> },
                  cancelProps: { color: 'yellow', variant: 'outline', leftIcon: <IconX /> },
                  onConfirm: () => {
                    CompraService.compraSeguroRemoverEBaseRetrieve({ id: row.original.id }).then(
                      () => {
                        fetchData(pagination.pageIndex, pagination.pageSize);
                        notifications.show({
                          title: 'Sucesso ',
                          message: `Seguro  Base atribuido`,
                          withCloseButton: true,
                          icon: <IconTrashX />,
                          color: 'green',
                        });
                      },
                      (reason: ApiError) => {
                        msgAPIError(reason);
                      }
                    );
                  },
                });
              }}>
              <IconTrash />
            </ActionIcon>
          </Tooltip>
        )}
      </Box>
    ),
  });

  if (!seguros) {
    return <>...</>;
  }
  return (
    <Stack>
      <LoadingOverlay
        visible={loading}
        overlayBlur={2}></LoadingOverlay>
      <MantineReactTable table={tableSeguros} />
      <Modal
        centered
        title={'Tratar Seguro:'}
        opened={tratarSeguroModalVisible}
        size={'auto'}
        onClose={() => {
          setTratarSeguroModalVisible(false);
        }}>
        <TextInput
          label={'Nrº Certificado de Apolice'}
          value={apoliceEdit}
          onChange={(event) => setApoliceEdit(event.currentTarget.value)}></TextInput>
        <Group
          position='right'
          mt='md'>
          <Button
            color={'yellow'}
            variant={'outline'}
            leftIcon={<IconX />}
            type='button'
            onClick={() => setTratarSeguroModalVisible(false)}>
            Cancelar
          </Button>
          <Button
            type='button'
            onClick={() => {
              if (editCompra.estado === CompraEstadoEnum.CANCELADO) {
                setTratarSeguroModalVisible(false);
              }
              CompraService.compraSeguroPartialUpdate({
                id: editCompra.id,
                requestBody: {
                  numero_apolice: apoliceEdit,
                  estado_pagamento: EstadoPagamentoEnum.PAGO_E_TRATADO,
                },
              }).then(
                (value) => {
                  setEditCompra(null);
                  fetchData(pagination.pageIndex, pagination.pageSize);
                  setTratarSeguroModalVisible(false);
                },
                (reason) => msgAPIError(reason)
              );
            }}
            variant={'outline'}
            leftIcon={<IconDeviceFloppy />}>
            Guardar
          </Button>
        </Group>
      </Modal>
    </Stack>
  );
}
