import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Grupo,
  GrupoEstadoEnum,
  GrupoRequest,
  GrupoService,
  InstituicaoItem,
  InstituicaoService,
  SlideinRoleEnum,
} from '../../../slidein_api';
import { DetalheProps, msgAPIError } from '../../helpers';
import { useCampanha } from '../CampanhaWrapperView';
import { useBread } from '../../../layout/context/BreadContext';
import { useForm, yupResolver } from '@mantine/form';
import {
  Button,
  Container,
  Flex,
  Group,
  NumberInput,
  Select,
  SelectItem,
  TextInput,
  Title,
} from '@mantine/core';
import {
  IconBuilding,
  IconCheck,
  IconCurrencyEuro,
  IconDeviceFloppy,
  IconPlane,
  IconTag,
  IconTrash,
  IconUsersGroup,
  IconUserShield,
  IconUserStar,
  IconX,
} from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { ADMIN, checkPermission, MASTER } from '../../../components/CheckPermission';
import { useAuth } from '../../../user-auth/SlideInAuthContext';

function GrupoEdit({ creation }: DetalheProps) {
  const { colaborador } = useAuth();
  const { setBread } = useBread();
  const { grupoID } = useParams();
  const { campanhaID } = useParams();
  const { campanha } = useCampanha();
  useEffect(() => {
    if (creation) {
      setBread({
        campanha: {
          title: campanhaID,
          href: '/campanha/' + campanhaID,
          icon: <IconPlane />,
        },
        accao: {
          title: 'Criar Grupo',
          icon: <IconUsersGroup />,
        },
      });
    }
  }, []);

  const navigate = useNavigate();

  const [instList, setInstList] = useState<InstituicaoItem[]>([]);

  const [grupo, setGrupo] = useState<Grupo | GrupoRequest>({
    codigo: '',
    estado: GrupoEstadoEnum.SUSPENSO,
    notas: '',
    comercial_responsavel: undefined,
    admin_responsavel: checkPermission(ADMIN, colaborador) ? colaborador.id : undefined,
    instituicao: null,
    campanha: campanhaID,
    valor_presta_ref_min: 100,
  });

  const [colaboradores, setColaboradores] = useState<SelectItem[]>([]);
  const [admins, setAdmin] = useState<SelectItem[]>([]);

  useEffect(() => {
    if (!campanha) return;
    setColaboradores(
      campanha.colaboradores
        .filter((co) => {
          return co.slidein_role != SlideinRoleEnum.TRAVEL_STAFF;
        })
        .map((x) => {
          return {
            label: x.nome_simples,
            value: x.id,
          };
        }) as unknown as SelectItem[]
    );
    if (colaborador.slidein_role == SlideinRoleEnum.ADMIN) {
      grupo.admin_responsavel = colaborador.id;
    }
    setAdmin(
      campanha.colaboradores
        .filter((co) => {
          return (
            co.slidein_role != SlideinRoleEnum.TRAVEL_STAFF &&
            co.slidein_role != SlideinRoleEnum.COMERCIAL
          );
        })
        .map((x) => {
          return {
            label: x.nome_simples,
            value: x.id,
          };
        }) as unknown as SelectItem[]
    );

    InstituicaoService.instituicaoItemList({}).then(
      (value) => {
        setInstList(value || []);
      },
      (reason) => {
        msgAPIError(reason);
      }
    );

    if (!creation) {
      GrupoService.grupoRetrieve({ codigo: grupoID }).then(
        (value) => {
          setGrupo(value);
          groupForm.setValues(value);
        },
        (reason) => {
          msgAPIError(reason);
        }
      );
    }
  }, [campanha]);

  const groupForm = useForm({
    initialValues: grupo,
    validate: yupResolver(
      Yup.object({
        codigo: Yup.string()
          .max(40, 'Tamanho máximo de 40 caracteres')
          .min(5, 'Tamanho mínimo de 5 caracteres')
          .matches(
            /^[-a-zA-Z0-9_]+$/,
            'Insira um “codigo” válido composto por letras (alfabeto inglês), números, sublinhados ou hífens(sem espaços).'
          )
          .required('Obrigatório'),
        instituicao: Yup.string().required('Obrigatório'),
        comercial_responsavel: Yup.string().required('Obrigatório'),
        admin_responsavel: Yup.string().required('Obrigatório'),
        valor_presta_ref_min: Yup.number().min(0).required('Obrigatório'),
      })
    ),
  });

  const saveGrupo = (grupo: GrupoRequest) => {
    if (creation) {
      if (colaborador.slidein_role == SlideinRoleEnum.ADMIN) {
        grupo.admin_responsavel = colaborador.id;
      }
      GrupoService.grupoCreate({ requestBody: grupo }).then(
        (newG) => {
          navigate('/campanha/' + campanhaID + '/grupo/' + newG.codigo);
        },
        (reason) => {
          groupForm.setErrors(reason.body);
        }
      );
    } else {
      GrupoService.grupoUpdate({ codigo: grupoID, requestBody: grupo }).then(
        () => {
          navigate(0);
        },
        (reason) => {
          groupForm.setErrors(reason.body);
        }
      );
    }
  };

  const removerGrupo = () => {
    if (confirm('Confirma que deseja remover o grupo : ' + grupo.codigo + ' ?')) {
      GrupoService.grupoDestroy({ codigo: grupo.codigo }).then(
        () => {
          navigate('/campanha/' + campanhaID);
          notifications.show({
            title: 'Removido com sucesso ',
            message: 'Removido com sucesso',
            icon: <IconCheck />,
          });
        },
        (reason) => msgAPIError(reason)
      );
    }
  };
  return (
    <Container size={'md'}>
      <Title order={3}> {creation ? 'Criar Grupo' : 'Editar Grupo'}</Title>

      <form onSubmit={groupForm.onSubmit(saveGrupo)}>
        {!creation && (
          <Flex
            direction='row'
            gap={'xs'}
            justify={'flex-end'}
            wrap={'wrap'}>
            <Button
              disabled={(grupo as Grupo).nr_viajantes > 0 || !checkPermission(MASTER, colaborador)}
              type='button'
              variant={'outline'}
              leftIcon={<IconTrash />}
              color={'red'}
              onClick={() => removerGrupo()}>
              Remover grupo
            </Button>
          </Flex>
        )}
        <TextInput
          label={'Codigo'}
          disabled={!creation}
          icon={<IconTag />}
          {...groupForm.getInputProps('codigo')}
        />
        <Select
          label={'Instituição'}
          icon={<IconBuilding />}
          placeholder='Escolher a Instituição'
          searchable
          nothingFound='Não encontrado'
          data={
            instList.map((x) => {
              return {
                label: `${x.nome} (${x.concelho})`,
                value: x.id,
              };
            }) as unknown as SelectItem[]
          }
          {...groupForm.getInputProps('instituicao')}
        />
        <Select
          label={'Administrador'}
          icon={<IconUserShield />}
          placeholder='Escolher o Administrador'
          searchable
          disabled={colaborador.slidein_role != SlideinRoleEnum.MASTER}
          nothingFound='Não encontrado'
          data={admins || []}
          {...groupForm.getInputProps('admin_responsavel')}
        />
        <Select
          label={'Comercial'}
          icon={<IconUserStar />}
          placeholder='Escolher o Comercial'
          searchable
          nothingFound='Não encontrado'
          data={colaboradores || []}
          {...groupForm.getInputProps('comercial_responsavel')}
        />

        <NumberInput
          placeholder='Min'
          description={
            'CUIDADO: Alterar o valor não altera os limites das referência MB previamente geradas !!!'
          }
          disabled={colaborador.slidein_role != SlideinRoleEnum.MASTER}
          label='Valor Minimo Prestações/Referências Multibanco'
          precision={2}
          icon={<IconCurrencyEuro />}
          hideControls
          decimalSeparator=','
          thousandsSeparator='.'
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          formatter={(value) =>
            !Number.isNaN(parseFloat(value))
              ? // eslint-disable-next-line security/detect-unsafe-regex
                `${value} €`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
              : ''
          }
          {...groupForm.getInputProps('valor_presta_ref_min')}
        />

        <Group
          position='right'
          mt='md'>
          <Button
            hidden={!creation}
            color={'yellow'}
            variant={'outline'}
            leftIcon={<IconX />}
            type='button'
            onClick={() => {
              navigate(-1);
            }}>
            Cancelar
          </Button>
          <Button
            variant={'outline'}
            leftIcon={<IconDeviceFloppy />}
            type='submit'>
            {creation ? 'Criar ' : 'Guardar Alterações'}
          </Button>
        </Group>
      </form>
    </Container>
  );
}

export default GrupoEdit;
