import React, { useEffect, useState } from 'react';
import {
  CompraService,
  PagamentoService,
  PagamentosInstituicaoStats,
  PagamentosStats,
  ProdutoService,
  ProdutoStats,
  TipoEnum,
} from '../../../slidein_api';
import {
  Button,
  Center,
  Container,
  Flex,
  LoadingOverlay,
  Stack,
  Table,
  Tabs,
  Text,
  Title,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import dayjs from 'dayjs';
import {
  IconCalendarDown,
  IconCalendarUp,
  IconDownload,
  IconFileEuro,
  IconFileSpreadsheet,
  IconFilter,
  IconPackage,
  IconPlane,
  IconTrash,
  IconUsersGroup,
} from '@tabler/icons-react';
import { down_xlsx, msgAPIError } from '../../helpers';
import { useParams } from 'react-router-dom';
import { useBread } from '../../../layout/context/BreadContext';

export function BalancoGeral() {
  const { campanhaID } = useParams();
  const { setBread } = useBread();
  useEffect(() => {
    setBread({
      campanha: {
        title: campanhaID,
        href: '/campanha/' + campanhaID,
        icon: <IconPlane />,
      },
      accao: {
        title: 'Balanço Geral',
        icon: <IconFileEuro />,
      },
    });
  }, []);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pacoteStats, setPacotesStats] = useState<ProdutoStats[]>([]);
  const [seguroStats, setSeguroStats] = useState<ProdutoStats[]>([]);
  const [actividadeStats, setActividadeStats] = useState<ProdutoStats[]>([]);
  const [suplementoStats, setSuplementoStats] = useState<ProdutoStats[]>([]);
  const [outroStats, setOutroStats] = useState<ProdutoStats[]>([]);
  const [descontoStats, setDescontoStats] = useState<ProdutoStats[]>([]);
  const [pagamentosStats, setPagamentosStats] = useState<PagamentosStats[]>([]);
  const [pagamentosGrupos, setPagamentosGrupos] = useState<PagamentosInstituicaoStats[]>([]);

  const [countPacote, setCountPacote] = useState<number>(0);
  const [countSeguro, setCountSeguro] = useState<number>(0);
  const [countActividade, setCountActividade] = useState<number>(0);
  const [countSuplemento, setCountSuplemento] = useState<number>(0);
  const [countOutro, setCountOutro] = useState<number>(0);
  const [countDesconto, setCountDesconto] = useState<number>(0);

  const [totalPacote, setTotalPacote] = useState<number>(0);
  const [totalSeguro, setTotalSeguro] = useState<number>(0);
  const [totalActividade, setTotalActividade] = useState<number>(0);
  const [totalSuplemento, setTotalSuplemento] = useState<number>(0);
  const [totalOutro, setTotalOutro] = useState<number>(0);
  const [totalDesconto, setTotalDesconto] = useState<number>(0);

  const [totalVendas, setTotalVendas] = useState<number>(0);
  const [totalPagamentos, setTotalPagamentos] = useState<number>(0);

  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);

  const [apply, toggleApply] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    ProdutoService.produtoSeguroStatsList({
      campanha: campanhaID,
      startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
      endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
    }).then(
      (sta) => {
        setSeguroStats(sta);
      },
      (reason) => msgAPIError(reason)
    );
    ProdutoService.produtoPacoteStatsList({
      campanha: campanhaID,
      startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
      endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
    }).then(
      (sta) => {
        setPacotesStats(sta);
      },
      (reason) => msgAPIError(reason)
    );
    ProdutoService.produtoActividadeStatsList({
      campanha: campanhaID,
      startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
      endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
    }).then((sta) => {
      setActividadeStats(sta);
    });
    ProdutoService.produtoSuplementoStatsList({
      campanha: campanhaID,
      startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
      endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
    }).then(
      (sta) => {
        setSuplementoStats(sta);
      },
      (reason) => msgAPIError(reason)
    );
    CompraService.compraOutroStatsList({
      campanha: campanhaID,
      startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
      endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
    }).then(
      (sta) => {
        setOutroStats(sta as ProdutoStats[]);
      },
      (reason) => msgAPIError(reason)
    );

    ProdutoService.produtoDescontoStatsList({
      campanha: campanhaID,
      startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
      endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
    }).then(
      (sta) => {
        setDescontoStats(sta);
      },
      (reason) => msgAPIError(reason)
    );
    PagamentoService.pagamentoStatsList({
      campanha: campanhaID,
      startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
      endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
    }).then(
      (pag) => {
        setPagamentosStats(pag);
      },
      (reason) => msgAPIError(reason)
    );
    PagamentoService.pagamentoStatsGruposList({
      campanha: campanhaID,
      startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
      endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
    }).then(
      (pag) => {
        setPagamentosGrupos(pag);
        setIsLoading(false);
      },
      (reason) => {
        msgAPIError(reason);
        setIsLoading(false);
      }
    );
  }, [apply]);

  useEffect(() => {
    setCountPacote(
      pacoteStats.reduce((acc, obj) => {
        return acc + obj.count;
      }, 0)
    );
    setCountSeguro(
      seguroStats.reduce((acc, obj) => {
        return acc + obj.count;
      }, 0)
    );
    setCountActividade(
      actividadeStats.reduce((acc, obj) => {
        return acc + obj.count;
      }, 0)
    );
    setCountSuplemento(
      suplementoStats.reduce((acc, obj) => {
        return acc + obj.count;
      }, 0)
    );
    setCountOutro(
      outroStats.reduce((acc, obj) => {
        return acc + obj.count;
      }, 0)
    );
    setCountDesconto(
      descontoStats.reduce((acc, obj) => {
        return acc + obj.count;
      }, 0)
    );
    setTotalPacote(
      pacoteStats.reduce((acc, obj) => {
        return acc + obj.total;
      }, 0)
    );
    setTotalSeguro(
      seguroStats.reduce((acc, obj) => {
        return acc + obj.total;
      }, 0)
    );
    setTotalActividade(
      actividadeStats.reduce((acc, obj) => {
        return acc + obj.total;
      }, 0)
    );
    setTotalSuplemento(
      suplementoStats.reduce((acc, obj) => {
        return acc + obj.total;
      }, 0)
    );
    setTotalOutro(
      outroStats.reduce((acc, obj) => {
        return acc + obj.total;
      }, 0)
    );
    setTotalDesconto(
      descontoStats.reduce((acc, obj) => {
        return acc + obj.total;
      }, 0)
    );
  }, [seguroStats, pacoteStats, actividadeStats, suplementoStats, outroStats, descontoStats]);

  useEffect(() => {
    const pagT = pagamentosStats.reduce((acc, pag) => {
      if (pag.tipo === TipoEnum.DEVOLU_O) {
        return acc - pag.total;
      }
      return acc + pag.total;
    }, 0);
    const vedT =
      totalPacote + totalSeguro + totalActividade + totalSuplemento + totalOutro - totalDesconto;

    setTotalPagamentos(pagT);
    setTotalVendas(vedT);
  }, [pagamentosStats, totalPacote, totalDesconto, totalActividade, totalSuplemento, totalSeguro]);

  return (
    <Stack>
      <LoadingOverlay
        visible={isLoading}
        overlayBlur={2}
      />
      <Center>
        <Flex
          direction={'row'}
          justify={'center'}
          align={'end'}
          gap={'md'}
          w={'100%'}>
          <DatePickerInput
            icon={<IconCalendarUp />}
            label={'Inicio'}
            valueFormat='DD/MM/YYYY'
            dropdownType={'modal'}
            w={'14rem'}
            value={startDate}
            onChange={setStartDate}
          />
          <DatePickerInput
            icon={<IconCalendarDown />}
            label={'Fim'}
            w={'10rem'}
            dropdownType={'modal'}
            valueFormat='DD/MM/YYYY'
            value={endDate}
            onChange={setEndDate}
          />
          <Button
            leftIcon={<IconFilter />}
            variant={'outline'}
            onClick={() => {
              toggleApply(!apply);
            }}>
            Filtrar
          </Button>
          <Button
            leftIcon={<IconTrash />}
            variant={'outline'}
            onClick={() => {
              setEndDate(null);
              setStartDate(null);
              toggleApply(!apply);
            }}>
            Limpar
          </Button>
        </Flex>
      </Center>
      <Tabs defaultValue={'vendas'}>
        <Tabs.List>
          <Tabs.Tab
            value={'vendas'}
            icon={<IconPackage />}>
            Vendas
          </Tabs.Tab>
          <Tabs.Tab
            value={'paga'}
            icon={<IconFileEuro />}>
            Pagamentos
          </Tabs.Tab>
          <Tabs.Tab
            value={'paga_grupos'}
            icon={<IconUsersGroup />}>
            Pagamentos por Instituição/Grupo
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel
          value='vendas'
          pt='xs'>
          <Container p={'xl'}>
            <Table
              horizontalSpacing='xs'
              verticalSpacing='xs'
              striped
              withBorder
              withColumnBorders>
              <thead>
                <tr>
                  <th>Produto</th>
                  <th>Valor</th>
                  <th>Quantidade</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    colSpan={4}
                    align={'center'}>
                    <Title order={4}>Alojamentos/Pacotes</Title>
                  </td>
                </tr>
                {pacoteStats.map((ps) => (
                  <tr key={ps.nome_interno}>
                    <td>{ps.nome_interno}</td>
                    <td>{ps.valor} €</td>
                    <td align={'center'}>{(ps.count || 0).toLocaleString()}</td>
                    <td>{(ps.total || 0).toLocaleString()} €</td>
                  </tr>
                ))}
                <tr>
                  <td
                    align={'right'}
                    colSpan={2}>
                    <Title order={4}>SubTotal:</Title>
                  </td>
                  <td>{(countPacote || 0).toLocaleString()}</td>
                  <td>{(totalPacote || 0).toLocaleString()}€</td>
                </tr>
                <tr>
                  <td
                    colSpan={4}
                    align={'center'}>
                    <Title order={4}>Seguros</Title>
                  </td>
                </tr>
                {seguroStats.map((ps) => (
                  <tr key={ps.nome_interno}>
                    <td>{ps.nome_interno}</td>
                    <td>{ps.valor || '-'} €</td>
                    <td align={'center'}>{(ps.count || 0).toLocaleString()}</td>
                    <td>{(ps.total || 0).toLocaleString()} €</td>
                  </tr>
                ))}
                <tr>
                  <td
                    align={'right'}
                    colSpan={2}>
                    <Title order={4}>SubTotal:</Title>
                  </td>
                  <td align={'center'}>{countSeguro}</td>
                  <td>{(totalSeguro || 0).toLocaleString()}€</td>
                </tr>
                <tr>
                  <td
                    colSpan={4}
                    align={'center'}>
                    <Title order={4}>Actividades/Opcionais</Title>
                  </td>
                </tr>
                {actividadeStats.map((ps) => (
                  <tr key={ps.nome_interno}>
                    <td>{ps.nome_interno}</td>
                    <td>{ps.valor || '-'} €</td>
                    <td align={'center'}>{(ps.count || 0).toLocaleString()}</td>
                    <td>{(ps.total || 0).toLocaleString()} €</td>
                  </tr>
                ))}
                <tr>
                  <td
                    align={'right'}
                    colSpan={2}>
                    <Title order={4}>SubTotal:</Title>
                  </td>
                  <td align={'center'}>{(countActividade || 0).toLocaleString()}</td>
                  <td>{(totalActividade || 0).toLocaleString()}€</td>
                </tr>

                <tr>
                  <td
                    colSpan={4}
                    align={'center'}>
                    <Title order={4}>Suplementos</Title>
                  </td>
                </tr>
                {suplementoStats.map((ps) => (
                  <tr key={ps.nome_interno}>
                    <td>{ps.nome_interno}</td>
                    <td>{ps.valor || '-'} €</td>
                    <td align={'center'}>{(ps.count || 0).toLocaleString()}</td>
                    <td>{(ps.total || 0).toLocaleString()} €</td>
                  </tr>
                ))}
                <tr>
                  <td
                    align={'right'}
                    colSpan={2}>
                    <Title order={4}>SubTotal:</Title>
                  </td>
                  <td align={'center'}>{(countSuplemento || 0).toLocaleString()}</td>
                  <td>{(totalSuplemento || 0).toLocaleString()}€</td>
                </tr>
                <tr>
                  <td
                    colSpan={4}
                    align={'center'}>
                    <Title order={4}>Outros</Title>
                  </td>
                </tr>
                {outroStats.map((ps) => (
                  <tr key={ps.nome_interno}>
                    <td colSpan={2}>{ps.nome_interno}</td>
                    <td align={'center'}>{(ps.count || 0).toLocaleString()}</td>
                    <td>{(ps.total || 0).toLocaleString()} €</td>
                  </tr>
                ))}
                <tr>
                  <td
                    align={'right'}
                    colSpan={2}>
                    <Title order={4}>SubTotal:</Title>
                  </td>
                  <td align={'center'}>{(countOutro || 0).toLocaleString()}</td>
                  <td>{(totalOutro || 0).toLocaleString()}€</td>
                </tr>

                <tr>
                  <td
                    colSpan={4}
                    align={'center'}>
                    <Title order={4}>Descontos</Title>
                  </td>
                </tr>
                {descontoStats.map((ps) => (
                  <tr key={ps.nome_interno}>
                    <td>{ps.nome_interno}</td>
                    <td>{ps.valor || '-'} €</td>
                    <td align={'center'}>{(ps.count || 0).toLocaleString()}</td>
                    <td>{(ps.total || 0).toLocaleString()} €</td>
                  </tr>
                ))}
                <tr>
                  <td
                    align={'right'}
                    colSpan={2}>
                    <Title order={4}>SubTotal:</Title>
                  </td>
                  <td align={'center'}>{countDesconto}</td>
                  <td>{totalDesconto ? `-${(totalDesconto || 0).toLocaleString()}` : 0}€</td>
                </tr>
                <tr>
                  <td
                    colSpan={3}
                    align={'right'}>
                    <Title order={2}>Total:</Title>
                  </td>
                  <td>
                    <Text
                      fz='lg'
                      fw={700}>
                      {(totalVendas || 0).toLocaleString()}€
                    </Text>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Container>
        </Tabs.Panel>
        <Tabs.Panel
          value='paga'
          pt='xs'>
          <Container p={'xl'}>
            <Table
              horizontalSpacing='xl'
              verticalSpacing='xs'
              striped
              withBorder
              withColumnBorders>
              <thead>
                <tr>
                  <th>Metodo</th>
                  <th>Quantidade</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {pagamentosStats.map((ps) => (
                  <tr key={ps.tipo}>
                    <td>{ps.tipo}</td>
                    <td align={'center'}>{(ps.count || 0).toLocaleString()}</td>
                    <td>{(ps.total || 0).toLocaleString()} €</td>
                  </tr>
                ))}
                <tr>
                  <td
                    colSpan={2}
                    align={'right'}>
                    <Title order={2}>Total:</Title>
                  </td>
                  <td>
                    <Text
                      fz='lg'
                      fw={700}>
                      {(totalPagamentos || 0).toLocaleString()}€
                    </Text>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Container>
        </Tabs.Panel>
        <Tabs.Panel
          value='paga_grupos'
          pt='xs'>
          <Flex
            direction={'row'}
            justify={'start'}
            align={'end'}
            my={'xs'}
            gap={'md'}
            w={'100%'}>
            <Button
              color='lightblue'
              onClick={() => {
                const sd = startDate ? dayjs(startDate).format('YYYY-MM-DD') : null;
                const ed = endDate ? dayjs(endDate).format('YYYY-MM-DD') : null;
                down_xlsx(
                  `/gestao/campanha/${campanhaID}/mapa_pag_grupo`,
                  `mapa_pagamentos_grupo${sd !== null ? '_' + sd : ''}${
                    ed !== null ? '_' + ed : ''
                  }.xlsx`,
                  {
                    start_date: sd,
                    end_date: ed,
                  }
                );
              }}
              rightIcon={<IconFileSpreadsheet />}
              leftIcon={<IconDownload />}
              variant='outline'>
              Mapa
            </Button>
          </Flex>
          <Table
            horizontalSpacing='xl'
            verticalSpacing='xs'
            withBorder
            withColumnBorders>
            <thead>
              <tr>
                <th>Instituição</th>
                <th>Quantidade</th>
                <th>Total</th>
                <th>Grupo</th>
                <th>Admin</th>
                <th>Quantidade</th>
                <th>Total</th>
                <th>Tipo</th>
                <th>Total</th>
                <th>Quantidade</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {pagamentosGrupos.map((inst) => {
                let i = 0;
                let key = 0;
                return inst.grupos.map((gro) => {
                  let g = 0;
                  return gro.stats.map((st) => {
                    return (
                      <tr key={key++}>
                        {i == 0 && (
                          <td rowSpan={inst.grupos.length * gro.stats.length}>
                            {inst.instituicao}
                          </td>
                        )}
                        {i == 0 && (
                          <td rowSpan={inst.grupos.length * gro.stats.length}>
                            {(inst.count || 0).toLocaleString()}
                          </td>
                        )}
                        {i++ == 0 && (
                          <td rowSpan={inst.grupos.length * gro.stats.length}>
                            {(inst.total || 0).toLocaleString()}€
                          </td>
                        )}
                        {g == 0 && <td rowSpan={gro.stats.length}>{gro.grupo}</td>}
                        {g == 0 && <td rowSpan={gro.stats.length}>{gro.admin}</td>}
                        {g == 0 && <td rowSpan={gro.stats.length}>{gro.comercial}</td>}
                        {g == 0 && (
                          <td rowSpan={gro.stats.length}>{(gro.count || 0).toLocaleString()}</td>
                        )}
                        {g++ == 0 && (
                          <td rowSpan={gro.stats.length}>{(gro.total || 0).toLocaleString()}€</td>
                        )}
                        <td> {st.tipo}</td>
                        <td align={'center'}>{(st.count || 0).toLocaleString()}</td>
                        <td>{(st.total || 0).toLocaleString()}€</td>
                      </tr>
                    );
                  });
                });
              })}
            </tbody>
          </Table>
        </Tabs.Panel>
      </Tabs>
    </Stack>
  );
}
