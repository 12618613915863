import React, { ReactElement, useContext } from 'react';
import {
  ActionIcon,
  AppShell,
  Breadcrumbs,
  Burger,
  Divider,
  Flex,
  Group,
  Header,
  MediaQuery,
  Navbar,
  px,
  ScrollArea,
  Text,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import GestaoNavbar from './GestaoNavbar';
import { useAuth } from '../user-auth/SlideInAuthContext';
import { BreadContext } from './context/BreadContext';
import { IconLogout, IconMoonStars, IconSun, IconUserEdit } from '@tabler/icons-react';
import { Notifications } from '@mantine/notifications';
import { SlideinRoleEnum } from '../slidein_api';
import { SlideinLogo } from '../components/logos';
import { Link, useNavigate } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import { ModalsProvider } from '@mantine/modals';

export type GestaoMenuItem = {
  disabled?: boolean;
  icon?: ReactElement;
  items?: GestaoMenuItem[];
  indicator?: number;
  label: string;
  to?: string;
  separator?: boolean;
  roles: SlideinRoleEnum[];
};

function Layout(props: { children }) {
  const theme = useMantineTheme();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const { logout, colaborador } = useAuth();
  const dark = colorScheme === 'dark';
  const navigate = useNavigate();
  const [opened, { toggle }] = useDisclosure(false);
  const { breadpath, sm_breadpath } = useContext(BreadContext);

  return (
    <AppShell
      styles={{
        main: {
          background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
          width: '99vw',
          minHeight: 'calc(99vh - var(--mantine-header-height, 0px))',
        },
      }}
      navbar={
        <Navbar
          p='md'
          hiddenBreakpoint='sm'
          hidden={!opened}
          top={{ sm: 0 }}
          height={'100%'}
          width={{ sm: 220 }}>
          <Navbar.Section>
            <Flex
              justify={'center'}
              py={'10px'}>
              <Link to={'/'}>
                <SlideinLogo />
              </Link>
            </Flex>
            <Divider></Divider>
            <Group
              my={'8px'}
              position='center'
              spacing='lg'>
              {colaborador && (
                <>
                  <Tooltip label={'Editar o Meu Perfil'}>
                    <ActionIcon
                      onClick={() => {
                        toggle();
                        navigate('/colaborador/meu_perfil');
                      }}
                      size='sm'>
                      <IconUserEdit />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label={'Sair (Logout)'}>
                    <ActionIcon
                      onClick={() => {
                        logout();
                        navigate('/');
                      }}
                      size='sm'>
                      <IconLogout />
                    </ActionIcon>
                  </Tooltip>
                </>
              )}
              <ActionIcon
                variant='outline'
                color={dark ? 'yellow' : 'blue'}
                onClick={() => toggleColorScheme()}
                title='Toggle color scheme'>
                {dark ? <IconSun size={px('1.1rem')} /> : <IconMoonStars size={px('1.1rem')} />}
              </ActionIcon>
            </Group>
            <Divider></Divider>
          </Navbar.Section>
          <Navbar.Section>
            {colaborador && (
              <Group
                spacing='xs'
                my={'4px'}
                position='center'>
                <Text
                  size='sm'
                  weight={500}>
                  {colaborador.nome_simples}
                </Text>
                <Text
                  color='dimmed'
                  size='xs'>
                  ( {colaborador.slidein_role} )
                </Text>
              </Group>
            )}
          </Navbar.Section>
          <Navbar.Section
            mt='md'
            grow
            component={ScrollArea}
            mx='-xs'
            px='xs'>
            <GestaoNavbar toggle={toggle} />
          </Navbar.Section>
        </Navbar>
      }
      header={
        <Header
          height={60}
          left={{ sm: 220 }}
          p='xs'>
          <Group>
            <MediaQuery
              largerThan='sm'
              styles={{ display: 'none' }}>
              <Flex
                direction={'row'}
                wrap={'nowrap'}>
                <Burger
                  opened={opened}
                  onClick={toggle}
                />
                <Breadcrumbs m={'xs'}>{sm_breadpath}</Breadcrumbs>
              </Flex>
            </MediaQuery>
            <MediaQuery
              smallerThan='sm'
              styles={{ display: 'none' }}>
              <Breadcrumbs m={'xs'}>{breadpath}</Breadcrumbs>
            </MediaQuery>
          </Group>
        </Header>
      }>
      <ModalsProvider>{props.children}</ModalsProvider>
      <Notifications
        position={'top-right'}
        limit={10}
      />
    </AppShell>
  );
}

export default Layout;
