import { Button, LoadingOverlay, NumberInput, Select, Stack } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { ComprasViagem, TamanhoTshirtEnum, ViagemService } from '../../../../slidein_api';
import { msgAPIError } from '../../../helpers';
import { useCampanha } from '../../CampanhaWrapperView';
import { IconDeviceFloppy } from '@tabler/icons-react';

export function ViagemLogi({ viagem, fetch }: { viagem: ComprasViagem; fetch }) {
  const { campanha } = useCampanha();
  const [loading, setLoading] = useState(false);
  const [tshirt, setTshirt] = useState<TamanhoTshirtEnum>();
  const [peso, setPeso] = useState<number>(undefined);
  const [altura, setAltura] = useState<number>(undefined);
  const [calcado, setCalcado] = useState<number>(undefined);
  const [changed, setChanged] = useState<boolean>(false);

  useEffect(() => {
    setPeso(viagem?.peso == null ? undefined : viagem?.peso);
    setAltura(viagem?.altura == null ? undefined : viagem?.altura);
    setCalcado(viagem?.calcado == null ? undefined : viagem?.calcado);
    setTshirt(viagem?.tamanho_tshirt);
    setChanged(false);
  }, [viagem]);

  const saveInfo = () => {
    setLoading(true);
    ViagemService.viagemPartialUpdate({
      id: viagem?.id,
      requestBody: {
        tamanho_tshirt: tshirt,
        peso: peso,
        altura: altura,
        calcado: calcado,
      },
    }).then(
      () => {
        setLoading(false);
        fetch();
        setChanged(false);
      },
      (reason) => {
        msgAPIError(reason);
        setLoading(false);
      }
    );
  };

  return (
    <Stack w={'14rem'}>
      <LoadingOverlay
        visible={loading}
        overlayBlur={2}
      />
      {changed && (
        <Button
          w={'100%'}
          type={'button'}
          onClick={() => saveInfo()}
          rightIcon={<IconDeviceFloppy />}
          variant={'outline'}>
          Guardar Alterações
        </Button>
      )}
      <Select
        label={'Tamanho Tshirt'}
        variant={'filled'}
        disabled={!campanha?.pedir_tshirt}
        value={tshirt}
        data={Object.values(TamanhoTshirtEnum)}
        onChange={(value: TamanhoTshirtEnum) => {
          setChanged(true);
          setTshirt(value);
        }}></Select>
      <NumberInput
        label={'Peso Kg'}
        disabled={!campanha?.pedir_peso}
        value={peso}
        precision={1}
        onChange={(value: number) => {
          setChanged(true);
          setPeso(value);
        }}></NumberInput>
      <NumberInput
        label={'Altura m'}
        disabled={!campanha?.pedir_altura}
        value={altura}
        precision={2}
        onChange={(value: number) => {
          setChanged(true);
          setAltura(value);
        }}></NumberInput>
      <NumberInput
        label={'Calcado'}
        disabled={!campanha?.pedir_calcado}
        value={calcado}
        onChange={(value: number) => {
          setChanged(true);
          setCalcado(value);
        }}></NumberInput>
    </Stack>
  );
}
