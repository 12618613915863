/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedViagemList } from '../models/PaginatedViagemList';
import type { PatchedViagemChefeRequest } from '../models/PatchedViagemChefeRequest';
import type { PatchedViagemDocRequest } from '../models/PatchedViagemDocRequest';
import type { PatchedViagemQuartoRequest } from '../models/PatchedViagemQuartoRequest';
import type { PatchedViagemRequest } from '../models/PatchedViagemRequest';
import type { PatchedViagemTransporteRequest } from '../models/PatchedViagemTransporteRequest';
import type { Viagem } from '../models/Viagem';
import type { ViagemChefe } from '../models/ViagemChefe';
import type { ViagemChefeRequest } from '../models/ViagemChefeRequest';
import type { ViagemDoc } from '../models/ViagemDoc';
import type { ViagemDocRequest } from '../models/ViagemDocRequest';
import type { ViagemNotificaAtraso } from '../models/ViagemNotificaAtraso';
import type { ViagemQuarto } from '../models/ViagemQuarto';
import type { ViagemQuartoRequest } from '../models/ViagemQuartoRequest';
import type { ViagemRequest } from '../models/ViagemRequest';
import type { ViagemSimples } from '../models/ViagemSimples';
import type { ViagemTransporte } from '../models/ViagemTransporte';
import type { ViagemTransporteRequest } from '../models/ViagemTransporteRequest';
import type { ViList } from '../models/ViList';
import type { ViListRequest } from '../models/ViListRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ViagemService {

    /**
     * @returns PaginatedViagemList
     * @throws ApiError
     */
    public static viagemList({
        actividadesList,
        actividadesListNot,
        adminResponsavel,
        alojamento,
        alturaMax,
        alturaMin,
        apelido,
        apoliceSeguro,
        bloqueadaPagamentos,
        calcadoMax,
        calcadoMin,
        campanha,
        cancelada,
        chefeDeGrupo,
        comercialResponsavel,
        contactoCasa,
        contactoTelemovel,
        contactoUrgencia,
        creationDateAfter,
        creationDateBefore,
        dataEmissaoAfter,
        dataEmissaoBefore,
        dataNascimentoAfter,
        dataNascimentoBefore,
        descontosList,
        descontosListNot,
        email,
        emailContactoUrgencia,
        emailPais,
        generoSexual,
        grupo,
        idadeNoFimMax,
        idadeNoFimMin,
        identificacao,
        instituicao,
        lastLoginAfter,
        lastLoginBefore,
        moradaCodigoPostal,
        moradaLocalidade,
        moradaPais,
        moradaPorta,
        moradaRua,
        nacionalidade,
        naoNotificar,
        nif,
        nomeCompleto,
        nomeContactoUrgencia,
        nomeMae,
        nomePai,
        nomeProprio,
        nrFactura,
        ordering,
        page,
        pageSize,
        pesoMax,
        pesoMin,
        prestacoesEmAtrasoMax,
        prestacoesEmAtrasoMin,
        search,
        seguroContratado,
        suplementosList,
        suplementosListNot,
        tamanhoTshirt,
        temDecaMenores,
        validadeIdentificacaoAfter,
        validadeIdentificacaoBefore,
        valorDevidoMax,
        valorDevidoMin,
        valorPagoMax,
        valorPagoMin,
        valorTotalMax,
        valorTotalMin,
        vfree,
    }: {
        actividadesList?: string,
        actividadesListNot?: string,
        adminResponsavel?: string,
        alojamento?: string,
        alturaMax?: number | null,
        alturaMin?: number | null,
        apelido?: string,
        apoliceSeguro?: string,
        bloqueadaPagamentos?: boolean,
        calcadoMax?: number | null,
        calcadoMin?: number | null,
        campanha?: string,
        cancelada?: boolean,
        chefeDeGrupo?: boolean,
        comercialResponsavel?: string,
        contactoCasa?: string,
        contactoTelemovel?: string,
        contactoUrgencia?: string,
        creationDateAfter?: string,
        creationDateBefore?: string,
        dataEmissaoAfter?: string,
        dataEmissaoBefore?: string,
        dataNascimentoAfter?: string,
        dataNascimentoBefore?: string,
        descontosList?: string,
        descontosListNot?: string,
        email?: string,
        emailContactoUrgencia?: string,
        emailPais?: string,
        generoSexual?: string,
        grupo?: string,
        idadeNoFimMax?: number,
        idadeNoFimMin?: number,
        identificacao?: string,
        instituicao?: string,
        lastLoginAfter?: string,
        lastLoginBefore?: string,
        moradaCodigoPostal?: string,
        moradaLocalidade?: string,
        moradaPais?: string,
        moradaPorta?: string,
        moradaRua?: string,
        nacionalidade?: string,
        naoNotificar?: boolean,
        nif?: string,
        nomeCompleto?: string,
        nomeContactoUrgencia?: string,
        nomeMae?: string,
        nomePai?: string,
        nomeProprio?: string,
        nrFactura?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        pesoMax?: number | null,
        pesoMin?: number | null,
        prestacoesEmAtrasoMax?: number,
        prestacoesEmAtrasoMin?: number,
        /**
         * A search term.
         */
        search?: string,
        seguroContratado?: string,
        suplementosList?: string,
        suplementosListNot?: string,
        tamanhoTshirt?: string,
        temDecaMenores?: boolean,
        validadeIdentificacaoAfter?: string,
        validadeIdentificacaoBefore?: string,
        valorDevidoMax?: number,
        valorDevidoMin?: number,
        valorPagoMax?: number,
        valorPagoMin?: number,
        valorTotalMax?: number,
        valorTotalMin?: number,
        vfree?: boolean,
    }): CancelablePromise<PaginatedViagemList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/viagem',
            query: {
                'actividades_list': actividadesList,
                'actividades_list_not': actividadesListNot,
                'admin_responsavel': adminResponsavel,
                'alojamento': alojamento,
                'altura_max': alturaMax,
                'altura_min': alturaMin,
                'apelido': apelido,
                'apolice_seguro': apoliceSeguro,
                'bloqueada_pagamentos': bloqueadaPagamentos,
                'calcado_max': calcadoMax,
                'calcado_min': calcadoMin,
                'campanha': campanha,
                'cancelada': cancelada,
                'chefe_de_grupo': chefeDeGrupo,
                'comercial_responsavel': comercialResponsavel,
                'contacto_casa': contactoCasa,
                'contacto_telemovel': contactoTelemovel,
                'contacto_urgencia': contactoUrgencia,
                'creation_date_after': creationDateAfter,
                'creation_date_before': creationDateBefore,
                'data_emissao_after': dataEmissaoAfter,
                'data_emissao_before': dataEmissaoBefore,
                'data_nascimento_after': dataNascimentoAfter,
                'data_nascimento_before': dataNascimentoBefore,
                'descontos_list': descontosList,
                'descontos_list_not': descontosListNot,
                'email': email,
                'email_contacto_urgencia': emailContactoUrgencia,
                'email_pais': emailPais,
                'genero_sexual': generoSexual,
                'grupo': grupo,
                'idade_no_fim_max': idadeNoFimMax,
                'idade_no_fim_min': idadeNoFimMin,
                'identificacao': identificacao,
                'instituicao': instituicao,
                'last_login_after': lastLoginAfter,
                'last_login_before': lastLoginBefore,
                'morada_codigo_postal': moradaCodigoPostal,
                'morada_localidade': moradaLocalidade,
                'morada_pais': moradaPais,
                'morada_porta': moradaPorta,
                'morada_rua': moradaRua,
                'nacionalidade': nacionalidade,
                'nao_notificar': naoNotificar,
                'nif': nif,
                'nome_completo': nomeCompleto,
                'nome_contacto_urgencia': nomeContactoUrgencia,
                'nome_mae': nomeMae,
                'nome_pai': nomePai,
                'nome_proprio': nomeProprio,
                'nr_factura': nrFactura,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'peso_max': pesoMax,
                'peso_min': pesoMin,
                'prestacoes_em_atraso_max': prestacoesEmAtrasoMax,
                'prestacoes_em_atraso_min': prestacoesEmAtrasoMin,
                'search': search,
                'seguro_contratado': seguroContratado,
                'suplementos_list': suplementosList,
                'suplementos_list_not': suplementosListNot,
                'tamanho_tshirt': tamanhoTshirt,
                'tem_deca_menores': temDecaMenores,
                'validade_identificacao_after': validadeIdentificacaoAfter,
                'validade_identificacao_before': validadeIdentificacaoBefore,
                'valor_devido_max': valorDevidoMax,
                'valor_devido_min': valorDevidoMin,
                'valor_pago_max': valorPagoMax,
                'valor_pago_min': valorPagoMin,
                'valor_total_max': valorTotalMax,
                'valor_total_min': valorTotalMin,
                'vfree': vfree,
            },
        });
    }

    /**
     * @returns Viagem
     * @throws ApiError
     */
    public static viagemCreate({
        requestBody,
    }: {
        requestBody?: ViagemRequest,
    }): CancelablePromise<Viagem> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/viagem',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Viagem
     * @throws ApiError
     */
    public static viagemRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
    }): CancelablePromise<Viagem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/viagem/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Viagem
     * @throws ApiError
     */
    public static viagemUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
        requestBody?: ViagemRequest,
    }): CancelablePromise<Viagem> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/viagem/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Viagem
     * @throws ApiError
     */
    public static viagemPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
        requestBody?: PatchedViagemRequest,
    }): CancelablePromise<Viagem> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/viagem/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static viagemDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/viagem/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Viagem
     * @throws ApiError
     */
    public static viagemBloquearFaltaPagamentoRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
    }): CancelablePromise<Viagem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/viagem/{id}/bloquear_falta_pagamento',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Viagem
     * @throws ApiError
     */
    public static viagemCancelViagemRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
    }): CancelablePromise<Viagem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/viagem/{id}/cancel_viagem',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Viagem
     * @throws ApiError
     */
    public static viagemDesbloquearFaltaPagamentoRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
    }): CancelablePromise<Viagem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/viagem/{id}/desbloquear_falta_pagamento',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Viagem
     * @throws ApiError
     */
    public static viagemMoverViajanteRetrieve({
        grupoDst,
        id,
    }: {
        grupoDst: string,
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
    }): CancelablePromise<Viagem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/viagem/{id}/mover_viajante',
            path: {
                'id': id,
            },
            query: {
                'grupo_dst': grupoDst,
            },
        });
    }

    /**
     * @returns Viagem
     * @throws ApiError
     */
    public static viagemRemoverViagemRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
    }): CancelablePromise<Viagem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/viagem/{id}/remover_viagem',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Viagem
     * @throws ApiError
     */
    public static viagemUncancelViagemRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
    }): CancelablePromise<Viagem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/viagem/{id}/uncancel_viagem',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ViagemChefe
     * @throws ApiError
     */
    public static viagemChefeUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
        requestBody?: ViagemChefeRequest,
    }): CancelablePromise<ViagemChefe> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/viagem/chefe/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ViagemChefe
     * @throws ApiError
     */
    public static viagemChefePartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
        requestBody?: PatchedViagemChefeRequest,
    }): CancelablePromise<ViagemChefe> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/viagem/chefe/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static viagemChefeDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/viagem/chefe/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ViagemDoc
     * @throws ApiError
     */
    public static viagemDocsList({
        viagem,
    }: {
        viagem?: number,
    }): CancelablePromise<Array<ViagemDoc>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/viagem/docs',
            query: {
                'viagem': viagem,
            },
        });
    }

    /**
     * @returns ViagemDoc
     * @throws ApiError
     */
    public static viagemDocsCreate({
        formData,
    }: {
        formData: ViagemDocRequest,
    }): CancelablePromise<ViagemDoc> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/viagem/docs',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns ViagemDoc
     * @throws ApiError
     */
    public static viagemDocsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this viagem doc.
         */
        id: number,
    }): CancelablePromise<ViagemDoc> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/viagem/docs/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ViagemDoc
     * @throws ApiError
     */
    public static viagemDocsUpdate({
        id,
        formData,
    }: {
        /**
         * A unique integer value identifying this viagem doc.
         */
        id: number,
        formData: ViagemDocRequest,
    }): CancelablePromise<ViagemDoc> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/viagem/docs/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns ViagemDoc
     * @throws ApiError
     */
    public static viagemDocsPartialUpdate({
        id,
        formData,
    }: {
        /**
         * A unique integer value identifying this viagem doc.
         */
        id: number,
        formData?: PatchedViagemDocRequest,
    }): CancelablePromise<ViagemDoc> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/viagem/docs/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static viagemDocsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this viagem doc.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/viagem/docs/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Viagem
     * @throws ApiError
     */
    public static viagemMapaRetrieve(): CancelablePromise<Viagem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/viagem/mapa',
        });
    }

    /**
     * ListModelMixin and GenericViewSet only Viewset
     * @returns ViagemNotificaAtraso
     * @throws ApiError
     */
    public static viagemNotificaList({
        campanha,
    }: {
        /**
         * Identifica univocamente uma campanha
         */
        campanha?: string,
    }): CancelablePromise<Array<ViagemNotificaAtraso>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/viagem/notifica',
            query: {
                'campanha': campanha,
            },
        });
    }

    /**
     * ListModelMixin and GenericViewSet only Viewset
     * @returns ViList
     * @throws ApiError
     */
    public static viagemNotificaMailClienteCreate({
        requestBody,
    }: {
        requestBody: ViListRequest,
    }): CancelablePromise<ViList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/viagem/notifica/mailCliente',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * ListModelMixin and GenericViewSet only Viewset
     * @returns ViList
     * @throws ApiError
     */
    public static viagemNotificaMailPaisCreate({
        requestBody,
    }: {
        requestBody: ViListRequest,
    }): CancelablePromise<ViList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/viagem/notifica/mailPais',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * ListModelMixin and GenericViewSet only Viewset
     * @returns ViList
     * @throws ApiError
     */
    public static viagemNotificaSmsClienteCreate({
        requestBody,
    }: {
        requestBody: ViListRequest,
    }): CancelablePromise<ViList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/viagem/notifica/smsCliente',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * ListModelMixin and GenericViewSet only Viewset
     * @returns ViList
     * @throws ApiError
     */
    public static viagemNotificaSmsPaisCreate({
        requestBody,
    }: {
        requestBody: ViListRequest,
    }): CancelablePromise<ViList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/viagem/notifica/smsPais',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ViagemQuarto
     * @throws ApiError
     */
    public static viagemQuartoList({
        grupo,
    }: {
        grupo?: string,
    }): CancelablePromise<Array<ViagemQuarto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/viagem/quarto',
            query: {
                'grupo': grupo,
            },
        });
    }

    /**
     * @returns ViagemQuarto
     * @throws ApiError
     */
    public static viagemQuartoUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
        requestBody?: ViagemQuartoRequest,
    }): CancelablePromise<ViagemQuarto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/viagem/quarto/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ViagemQuarto
     * @throws ApiError
     */
    public static viagemQuartoPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
        requestBody?: PatchedViagemQuartoRequest,
    }): CancelablePromise<ViagemQuarto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/viagem/quarto/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static viagemQuartoDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/viagem/quarto/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * ListModelMixin and GenericViewSet only Viewset
     * @returns ViagemSimples
     * @throws ApiError
     */
    public static viagemSimplesList({
        grupo,
    }: {
        grupo?: string,
    }): CancelablePromise<Array<ViagemSimples>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/viagem/simples',
            query: {
                'grupo': grupo,
            },
        });
    }

    /**
     * @returns ViagemTransporte
     * @throws ApiError
     */
    public static viagemTransporteList({
        grupo,
    }: {
        grupo?: string,
    }): CancelablePromise<Array<ViagemTransporte>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/viagem/transporte',
            query: {
                'grupo': grupo,
            },
        });
    }

    /**
     * @returns ViagemTransporte
     * @throws ApiError
     */
    public static viagemTransporteUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
        requestBody?: ViagemTransporteRequest,
    }): CancelablePromise<ViagemTransporte> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/viagem/transporte/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ViagemTransporte
     * @throws ApiError
     */
    public static viagemTransportePartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
        requestBody?: PatchedViagemTransporteRequest,
    }): CancelablePromise<ViagemTransporte> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/viagem/transporte/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static viagemTransporteDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/viagem/transporte/{id}',
            path: {
                'id': id,
            },
        });
    }

}
