import React, { ReactElement, useContext, useMemo, useState } from 'react';
import { Flex } from '@mantine/core';
import { IconHome } from '@tabler/icons-react';

type BreadItem = {
  title: string;
  href: string;
  icon?: ReactElement;
};

type Bread = {
  campanha?: BreadItem;
  grupo?: BreadItem;
  cliente?: BreadItem;
  accao?: BreadItem;
};

type BreadContextCenas = {
  setBread;
  breadpath;
  sm_breadpath;
};
export const BreadContext = React.createContext<Partial<BreadContextCenas>>({});

export function BreadProvider(props: { children: React.ReactNode }) {
  const [breadpath, setBreadPath] = useState<ReactElement[]>([]);
  const [sm_breadpath, setSMBreadPath] = useState<ReactElement[]>([]);

  const setBread = (breadIn: Bread) => {
    if (!breadIn) {
      setBreadPath([]);
      setSMBreadPath([]);
    }
    const cenas = [];
    const sm_cenas = [];
    cenas.push({
      title: '',
      href: '/',
      icon: <IconHome />,
    });
    sm_cenas.push({
      title: '',
      href: '/',
      icon: <IconHome />,
    });
    if ('campanha' in breadIn) {
      cenas.push(breadIn.campanha);
      //  sm_cenas.push(breadIn.campanha);
    }
    if ('grupo' in breadIn) {
      cenas.push(breadIn.grupo);
      sm_cenas.push(breadIn.grupo);
    }
    if ('cliente' in breadIn) {
      cenas.push(breadIn.cliente);
    }
    if ('accao' in breadIn) {
      cenas.push(breadIn.accao);
      sm_cenas.push(breadIn.accao);
    }

    const items = cenas.map((item, index) => (
      <a
        href={item.href}
        key={index}>
        <Flex
          direction={'row'}
          align='center'
          gap={'xs'}>
          {item.icon} {item.title}
        </Flex>
      </a>
    ));
    const sm_items = sm_cenas.map((item, index) => (
      <a
        href={item.href}
        key={index}>
        <Flex
          direction={'row'}
          align='center'
          gap={'xs'}>
          {item.icon} {item.title}
        </Flex>
      </a>
    ));
    setBreadPath(items);
    setSMBreadPath(sm_items);
  };

  const value = useMemo(() => {
    return {
      setBread,
      breadpath,
      sm_breadpath,
    };
  }, [breadpath]);

  return <BreadContext.Provider value={value}>{props.children}</BreadContext.Provider>;
}

export const useBread = () => useContext(BreadContext);
