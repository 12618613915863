/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Campanha } from '../models/Campanha';
import type { CampanhaColaborador } from '../models/CampanhaColaborador';
import type { CampanhaColaboradorRequest } from '../models/CampanhaColaboradorRequest';
import type { CampanhaRequest } from '../models/CampanhaRequest';
import type { DocumentoCampanha } from '../models/DocumentoCampanha';
import type { DocumentoCampanhaRequest } from '../models/DocumentoCampanhaRequest';
import type { DocumentoCondicoesCampanha } from '../models/DocumentoCondicoesCampanha';
import type { DocumentoCondicoesCampanhaRequest } from '../models/DocumentoCondicoesCampanhaRequest';
import type { PatchedCampanhaColaboradorRequest } from '../models/PatchedCampanhaColaboradorRequest';
import type { PatchedCampanhaRequest } from '../models/PatchedCampanhaRequest';
import type { PatchedDocumentoCampanhaRequest } from '../models/PatchedDocumentoCampanhaRequest';
import type { PatchedDocumentoCondicoesCampanhaRequest } from '../models/PatchedDocumentoCondicoesCampanhaRequest';
import type { PatchedPrestacaoTipoRequest } from '../models/PatchedPrestacaoTipoRequest';
import type { PrestacaoTipo } from '../models/PrestacaoTipo';
import type { PrestacaoTipoRequest } from '../models/PrestacaoTipoRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CampanhaService {

    /**
     * Gerir Campanha
     * @returns Campanha
     * @throws ApiError
     */
    public static campanhaList({
        estado,
    }: {
        /**
         * Estado da Campanha, limita as operações possíveis.
         *
         * * `Em Preparação` - Em Preparacao
         * * `Suspensa` - Suspensa
         * * `Aberta` - Activa
         * * `Finalizada` - Finalizada
         * * `Inscrições Bloqueadas` - Inscricoes Bloqueadas
         */
        estado?: 'Aberta' | 'Em Preparação' | 'Finalizada' | 'Inscrições Bloqueadas' | 'Suspensa',
    }): CancelablePromise<Array<Campanha>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/campanha',
            query: {
                'estado': estado,
            },
        });
    }

    /**
     * Gerir Campanha
     * @returns Campanha
     * @throws ApiError
     */
    public static campanhaCreate({
        requestBody,
    }: {
        requestBody: CampanhaRequest,
    }): CancelablePromise<Campanha> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/campanha',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Gerir Campanha
     * @returns Campanha
     * @throws ApiError
     */
    public static campanhaRetrieve({
        identificador,
    }: {
        identificador: string,
    }): CancelablePromise<Campanha> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/campanha/{identificador}',
            path: {
                'identificador': identificador,
            },
        });
    }

    /**
     * Gerir Campanha
     * @returns Campanha
     * @throws ApiError
     */
    public static campanhaUpdate({
        identificador,
        requestBody,
    }: {
        identificador: string,
        requestBody: CampanhaRequest,
    }): CancelablePromise<Campanha> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/campanha/{identificador}',
            path: {
                'identificador': identificador,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Gerir Campanha
     * @returns Campanha
     * @throws ApiError
     */
    public static campanhaPartialUpdate({
        identificador,
        requestBody,
    }: {
        identificador: string,
        requestBody?: PatchedCampanhaRequest,
    }): CancelablePromise<Campanha> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/campanha/{identificador}',
            path: {
                'identificador': identificador,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Gerir Campanha
     * @returns void
     * @throws ApiError
     */
    public static campanhaDestroy({
        identificador,
    }: {
        identificador: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/campanha/{identificador}',
            path: {
                'identificador': identificador,
            },
        });
    }

    /**
     * Gerir Campanha
     * @returns Campanha
     * @throws ApiError
     */
    public static campanhaBloquearFaltaPagamentosRetrieve({
        ichefe,
        identificador,
        valor,
    }: {
        ichefe: boolean,
        identificador: string,
        valor: number,
    }): CancelablePromise<Campanha> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/campanha/{identificador}/bloquear_falta_pagamentos',
            path: {
                'identificador': identificador,
            },
            query: {
                'ichefe': ichefe,
                'valor': valor,
            },
        });
    }

    /**
     * Gerir Campanha
     * @returns Campanha
     * @throws ApiError
     */
    public static campanhaChefeEditavelNenhumRetrieve({
        identificador,
    }: {
        identificador: string,
    }): CancelablePromise<Campanha> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/campanha/{identificador}/chefe_editavel_nenhum',
            path: {
                'identificador': identificador,
            },
        });
    }

    /**
     * Gerir Campanha
     * @returns Campanha
     * @throws ApiError
     */
    public static campanhaChefeEditavelTodosRetrieve({
        identificador,
    }: {
        identificador: string,
    }): CancelablePromise<Campanha> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/campanha/{identificador}/chefe_editavel_todos',
            path: {
                'identificador': identificador,
            },
        });
    }

    /**
     * Gerir Campanha
     * @returns Campanha
     * @throws ApiError
     */
    public static campanhaDesbloquearFaltaDePagamentoRetrieve({
        ichefe,
        identificador,
        valor,
    }: {
        ichefe: boolean,
        identificador: string,
        valor: number,
    }): CancelablePromise<Campanha> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/campanha/{identificador}/desbloquear_falta_de_pagamento',
            path: {
                'identificador': identificador,
            },
            query: {
                'ichefe': ichefe,
                'valor': valor,
            },
        });
    }

    /**
     * Gerir Campanha
     * @returns Campanha
     * @throws ApiError
     */
    public static campanhaGruposActivoToBlockinscRetrieve({
        identificador,
    }: {
        identificador: string,
    }): CancelablePromise<Campanha> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/campanha/{identificador}/grupos_activo_to_blockinsc',
            path: {
                'identificador': identificador,
            },
        });
    }

    /**
     * Gerir Campanha
     * @returns Campanha
     * @throws ApiError
     */
    public static campanhaGruposBlockinscToActivoRetrieve({
        identificador,
    }: {
        identificador: string,
    }): CancelablePromise<Campanha> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/campanha/{identificador}/grupos_blockinsc_to_activo',
            path: {
                'identificador': identificador,
            },
        });
    }

    /**
     * Gerir Campanha
     * @returns Campanha
     * @throws ApiError
     */
    public static campanhaMapaPagGrupoRetrieve({
        identificador,
        endDate,
        startDate,
    }: {
        identificador: string,
        endDate?: string,
        startDate?: string,
    }): CancelablePromise<Campanha> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/campanha/{identificador}/mapa_pag_grupo',
            path: {
                'identificador': identificador,
            },
            query: {
                'end_date': endDate,
                'start_date': startDate,
            },
        });
    }

    /**
     * Gerir Campanha
     * @returns Campanha
     * @throws ApiError
     */
    public static campanhaMapaQuartosRetrieve({
        identificador,
    }: {
        identificador: string,
    }): CancelablePromise<Campanha> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/campanha/{identificador}/mapa_quartos',
            path: {
                'identificador': identificador,
            },
        });
    }

    /**
     * Gerir Campanha
     * @returns Campanha
     * @throws ApiError
     */
    public static campanhaMapaTransportesRetrieve({
        identificador,
    }: {
        identificador: string,
    }): CancelablePromise<Campanha> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/campanha/{identificador}/mapa_transportes',
            path: {
                'identificador': identificador,
            },
        });
    }

    /**
     * Gerir Campanha
     * @returns Campanha
     * @throws ApiError
     */
    public static campanhaQuartosNenhumRetrieve({
        identificador,
    }: {
        identificador: string,
    }): CancelablePromise<Campanha> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/campanha/{identificador}/quartos_nenhum',
            path: {
                'identificador': identificador,
            },
        });
    }

    /**
     * Gerir Campanha
     * @returns Campanha
     * @throws ApiError
     */
    public static campanhaQuartosTodosRetrieve({
        identificador,
    }: {
        identificador: string,
    }): CancelablePromise<Campanha> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/campanha/{identificador}/quartos_todos',
            path: {
                'identificador': identificador,
            },
        });
    }

    /**
     * Gerir Campanha
     * @returns Campanha
     * @throws ApiError
     */
    public static campanhaTransportesNenhumRetrieve({
        identificador,
    }: {
        identificador: string,
    }): CancelablePromise<Campanha> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/campanha/{identificador}/transportes_nenhum',
            path: {
                'identificador': identificador,
            },
        });
    }

    /**
     * Gerir Campanha
     * @returns Campanha
     * @throws ApiError
     */
    public static campanhaTransportesTodosRetrieve({
        identificador,
    }: {
        identificador: string,
    }): CancelablePromise<Campanha> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/campanha/{identificador}/transportes_todos',
            path: {
                'identificador': identificador,
            },
        });
    }

    /**
     * @returns CampanhaColaborador
     * @throws ApiError
     */
    public static campanhaColaboradoresList({
        campanha,
    }: {
        /**
         * Identifica univocamente uma campanha
         */
        campanha?: string,
    }): CancelablePromise<Array<CampanhaColaborador>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/campanha/colaboradores',
            query: {
                'campanha': campanha,
            },
        });
    }

    /**
     * @returns CampanhaColaborador
     * @throws ApiError
     */
    public static campanhaColaboradoresCreate({
        requestBody,
    }: {
        requestBody: CampanhaColaboradorRequest,
    }): CancelablePromise<CampanhaColaborador> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/campanha/colaboradores',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CampanhaColaborador
     * @throws ApiError
     */
    public static campanhaColaboradoresRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this staff campanha.
         */
        id: number,
    }): CancelablePromise<CampanhaColaborador> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/campanha/colaboradores/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CampanhaColaborador
     * @throws ApiError
     */
    public static campanhaColaboradoresUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this staff campanha.
         */
        id: number,
        requestBody: CampanhaColaboradorRequest,
    }): CancelablePromise<CampanhaColaborador> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/campanha/colaboradores/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CampanhaColaborador
     * @throws ApiError
     */
    public static campanhaColaboradoresPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this staff campanha.
         */
        id: number,
        requestBody?: PatchedCampanhaColaboradorRequest,
    }): CancelablePromise<CampanhaColaborador> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/campanha/colaboradores/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static campanhaColaboradoresDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this staff campanha.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/campanha/colaboradores/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Documentos da Campanha
     * @returns DocumentoCampanha
     * @throws ApiError
     */
    public static campanhaDocsList({
        campanha,
    }: {
        /**
         * Identifica univocamente uma campanha
         */
        campanha?: string,
    }): CancelablePromise<Array<DocumentoCampanha>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/campanha/docs',
            query: {
                'campanha': campanha,
            },
        });
    }

    /**
     * Documentos da Campanha
     * @returns DocumentoCampanha
     * @throws ApiError
     */
    public static campanhaDocsCreate({
        formData,
    }: {
        formData: DocumentoCampanhaRequest,
    }): CancelablePromise<DocumentoCampanha> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/campanha/docs',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Documentos da Campanha
     * @returns DocumentoCampanha
     * @throws ApiError
     */
    public static campanhaDocsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this Campanha.
         */
        id: number,
    }): CancelablePromise<DocumentoCampanha> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/campanha/docs/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Documentos da Campanha
     * @returns DocumentoCampanha
     * @throws ApiError
     */
    public static campanhaDocsUpdate({
        id,
        formData,
    }: {
        /**
         * A unique integer value identifying this Campanha.
         */
        id: number,
        formData: DocumentoCampanhaRequest,
    }): CancelablePromise<DocumentoCampanha> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/campanha/docs/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Documentos da Campanha
     * @returns DocumentoCampanha
     * @throws ApiError
     */
    public static campanhaDocsPartialUpdate({
        id,
        formData,
    }: {
        /**
         * A unique integer value identifying this Campanha.
         */
        id: number,
        formData?: PatchedDocumentoCampanhaRequest,
    }): CancelablePromise<DocumentoCampanha> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/campanha/docs/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Documentos da Campanha
     * @returns void
     * @throws ApiError
     */
    public static campanhaDocsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this Campanha.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/campanha/docs/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Documentos de Condições da Campanha
     * @returns DocumentoCondicoesCampanha
     * @throws ApiError
     */
    public static campanhaDocsCondicoesList({
        campanha,
    }: {
        /**
         * Identifica univocamente uma campanha
         */
        campanha?: string,
    }): CancelablePromise<Array<DocumentoCondicoesCampanha>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/campanha/docs/condicoes',
            query: {
                'campanha': campanha,
            },
        });
    }

    /**
     * Documentos de Condições da Campanha
     * @returns DocumentoCondicoesCampanha
     * @throws ApiError
     */
    public static campanhaDocsCondicoesCreate({
        formData,
    }: {
        formData: DocumentoCondicoesCampanhaRequest,
    }): CancelablePromise<DocumentoCondicoesCampanha> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/campanha/docs/condicoes',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Documentos de Condições da Campanha
     * @returns DocumentoCondicoesCampanha
     * @throws ApiError
     */
    public static campanhaDocsCondicoesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this Campanha.
         */
        id: number,
    }): CancelablePromise<DocumentoCondicoesCampanha> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/campanha/docs/condicoes/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Documentos de Condições da Campanha
     * @returns DocumentoCondicoesCampanha
     * @throws ApiError
     */
    public static campanhaDocsCondicoesUpdate({
        id,
        formData,
    }: {
        /**
         * A unique integer value identifying this Campanha.
         */
        id: number,
        formData: DocumentoCondicoesCampanhaRequest,
    }): CancelablePromise<DocumentoCondicoesCampanha> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/campanha/docs/condicoes/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Documentos de Condições da Campanha
     * @returns DocumentoCondicoesCampanha
     * @throws ApiError
     */
    public static campanhaDocsCondicoesPartialUpdate({
        id,
        formData,
    }: {
        /**
         * A unique integer value identifying this Campanha.
         */
        id: number,
        formData?: PatchedDocumentoCondicoesCampanhaRequest,
    }): CancelablePromise<DocumentoCondicoesCampanha> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/campanha/docs/condicoes/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Documentos de Condições da Campanha
     * @returns void
     * @throws ApiError
     */
    public static campanhaDocsCondicoesDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this Campanha.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/campanha/docs/condicoes/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PrestacaoTipo
     * @throws ApiError
     */
    public static campanhaPrestacaotipoList({
        campanha,
    }: {
        /**
         * Identifica univocamente uma campanha
         */
        campanha?: string,
    }): CancelablePromise<Array<PrestacaoTipo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/campanha/prestacaotipo',
            query: {
                'campanha': campanha,
            },
        });
    }

    /**
     * @returns PrestacaoTipo
     * @throws ApiError
     */
    public static campanhaPrestacaotipoCreate({
        requestBody,
    }: {
        requestBody: PrestacaoTipoRequest,
    }): CancelablePromise<PrestacaoTipo> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/campanha/prestacaotipo',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PrestacaoTipo
     * @throws ApiError
     */
    public static campanhaPrestacaotipoRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this Prestação Tipo.
         */
        id: number,
    }): CancelablePromise<PrestacaoTipo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/campanha/prestacaotipo/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PrestacaoTipo
     * @throws ApiError
     */
    public static campanhaPrestacaotipoUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Prestação Tipo.
         */
        id: number,
        requestBody: PrestacaoTipoRequest,
    }): CancelablePromise<PrestacaoTipo> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/campanha/prestacaotipo/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PrestacaoTipo
     * @throws ApiError
     */
    public static campanhaPrestacaotipoPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Prestação Tipo.
         */
        id: number,
        requestBody?: PatchedPrestacaoTipoRequest,
    }): CancelablePromise<PrestacaoTipo> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/campanha/prestacaotipo/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static campanhaPrestacaotipoDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this Prestação Tipo.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/campanha/prestacaotipo/{id}',
            path: {
                'id': id,
            },
        });
    }

}
