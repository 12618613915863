import React, { useContext, useEffect, useState } from 'react';
import { useAuth } from '../../user-auth/SlideInAuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { MenuContext } from '../../layout/context/MenuContext';
import { useBread } from '../../layout/context/BreadContext';
import { homeMenu } from '../Home';
import {
  Colaborador,
  ColaboradorOwn,
  ColaboradorOwnRequest,
  ColaboradorRequest,
  ColaboradorService,
  GeneroSexualEnum,
  SlideinFuncaoEnum,
  SlideinRoleEnum,
  TipoIdEnum,
} from '../../slidein_api';
import {
  Badge,
  Button,
  Center,
  Checkbox,
  Container,
  Divider,
  Flex,
  Group,
  Loader,
  PasswordInput,
  Select,
  Stack,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import {
  IconAt,
  IconBuildingCommunity,
  IconCake,
  IconDeviceFloppy,
  IconDoor,
  IconDoorExit,
  IconFaceId,
  IconFlag,
  IconFlag3,
  IconGenderBigender,
  IconHome2,
  IconIdBadge,
  IconIdBadge2,
  IconLock,
  IconNote,
  IconNotes,
  IconNumber,
  IconTimeDuration0,
  IconUserEdit,
  IconX,
} from '@tabler/icons-react';
import { useForm, yupResolver } from '@mantine/form';
import { msgAPIError } from '../helpers';
import { DatePickerInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { MantinePhoneInput } from '../../components/MantinePhoneInput';
import { modals } from '@mantine/modals';

export function EditColaborador(props: { create: boolean; own: boolean }) {
  const { setMenuItems } = useContext(MenuContext);
  const { setBread } = useBread();
  const { colaborador, logout } = useAuth();
  const { colaboradorID } = useParams();
  const [editColaborador, setEditColaborador] = useState<
    Colaborador | ColaboradorRequest | ColaboradorOwn | ColaboradorOwnRequest
  >();

  useEffect(() => {
    setMenuItems(homeMenu);
  }, []);
  useEffect(() => {
    if (props.own) {
      setBread({ accao: { title: 'O meu Perfil', icon: <IconUserEdit /> } });
    } else if (props.create) {
      setBread({
        accao: { title: 'Novo colaborador', icon: <IconUserEdit /> },
      });
    } else {
      setBread({
        cliente: {
          title: editColaborador ? editColaborador['nome_simples'] : '-',
          icon: <IconUserEdit />,
        },
        accao: { title: 'Editar', icon: <IconUserEdit /> },
      });
    }
  }, [editColaborador]);

  const navigate = useNavigate();

  const [idade, setIdade] = useState<number | undefined>(undefined);

  const passportSchema = Yup.object;

  const newvalYUP = Yup.object({
    email: Yup.string().email().required('Obrigatório'),
    nome_proprio: Yup.string().required('Obrigatório'),
    apelido: Yup.string().required('Obrigatório'),
    password: Yup.string().required('Obrigatorio'),
    re_password: Yup.string().required('Obrigatorio'),
    slidein_funcao: Yup.string().required('Obrigatorio'),
    data_nascimento: Yup.date().required('Obrigatório'),
    genero_sexual: Yup.string().required('Obrigatorio'),
    nif: Yup.string().required('Obrigatorio'),
    tipo_id: Yup.string().required('Obrigatório'),
    data_emissao: Yup.date()
      .nullable()
      .when('tipo_id', {
        is: TipoIdEnum.PASSAPORTE,
        then: (schema) => schema.required('Obrigatório'),
      }),
    id_emissor: Yup.string().when('tipo_id', {
      is: TipoIdEnum.PASSAPORTE,
      then: (schema) => schema.required('Obrigatório'),
    }),
    identificacao: Yup.string().required('Obrigatorio'),
    validade_identificacao: Yup.date().required('Obrigatório'),
    nacionalidade: Yup.string().required('Obrigatorio'),
    contacto_telemovel: Yup.string().required('Obrigatorio'),
    morada_rua: Yup.string().required('Obrigatorio'),
    morada_porta: Yup.string().required('Obrigatorio'),
    morada_codigo_postal: Yup.string().required('Obrigatorio'),
    morada_localidade: Yup.string().required('Obrigatorio'),
    contacto_urgencia: Yup.string().min(6).required('Obrigatorio'),
    nome_contacto_urgencia: Yup.string().required('Obrigatorio'),
    email_contacto_urgencia: Yup.string().email().required('Obrigatorio'),
  });
  const editvalUP = Yup.object({
    email: Yup.string().email().required('Obrigatório'),
    nome_proprio: Yup.string().required('Obrigatório'),
    apelido: Yup.string().required('Obrigatório'),
    slidein_funcao: Yup.string().required('Obrigatorio'),
    data_nascimento: Yup.date().required('Obrigatório'),
    genero_sexual: Yup.string().required('Obrigatorio'),
    nif: Yup.string().required('Obrigatorio'),
    identificacao: Yup.string().required('Obrigatório'),
    tipo_id: Yup.string().required('Obrigatório'),
    data_emissao: Yup.date()
      .nullable()
      .when('tipo_id', {
        is: TipoIdEnum.PASSAPORTE,
        then: (schema) => schema.required('Obrigatório'),
      }),
    id_emissor: Yup.string().when('tipo_id', {
      is: TipoIdEnum.PASSAPORTE,
      then: (schema) => schema.required('Obrigatório'),
    }),
    validade_identificacao: Yup.date().required('Obrigatório'),
    nacionalidade: Yup.string().required('Obrigatorio'),
    contacto_telemovel: Yup.string().required('Obrigatorio'),
    morada_rua: Yup.string().required('Obrigatorio'),
    morada_porta: Yup.string().required('Obrigatorio'),
    morada_codigo_postal: Yup.string().required('Obrigatorio'),
    morada_localidade: Yup.string().required('Obrigatorio'),
    contacto_urgencia: Yup.string().required('Obrigatorio'),
    nome_contacto_urgencia: Yup.string().required('Obrigatorio'),
    email_contacto_urgencia: Yup.string().email().required('Obrigatorio'),
  });

  const colaboradorForm = useForm<ColaboradorRequest | ColaboradorOwnRequest>({
    initialValues: editColaborador,

    validate: yupResolver(props.create ? newvalYUP : editvalUP),

    transformValues: (values) => {
      const dn = values.data_nascimento ? new Date(values.data_nascimento) : null;
      const dvi = values.validade_identificacao ? new Date(values.validade_identificacao) : null;

      return {
        ...values,
        data_nascimento: dn
          ? `${dn.getFullYear()}-${(dn.getMonth() + 1).toString().padStart(2, '0')}-${dn
              .getDate()
              .toString()
              .padStart(2, '0')}`
          : '',
        validade_identificacao: dvi
          ? `${dvi.getFullYear()}-${(dvi.getMonth() + 1).toString().padStart(2, '0')}-${dvi
              .getDate()
              .toString()
              .padStart(2, '0')}`
          : '',
      };
    },
  });

  const saveColaborador = (user: ColaboradorRequest | ColaboradorOwnRequest) => {
    let req: Promise<Colaborador>;
    if (user.data_nascimento == '') {
      delete user['data_nascimento'];
    }
    if (user.validade_identificacao == '') {
      delete user['validade_cc'];
    }
    if (props.create) {
      if (props.own) {
        req = ColaboradorService.colaboradorOwnCreate({ requestBody: user });
      } else {
        req = ColaboradorService.colaboradorCreate({ requestBody: user });
      }
    } else if (props.own) {
      req = ColaboradorService.colaboradorOwnPartialUpdate({
        id: (user as Colaborador).id,
        requestBody: user,
      });
    } else {
      req = ColaboradorService.colaboradorPartialUpdate({
        id: (user as Colaborador).id,
        requestBody: user,
      });
    }
    if (req != undefined) {
      req.then(
        (value) => {
          if (
            value?.slidein_role == SlideinRoleEnum.TRAVEL_STAFF &&
            (colaborador?.slidein_role == SlideinRoleEnum.TRAVEL_STAFF || !colaborador)
          ) {
            modals.open({
              title: props.create ? 'Utilizador Criado!' : 'Utilizador Actualizado',
              children: (
                <Stack>
                  <Text>Pode fazer Login com as credencias definidas</Text>
                  <Button
                    variant={'outline'}
                    leftIcon={<IconDoorExit />}
                    onClick={() => {
                      modals.closeAll();
                      logout();
                      navigate('/');
                    }}>
                    Sair
                  </Button>
                </Stack>
              ),
            });
          } else {
            navigate(-1);
          }
        },
        (reason) => {
          colaboradorForm.setErrors(reason.body);
        }
      );
    }
  };

  useEffect(() => {
    if (!colaborador || props.create) {
      if (props.own) {
        setEditColaborador({
          password: '',
          re_password: '',
          slidein_funcao: null,
          email: '',
          nome_proprio: '',
          apelido: '',
          data_nascimento: null,
          genero_sexual: null,
          nif: '',
          identificacao: '',
          validade_identificacao: null,
          nacionalidade: '',
          contacto_telemovel: '',
          contacto_casa: '',
          morada_rua: '',
          morada_porta: '',
          morada_codigo_postal: '',
          morada_localidade: '',
          morada_pais: '',
          contacto_urgencia: '',
          email_contacto_urgencia: '',
          nome_contacto_urgencia: '',
        } as ColaboradorOwnRequest);
      } else {
        setEditColaborador({
          password: '',
          re_password: '',
          slidein_funcao: null,
          email: '',
          nome_proprio: '',
          apelido: '',
          data_nascimento: null,
          genero_sexual: null,
          nif: '',
          identificacao: '',
          validade_identificacao: null,
          nacionalidade: '',
          contacto_telemovel: '',
          contacto_casa: '',
          morada_rua: '',
          morada_porta: '',
          morada_codigo_postal: '',
          morada_localidade: '',
          morada_pais: '',
          contacto_urgencia: '',
          email_contacto_urgencia: '',
          nome_contacto_urgencia: '',
        });
      }
      return;
    }
    if (props.own) {
      ColaboradorService.colaboradorOwnMeRetrieve().then((colab) => {
        colaboradorForm.setValues(colab);
        if (colab.data_nascimento) {
          setIdade(getAge(dayjs(colab.data_nascimento).format('YYYY-MM-DD')));
        }
        setEditColaborador(colab);
      });
    } else {
      ColaboradorService.colaboradorRetrieve({ id: parseInt(colaboradorID) }).then(
        (pvalue) => {
          colaboradorForm.setValues(pvalue);
          if (pvalue.data_nascimento) {
            setIdade(getAge(dayjs(pvalue.data_nascimento).format('YYYY-MM-DD')));
          } else {
            pvalue.data_nascimento = '';
          }
          setEditColaborador(pvalue);
        },
        (reason) => {
          msgAPIError(reason);
        }
      );
    }
  }, []);

  const getAge = (birthDateStr: string) => {
    const today = new Date();
    const birthDate = new Date(birthDateStr);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  useEffect(() => {
    if (colaboradorForm.values.data_nascimento) {
      setIdade(getAge(dayjs(colaboradorForm.values.data_nascimento).format('YYYY-MM-DD')));
    }
  }, [colaboradorForm.values.data_nascimento]);

  if (!editColaborador) {
    return (
      <Center>
        <Loader
          size={'xl'}
          variant={'dots'}></Loader>
      </Center>
    );
  }

  return (
    <Container p={'xs'}>
      <Title order={2}>{props.create ? 'Novo Colaborador ' : 'Editar Colaborador '}</Title>
      <form onSubmit={colaboradorForm.onSubmit(saveColaborador)}>
        <Title order={4}>Credenciais</Title>
        <TextInput
          label={'Email'}
          disabled={props.own && !props.create}
          required
          icon={<IconAt />}
          {...colaboradorForm.getInputProps('email')}
        />
        <PasswordInput
          label={'Password'}
          required={props.create}
          icon={<IconLock />}
          {...colaboradorForm.getInputProps('password')}
        />
        <PasswordInput
          label={'Repetir Password'}
          required={props.create}
          icon={<IconLock />}
          {...colaboradorForm.getInputProps('re_password')}
        />
        <Title order={3}>Funções SlideIn</Title>
        {!props.own && (
          <Checkbox
            mt={'lg'}
            labelPosition='left'
            label='Activo'
            color='green'
            radius='md'
            size='lg'
            {...colaboradorForm.getInputProps('is_active', { type: 'checkbox' })}
          />
        )}

        {!props.own && (
          <Select
            label='Cargo na Plataforma/ Permissões:'
            required
            disabled={props.own}
            placeholder='escolher'
            {...colaboradorForm.getInputProps('slidein_role')}
            data={Object.keys(SlideinRoleEnum).map((x) => {
              return {
                label: SlideinRoleEnum[x as keyof typeof SlideinRoleEnum],
                value: SlideinRoleEnum[x as keyof typeof SlideinRoleEnum],
              };
            })}
          />
        )}
        <Select
          label='Função:'
          required
          icon={<IconNote />}
          placeholder='escolher'
          {...colaboradorForm.getInputProps('slidein_funcao')}
          data={Object.keys(SlideinFuncaoEnum).map((x) => {
            return {
              label: SlideinFuncaoEnum[x as keyof typeof SlideinFuncaoEnum],
              value: SlideinFuncaoEnum[x as keyof typeof SlideinFuncaoEnum],
            };
          })}
        />
        {!props.own && (
          <Textarea
            placeholder='notas...'
            label='Notas:'
            size='md'
            icon={<IconNotes />}
            autosize={true}
            {...colaboradorForm.getInputProps('notas')}
          />
        )}

        <Title
          order={3}
          mt={'md'}>
          Informação Pessoal
        </Title>
        <Flex
          direction='row'
          gap={'xs'}
          justify={'space-between'}
          wrap={'wrap'}>
          <TextInput
            disabled={props.own && !props.create}
            w={{ base: '100%', xs: '49%' }}
            required
            label={'Nomes Próprios'}
            icon={<IconUserEdit />}
            {...colaboradorForm.getInputProps('nome_proprio')}
          />
          <TextInput
            w={{ base: '100%', xs: '49%' }}
            disabled={props.own && !props.create}
            required
            label={'Apelidos'}
            icon={<IconUserEdit />}
            {...colaboradorForm.getInputProps('apelido')}
          />
        </Flex>
        <Flex
          direction='row'
          justify={'space-between'}
          wrap={'wrap'}>
          <Select
            label={'Género'}
            autoComplete={'sex'}
            required
            w={{ base: '100%', xs: '49%' }}
            icon={<IconGenderBigender />}
            data={Object.keys(GeneroSexualEnum).map((x) => {
              return {
                label: GeneroSexualEnum[x as keyof typeof GeneroSexualEnum],
                value: GeneroSexualEnum[x as keyof typeof GeneroSexualEnum],
              };
            })}
            {...colaboradorForm.getInputProps('genero_sexual')}
          />
          <>
            <DatePickerInput
              w={{ base: '50%', xs: '29%' }}
              label='Data de Nascimento'
              locale={'pt'}
              firstDayOfWeek={1}
              id={'data_nascimento'}
              name={'data_nascimento'}
              required
              placeholder='Escolher Data'
              defaultDate={null}
              maxDate={new Date()}
              icon={<IconCake stroke={1.5} />}
              valueFormat='DD/MM/YYYY'
              {...colaboradorForm.getInputProps('data_nascimento')}
              value={
                colaboradorForm.values.data_nascimento
                  ? dayjs(colaboradorForm.values.data_nascimento).toDate()
                  : null
              }
            />
            <Badge
              w={{ base: '50%', xs: '16%' }}
              size='lg'
              mt={'xl'}
              color={idade <= 14 ? 'red' : 'slideinGreen2'}
              radius='md'>
              Idade {idade}
            </Badge>
          </>
        </Flex>
        <Divider
          label={'Identificação'}
          labelPosition='center'
        />
        <Flex
          direction='row'
          justify={'space-between'}
          wrap={'wrap'}>
          <Select
            label={'Tipo de ID'}
            w={{ base: '100%', xs: '25%' }}
            icon={<IconIdBadge2 />}
            data={Object.keys(TipoIdEnum).map((x) => {
              return {
                label: TipoIdEnum[x as keyof typeof TipoIdEnum],
                value: TipoIdEnum[x as keyof typeof TipoIdEnum],
              };
            })}
            {...colaboradorForm.getInputProps('tipo_id')}
          />
          <TextInput
            label={'Nrº Doc. de Identificação (CC, Passaporte,...)'}
            w={{ base: '100%', xs: '50%' }}
            icon={<IconFaceId />}
            {...colaboradorForm.getInputProps('identificacao')}
          />
          <DatePickerInput
            label='Validade do Doc. Id.'
            id={'validade_identificacao'}
            w={{ base: '100%', xs: '20%' }}
            locale={'pt'}
            firstDayOfWeek={1}
            placeholder='Escolher Data'
            minDate={new Date()}
            defaultDate={new Date()}
            icon={<IconFaceId stroke={1.5} />}
            valueFormat='DD/MM/YYYY'
            {...colaboradorForm.getInputProps('validade_identificacao')}
            value={
              colaboradorForm.values.validade_identificacao
                ? dayjs(colaboradorForm.values.validade_identificacao).toDate()
                : null
            }
          />
        </Flex>
        {colaboradorForm.values.tipo_id == TipoIdEnum.PASSAPORTE && (
          <Flex
            direction='row'
            justify={'space-between'}
            wrap={'wrap'}>
            <TextInput
              label={'Doc. Identificação emitido por:'}
              w={{ base: '100%', xs: '49%' }}
              icon={<IconFaceId />}
              {...colaboradorForm.getInputProps('id_emissor')}
            />
            <DatePickerInput
              label='Data_emissao'
              id={'data_emissao'}
              w={{ base: '100%', xs: '49%' }}
              locale={'pt'}
              firstDayOfWeek={1}
              placeholder='Escolher Data'
              maxDate={new Date()}
              defaultDate={new Date()}
              icon={<IconTimeDuration0 stroke={1.5} />}
              valueFormat='DD/MM/YYYY'
              {...colaboradorForm.getInputProps('data_emissao')}
              value={
                colaboradorForm.values.data_emissao
                  ? dayjs(colaboradorForm.values.data_emissao).toDate()
                  : null
              }
            />
          </Flex>
        )}
        <Divider my={'md'} />
        <Flex
          direction='row'
          justify={'space-between'}
          wrap={'wrap'}>
          <TextInput
            disabled={props.own && !props.create}
            label={'Nrº Identificação Fiscal (NIF)'}
            w={{ base: '100%', xs: '49%' }}
            required
            icon={<IconIdBadge />}
            {...colaboradorForm.getInputProps('nif')}
          />
          <TextInput
            disabled={props.own && !props.create}
            label={'Nacionalidade'}
            w={{ base: '100%', xs: '49%' }}
            required
            icon={<IconFlag3 />}
            {...colaboradorForm.getInputProps('nacionalidade')}
          />
        </Flex>
        <Flex
          direction='row'
          justify={'space-between'}
          wrap={'wrap'}>
          <MantinePhoneInput
            w={{ base: '100%', xs: '49%' }}
            label={'Número de Telémovel.'}
            required
            {...colaboradorForm.getInputProps('contacto_telemovel')}
          />
          <MantinePhoneInput
            label={'Número de Telefone Casa.'}
            w={{ base: '100%', xs: '49%' }}
            {...colaboradorForm.getInputProps('contacto_casa')}
          />
        </Flex>
        <Title
          order={3}
          mt={'md'}>
          Morada
        </Title>
        <Flex
          direction='row'
          justify={'space-between'}
          wrap={'wrap'}>
          <TextInput
            w={{ base: '100%', xs: '70%' }}
            label={'Rua/Ave...'}
            required
            icon={<IconHome2 />}
            {...colaboradorForm.getInputProps('morada_rua')}
          />
          <TextInput
            w={{ base: '100%', xs: '29%' }}
            label={'Nrº Porta/ Andar'}
            icon={<IconDoor />}
            required
            {...colaboradorForm.getInputProps('morada_porta')}
          />
        </Flex>
        <Flex
          direction='row'
          justify={'space-between'}
          wrap={'wrap'}>
          <TextInput
            label={'Codigo Postal'}
            w={{ base: '100%', xs: '33%' }}
            required
            icon={<IconNumber />}
            {...colaboradorForm.getInputProps('morada_codigo_postal')}
          />
          <TextInput
            label={'Localidade'}
            w={{ base: '100%', xs: '32%' }}
            icon={<IconBuildingCommunity />}
            required
            {...colaboradorForm.getInputProps('morada_localidade')}
          />
          <TextInput
            label={'País.'}
            w={{ base: '100%', xs: '32%' }}
            icon={<IconFlag />}
            required
            {...colaboradorForm.getInputProps('morada_pais')}
          />
        </Flex>

        <Title
          order={3}
          mt={'md'}>
          Contactos Urgência/Familia
        </Title>
        <Flex
          direction='row'
          justify={'space-between'}
          wrap={'wrap'}>
          <MantinePhoneInput
            label={'Número de Contacto Urgência.'}
            w={{ base: '100%', xs: '49%' }}
            {...colaboradorForm.getInputProps('contacto_urgencia')}
          />
          <TextInput
            w={{ base: '100%', xs: '49%' }}
            label={'Nome do Contacto Urgência.'}
            {...colaboradorForm.getInputProps('nome_contacto_urgencia')}
          />
          <TextInput
            w={{ base: '100%', xs: '100%%' }}
            label={'Email do Contacto de Urgência'}
            type={'email'}
            icon={<IconFaceId />}
            {...colaboradorForm.getInputProps('email_contacto_urgencia')}
          />
        </Flex>
        <Textarea
          size='md'
          icon={<IconNotes />}
          autosize={true}
          label={'Notas importantes (Doenças, Alergias, etc...):'}
          {...colaboradorForm.getInputProps('notas_cliente')}
        />

        {colaborador && colaborador.slidein_role != SlideinRoleEnum.TRAVEL_STAFF ? (
          <Group
            position='right'
            mt='md'>
            <Button
              color={'yellow'}
              variant={'outline'}
              leftIcon={<IconX />}
              type='button'
              onClick={() => navigate(-1)}>
              Cancelar
            </Button>
            <Button
              type='submit'
              variant={'outline'}
              leftIcon={<IconDeviceFloppy />}>
              {props.create ? 'Criar Colaborador SlideIn' : 'Guardar Alterações'}
            </Button>
          </Group>
        ) : (
          <Group
            position='right'
            mt='md'>
            <Button
              color={'yellow'}
              variant={'outline'}
              leftIcon={<IconX />}
              type='button'
              onClick={() => {
                logout();
                navigate('/');
              }}>
              Cancelar e Sair
            </Button>
            <Button
              variant={'outline'}
              leftIcon={<IconDeviceFloppy />}
              type='submit'>
              {props.create ? 'Criar Colaborador SlideIn' : 'Guardar Alterações e Sair'}
            </Button>
          </Group>
        )}
      </form>
    </Container>
  );
}
