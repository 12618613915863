import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ApiError, AuthService, ColaboradorOwn, ColaboradorService } from '../slidein_api';
import axios from 'axios';
import { msgAPIError } from '../pages/helpers';

type Token = {
  access?: string;
  refresh?: string;
};

type AuthContextProps = {
  isAuthenticated: boolean;
  authLoading: boolean;
  token: () => Token;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => void;
  colaborador: ColaboradorOwn;
  setColaborador: (colaborador: ColaboradorOwn) => void;
};

const AuthContext = React.createContext<Partial<AuthContextProps>>({});

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps): React.ReactElement => {
  const [authLoading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [colaborador, setColaborador] = useState<ColaboradorOwn>(undefined);

  const token = () => {
    const stToken = JSON.parse(localStorage.getItem('kunami'));
    if (stToken) {
      setIsAuthenticated(true);
      setLoading(false);
      return stToken;
    } else {
      setIsAuthenticated(false);
      setLoading(false);
      return null;
    }
  };

  useEffect(() => {
    token();
    if (isAuthenticated) {
      ColaboradorService.colaboradorOwnMeRetrieve().then(
        (value1) => setColaborador(value1),
        (reason: ApiError) => {
          msgAPIError(reason);
          logout();
        }
      );
    }
  }, [isAuthenticated]);
  const login = async (email: string, password: string): Promise<boolean> => {
    setLoading(true);
    return AuthService.authLoginCreate({
      requestBody: { email: email, password: password },
    }).then(
      (data) => {
        localStorage.setItem('kunami', JSON.stringify(data));
        setIsAuthenticated(true);
        setLoading(false);

        return true;
      },
      () => {
        setIsAuthenticated(false);
        localStorage.removeItem('kunami');
        setLoading(false);
        return false;
      }
    );
  };

  const logout = (): void => {
    setIsAuthenticated(false);
    setColaborador(undefined);
    localStorage.removeItem('kunami');
    setLoading(false);
  };

  const value = useMemo(() => {
    return {
      isAuthenticated,
      authLoading,
      token,
      login,
      logout,
      colaborador,
      setColaborador,
    };
  }, [isAuthenticated, colaborador, token, authLoading, login, logout, setColaborador]);

  interface QueueItem {
    resolve: (value?: string | PromiseLike<string> | undefined) => void;
    reject: (reason?) => void;
  }

  let isRefreshing = false;

  // Store requests that waiting for refresh token
  let queue: QueueItem[] = [];

  function handleQueue(err: Error | null, token = '') {
    queue.forEach((prom) => {
      if (err) {
        prom.reject(err);
      } else {
        prom.resolve(token);
      }
    });
    queue = [];
  }

  const refreshTokenUrl = '/auth/refresh/';

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const originalRequest = error.config;

      // If error from refresh token api we immediately return error
      if (error?.response?.status === 404) {
        window.location.href = '/';
        return Promise.reject(error);
      }
      if (error?.response?.status !== 401) {
        // Other error not 401 we can safely return error
        return Promise.reject(error);
      }
      if (originalRequest.url.endsWith(refreshTokenUrl) || !isAuthenticated) {
        //  logout();
        return Promise.reject(error);
      }

      // There are no request trying to get the refresh token
      if (!isRefreshing && !originalRequest._retry) {
        originalRequest._retry = true;

        isRefreshing = true;
        const toto = token();
        if (toto === null) {
          logout();
          return Promise.reject(error);
        }

        return new Promise((resolve, reject) => {
          AuthService.authRefreshCreate({
            requestBody: { refresh: toto.refresh },
          })
            .then((res) => {
              const ptoken = { ...toto };
              ptoken.access = res.access;
              localStorage.setItem('kunami', JSON.stringify(ptoken));

              originalRequest.headers.Authorization = 'Bearer ' + res.access;
              resolve(axios(originalRequest));
              handleQueue(null, res.access);
            })
            .catch((err) => {
              logout();
              handleQueue(err);
              // Handle your logic when get token failed
              reject(err);
            })
            .then(() => {
              isRefreshing = false;
            });
        });
      }

      // There are a request trying to get the refresh token
      if (isRefreshing) {
        return new Promise<string>((resolve, reject) => {
          queue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = 'Bearer ' + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return err;
          });
      }
      return Promise.reject(error);
    }
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
