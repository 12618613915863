import { Grupo, GrupoService, Prestacao, PrestacaoRequest } from '../../../slidein_api';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Group,
  Modal,
  NumberInput,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { IconCurrencyEuro, IconEdit, IconFaceId, IconPlus, IconTrash } from '@tabler/icons-react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import { msgAPIError } from '../../helpers';
import { useCampanha } from '../CampanhaWrapperView';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import {
  CheckPermission,
  checkPermission,
  MASTER_ADMIN,
} from '../../../components/CheckPermission';
import { useAuth } from '../../../user-auth/SlideInAuthContext';

export function Prestacoes(props: { loading: boolean; grupo: Grupo }) {
  const { colaborador } = useAuth();
  const { campanha } = useCampanha();
  const { grupoID } = useParams();
  const [prestas, setPrestas] = useState<Prestacao[]>([]);
  const [prestaDialog, setPrestaDialog] = useState<boolean>(false);
  const [newPresta, setNewPresta] = useState<boolean>(false);

  useEffect(() => {
    if (props.grupo) {
      setPrestas(props.grupo.prestacoes);
    }
  }, [props.grupo]);
  const savePresta = (presta: Prestacao) => {
    if (newPresta) {
      GrupoService.grupoPrestacaoCreate({ requestBody: presta }).then(
        (value) => {
          const cenas = [...prestas];
          cenas.push(value);
          cenas.sort((a, b) => {
            return dayjs(a.data_limite) > dayjs(b.data_limite) ? 1 : -1;
          });
          setPrestas(cenas);
          setNewPresta(false);
          setPrestaDialog(false);
        },
        (reason) => prestaForm.setErrors(reason.body)
      );
    } else {
      GrupoService.grupoPrestacaoUpdate({ id: presta.id, requestBody: presta }).then(
        (value) => {
          const cenas = [...prestas];
          const foundIndex = cenas.findIndex((x) => x.id == value.id);
          cenas[foundIndex] = value;
          cenas.sort((a, b) => {
            return dayjs(a.data_limite) > dayjs(b.data_limite) ? 1 : -1;
          });
          setPrestas(cenas);
          setPrestaDialog(false);
          setNewPresta(false);
        },
        (reason) => prestaForm.setErrors(reason.body)
      );
    }
  };

  function deletePresta(pes: Prestacao) {
    if (confirm('Confirma que deseja remover a Prestação ?')) {
      GrupoService.grupoPrestacaoDestroy({ id: pes.id }).then(
        () => {
          const tt = prestas.filter((obj) => obj.id !== pes.id);
          setPrestas(tt);
        },
        (reason) => {
          msgAPIError(reason);
        }
      );
    }
  }

  const columnPrestacoes = [
    {
      accessorFn: (row) => dayjs(row.data_limite).locale('pt').format('YYYY-MM-DD'),
      header: 'Data Limite',
    },
    {
      accessorFn: (row) => {
        let idf;
        let idl;
        if (props.grupo.prestacoes.length === 0) {
          idf = -1;
          idl = -1;
        } else if (props.grupo.prestacoes.length == 1 || props.grupo.prestacoes.length == 2) {
          idf = props.grupo.prestacoes.at(0).id;
          idl = -1;
        } else {
          idf = props.grupo.prestacoes.at(0).id;
          if (props.grupo.prestacoes.at(-1).ajuste_de_contas) {
            idl = props.grupo.prestacoes.at(-2).id;
          } else {
            idl = -1;
          }
        }

        if (row.ajuste_de_contas) {
          return `A restante diferença se >= ${props.grupo.valor_presta_ref_min} €`;
        }
        if (row.id == idf) {
          return `${row.valor}€ + valor do seguro (se aplicável)`;
        }
        if (row.id == idl) {
          return `${row.valor}€ + a restante diferença se < ${props.grupo.valor_presta_ref_min} €`;
        }
        return `${row.valor}€ `;
      },
      header: 'Valor da Prestação',
      Footer: (props: { table }) => {
        return (
          <b>
            Total:
            {props.table
              .getFilteredRowModel()
              .rows.reduce((acu, rr) => acu + parseFloat(rr.original.valor || 0), 0)}
            €
          </b>
        );
      },
    },
  ];

  const prestaForm = useForm<Prestacao | PrestacaoRequest>({
    initialValues: {
      data_limite: null,
      ajuste_de_contas: false,
      valor: '',
      grupo: grupoID,
    },
    validate: yupResolver(
      Yup.object({
        data_limite: Yup.string().required('Obrigatório'),
        valor: Yup.string().nullable(),
      })
    ),
    transformValues: (values) => {
      const di = values.data_limite ? new Date(values.data_limite) : null;
      if (values.ajuste_de_contas) {
        values.valor = '';
      }
      return {
        ...values,
        data_limite: di
          ? `${di.getFullYear()}-${(di.getMonth() + 1).toString().padStart(2, '0')}-${di
              .getDate()
              .toString()
              .padStart(2, '0')}`
          : '',
      };
    },
  });

  const tablePrestacoes = useMantineReactTable({
    data: prestas || [],
    columns: columnPrestacoes,
    enableColumnOrdering: true,
    initialState: {
      density: 'xs',
      showGlobalFilter: false,
    },
    enableRowActions: checkPermission(MASTER_ADMIN, colaborador),
    positionActionsColumn: 'first',
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <Tooltip
          withArrow
          position='left'
          label='Edit'>
          <ActionIcon
            onClick={() => {
              prestaForm.reset();
              prestaForm.setValues(row.original);
              setNewPresta(false);
              setPrestaDialog(true);
            }}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>

        <Tooltip
          withArrow
          position='right'
          label='Delete'>
          <ActionIcon
            color='red'
            onClick={() => deletePresta(row.original)}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: () => (
      <Group>
        <CheckPermission
          roles={MASTER_ADMIN}
          goHome={false}>
          <Button
            disabled={campanha?.nr_maximo_de_prestacoes <= prestas.length}
            leftIcon={<IconPlus />}
            onClick={() => {
              prestaForm.reset();
              setNewPresta(true);
              setPrestaDialog(true);
            }}
            variant='outline'>
            Criar Prestação
          </Button>
          <Text>
            {prestas.length}/{campanha?.nr_maximo_de_prestacoes} Prestações
          </Text>
        </CheckPermission>
      </Group>
    ),
    // renderBottomToolbarCustomActions: () => {
    //   let total = 0;
    //   prestas.forEach((pp) => {
    //     if (pp.valor) {
    //       total = total + parseFloat(pp.valor);
    //     }
    //   });
    //   return <Text mr={'6rem'} fz={'xl'} w={'100%'} align={'center'}>Total:{total}€</Text>;
    // },

    mantineTableProps: {
      highlightOnHover: true,
      withColumnBorders: true,
    },
    enableFullScreenToggle: false,
    localization: MRT_Localization_PT,
    enableGlobalFilter: false,
    enablePagination: false,
    enableFilters: false,
    enableHiding: false,
    enableRowOrdering: false,
  });

  return (
    <>
      <MantineReactTable table={tablePrestacoes} />
      <Modal
        withCloseButton={false}
        closeOnEscape={false}
        opened={prestaDialog}
        onClose={() => {
          setPrestaDialog(false);
        }}
        title={newPresta ? 'Criar Prestação' : 'Editar Prestação'}>
        <form onSubmit={prestaForm.onSubmit(savePresta)}>
          <Stack>
            <DatePickerInput
              label='Data Limite:'
              w={{ base: '100%', xs: '49%' }}
              locale={'pt'}
              firstDayOfWeek={1}
              dropdownType={'modal'}
              placeholder='Escolher Data'
              icon={<IconFaceId stroke={1.5} />}
              valueFormat='DD/MM/YYYY'
              {...prestaForm.getInputProps('data_limite')}
              value={
                prestaForm.values.data_limite ? dayjs(prestaForm.values.data_limite).toDate() : null
              }
            />

            <NumberInput
              placeholder='Valor'
              label='Valor'
              precision={2}
              icon={<IconCurrencyEuro />}
              hideControls
              decimalSeparator=','
              thousandsSeparator='.'
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              formatter={(value) =>
                value && !Number.isNaN(parseFloat(value))
                  ? // eslint-disable-next-line security/detect-unsafe-regex
                    `${value} €`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                  : ''
              }
              {...prestaForm.getInputProps('valor')}
              value={
                prestaForm.values.valor != '' || prestaForm.values.valor !== null
                  ? parseFloat(prestaForm.values.valor)
                  : ''
              }
              disabled={prestaForm.values.ajuste_de_contas}
              onChange={(e) => prestaForm.setFieldValue('valor', (e || '').toString())}
              min={0}></NumberInput>

            <Checkbox
              label={'Restante? (Acertar Contas)'}
              {...prestaForm.getInputProps('ajuste_de_contas', { type: 'checkbox' })}
              onChange={(e) => {
                if (e.currentTarget.checked) {
                  prestaForm.setFieldValue('valor', '');
                }
                prestaForm.setFieldValue('ajuste_de_contas', e.currentTarget.checked);
              }}
            />
            <Group
              position='right'
              mt='md'>
              <Button
                disabled={
                  !(
                    prestaForm.values.data_limite &&
                    (prestaForm.values.valor != '' || prestaForm.values.ajuste_de_contas)
                  )
                }
                variant={'outline'}
                type='submit'>
                {newPresta ? 'Criar Prestação' : 'Guardar Alterações'}
              </Button>
              <Button
                type='button'
                onClick={() => {
                  setPrestaDialog(false);
                  setNewPresta(false);
                }}>
                Cancelar
              </Button>
            </Group>
          </Stack>
        </form>
      </Modal>
    </>
  );
}
