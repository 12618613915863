/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `XS` - Xs
 * * `S` - S
 * * `M` - M
 * * `L` - L
 * * `XL` - Xl
 */
export enum TamanhoTshirtEnum {
    XS = 'XS',
    S = 'S',
    M = 'M',
    L = 'L',
    XL = 'XL',
}
