import { useAuth } from './SlideInAuthContext';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  LoadingOverlay,
  Paper,
  PasswordInput,
  TextInput,
  Title,
} from '@mantine/core';
import { SlideinLogo } from '../components/logos';
import { Link, useNavigate } from 'react-router-dom';
import { useBread } from '../layout/context/BreadContext';
import { IconLogin } from '@tabler/icons-react';
import { useSlideInMenu } from '../layout/context/MenuContext';
import { ColaboradorService, SendEmailResetRequest } from '../slidein_api';
import { msgAPIError } from '../pages/helpers';

export function Login() {
  //bread
  const navigate = useNavigate();
  const { setBread } = useBread();
  const { setMenuItems } = useSlideInMenu();
  const [recuperarMode, setRecuperarMode] = useState<boolean>(false);
  useEffect(() => {
    setBread({
      accao: {
        title: 'login',
        href: '/',
        icon: <IconLogin />,
      },
    });
    //menu
    setMenuItems([]);
  }, []);

  const { authLoading, login, colaborador } = useAuth();
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: yupResolver(
      Yup.object({
        email: Yup.string().required('Obrigatório'),
        password: Yup.string().required('Obrigatório'),
      })
    ),
  });

  const recuperarForm = useForm<SendEmailResetRequest>({
    initialValues: {
      email: '',
    },
    validate: yupResolver(
      Yup.object({
        email: Yup.string().email().required('Obrigatório'),
      })
    ),
  });

  const recuperarPass = (rec: SendEmailResetRequest) => {
    ColaboradorService.colaboradorResetPasswordCreate({ requestBody: rec }).then(
      () => {
        setRecuperarMode(false);
      },
      (reason) => {
        msgAPIError(reason);
      }
    );
  };

  function sumitForm(values) {
    login(values.email, values.password).then((value) => {
      if (!value) {
        form.setFieldError('email', 'Acesso Negado - Reveja as suas credenciais!');
      }
      if (colaborador) {
        navigate('/colaborador/meu_perfil');
      }
    });
  }

  return (
    <Container
      size={520}
      my={{ base: 30, sm: 160 }}>
      <LoadingOverlay
        visible={authLoading}
        overlayBlur={2}
      />
      <Title
        align='center'
        order={1}>
        <SlideinLogo />
      </Title>

      {recuperarMode ? (
        <form onSubmit={recuperarForm.onSubmit(recuperarPass)}>
          <Paper
            withBorder
            shadow='md'
            p={30}
            mt={30}
            radius='md'>
            <TextInput
              label='Email'
              type={'email'}
              placeholder='...'
              required
              {...recuperarForm.getInputProps('email')}
            />

            <Button
              type={'submit'}
              variant={'outline'}
              fullWidth
              mt='xl'>
              Recuperar
            </Button>
          </Paper>
        </form>
      ) : (
        <form onSubmit={form.onSubmit(sumitForm)}>
          <Paper
            withBorder
            shadow='md'
            p={30}
            mt={30}
            radius='md'>
            <TextInput
              label='Email'
              placeholder='...'
              type={'email'}
              required
              {...form.getInputProps('email')}
            />
            <PasswordInput
              label='Password'
              placeholder='...'
              required
              mt='md'
              {...form.getInputProps('password')}
            />
            <Link
              to={''}
              onClick={() => setRecuperarMode(true)}>
              Recuperar Pass
            </Link>

            <Button
              type={'submit'}
              variant={'outline'}
              fullWidth
              mt='xl'>
              Entrar
            </Button>
          </Paper>
        </form>
      )}
    </Container>
  );
}
