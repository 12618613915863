import { Link, useParams } from 'react-router-dom';
import {
  Badge,
  Button,
  Divider,
  Flex,
  Group,
  Modal,
  Stack,
  Tabs,
  Text,
  Title,
} from '@mantine/core';
import React, { useEffect, useMemo, useState } from 'react';
import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table';
import {
  CampanhaColaborador,
  CampanhaService,
  TransporteLocalPartida,
  TransporteService,
  ViagemService,
  ViagemSimples,
} from '../../../slidein_api';
import { msgAPIError } from '../../helpers';
import {
  IconBus,
  IconBusStop,
  IconDeviceFloppy,
  IconPlane,
  IconUserBolt,
  IconUserStar,
  IconX,
} from '@tabler/icons-react';
import { useBread } from '../../../layout/context/BreadContext';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import dayjs from 'dayjs';

function getRows(table) {
  if (table?.getSelectedRowModel() && table.getSelectedRowModel().rows) {
    return table.getSelectedRowModel().rows;
  } else {
    return [];
  }
}

export function TransporteManageDetalhe() {
  const { campanhaID } = useParams();
  const { setBread } = useBread();

  const { transID } = useParams();
  const [localPartida, setLocalPartida] = useState<TransporteLocalPartida>();
  const [staff, setStaff] = useState<CampanhaColaborador[]>();
  const [addStaffModalVisible, setAddStaffModalVisible] = useState<boolean>(false);

  const fetchTransporteDetalhe = () => {
    CampanhaService.campanhaColaboradoresList({ campanha: campanhaID }).then(
      (staff) => {
        setStaff(staff);
      },
      (reason) => msgAPIError(reason)
    );
    TransporteService.transporteLocaisPartidaRetrieve({ id: parseInt(transID) }).then(
      (value) => {
        setLocalPartida(value);
        setBread({
          campanha: {
            title: campanhaID,
            href: '/campanha/' + campanhaID,
            icon: <IconPlane />,
          },
          cliente: {
            title: 'Transportes',
            icon: <IconBus />,
            href: '/campanha/' + campanhaID + '/transporte',
          },
          accao: {
            title: `${value.transporte_ro.nome}-${value.localidade}-${value.local_partida}-${
              value.hora_partida
                ? dayjs(value.hora_partida).locale('pt').format('YYYY-MM-DD HH:mm')
                : '-'
            }`,
            icon: <IconBusStop />,
          },
        });
      },
      (reason) => msgAPIError(reason)
    );
  };

  useEffect(() => {
    fetchTransporteDetalhe();
  }, []);

  const removeStaff = () => {
    tableStaff.getSelectedRowModel().rows.forEach((staff) => {
      CampanhaService.campanhaColaboradoresPartialUpdate({
        id: staff.original.id,
        requestBody: { transporte: null },
      }).then(
        () => fetchTransporteDetalhe(),
        (reason) => msgAPIError(reason)
      );
    });
  };
  const adicionarStaff = () => {
    setAddStaffModalVisible(true);
  };

  const columnsStaff = useMemo<MRT_ColumnDef<CampanhaColaborador>[]>(
    () => [
      { accessorKey: 'colaborador_ro.nome_simples', header: 'Nome' },
      { accessorKey: 'colaborador_ro.email', header: 'Email' },
      { accessorKey: 'colaborador_ro.contacto_telemovel', header: 'Telemovel' },
      { accessorKey: 'colaborador_ro.slidein_role', header: 'Role' },
      { accessorKey: 'colaborador_ro.slidein_funcao', header: 'Função' },
    ],
    []
  );
  const tableStaff = useMantineReactTable<CampanhaColaborador>({
    columns: columnsStaff,
    data: localPartida ? localPartida.staff_campanha : [],
    enableMultiRowSelection: true,
    enableRowSelection: true,
    enableSelectAll: true,
    selectAllMode: 'all',
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: 'pointer',
      },
      onClick: row.getToggleSelectedHandler(),
    }),
    getRowId: (originalRow) => originalRow.id.toString(),
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFilters: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enablePagination: true,
    localization: MRT_Localization_PT,
    initialState: {
      density: 'xs',
    },
    positionActionsColumn: 'first',
    renderTopToolbarCustomActions: () => (
      <Group grow>
        <Button
          disabled={getRows(tableStaff).length <= 0}
          onClick={() => {
            removeStaff();
          }}>
          Remover Selecionados
        </Button>
        <Button
          disabled={localPartida?.transporte_ro.nr_livres === 0}
          onClick={() => {
            adicionarStaff();
          }}>
          Adicionar Colaborador
        </Button>
      </Group>
    ),
  });

  const removeViajantes = () => {
    tableViajantes.getSelectedRowModel().rows.forEach((viaj) => {
      ViagemService.viagemTransportePartialUpdate({
        id: viaj.original.id,
        requestBody: { transporte: null },
      }).then(
        () => fetchTransporteDetalhe(),
        (reason) => msgAPIError(reason)
      );
    });
  };
  const columnsViajantes = useMemo<MRT_ColumnDef<ViagemSimples>[]>(
    () => [
      { accessorKey: 'chefe_de_grupo', header: 'Chefe' },
      {
        accessorFn: (or) => or.cliente.nome_simples,
        header: 'Viajante',
        Cell: ({ row }: { row }) => (
          <Link to={'/campanha/' + campanhaID + '/viagem/' + row.original.id}>
            {row.original.cliente.nome_simples}
          </Link>
        ),
      },
      { accessorFn: (or) => or.cliente.email, header: 'Email' },
      { accessorFn: (or) => or.cliente.contacto_telemovel, header: 'Telemovel' },
      {
        accessorFn: (or) => or.grupo.codigo,
        header: 'Grupo',
        Cell: ({ row }: { row }) => (
          <Link to={'/campanha/' + campanhaID + '/grupo/' + row.original.grupo.codigo}>
            {row.original.grupo.codigo}
          </Link>
        ),
      },
    ],
    []
  );
  const tableViajantes = useMantineReactTable<ViagemSimples>({
    columns: columnsViajantes,
    data: localPartida ? localPartida.viajantes : [],
    enableMultiRowSelection: true,
    enableRowSelection: true,
    enableSelectAll: true,
    selectAllMode: 'all',
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: 'pointer',
      },
      onClick: row.getToggleSelectedHandler(),
    }),
    getRowId: (originalRow) => originalRow.id.toString(),
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFilters: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enablePagination: true,
    localization: MRT_Localization_PT,
    initialState: {
      density: 'xs',
    },
    positionActionsColumn: 'first',
    renderTopToolbarCustomActions: () => (
      <Button
        disabled={getRows(tableViajantes).length <= 0}
        onClick={() => {
          removeViajantes();
        }}>
        Remover Selecionados
      </Button>
    ),
  });

  const columsColaborador = useMemo<MRT_ColumnDef<CampanhaColaborador>[]>(
    () => [
      { accessorKey: 'colaborador_ro.nome_simples', header: 'Nome' },
      { accessorKey: 'colaborador_ro.email', header: 'Email' },
      { accessorKey: 'colaborador_ro.contacto_telemovel', header: 'Telemovel' },
      { accessorKey: 'colaborador_ro.slidein_role', header: 'Role' },
      { accessorKey: 'colaborador_ro.slidein_funcao', header: 'Função' },
      {
        accessorFn: (cc) => {
          return cc.transporte_nome ? cc.transporte_nome : '-';
        },
        header: 'Transporte',
      },
    ],
    []
  );
  const tableColaboradores = useMantineReactTable<CampanhaColaborador>({
    columns: columsColaborador,
    data: staff || [],
    enableSelectAll: true,
    selectAllMode: 'all',
    getRowId: (originalRow) => originalRow.id.toString(),
    enableGlobalFilter: true,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enablePagination: true,
    localization: MRT_Localization_PT,
    initialState: {
      density: 'xs',
    },
    positionGlobalFilter: 'left',
    globalFilterFn: 'contains',
    enableRowSelection: (row) => !row.original.transporte,
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        opacity: !row.original.transporte ? '100%' : '35%',
        cursor: 'pointer',
      },
      onClick: row.getToggleSelectedHandler(),
    }),
  });
  const addColaboradores = () => {
    tableColaboradores.getSelectedRowModel().rows.forEach((staff) => {
      CampanhaService.campanhaColaboradoresPartialUpdate({
        id: staff.original.id,
        requestBody: { transporte: localPartida?.id },
      }).then(
        () => fetchTransporteDetalhe(),
        (reason) => msgAPIError(reason)
      );
    });
  };

  return (
    <Stack>
      <Flex
        direction={'row'}
        gap={'md'}
        mx={'xl'}>
        <Flex direction={'column'}>
          <Text
            fw={'bold'}
            size={40}>
            {localPartida?.transporte_ro.nome}
          </Text>
          <Text>
            ({localPartida?.transporte_ro.nr_livres}/{localPartida?.transporte_ro.vagas} vagas)
          </Text>
        </Flex>
        <Divider orientation={'vertical'}></Divider>
        <Flex direction={'column'}>
          <Flex
            direction={'row'}
            gap={'xs'}>
            <Text fw={'bold'}>Localidade:</Text>
            <Text>{localPartida?.localidade}</Text>
          </Flex>
          <Flex
            direction={'row'}
            gap={'xs'}>
            <Text fw={'bold'}>Local de Partida:</Text>
            <Text>{localPartida?.local_partida}</Text>
          </Flex>
          <Flex
            direction={'row'}
            gap={'xs'}>
            <Text fw={'bold'}>Data e Hora:</Text>
            <Text>
              {localPartida?.hora_partida
                ? dayjs(localPartida?.hora_partida)
                    .locale('pt')
                    .format('YYYY-MM-DD HH:mm')
                : '-'}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Tabs defaultValue='viajantes'>
        <Tabs.List>
          <Tabs.Tab
            value='viajantes'
            rightSection={
              <Badge
                w={20}
                h={20}
                sx={{ pointerEvents: 'none' }}
                variant='filled'
                size='md'
                p={0}>
                {localPartida?.viajantes.length}
              </Badge>
            }
            icon={<IconUserStar />}>
            Viajantes
          </Tabs.Tab>
          <Tabs.Tab
            rightSection={
              <Badge
                w={20}
                h={20}
                sx={{ pointerEvents: 'none' }}
                variant='filled'
                size='md'
                p={0}>
                {localPartida?.staff_campanha.length}
              </Badge>
            }
            value='colaboradores'
            icon={<IconUserBolt />}>
            Colaboradores
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel
          value='viajantes'
          pt='md'>
          <MantineReactTable table={tableViajantes} />
        </Tabs.Panel>
        <Tabs.Panel
          value='colaboradores'
          pt='md'>
          <MantineReactTable table={tableStaff} />
        </Tabs.Panel>
      </Tabs>
      <Modal
        centered
        title={'Escolher Colaboradores:'}
        opened={addStaffModalVisible}
        size={'auto'}
        onClose={() => {
          setAddStaffModalVisible(false);
        }}>
        <Title order={4}>{getRows(tableColaboradores).length} Colaboradores Seleccionados.</Title>
        <MantineReactTable table={tableColaboradores} />
        <Group
          position='right'
          mt='md'>
          <Button
            color={'yellow'}
            variant={'outline'}
            leftIcon={<IconX />}
            type='button'
            onClick={() => {
              setAddStaffModalVisible(false);
            }}>
            Cancelar
          </Button>
          <Button
            variant={'outline'}
            onClick={() => {
              addColaboradores();
              setAddStaffModalVisible(false);
            }}
            leftIcon={<IconDeviceFloppy />}
            type='button'>
            Adicionar
          </Button>
        </Group>
      </Modal>
    </Stack>
  );
}
