import { Button, Textarea } from '@mantine/core';
import { IconDeviceFloppy, IconNotes } from '@tabler/icons-react';
import React, { useEffect } from 'react';
import { GrupoService, PatchedGrupoRequest } from '../../../slidein_api';
import { msgAPIError } from '../../helpers';
import { useForm } from '@mantine/form';
import { useParams } from 'react-router-dom';

export function GrupoNotas({ notas, fetch }: { notas: string; fetch }) {
  const { grupoID } = useParams();
  const saveNotas = (notasF: PatchedGrupoRequest) => {
    GrupoService.grupoPartialUpdate({
      codigo: grupoID,
      requestBody: notasF,
    }).then(
      () => {
        fetch();
        notas = notasF.notas;
        notasForm.reset;
      },
      (reason) => msgAPIError(reason)
    );
  };
  useEffect(() => {
    notasForm.setFieldValue('notas', notas);
  }, [notas]);
  const notasForm = useForm<PatchedGrupoRequest>({
    initialValues: {
      notas: notas,
    },
  });
  return (
    <>
      <form onSubmit={notasForm.onSubmit(saveNotas)}>
        <Textarea
          placeholder='Notas...'
          label='Notas:'
          size='md'
          minRows={5}
          icon={<IconNotes />}
          {...notasForm.getInputProps('notas')}></Textarea>
        <Button
          w={'100%'}
          disabled={notas == notasForm.values.notas}
          type={'submit'}
          rightIcon={<IconDeviceFloppy />}
          variant={'outline'}>
          Guardar Notas
        </Button>
      </form>
    </>
  );
}
