/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Grupo } from '../models/Grupo';
import type { GrupoDoc } from '../models/GrupoDoc';
import type { GrupoDocRequest } from '../models/GrupoDocRequest';
import type { GrupoItem } from '../models/GrupoItem';
import type { GrupoProdutos } from '../models/GrupoProdutos';
import type { GrupoProdutosRequest } from '../models/GrupoProdutosRequest';
import type { GrupoRequest } from '../models/GrupoRequest';
import type { GrupoSimples } from '../models/GrupoSimples';
import type { PatchedGrupoDocRequest } from '../models/PatchedGrupoDocRequest';
import type { PatchedGrupoProdutosRequest } from '../models/PatchedGrupoProdutosRequest';
import type { PatchedGrupoRequest } from '../models/PatchedGrupoRequest';
import type { PatchedPrestacaoRequest } from '../models/PatchedPrestacaoRequest';
import type { PatchedQuartoRequest } from '../models/PatchedQuartoRequest';
import type { Prestacao } from '../models/Prestacao';
import type { PrestacaoRequest } from '../models/PrestacaoRequest';
import type { Quarto } from '../models/Quarto';
import type { QuartoItem } from '../models/QuartoItem';
import type { QuartoRequest } from '../models/QuartoRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GrupoService {

    /**
     * @returns Grupo
     * @throws ApiError
     */
    public static grupoList({
        campanha,
        codigo,
        estado,
    }: {
        /**
         * Identifica univocamente uma campanha
         */
        campanha?: string,
        codigo?: string,
        /**
         * * `Aberto` - Aberto
         * * `Suspenso` - Suspenso
         * * `Fechado` - Fechado
         * * `Inscrições Bloqueadas` - Incricoesbloqueadas
         */
        estado?: 'Aberto' | 'Fechado' | 'Inscrições Bloqueadas' | 'Suspenso',
    }): CancelablePromise<Array<Grupo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/grupo',
            query: {
                'campanha': campanha,
                'codigo': codigo,
                'estado': estado,
            },
        });
    }

    /**
     * @returns Grupo
     * @throws ApiError
     */
    public static grupoCreate({
        requestBody,
    }: {
        requestBody: GrupoRequest,
    }): CancelablePromise<Grupo> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/grupo',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Grupo
     * @throws ApiError
     */
    public static grupoRetrieve({
        codigo,
    }: {
        /**
         * A unique value identifying this Grupo .
         */
        codigo: string,
    }): CancelablePromise<Grupo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/grupo/{codigo}',
            path: {
                'codigo': codigo,
            },
        });
    }

    /**
     * @returns Grupo
     * @throws ApiError
     */
    public static grupoUpdate({
        codigo,
        requestBody,
    }: {
        /**
         * A unique value identifying this Grupo .
         */
        codigo: string,
        requestBody: GrupoRequest,
    }): CancelablePromise<Grupo> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/grupo/{codigo}',
            path: {
                'codigo': codigo,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Grupo
     * @throws ApiError
     */
    public static grupoPartialUpdate({
        codigo,
        requestBody,
    }: {
        /**
         * A unique value identifying this Grupo .
         */
        codigo: string,
        requestBody?: PatchedGrupoRequest,
    }): CancelablePromise<Grupo> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/grupo/{codigo}',
            path: {
                'codigo': codigo,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static grupoDestroy({
        codigo,
    }: {
        /**
         * A unique value identifying this Grupo .
         */
        codigo: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/grupo/{codigo}',
            path: {
                'codigo': codigo,
            },
        });
    }

    /**
     * @returns Grupo
     * @throws ApiError
     */
    public static grupoBloquearFaltaPagamentosRetrieve({
        codigo,
        ichefe,
        valor,
    }: {
        /**
         * A unique value identifying this Grupo .
         */
        codigo: string,
        ichefe: boolean,
        valor: number,
    }): CancelablePromise<Grupo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/grupo/{codigo}/bloquear_falta_pagamentos',
            path: {
                'codigo': codigo,
            },
            query: {
                'ichefe': ichefe,
                'valor': valor,
            },
        });
    }

    /**
     * @returns Grupo
     * @throws ApiError
     */
    public static grupoDesbloquearFaltaDePagamentoRetrieve({
        codigo,
        ichefe,
        valor,
    }: {
        /**
         * A unique value identifying this Grupo .
         */
        codigo: string,
        ichefe: boolean,
        valor: number,
    }): CancelablePromise<Grupo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/grupo/{codigo}/desbloquear_falta_de_pagamento',
            path: {
                'codigo': codigo,
            },
            query: {
                'ichefe': ichefe,
                'valor': valor,
            },
        });
    }

    /**
     * @returns GrupoDoc
     * @throws ApiError
     */
    public static grupoDocsList({
        grupo,
    }: {
        grupo?: string,
    }): CancelablePromise<Array<GrupoDoc>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/grupo/docs',
            query: {
                'grupo': grupo,
            },
        });
    }

    /**
     * @returns GrupoDoc
     * @throws ApiError
     */
    public static grupoDocsCreate({
        formData,
    }: {
        formData: GrupoDocRequest,
    }): CancelablePromise<GrupoDoc> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/grupo/docs',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns GrupoDoc
     * @throws ApiError
     */
    public static grupoDocsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this grupo doc.
         */
        id: number,
    }): CancelablePromise<GrupoDoc> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/grupo/docs/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns GrupoDoc
     * @throws ApiError
     */
    public static grupoDocsUpdate({
        id,
        formData,
    }: {
        /**
         * A unique integer value identifying this grupo doc.
         */
        id: number,
        formData: GrupoDocRequest,
    }): CancelablePromise<GrupoDoc> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/grupo/docs/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns GrupoDoc
     * @throws ApiError
     */
    public static grupoDocsPartialUpdate({
        id,
        formData,
    }: {
        /**
         * A unique integer value identifying this grupo doc.
         */
        id: number,
        formData?: PatchedGrupoDocRequest,
    }): CancelablePromise<GrupoDoc> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/grupo/docs/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static grupoDocsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this grupo doc.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/grupo/docs/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * ListModelMixin and GenericViewSet only Viewset
     * @returns GrupoItem
     * @throws ApiError
     */
    public static grupoItemList({
        campanha,
    }: {
        /**
         * Identifica univocamente uma campanha
         */
        campanha?: string,
    }): CancelablePromise<Array<GrupoItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/grupo/item',
            query: {
                'campanha': campanha,
            },
        });
    }

    /**
     * @returns Prestacao
     * @throws ApiError
     */
    public static grupoPrestacaoList({
        grupo,
    }: {
        grupo?: string,
    }): CancelablePromise<Array<Prestacao>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/grupo/prestacao',
            query: {
                'grupo': grupo,
            },
        });
    }

    /**
     * @returns Prestacao
     * @throws ApiError
     */
    public static grupoPrestacaoCreate({
        requestBody,
    }: {
        requestBody: PrestacaoRequest,
    }): CancelablePromise<Prestacao> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/grupo/prestacao',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Prestacao
     * @throws ApiError
     */
    public static grupoPrestacaoRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this Prestação.
         */
        id: number,
    }): CancelablePromise<Prestacao> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/grupo/prestacao/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Prestacao
     * @throws ApiError
     */
    public static grupoPrestacaoUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Prestação.
         */
        id: number,
        requestBody: PrestacaoRequest,
    }): CancelablePromise<Prestacao> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/grupo/prestacao/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Prestacao
     * @throws ApiError
     */
    public static grupoPrestacaoPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Prestação.
         */
        id: number,
        requestBody?: PatchedPrestacaoRequest,
    }): CancelablePromise<Prestacao> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/grupo/prestacao/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static grupoPrestacaoDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this Prestação.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/grupo/prestacao/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns GrupoProdutos
     * @throws ApiError
     */
    public static grupoProdutosUpdate({
        codigo,
        requestBody,
    }: {
        /**
         * A unique value identifying this Grupo .
         */
        codigo: string,
        requestBody?: GrupoProdutosRequest,
    }): CancelablePromise<GrupoProdutos> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/grupo/produtos/{codigo}',
            path: {
                'codigo': codigo,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GrupoProdutos
     * @throws ApiError
     */
    public static grupoProdutosPartialUpdate({
        codigo,
        requestBody,
    }: {
        /**
         * A unique value identifying this Grupo .
         */
        codigo: string,
        requestBody?: PatchedGrupoProdutosRequest,
    }): CancelablePromise<GrupoProdutos> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/grupo/produtos/{codigo}',
            path: {
                'codigo': codigo,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static grupoProdutosDestroy({
        codigo,
    }: {
        /**
         * A unique value identifying this Grupo .
         */
        codigo: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/grupo/produtos/{codigo}',
            path: {
                'codigo': codigo,
            },
        });
    }

    /**
     * @returns Quarto
     * @throws ApiError
     */
    public static grupoQuartoList({
        grupo,
        grupoCampanha,
    }: {
        grupo?: string,
        /**
         * Identifica univocamente uma campanha
         */
        grupoCampanha?: string,
    }): CancelablePromise<Array<Quarto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/grupo/quarto',
            query: {
                'grupo': grupo,
                'grupo__campanha': grupoCampanha,
            },
        });
    }

    /**
     * @returns Quarto
     * @throws ApiError
     */
    public static grupoQuartoCreate({
        requestBody,
    }: {
        requestBody: QuartoRequest,
    }): CancelablePromise<Quarto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/grupo/quarto',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Quarto
     * @throws ApiError
     */
    public static grupoQuartoRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this Quarto.
         */
        id: number,
    }): CancelablePromise<Quarto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/grupo/quarto/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Quarto
     * @throws ApiError
     */
    public static grupoQuartoUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Quarto.
         */
        id: number,
        requestBody: QuartoRequest,
    }): CancelablePromise<Quarto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/grupo/quarto/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Quarto
     * @throws ApiError
     */
    public static grupoQuartoPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Quarto.
         */
        id: number,
        requestBody?: PatchedQuartoRequest,
    }): CancelablePromise<Quarto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/grupo/quarto/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static grupoQuartoDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this Quarto.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/grupo/quarto/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * ListModelMixin and GenericViewSet only Viewset
     * @returns QuartoItem
     * @throws ApiError
     */
    public static grupoQuartosItemList({
        grupo,
    }: {
        grupo?: string,
    }): CancelablePromise<Array<QuartoItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/grupo/quartos/item',
            query: {
                'grupo': grupo,
            },
        });
    }

    /**
     * ListModelMixin and GenericViewSet only Viewset
     * @returns GrupoSimples
     * @throws ApiError
     */
    public static grupoSimplesList({
        adminResponsavel,
        campanha,
        chefeEditavel,
        codigo,
        comercialResponsavel,
        estado,
        instituicao,
        mostrarQuartos,
        mostrarTransporte,
        search,
    }: {
        adminResponsavel?: string,
        /**
         * Identifica univocamente uma campanha
         */
        campanha?: string,
        chefeEditavel?: boolean,
        codigo?: string,
        comercialResponsavel?: string,
        estado?: string,
        instituicao?: string,
        mostrarQuartos?: boolean,
        mostrarTransporte?: boolean,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<Array<GrupoSimples>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/grupo/simples',
            query: {
                'admin_responsavel': adminResponsavel,
                'campanha': campanha,
                'chefe_editavel': chefeEditavel,
                'codigo': codigo,
                'comercial_responsavel': comercialResponsavel,
                'estado': estado,
                'instituicao': instituicao,
                'mostrar_quartos': mostrarQuartos,
                'mostrar_transporte': mostrarTransporte,
                'search': search,
            },
        });
    }

    /**
     * ListModelMixin and GenericViewSet only Viewset
     * @returns void
     * @throws ApiError
     */
    public static grupoSimplesDestroy({
        codigo,
    }: {
        /**
         * A unique value identifying this Grupo .
         */
        codigo: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/grupo/simples/{codigo}',
            path: {
                'codigo': codigo,
            },
        });
    }

    /**
     * ListModelMixin and GenericViewSet only Viewset
     * @returns GrupoSimples
     * @throws ApiError
     */
    public static grupoSimplesMapaQuartosRetrieve({
        codigo,
    }: {
        /**
         * A unique value identifying this Grupo .
         */
        codigo: string,
    }): CancelablePromise<GrupoSimples> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/grupo/simples/{codigo}/mapa_quartos',
            path: {
                'codigo': codigo,
            },
        });
    }

    /**
     * ListModelMixin and GenericViewSet only Viewset
     * @returns GrupoSimples
     * @throws ApiError
     */
    public static grupoSimplesMapaTransportesRetrieve({
        codigo,
    }: {
        /**
         * A unique value identifying this Grupo .
         */
        codigo: string,
    }): CancelablePromise<GrupoSimples> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/grupo/simples/{codigo}/mapa_transportes',
            path: {
                'codigo': codigo,
            },
        });
    }

    /**
     * ListModelMixin and GenericViewSet only Viewset
     * @returns GrupoSimples
     * @throws ApiError
     */
    public static grupoSimplesMapaRetrieve(): CancelablePromise<GrupoSimples> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/grupo/simples/mapa',
        });
    }

}
