/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Administrativo` - Administrativo
 * * `Comercial` - Comercial
 * * `RP` - Rp
 * * `Artista` - Artista
 * * `Monitor` - Monitor
 * * `Outro` - Outro
 */
export enum SlideinFuncaoEnum {
    ADMINISTRATIVO = 'Administrativo',
    COMERCIAL = 'Comercial',
    RP = 'RP',
    ARTISTA = 'Artista',
    MONITOR = 'Monitor',
    OUTRO = 'Outro',
}
