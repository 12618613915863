import * as Yup from 'yup';
import {
  ApiError,
  DistritoEnum,
  Instituicao,
  InstituicaoRequest,
  InstituicaoService,
} from '../../slidein_api';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MenuContext } from '../../layout/context/MenuContext';
import { homeMenu } from '../Home';
import { useForm, yupResolver } from '@mantine/form';
import { Button, Container, Group, Select, Textarea, TextInput, Title } from '@mantine/core';
import { IconNotes, IconPlus, IconRecycle, IconUserEdit } from '@tabler/icons-react';
import { msgAPIError } from '../helpers';
import { notifications } from '@mantine/notifications';

export function InstituicaoEdit(props: { createNew: boolean }) {
  const { setMenuItems } = useContext(MenuContext);
  const { instID } = useParams();
  useEffect(() => {
    setMenuItems(homeMenu);
  }, []);

  const navigate = useNavigate();

  const [instituicao, setInstituicao] = useState<Instituicao | InstituicaoRequest>({
    nome: '',
    sigla: '',
    morada: '',
    distrito: null,
    concelho: '',
    notas: '',
  });

  useEffect(() => {
    if (!props.createNew) {
      InstituicaoService.instituicaoRetrieve({ id: parseInt(instID) }).then(
        (value) => {
          if (value.notas === null) {
            value.notas = '';
          }
          setInstituicao(value);
          instForm.setValues(value);
        },
        (reason) => {
          msgAPIError(reason);
        }
      );
    }
  }, []);

  const instForm = useForm<Instituicao | InstituicaoRequest>({
    initialValues: instituicao,
    validate: yupResolver(
      Yup.object({
        nome: Yup.string().min(10, 'Tamanho mínimo de 10 caracteres').required('Obrigatório'),
        sigla: Yup.string()
          .min(1, 'Tamanho mínimo de 1 caracteres')
          .max(12, 'Tamanho maximo de 12 caracteres')
          .required('Obrigatório'),
      })
    ),
  });

  const saveInstituicao = (inst: Instituicao) => {
    if (props.createNew) {
      InstituicaoService.instituicaoCreate({ requestBody: inst }).then(
        () => {
          notifications.show({
            title: 'Instituição criada!',
            message: `A instituição ${inst.nome} foi criada.`,
            withCloseButton: true,
            icon: <IconPlus />,
            color: 'green',
          });
          navigate(-1);
        },
        (reason: ApiError) => {
          instForm.setErrors(reason.body);
        }
      );
    } else {
      InstituicaoService.instituicaoUpdate({ id: inst.id, requestBody: inst }).then(
        () => {
          notifications.show({
            title: 'Instituição actualizada!',
            message: `A instituição ${inst.nome} foi actualizada.`,
            withCloseButton: true,
            icon: <IconRecycle />,
            color: 'green',
          });
          navigate(-1);
        },
        (reason: ApiError) => {
          instForm.setErrors(reason.body);
        }
      );
    }
  };

  return (
    <Container p={'xs'}>
      <Title order={2}>{props.createNew ? 'Nova Instituição' : 'Editar Instituição'}</Title>
      <form onSubmit={instForm.onSubmit(saveInstituicao)}>
        <TextInput
          label={'Nome'}
          required
          icon={<IconUserEdit />}
          {...instForm.getInputProps('nome')}
        />
        <TextInput
          label={'Sigla'}
          required
          icon={<IconUserEdit />}
          {...instForm.getInputProps('sigla')}
        />
        <TextInput
          label={'Morada'}
          icon={<IconUserEdit />}
          {...instForm.getInputProps('morada')}
        />
        <Select
          label='Distrito'
          placeholder='Escolher'
          {...instForm.getInputProps('distrito')}
          data={Object.keys(DistritoEnum).map((x) => {
            return {
              label: DistritoEnum[x as keyof typeof DistritoEnum],
              value: DistritoEnum[x as keyof typeof DistritoEnum],
            };
          })}
        />
        <TextInput
          label={'Concelho'}
          icon={<IconUserEdit />}
          {...instForm.getInputProps('concelho')}
        />
        <Textarea
          placeholder='notas...'
          label='Notas:'
          size='md'
          icon={<IconNotes />}
          autosize
          minRows={2}
          {...instForm.getInputProps('notas')}
        />
        <Group
          position='right'
          mt='md'>
          <Button
            variant={'outline'}
            type='submit'>
            {props.createNew ? 'Criar Instituição' : 'Guardar Alterações'}
          </Button>
          <Button
            variant={'outline'}
            type='button'
            onClick={() => navigate(-1)}>
            Cancelar
          </Button>
        </Group>
      </form>
    </Container>
  );
}
