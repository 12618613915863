import { Input, useMantineTheme } from '@mantine/core';
import PhoneInput from 'react-phone-input-2';
import pt from 'react-phone-input-2/lang/pt.json';
import React from 'react';

type MantinePhoneInputProps = {
  onChange?;
  value?;
  label?: string;
  error?;
  w?;
  required?: boolean;
};

export function MantinePhoneInput({
  value,
  onChange,
  label,
  w,
  required,
  error,
}: MantinePhoneInputProps) {
  const theme = useMantineTheme();
  const mantineButtonStyles = {
    background: theme.colorScheme == 'dark' ? '#25262b' : 'white',
    borderWidth: '0.0625rem',
    borderColor: theme.colorScheme == 'dark' ? '#373A40' : '#ced4da',
  };
  const mantineBoxStyles = {
    width: '100%',
    background: theme.colorScheme == 'dark' ? '#25262b' : 'white',
    borderWidth: '0.0625rem',
    borderColor: theme.colorScheme == 'dark' ? '#373A40' : '#ced4da',
  };
  return (
    <Input.Wrapper
      w={w}
      required={required}
      error={error}
      label={label}>
      {error}
      <PhoneInput
        country={'pt'}
        countryCodeEditable={false}
        localization={pt}
        containerClass={'mantine-Input-wrapper mantine-TextInput-wrapper'}
        inputStyle={mantineBoxStyles}
        buttonStyle={mantineButtonStyles}
        dropdownStyle={mantineButtonStyles}
        containerStyle={mantineBoxStyles}
        searchStyle={mantineButtonStyles}
        value={value}
        onChange={onChange}
      />
    </Input.Wrapper>
  );
}
