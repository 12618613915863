import React, { useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Desconto, DescontoRequest, ProdutoService } from '../../../slidein_api';
import { msgAPIError } from '../../helpers';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
} from 'mantine-react-table';
import {
  ActionIcon,
  Box,
  Button,
  Group,
  Modal,
  NumberInput,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from '@mantine/core';
import {
  IconCircleCheck,
  IconCurrencyEuro,
  IconEdit,
  IconNotes,
  IconPackage,
  IconPlus,
  IconTrash,
  IconTrashX,
  IconUser,
} from '@tabler/icons-react';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import { useForm, yupResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';

const EMPTYDESCONTO: Desconto = {
  descricao: '',
  nome_interno: '',
  titulo_cliente: '',
  valor: undefined,
  campanha: '',
  activo: true,
  inicial: false,
};

export function DescontoManager() {
  const params = useParams();
  const [dialogParams, setDialogParams] = useState<{
    create: boolean;
    visible: boolean;
  }>({
    create: true,
    visible: false,
  });
  const [update, setUpdate] = useState<number>(0);
  const [descontos, setDescontos] = useState<Desconto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    ProdutoService.produtoDescontoList({ campanha: params.campanhaID }).then(
      (value) => {
        setDescontos(value);
        setIsLoading(false);
      },
      (reason) => msgAPIError(reason)
    );
  }, [update]);

  const descontoForm = useForm<Desconto | DescontoRequest>({
    initialValues: EMPTYDESCONTO,
    validate: yupResolver(
      Yup.object({
        nome_interno: Yup.string().min(4, 'Tamanho mínimo de 4 caracteres').required('Obrigatório'),
        titulo_cliente: Yup.string().required('Obrigatório'),
        valor: Yup.number().min(0).required('Obrigatório'),
      })
    ),
  });
  const saveDesconto = async (desconto: Desconto) => {
    if (dialogParams.create) {
      desconto.campanha = params.campanhaID;
      ProdutoService.produtoDescontoCreate({ requestBody: desconto }).then(
        () => {
          setUpdate(update + 1);
          setDialogParams({ create: true, visible: false });
        },
        (error) => {
          descontoForm.setErrors(error.body);
        }
      );
    } else {
      ProdutoService.produtoDescontoUpdate({
        id: desconto.id,
        requestBody: desconto,
      }).then(
        () => {
          setUpdate(update + 1);
          setDialogParams({ create: true, visible: false });
          notifications.show({
            title: `Desconto actualizado!`,
            message: `O Desconto ${desconto.nome_interno} foi actualizado.`,
            withCloseButton: true,
            icon: <IconCircleCheck />,
            color: 'green',
          });
        },
        (error) => {
          descontoForm.setErrors(error.body);
        }
      );
    }
  };

  function criarDesconto() {
    descontoForm.reset();
    setDialogParams({
      create: true,
      visible: true,
    });
  }

  function editDesconto(desconto: Desconto) {
    descontoForm.reset();
    descontoForm.setValues(desconto);
    setDialogParams({
      create: false,
      visible: true,
    });
  }

  function removeDesconto(desconto: Desconto) {
    if (confirm('Confirma que deseja remover o desconto : ' + desconto.nome_interno + ' ?')) {
      ProdutoService.produtoDescontoDestroy({ id: desconto.id }).then(
        () => {
          setUpdate(update + 1);
          notifications.show({
            title: 'Desconto removido!',
            message: `O desconto ${desconto.nome_interno} foi removido.`,
            withCloseButton: true,
            icon: <IconTrashX />,
            color: 'red',
          });
        },
        (reason) => {
          msgAPIError(reason);
        }
      );
    }
  }

  const editDescontoDialog = (
    <Modal
      withCloseButton={false}
      closeOnEscape={false}
      closeOnClickOutside={false}
      size={'auto'}
      opened={dialogParams.visible}
      onClose={() => {
        setDialogParams({ create: false, visible: false });
      }}
      title={dialogParams.create ? 'Criar Desconto' : 'Editar Desconto'}>
      <form onSubmit={descontoForm.onSubmit(saveDesconto)}>
        <TextInput
          label={'Nome Interno'}
          icon={<IconPackage />}
          {...descontoForm.getInputProps('nome_interno')}
        />
        <Text color={'red'}>{descontoForm.errors['non_field_errors']}</Text>
        <TextInput
          label={'Titulo Cliente'}
          icon={<IconUser />}
          {...descontoForm.getInputProps('titulo_cliente')}
        />
        <NumberInput
          placeholder='Valor'
          label='Valor'
          precision={2}
          icon={<IconCurrencyEuro />}
          hideControls
          decimalSeparator=','
          thousandsSeparator='.'
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          formatter={(value) =>
            !Number.isNaN(parseFloat(value))
              ? // eslint-disable-next-line security/detect-unsafe-regex
                `${value} €`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
              : ''
          }
          {...descontoForm.getInputProps('valor')}
        />
        {!dialogParams.create && (
          <Text color={'red'}>
            Não deve alterar o valor após criado/vendido! Criar um novo e desativar este!
          </Text>
        )}

        <Textarea
          placeholder='Descrição...'
          label='Descrição'
          size='md'
          icon={<IconNotes />}
          autosize={true}
          minRows={2}
          maxRows={4}
          {...descontoForm.getInputProps('descricao')}
        />

        <Group
          position='right'
          mt='md'>
          <Button type='submit'>Guardar</Button>
          <Button
            type='button'
            onClick={() => setDialogParams({ create: false, visible: false })}>
            Cancelar
          </Button>
        </Group>
      </form>
    </Modal>
  );

  //--------------------------------------------------------------

  const columns = useMemo<MRT_ColumnDef<Desconto>[]>(
    () => [
      { accessorKey: 'nome_interno', header: 'Nome Interno', size: 200 },
      { accessorKey: 'titulo_cliente', header: 'Título Cliente', size: 200 },
      {
        accessorKey: 'valor',
        header: 'Valor',
        size: 100,
        Cell: (cell) => `${cell.cell.getValue()}€`,
      },
      {
        accessorFn: (row) => `${row.nr_vendidos}`,
        header: 'Atribuidos',
      },
    ],
    []
  );
  return (
    <Box>
      <MantineReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            mantineTableHeadCellProps: {
              align: 'left',
            },
            size: 50,
          },
        }}
        renderToolbarInternalActions={({ table }) => (
          <>
            <Button
              leftIcon={<IconPlus />}
              variant='outline'
              onClick={criarDesconto}>
              Adicionar Desconto
            </Button>

            <MRT_ShowHideColumnsButton table={table} />
            <MRT_ToggleDensePaddingButton table={table} />
          </>
        )}
        initialState={{
          density: 'xs',
          showGlobalFilter: true,
          pagination: { pageSize: 15, pageIndex: 0 },
        }}
        state={{
          isLoading,
        }}
        enableRowActions
        renderRowActions={({ row }) => (
          <Box sx={{ display: 'flex', gap: '4px' }}>
            <Tooltip label='Editar Desconto'>
              <ActionIcon onClick={() => editDesconto(row.original)}>
                <IconEdit />
              </ActionIcon>
            </Tooltip>
            <Tooltip label='Remover Produto'>
              <ActionIcon
                color='red'
                onClick={() => removeDesconto(row.original)}>
                <IconTrash />
              </ActionIcon>
            </Tooltip>
          </Box>
        )}
        columns={columns}
        data={descontos}
        enablePagination={false}
        enableColumnResizing={false}
        enableColumnFilters={false}
        enableBottomToolbar={false}
        enableGlobalFilter={false}
        enableSorting={true}
        positionActionsColumn='first'
        enableFullScreenToggle={false}
        localization={MRT_Localization_PT}
      />
      {editDescontoDialog}
    </Box>
  );
}
