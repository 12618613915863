/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PatchedTransporteLocalPartidaRequest } from '../models/PatchedTransporteLocalPartidaRequest';
import type { PatchedTransporteRequest } from '../models/PatchedTransporteRequest';
import type { Transporte } from '../models/Transporte';
import type { TransporteLocalPartida } from '../models/TransporteLocalPartida';
import type { TransporteLocalPartidaRequest } from '../models/TransporteLocalPartidaRequest';
import type { TransporteRequest } from '../models/TransporteRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TransporteService {

    /**
     * @returns Transporte
     * @throws ApiError
     */
    public static transporteList({
        campanha,
    }: {
        /**
         * Identifica univocamente uma campanha
         */
        campanha?: string,
    }): CancelablePromise<Array<Transporte>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/transporte',
            query: {
                'campanha': campanha,
            },
        });
    }

    /**
     * @returns Transporte
     * @throws ApiError
     */
    public static transporteCreate({
        requestBody,
    }: {
        requestBody: TransporteRequest,
    }): CancelablePromise<Transporte> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/transporte',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Transporte
     * @throws ApiError
     */
    public static transporteRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this transporte.
         */
        id: number,
    }): CancelablePromise<Transporte> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/transporte/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Transporte
     * @throws ApiError
     */
    public static transporteUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this transporte.
         */
        id: number,
        requestBody: TransporteRequest,
    }): CancelablePromise<Transporte> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/transporte/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Transporte
     * @throws ApiError
     */
    public static transportePartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this transporte.
         */
        id: number,
        requestBody?: PatchedTransporteRequest,
    }): CancelablePromise<Transporte> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/transporte/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static transporteDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this transporte.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/transporte/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns TransporteLocalPartida
     * @throws ApiError
     */
    public static transporteLocaisPartidaList(): CancelablePromise<Array<TransporteLocalPartida>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/transporte/locais_partida',
        });
    }

    /**
     * @returns TransporteLocalPartida
     * @throws ApiError
     */
    public static transporteLocaisPartidaCreate({
        requestBody,
    }: {
        requestBody: TransporteLocalPartidaRequest,
    }): CancelablePromise<TransporteLocalPartida> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/transporte/locais_partida',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TransporteLocalPartida
     * @throws ApiError
     */
    public static transporteLocaisPartidaRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this transporte local partida.
         */
        id: number,
    }): CancelablePromise<TransporteLocalPartida> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/transporte/locais_partida/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns TransporteLocalPartida
     * @throws ApiError
     */
    public static transporteLocaisPartidaUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this transporte local partida.
         */
        id: number,
        requestBody: TransporteLocalPartidaRequest,
    }): CancelablePromise<TransporteLocalPartida> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/transporte/locais_partida/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TransporteLocalPartida
     * @throws ApiError
     */
    public static transporteLocaisPartidaPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this transporte local partida.
         */
        id: number,
        requestBody?: PatchedTransporteLocalPartidaRequest,
    }): CancelablePromise<TransporteLocalPartida> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/transporte/locais_partida/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static transporteLocaisPartidaDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this transporte local partida.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/transporte/locais_partida/{id}',
            path: {
                'id': id,
            },
        });
    }

}
