import { useAuth } from '../user-auth/SlideInAuthContext';
import { SlideinRoleEnum } from '../slidein_api';
import { Navigate } from 'react-router-dom';

type PGprops = {
  children;
  roles: SlideinRoleEnum[];
  goHome?: boolean;
  goEditPerfil?: boolean;
};

export const ALL_ALLOW = [
  SlideinRoleEnum.MASTER,
  SlideinRoleEnum.FINANCE,
  SlideinRoleEnum.COMERCIAL,
  SlideinRoleEnum.ADMIN,
];
export const MASTER_ADMIN = [SlideinRoleEnum.MASTER, SlideinRoleEnum.ADMIN];
export const MASTER_FINANCE = [SlideinRoleEnum.MASTER, SlideinRoleEnum.FINANCE];
export const MASTER_ADMIN_FINANCE = [
  SlideinRoleEnum.MASTER,
  SlideinRoleEnum.FINANCE,
  SlideinRoleEnum.ADMIN,
];

export const MASTER = [SlideinRoleEnum.MASTER];
export const ADMIN = [SlideinRoleEnum.MASTER];

export function CheckPermission({
  children,
  roles = [],
  goHome = false,
  goEditPerfil = false,
}: PGprops) {
  const { colaborador } = useAuth();
  if (!colaborador) {
    return;
  }
  if (roles.find((ro) => ro === colaborador.slidein_role)) {
    return <>{children}</>;
  } else {
    if (goEditPerfil) {
      return (
        <Navigate
          to='/colaborador/meu_perfil'
          replace={true}
        />
      );
    }
    if (goHome) {
      return (
        <Navigate
          to='/'
          replace={true}
        />
      );
    }
    return;
  }
}

export function checkPermission(roles: SlideinRoleEnum[], colaborador) {
  if (!colaborador) {
    return false;
  }
  return !!roles.find((ro) => ro === colaborador.slidein_role);
}
