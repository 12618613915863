import { useCampanha } from '../CampanhaWrapperView';
import React, { useEffect, useMemo, useState } from 'react';
import {
  CampanhaColaborador,
  CampanhaService,
  Colaborador,
  ColaboradorService,
} from '../../../slidein_api';
import { msgAPIError } from '../../helpers';
import { Button, Group, Modal, Stack, Title } from '@mantine/core';
import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import { IconDeviceFloppy, IconPlane, IconUsers, IconX } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { useBread } from '../../../layout/context/BreadContext';

export function CampanhaColaboradores() {
  const { refreshCampanha } = useCampanha();
  const { campanhaID } = useParams();
  const { setBread } = useBread();
  useEffect(() => {
    setBread({
      campanha: {
        title: campanhaID,
        href: '/campanha/' + campanhaID,
        icon: <IconPlane />,
      },
      accao: {
        title: 'Colaboradores',
        icon: <IconUsers />,
      },
    });
  }, []);

  const [staff, setStaff] = useState<CampanhaColaborador[]>();
  const [colaboradores, setColaboradores] = useState<Colaborador[]>();
  const [addStaffModalVisible, setAddStaffModalVisible] = useState<boolean>(false);

  const fetchData = () => {
    CampanhaService.campanhaColaboradoresList({ campanha: campanhaID }).then(
      (staff_in) => {
        ColaboradorService.colaboradorList({}).then(
          (colaboradores) => {
            setColaboradores(
              colaboradores.filter(
                (colaborador) =>
                  staff_in.findIndex((st) => st.colaborador_ro.id === colaborador.id) === -1
              )
            );
          },

          (reason) => msgAPIError(reason)
        );
        setStaff(staff_in);
      },
      (reason) => msgAPIError(reason)
    );
  };
  useEffect(() => {
    fetchData();
  }, []);

  const columsStaffCampanha = useMemo<MRT_ColumnDef<CampanhaColaborador>[]>(
    () => [
      { accessorKey: 'colaborador_ro.nome_simples', header: 'Nome' },
      { accessorKey: 'colaborador_ro.email', header: 'Email' },
      { accessorKey: 'colaborador_ro.contacto_telemovel', header: 'Telemovel' },
      { accessorKey: 'colaborador_ro.slidein_role', header: 'Role' },
      { accessorKey: 'colaborador_ro.slidein_funcao', header: 'Função' },
    ],
    []
  );

  const tableStaffCampanha = useMantineReactTable<CampanhaColaborador>({
    columns: columsStaffCampanha,
    data: staff || [],
    enableMultiRowSelection: true,
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: 'pointer',
      },
      onClick: row.getToggleSelectedHandler(),
    }),
    enableRowSelection: true,
    enableSelectAll: true,
    selectAllMode: 'all',
    getRowId: (originalRow) => originalRow.id.toString(),
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFilters: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enablePagination: true,
    localization: MRT_Localization_PT,
    initialState: {
      density: 'xs',
    },
    renderTopToolbarCustomActions: () => (
      <Group grow>
        <Button
          variant={'outline'}
          disabled={tableStaffCampanha.getSelectedRowModel().rows.length <= 0}
          onClick={() => {
            removeStaff();
          }}>
          Remover Selecionados
        </Button>
        <Button
          variant={'outline'}
          onClick={() => {
            setAddStaffModalVisible(true);
          }}>
          Adicionar Colaborador
        </Button>
      </Group>
    ),
  });
  const removeStaff = () => {
    tableStaffCampanha.getSelectedRowModel().rows.forEach((dd) => {
      CampanhaService.campanhaColaboradoresDestroy({ id: dd.original.id }).then(
        () => {
          fetchData();
          refreshCampanha();
        },
        (reason) => msgAPIError(reason)
      );
    });
  };

  const columsColaborador = useMemo<MRT_ColumnDef<Colaborador>[]>(
    () => [
      { accessorKey: 'nome_simples', header: 'Nome' },
      { accessorKey: 'email', header: 'Email' },
      { accessorKey: 'contacto_telemovel', header: 'Telemovel' },
      { accessorKey: 'slidein_role', header: 'Role' },
      { accessorKey: 'slidein_funcao', header: 'Função' },
    ],
    []
  );
  const tableColaboradores = useMantineReactTable<Colaborador>({
    columns: columsColaborador,
    data: colaboradores ? colaboradores : [],
    enableRowSelection: true,
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: 'pointer',
      },
      onClick: row.getToggleSelectedHandler(),
    }),
    enableSelectAll: true,
    selectAllMode: 'all',
    getRowId: (originalRow) => originalRow.id.toString(),
    enableGlobalFilter: true,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enablePagination: true,
    localization: MRT_Localization_PT,
    initialState: {
      density: 'xs',
    },
    positionGlobalFilter: 'left',
    globalFilterFn: 'contains',
  });
  const addColaboradores = () => {
    tableColaboradores.getSelectedRowModel().rows.forEach((staff) => {
      CampanhaService.campanhaColaboradoresCreate({
        requestBody: {
          campanha: campanhaID,
          colaborador: parseInt(staff.id),
        },
      }).then(
        () => {
          fetchData();
          refreshCampanha();
          setAddStaffModalVisible(false);
        },
        (reason) => msgAPIError(reason)
      );
    });
  };

  return (
    <Stack>
      <MantineReactTable table={tableStaffCampanha} />
      <Modal
        centered
        title={'Escolher Colaboradores:'}
        opened={addStaffModalVisible}
        size={'auto'}
        onClose={() => {
          setAddStaffModalVisible(false);
        }}>
        <Title order={4}>
          {tableColaboradores.getSelectedRowModel().rows.length} Colaboradores Selecionados.
        </Title>
        <MantineReactTable table={tableColaboradores} />
        <Group
          position='right'
          mt='md'>
          <Button
            color={'yellow'}
            variant={'outline'}
            leftIcon={<IconX />}
            type='button'
            onClick={() => {
              setAddStaffModalVisible(false);
            }}>
            Cancelar
          </Button>
          <Button
            variant={'outline'}
            onClick={() => {
              addColaboradores();
            }}
            leftIcon={<IconDeviceFloppy />}
            type='button'>
            Adicionar
          </Button>
        </Group>
      </Modal>
    </Stack>
  );
}
