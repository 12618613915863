import React, { useState } from 'react';
import {
  ComprasViagem,
  Devolucao,
  DevolucaoRequest,
  DevolucaoSimples,
  EstadosTransacoesEnum,
  PagamentoManual,
  PagamentoManualRequest,
  PagamentoReferenciaMultibancoSimples,
  PagamentoService,
  PatchedDevolucaoRequest,
  PatchedPagamentoManualRequest,
  TipoEnum,
  Transacao,
} from '../../../../slidein_api';
import {
  ActionIcon,
  Badge,
  Button,
  Code,
  FileInput,
  Flex,
  Group,
  Modal,
  NumberInput,
  Select,
  Stack,
  Table,
  Textarea,
  TextInput,
  Tooltip,
} from '@mantine/core';
import {
  IconBuildingBank,
  IconClock2,
  IconCurrencyEuro,
  IconDeviceFloppy,
  IconDownload,
  IconEye,
  IconNotes,
  IconPencil,
  IconStatusChange,
  IconTrash,
  IconTrashX,
  IconUpload,
  IconX,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import { msgAPIError } from '../../../helpers';
import { notifications } from '@mantine/notifications';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import { DatePickerInput } from '@mantine/dates';

export function PagamentosViagem({
  viagem,
  doReload,
}: {
  viagem: ComprasViagem;
  doReload: () => void;
}) {
  const [modaDevolucao, setModaDevolucao] = useState<boolean>(false);
  const [novaDevolucao, setNovadevolucao] = useState<boolean>(false);
  const [modaManual, setModaManual] = useState<boolean>(false);
  const [novaManual, setNovaManual] = useState<boolean>(false);

  const devoForm = useForm<DevolucaoRequest | PatchedDevolucaoRequest | Devolucao>({
    initialValues: {} as DevolucaoRequest,
    validate: yupResolver(
      Yup.object({
        estado: Yup.string().oneOf(Object.values(EstadosTransacoesEnum)).required('Obrigatório'),
        valor: Yup.number().min(0).required('Obrigatório'),
        data_facturacao: Yup.date().required('Obrigatório'),
      })
    ),
    transformValues: (values) => {
      const di = values.data_facturacao ? new Date(values.data_facturacao) : null;
      return {
        ...values,
        data_facturacao: di
          ? `${di.getFullYear()}-${(di.getMonth() + 1).toString().padStart(2, '0')}-${di
              .getDate()
              .toString()
              .padStart(2, '0')}`
          : '',
      };
    },
  });

  const manuForm = useForm<
    PagamentoManual | PatchedPagamentoManualRequest | PagamentoManualRequest
  >({
    initialValues: {} as PagamentoManualRequest,
    validate: yupResolver(
      Yup.object({
        estado: Yup.string().oneOf(Object.values(EstadosTransacoesEnum)).required('Obrigatório'),
        valor: Yup.number().min(0).required('Obrigatório'),
        data_facturacao: Yup.date().required('Obrigatório'),
      })
    ),
    transformValues: (values) => {
      const di = values.data_facturacao ? new Date(values.data_facturacao) : null;
      return {
        ...values,
        data_facturacao: di
          ? `${di.getFullYear()}-${(di.getMonth() + 1).toString().padStart(2, '0')}-${di
              .getDate()
              .toString()
              .padStart(2, '0')}`
          : '',
      };
    },
  });

  const saveDevolucao = async (dev) => {
    if (novaDevolucao) {
      dev.viagem = viagem?.id;
      PagamentoService.pagamentoDevolucaoCreate({
        formData: dev as DevolucaoRequest,
      }).then(
        () => {
          doReload();
          setModaDevolucao(false);
        },
        (reason) => msgAPIError(reason)
      );
    } else {
      PagamentoService.pagamentoDevolucaoPartialUpdate({
        id: dev.id,
        formData: dev,
      }).then(
        () => {
          doReload();
          setModaDevolucao(false);
        },
        (reason) => msgAPIError(reason)
      );
    }
  };
  const saveManual = async (manu) => {
    if (novaManual) {
      manu.viagem = viagem?.id;
      PagamentoService.pagamentoManualCreate({
        formData: manu as PagamentoManualRequest,
      }).then(
        () => {
          doReload();
          setModaManual(false);
        },
        (reason) => msgAPIError(reason)
      );
    } else {
      PagamentoService.pagamentoManualPartialUpdate({
        id: manu.id,
        formData: manu,
      }).then(
        () => {
          doReload();
          setModaManual(false);
        },
        (reason) => msgAPIError(reason)
      );
    }
  };

  const editDevo = (newDevo, devo) => {
    if (newDevo) {
      devoForm.reset();
      devoForm.setValues({
        estado: undefined,
        valor: undefined,
        notas: '',
      });
      setNovadevolucao(true);
    } else {
      setNovadevolucao(false);
      devoForm.setValues({
        id: devo.id,
        estado: devo.estado,
        viagem: devo.viagem,
        valor: devo.valor,
        notas: devo.notas,
      });
    }
    setModaDevolucao(true);
  };

  const editManu = (newManu, manu) => {
    if (newManu) {
      manuForm.reset();
      manuForm.setValues({
        estado: undefined,
        valor: undefined,
        notas: '',
      });
      setNovaManual(true);
    } else {
      setNovaManual(false);
      manuForm.setValues({
        id: manu.id,
        estado: manu.estado,
        viagem: manu.viagem,
        valor: manu.valor,
        notas: manu.notas,
      });
    }
    setModaManual(true);
  };
  return (
    <Stack>
      <Flex
        direction={'row'}
        justify={'end'}>
        <Group mt={'sm'}>
          <Button
            variant={'outline'}
            onClick={() => {
              editManu(true, undefined);
            }}>
            Adicionar Pagamento Manual
          </Button>
          <Button
            variant={'outline'}
            onClick={() => {
              editDevo(true, undefined);
            }}>
            Adicionar Devolução
          </Button>
        </Group>
      </Flex>
      <Table>
        <thead>
          <tr>
            <th>Tipo</th>
            <th>Estado</th>
            <th>Data Facturação</th>
            <th>Confirmada</th>
            <th>Data Pagamento</th>
            <th>Valor</th>
            <th>Ref/trid(EuPago)</th>
            <th>Banco/Local</th>
            <th>Detalhes</th>
            <th>Acções</th>
          </tr>
        </thead>
        <tbody>
          {viagem?.transacoes.map((tra: Transacao) => {
            let trid = <></>;
            let confirmada = <>--</>;
            let actions = <>--</>;
            let about = <>--</>;
            let valor = tra.valor + '€';
            if (tra.tipo == TipoEnum.REF_MULTIBANCO) {
              confirmada = (tra as PagamentoReferenciaMultibancoSimples).confirmada ? (
                <>Sim</>
              ) : (
                <>Não</>
              );
              const ref = (tra as PagamentoReferenciaMultibancoSimples).referencia;
              trid = (
                <Tooltip
                  multiline
                  width={180}
                  label={`Referência:${ref.referencia} Entidade:${ref.entidade}`}
                  openDelay={500}>
                  <Code>{(tra as PagamentoReferenciaMultibancoSimples).trid}</Code>
                </Tooltip>
              );
              about = (
                <Tooltip
                  label={JSON.stringify(
                    (tra as PagamentoReferenciaMultibancoSimples).ref_gen,
                    null,
                    4
                  )}>
                  <IconEye />
                </Tooltip>
              );
            }
            if (tra.tipo == TipoEnum.DEVOLU_O) {
              valor = '-' + valor;
              const jjj = tra as DevolucaoSimples;

              about = (
                <Tooltip label={`${jjj.notas || '--'}`}>
                  <a
                    href={jjj.documento}
                    target='_blank'
                    rel='noreferrer'
                    download>
                    <IconDownload />
                  </a>
                </Tooltip>
              );
              actions = (
                <Group>
                  <Tooltip label={'Editar'}>
                    <ActionIcon
                      onClick={() => {
                        editDevo(false, tra);
                      }}
                      color={'yellow'}
                      variant={'outline'}>
                      <IconPencil />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label={'Eliminar'}>
                    <ActionIcon
                      color='red'
                      variant={'outline'}
                      onClick={() => {
                        if (confirm('Confirma que deseja remover a devolução?')) {
                          PagamentoService.pagamentoDevolucaoDestroy({ id: tra.id }).then(
                            () => {
                              doReload();
                              notifications.show({
                                title: 'Transação removida!',
                                message: `A devolução foi removida`,
                                withCloseButton: true,
                                icon: <IconTrashX />,
                                color: 'red',
                              });
                            },
                            (reason) => {
                              msgAPIError(reason);
                            }
                          );
                        }
                      }}>
                      <IconTrash />
                    </ActionIcon>
                  </Tooltip>
                </Group>
              );
            }
            if (tra.tipo == TipoEnum.MANUAL) {
              const jjj = tra as PagamentoManual;

              about = (
                <Tooltip label={`${jjj.notas || '--'} Doc:${jjj.documento || '--'}`}>
                  <a
                    href={jjj.documento}
                    target='_blank'
                    rel='noreferrer'
                    download>
                    <IconDownload />
                  </a>
                </Tooltip>
              );
              actions = (
                <Group>
                  <Tooltip label={'Editar'}>
                    <ActionIcon
                      onClick={() => {
                        editManu(false, tra);
                      }}
                      color={'yellow'}
                      variant={'outline'}>
                      <IconPencil />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label={'Eliminar'}>
                    <ActionIcon
                      color='red'
                      variant={'outline'}
                      onClick={() => {
                        if (confirm('Confirma que deseja remover o pagamento?')) {
                          PagamentoService.pagamentoManualDestroy({ id: tra.id }).then(
                            () => {
                              doReload();
                              notifications.show({
                                title: 'Transação removida!',
                                message: `O Pagamento foi removido`,
                                withCloseButton: true,
                                icon: <IconTrashX />,
                                color: 'red',
                              });
                            },
                            (reason) => {
                              msgAPIError(reason);
                            }
                          );
                        }
                      }}>
                      <IconTrash />
                    </ActionIcon>
                  </Tooltip>
                </Group>
              );
            }

            return (
              <tr key={tra.id}>
                <td>
                  <Badge
                    color='gray'
                    size='lg'
                    radius='xs'>
                    {tra.tipo}
                  </Badge>
                </td>
                <td>
                  <Badge
                    variant={'outline'}
                    fullWidth={false}
                    color={'gray'}>
                    {tra.estado}
                  </Badge>
                </td>
                <td>
                  {tra.data_facturacao
                    ? dayjs(tra.data_facturacao).locale('pt').format('YYYY-MM-DD')
                    : '-'}
                </td>
                <td>{confirmada}</td>
                <td>{dayjs(tra.creation_date).locale('pt').format('YYYY-MM-DD HH:mm:ss')}</td>
                <td>{valor}</td>
                <td>{trid}</td>
                <td>{tra.banco}</td>
                <td>{about}</td>
                <td>{actions}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Modal
        opened={modaManual}
        onClose={() => setModaManual(false)}
        closeOnEscape={false}
        closeOnClickOutside={false}
        withCloseButton={false}
        title='Pagamento Manual'
        centered>
        <form onSubmit={manuForm.onSubmit(saveManual)}>
          <Select
            label={'Estado'}
            name={'estado'}
            id={'estado'}
            icon={<IconStatusChange />}
            data={Object.keys(EstadosTransacoesEnum).map((x) => {
              return {
                label: EstadosTransacoesEnum[x as keyof typeof EstadosTransacoesEnum],
                value: EstadosTransacoesEnum[x as keyof typeof EstadosTransacoesEnum],
              };
            })}
            {...manuForm.getInputProps('estado')}
          />
          <NumberInput
            placeholder='Valor'
            label='Valor'
            precision={2}
            name={'valor'}
            id={'valor'}
            icon={<IconCurrencyEuro />}
            hideControls
            decimalSeparator=','
            thousandsSeparator='.'
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            formatter={(value) =>
              !Number.isNaN(parseFloat(value))
                ? // eslint-disable-next-line security/detect-unsafe-regex
                  `${value} €`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                : ''
            }
            {...manuForm.getInputProps('valor')}
          />
          <TextInput
            label='Banco'
            size={'md'}
            id={'banco'}
            icon={<IconBuildingBank />}
            {...manuForm.getInputProps('banco')}></TextInput>
          <Textarea
            placeholder='notas...'
            id={'notas'}
            name={'notas'}
            label='Notas:'
            size='md'
            icon={<IconNotes />}
            autosize
            minRows={2}
            {...manuForm.getInputProps('notas')}
          />
          <FileInput
            label={'Documento Auxiliar'}
            multiple={false}
            icon={<IconUpload />}
            {...manuForm.getInputProps('documento')}
          />
          <DatePickerInput
            label='Data Facturação'
            id={'data_facturacao'}
            locale={'pt'}
            firstDayOfWeek={1}
            dropdownType={'modal'}
            placeholder='Escolher Data'
            defaultValue={new Date()}
            icon={<IconClock2 stroke={1.5} />}
            valueFormat='DD/MM/YYYY'
            {...manuForm.getInputProps('data_facturacao')}
            value={
              manuForm.values.data_facturacao
                ? dayjs(manuForm.values.data_facturacao).toDate()
                : null
            }
          />

          <Group
            position='right'
            mt='md'>
            <Button
              color={'yellow'}
              variant={'outline'}
              leftIcon={<IconX />}
              type='button'
              onClick={() => setModaManual(false)}>
              Cancelar
            </Button>
            <Button
              type='submit'
              variant={'outline'}
              leftIcon={<IconDeviceFloppy />}>
              Guardar
            </Button>
          </Group>
        </form>
      </Modal>
      <Modal
        opened={modaDevolucao}
        onClose={() => setModaDevolucao(false)}
        closeOnEscape={false}
        closeOnClickOutside={false}
        withCloseButton={false}
        title='Devolução'
        centered>
        <form onSubmit={devoForm.onSubmit(saveDevolucao)}>
          <Select
            label={'Estado'}
            name={'estado'}
            id={'estado'}
            icon={<IconStatusChange />}
            data={Object.keys(EstadosTransacoesEnum).map((x) => {
              return {
                label: EstadosTransacoesEnum[x as keyof typeof EstadosTransacoesEnum],
                value: EstadosTransacoesEnum[x as keyof typeof EstadosTransacoesEnum],
              };
            })}
            {...devoForm.getInputProps('estado')}
          />
          <NumberInput
            placeholder='Valor'
            label='Valor'
            precision={2}
            name={'valor'}
            id={'valor'}
            icon={<IconCurrencyEuro />}
            hideControls
            decimalSeparator=','
            thousandsSeparator='.'
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            formatter={(value) =>
              !Number.isNaN(parseFloat(value))
                ? // eslint-disable-next-line security/detect-unsafe-regex
                  `${value} €`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                : ''
            }
            {...devoForm.getInputProps('valor')}
          />
          <TextInput
            label='Banco'
            size={'md'}
            id={'banco'}
            icon={<IconBuildingBank />}
            {...devoForm.getInputProps('banco')}></TextInput>
          <Textarea
            placeholder='notas...'
            id={'notas'}
            name={'notas'}
            label='Notas:'
            size='md'
            icon={<IconNotes />}
            autosize
            minRows={2}
            {...devoForm.getInputProps('notas')}
          />
          <FileInput
            label={'Documento Auxiliar'}
            multiple={false}
            icon={<IconUpload />}
            {...devoForm.getInputProps('documento')}
          />
          <DatePickerInput
            label='Data Facturação'
            id={'data_facturacao'}
            locale={'pt'}
            firstDayOfWeek={1}
            dropdownType={'modal'}
            placeholder='Escolher Data'
            defaultValue={new Date()}
            icon={<IconClock2 stroke={1.5} />}
            valueFormat='DD/MM/YYYY'
            {...devoForm.getInputProps('data_facturacao')}
            value={
              devoForm.values.data_facturacao
                ? dayjs(devoForm.values.data_facturacao).toDate()
                : null
            }
          />

          <Group
            position='right'
            mt='md'>
            <Button
              color={'yellow'}
              variant={'outline'}
              leftIcon={<IconX />}
              type='button'
              onClick={() => setModaDevolucao(false)}>
              Cancelar
            </Button>
            <Button
              type='submit'
              variant={'outline'}
              leftIcon={<IconDeviceFloppy />}>
              Guardar
            </Button>
          </Group>
        </form>
      </Modal>
    </Stack>
  );
}
