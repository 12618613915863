/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Aberto` - Aberto
 * * `Suspenso` - Suspenso
 * * `Fechado` - Fechado
 * * `Inscrições Bloqueadas` - Incricoesbloqueadas
 */
export enum GrupoEstadoEnum {
    ABERTO = 'Aberto',
    SUSPENSO = 'Suspenso',
    FECHADO = 'Fechado',
    INSCRI_ES_BLOQUEADAS = 'Inscrições Bloqueadas',
}
