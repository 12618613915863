/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Master` - ♚
 * * `Admin` - ♜
 * * `Finance` - ♛
 * * `Comercial` - ♞
 * * `TravelStaff` - ♟
 */
export enum SlideinRoleEnum {
    MASTER = 'Master',
    ADMIN = 'Admin',
    FINANCE = 'Finance',
    COMERCIAL = 'Comercial',
    TRAVEL_STAFF = 'TravelStaff',
}
