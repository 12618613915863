/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Pendente` - Pedente
 * * `Pago` - Pago
 * * `Anulado` - Anulado
 */
export enum EstadosTransacoesEnum {
    PENDENTE = 'Pendente',
    PAGO = 'Pago',
    ANULADO = 'Anulado',
}
