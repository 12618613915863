/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Cartão Cidadão` - Cc
 * * `Passaporte` - Passport
 */
export enum TipoIdEnum {
    CART_O_CIDAD_O = 'Cartão Cidadão',
    PASSAPORTE = 'Passaporte',
}
