import React, { useEffect, useMemo, useState } from 'react';
import { GrupoEstadoEnum, GrupoService, GrupoSimples, OpenAPI } from '../../../slidein_api';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useBread } from '../../../layout/context/BreadContext';
import { msgAPIError } from '../../helpers';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_GlobalFilterTextInput,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  useMantineReactTable,
} from 'mantine-react-table';
import { Badge, Button, Code, Flex, LoadingOverlay, Progress, Stack, Tooltip } from '@mantine/core';
import {
  IconDownload,
  IconFileSpreadsheet,
  IconPlane,
  IconPlus,
  IconUsersGroup,
} from '@tabler/icons-react';
import { MRT_Localization_PT } from 'mantine-react-table/locales/pt';
import {
  checkPermission,
  CheckPermission,
  MASTER,
  MASTER_ADMIN,
} from '../../../components/CheckPermission';
import { useAuth } from '../../../user-auth/SlideInAuthContext';

import axios from 'axios';
import FileSaver from 'file-saver';
import { useDebouncedValue } from '@mantine/hooks';

export function CampanhaGrupoView() {
  const { campanhaID } = useParams();
  const { setBread } = useBread();
  const { colaborador } = useAuth();
  useEffect(() => {
    setBread({
      campanha: {
        title: campanhaID,
        href: '/campanha/' + campanhaID,
        icon: <IconPlane />,
      },
      accao: {
        title: 'Grupos',
        icon: <IconUsersGroup />,
      },
    });
  }, []);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);

  const [grupos, setGrupos] = useState<GrupoSimples[]>([]);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [rowCount, setRowCount] = useState(0);
  const [debounced] = useDebouncedValue(globalFilter, 400, { leading: true });

  function paramsNormalize(columnFilters: MRT_ColumnFiltersState) {
    const params = {};
    columnFilters.forEach((c) => (params[c.id] = c.value));
    return params;
  }

  function filter_props(values: GrupoSimples[]) {
    const params = {};
    let ret = values;
    columnFilters.forEach((c) => (params[c.id] = c.value));
    if (params['nrCancelados']) {
      if (params['nrCancelados'][0]) {
        ret = ret.filter((gu) => gu.nr_cancelados >= parseInt(params['nrCancelados'][0]));
      }
      if (params['nrCancelados'][1]) {
        ret = ret.filter((gu) => gu.nr_cancelados <= parseInt(params['nrCancelados'][1]));
      }
    }
    if (params['nrBloqueadosPagamento']) {
      if (params['nrBloqueadosPagamento'][0]) {
        ret = ret.filter(
          (gu) => gu.nr_bloqueados_pagamento >= parseInt(params['nrBloqueadosPagamento'][0])
        );
      }
      if (params['nrBloqueadosPagamento'][1]) {
        ret = ret.filter(
          (gu) => gu.nr_bloqueados_pagamento <= parseInt(params['nrBloqueadosPagamento'][1])
        );
      }
    }
    if (params['nrViajantes']) {
      if (params['nrViajantes'][0]) {
        ret = ret.filter((gu) => gu.nr_viajantes >= parseInt(params['nrViajantes'][0]));
      }
      if (params['nrViajantes'][1]) {
        ret = ret.filter((gu) => gu.nr_viajantes <= parseInt(params['nrViajantes'][1]));
      }
    }
    if (params['nrSemQuarto']) {
      if (params['nrSemQuarto'][0]) {
        ret = ret.filter((gu) => gu.nr_sem_quarto >= parseInt(params['nrSemQuarto'][0]));
      }
      if (params['nrSemQuarto'][1]) {
        ret = ret.filter((gu) => gu.nr_sem_quarto <= parseInt(params['nrSemQuarto'][1]));
      }
    }
    if (params['nrSemTransporte']) {
      if (params['nrSemTransporte'][0]) {
        ret = ret.filter((gu) => gu.nr_sem_transporte >= parseInt(params['nrSemTransporte'][0]));
      }
      if (params['nrSemTransporte'][1]) {
        ret = ret.filter((gu) => gu.nr_sem_transporte <= parseInt(params['nrSemTransporte'][1]));
      }
    }
    if (params['percentPago']) {
      if (params['percentPago'][0]) {
        ret = ret.filter((gu) => gu.percent_pago >= parseInt(params['percentPago'][0]));
      }
      if (params['percentPago'][1]) {
        ret = ret.filter((gu) => gu.percent_pago <= parseInt(params['percentPago'][1]));
      }
    }
    if (params['nrClientesPrestaAtrasada']) {
      if (params['nrClientesPrestaAtrasada'][0]) {
        ret = ret.filter(
          (gu) => gu.nr_clientes_presta_atrasada >= parseInt(params['nrClientesPrestaAtrasada'][0])
        );
      }
      if (params['nrClientesPrestaAtrasada'][1]) {
        ret = ret.filter(
          (gu) => gu.nr_clientes_presta_atrasada <= parseInt(params['nrClientesPrestaAtrasada'][1])
        );
      }
    }
    return ret;
  }

  const fetchData = async () => {
    setIsRefetching(true);
    setLoading(true);
    GrupoService.grupoSimplesList({
      ...paramsNormalize(columnFilters),
      search: globalFilter ?? '',
      campanha: campanhaID,
    }).then(
      (value) => {
        const cenas = filter_props(value);
        setGrupos(cenas);
        setRowCount(cenas.length || 0);
        setIsRefetching(false);
        setLoading(false);
      },
      (reason) => {
        msgAPIError(reason);
        setLoading(false);
        setIsRefetching(false);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, [columnFilters, debounced]);

  const columnsGrupo = useMemo<MRT_ColumnDef<GrupoSimples>[]>(
    () => [
      {
        accessorKey: 'codigo',
        size: 200,
        id: 'codigo',
        header: 'Codigo',
        Cell: (cell) => (
          <Link
            relative={'path'}
            to={'grupo/' + cell.cell.getValue()}>
            <Code>{cell.cell.getValue().toString()}</Code>
          </Link>
        ),
        Footer: (props: { table }) => {
          return <b>{props.table.getFilteredRowModel().rows.length} Grupos</b>;
        },
      },
      {
        accessorKey: 'instituicao',
        size: 200,
        id: 'instituicao',
        header: 'Instituição',
        enableGrouping: true,
        aggregationFn: 'count',
        Cell: (cell) => {
          return (
            <Tooltip label={cell.row.original.instituicao}>
              <div>{cell.row.original.instituicao}</div>
            </Tooltip>
          );
        },
      },
      {
        accessorKey: 'estado',
        size: 80,
        id: 'estado',
        filterVariant: 'select',
        mantineFilterSelectProps: {
          data: [
            GrupoEstadoEnum.ABERTO.toString(),
            GrupoEstadoEnum.FECHADO.toString(),
            GrupoEstadoEnum.SUSPENSO.toString(),
          ] as never,
        },
        header: 'Estado',
        Cell: (cell) => {
          const status: string = cell.cell.getValue().toString();
          let color: string;
          switch (status) {
            case GrupoEstadoEnum.SUSPENSO:
              color = 'yellow';
              break;
            case GrupoEstadoEnum.ABERTO:
              color = 'green';
              break;
            case GrupoEstadoEnum.FECHADO:
              color = 'white';
              break;
            default:
              color = 'gray';
          }
          return (
            <Badge
              color={color}
              size='md'
              radius='md'
              variant='filled'>
              {status}
            </Badge>
          );
        },
      },
      {
        accessorFn: (gu) => gu.comercial_responsavel || '',
        id: 'comercialResponsavel',
        enableGrouping: true,
        aggregationFn: 'count',
        header: 'Comercial',
        filterVariant: 'text',
        size: 80,
      },
      {
        accessorFn: (gu) => gu.admin_responsavel || '',
        id: 'adminResponsavel',
        header: 'Administrador',
        enableGrouping: true,
        aggregationFn: 'count',
        filterVariant: 'text',
        size: 80,
      },

      {
        accessorKey: 'nr_viajantes',
        size: 50,
        id: 'nrViajantes',
        filterVariant: 'range',
        filterFn: 'inNumberRange',
        header: 'Nrº inscritos',
        Cell: (cell) => {
          return (cell.cell.getValue() || 0).toString();
        },
        aggregationFn: 'count',
        Footer: (props: { table }) => {
          return (
            <b>
              {props.table
                .getFilteredRowModel()
                .rows.reduce((acu, rr) => acu + rr.original.nr_viajantes, 0)}
              &nbsp; Inscritos
            </b>
          );
        },
      },
      {
        accessorKey: 'nr_cancelados',
        size: 50,
        filterVariant: 'range',
        id: 'nrCancelados',
        filterFn: 'inNumberRange',
        header: 'Nrº Cancelados',
        Cell: (cell) => {
          return (cell.cell.getValue() || 0).toString();
        },
        Footer: (props: { table }) => {
          return (
            <b>
              {props.table
                .getFilteredRowModel()
                .rows.reduce((acu, rr) => acu + rr.original.nr_cancelados || 0, 0)}
              &nbsp; Cancelados
            </b>
          );
        },
      },
      {
        accessorKey: 'nr_bloqueados_pagamento',
        size: 50,
        filterVariant: 'range',
        id: 'nrBloqueadosPagamento',
        filterFn: 'inNumberRange',
        header: 'Nrº Bloqueados Falta Pagamento',
        Cell: (cell) => {
          return (cell.cell.getValue() || 0).toString();
        },
        Footer: (props: { table }) => {
          return (
            <b>
              {props.table
                .getFilteredRowModel()
                .rows.reduce((acu, rr) => acu + rr.original.nr_bloqueados_pagamento || 0, 0)}
              &nbsp; Bloqueados
            </b>
          );
        },
      },
      {
        accessorKey: 'nr_sem_quarto',
        size: 50,
        header: 'Nrº Sem Quarto',
        filterVariant: 'range',
        filterFn: 'inNumberRange',
        id: 'nrSemQuarto',
        Cell: (cell) => {
          return (cell.row.original.nr_sem_quarto || 0).toString();
        },
      },
      {
        accessorKey: 'nr_sem_transporte',
        size: 50,
        id: 'nrSemTransporte',
        header: 'Nrº Sem Transporte',
        filterVariant: 'range',
        filterFn: 'inNumberRange',

        Cell: (cell) => {
          return (cell.row.original.nr_sem_transporte || 0).toString();
        },
      },
      {
        accessorKey: 'nr_clientes_presta_atrasada',
        size: 50,
        header: 'Pagamentos Atrasados',
        id: 'nrClientesPrestaAtrasada',
        filterVariant: 'range',
        filterFn: 'inNumberRange',
        Cell: (cell) => {
          return (cell.cell.getValue() || 0).toString();
        },
        Footer: (props: { table }) => {
          return (
            <b>
              {props.table
                .getFilteredRowModel()
                .rows.reduce((acu, rr) => acu + rr.original.nr_clientes_presta_atrasada || 0, 0)}
              &nbsp; Pag. Atrasados
            </b>
          );
        },
      },
      {
        accessorKey: 'percent_pago',
        size: 100,
        id: 'percentPago',
        header: 'Percentagem Pago',
        filterVariant: 'range',
        filterFn: 'inNumberRange',

        Cell: (cell) => (
          <Progress
            value={parseInt(cell.cell.getValue().toString())}
            label={cell.cell.getValue().toString() + '%'}
            size={20}
            radius='xl'
            styles={{ label: { color: 'black' } }}
          />
        ),
      },
    ],
    []
  );

  const gruposTable = useMantineReactTable({
    data: grupos || [],
    columns: columnsGrupo,
    manualFiltering: true,
    enableGrouping: true,
    enableColumnOrdering: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    rowCount: rowCount,
    positionGlobalFilter: 'none',
    renderToolbarInternalActions: ({ table }) => (
      <Flex
        direction={'row'}
        wrap={'wrap'}
        gap={'sm'}
        justify={'stretch'}>
        <MRT_GlobalFilterTextInput table={table} />
        <CheckPermission
          roles={MASTER_ADMIN}
          goHome={false}>
          <Button
            leftIcon={<IconPlus />}
            variant='outline'
            size='md'
            onClick={() => {
              navigate('grupo/criar');
            }}>
            Adicionar Grupo
          </Button>
        </CheckPermission>
        <CheckPermission
          roles={MASTER}
          goHome={false}>
          <Button
            color='lightblue'
            size='md'
            onClick={() => {
              setLoading(true);
              const cenas: {
                adminResponsavel?: string;
                campanha?: string;
                codigo?: string;
                comercialResponsavel?: string;
                estado?: string;
                instituicao?: string;
                mostrarTransporte?: boolean;
                mostrarQuartos?: boolean;
                chefeEditavel?: boolean;
                ordering?: string;
                search?: string;
              } = {
                ...paramsNormalize(columnFilters),
                search: globalFilter ?? '',
                campanha: campanhaID,
              };
              const url = `${OpenAPI.BASE}/gestao/grupo/simples/mapa`;
              axios
                .get(url, {
                  responseType: 'blob',
                  params: {
                    admin_responsavel: cenas.adminResponsavel,
                    campanha: cenas.campanha,
                    codigo: cenas.codigo,
                    comercial_responsavel: cenas.comercialResponsavel,
                    estado: cenas.estado,
                    instituicao: cenas.instituicao,
                    mostrar_transporte: cenas.mostrarTransporte,
                    mostrar_quartos: cenas.mostrarQuartos,
                    chefe_editavel: cenas.chefeEditavel,
                    ordering: cenas.ordering,
                    search: cenas.search,
                  },
                })
                .then(
                  (res) => {
                    const blob = new Blob([res.data], {
                      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
                    });
                    FileSaver.saveAs(blob, 'grupos.xlsx');
                    setLoading(false);
                    return res;
                  },
                  (reason) => {
                    msgAPIError(reason);
                    setLoading(false);
                  }
                );
            }}
            rightIcon={<IconFileSpreadsheet />}
            leftIcon={<IconDownload />}
            variant='outline'>
            .xlsx
          </Button>
        </CheckPermission>

        <MRT_ToggleFiltersButton table={table} />
        <MRT_ShowHideColumnsButton table={table} />
      </Flex>
    ),
    state: {
      columnFilters,
      globalFilter,
      showProgressBars: isRefetching || loading,
    },
    initialState: {
      density: 'xs',
      showGlobalFilter: true,
      // pagination: { pageIndex: 0, pageSize: 15 },
      columnVisibility: {
        codigo: true,
        mostrar_transporte: false,
        mostrar_quartos: false,
        chefe_editavel: false,
        nr_sem_quarto: false,
        nr_sem_transporte: false,
        comercialResponsavel: checkPermission(MASTER_ADMIN, colaborador),
        adminResponsavel: checkPermission(MASTER, colaborador),
      },
    },

    mantineTableProps: {
      highlightOnHover: true,
      withColumnBorders: true,
    },
    enableFullScreenToggle: false,
    localization: MRT_Localization_PT,
    globalFilterFn: 'contains',
    mantineTableBodyRowProps: ({ row }) => ({
      href: 'grupo/' + row.original.codigo,
    }),
  });

  return (
    <Stack>
      <LoadingOverlay visible={loading}></LoadingOverlay>
      <MantineReactTable table={gruposTable} />
    </Stack>
  );
}
