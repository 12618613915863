import { Tabs } from '@mantine/core';
import { VendasPacotes } from './VendasPacotes';
import { VendasActividades } from './VendasActividades';
import { VendasSeguros } from './VendasSeguros';
import { VendasDescontos } from './VendasDescontos';
import { VendasSuplementos } from './VendasSuplementos';
import {
  IconCoinEuro,
  IconConfetti,
  IconHotelService,
  IconPackage,
  IconPlane,
  IconReceipt2,
  IconReceiptTax,
  IconShieldLock,
} from '@tabler/icons-react';
import { useBread } from '../../../layout/context/BreadContext';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { VendasOutros } from './VendasOutros';

export function Vendas() {
  const { campanhaID } = useParams();
  const { setBread } = useBread();
  useEffect(() => {
    setBread({
      campanha: {
        title: campanhaID,
        href: '/campanha/' + campanhaID,
        icon: <IconPlane />,
      },
      accao: {
        title: 'Vendas',
        icon: <IconCoinEuro />,
      },
    });
  }, []);

  return (
    <Tabs defaultValue='pacotes'>
      <Tabs.List>
        <Tabs.Tab
          value='pacotes'
          icon={<IconPackage />}>
          Alojamentos/Pacotes
        </Tabs.Tab>
        <Tabs.Tab
          value='seguros'
          icon={<IconShieldLock />}>
          Seguros
        </Tabs.Tab>
        <Tabs.Tab
          value='actividades'
          icon={<IconConfetti />}>
          Actividades/Opcionais
        </Tabs.Tab>
        <Tabs.Tab
          value='suplementos'
          icon={<IconHotelService />}>
          Suplementos
        </Tabs.Tab>
        <Tabs.Tab
          value='outros'
          icon={<IconReceiptTax />}>
         Outros
        </Tabs.Tab>
        <Tabs.Tab
          value='descontos'
          icon={<IconReceipt2 />}>
          Descontos
        </Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel value='pacotes'>
        <VendasPacotes />
      </Tabs.Panel>
      <Tabs.Panel value='seguros'>
        <VendasSeguros />
      </Tabs.Panel>
      <Tabs.Panel value='actividades'>
        <VendasActividades />
      </Tabs.Panel>
      <Tabs.Panel value='suplementos'>
        <VendasSuplementos />
      </Tabs.Panel>
      <Tabs.Panel value='outros'>
        <VendasOutros />
      </Tabs.Panel>
      <Tabs.Panel value='descontos'>
        <VendasDescontos />
      </Tabs.Panel>
    </Tabs>
  );
}
