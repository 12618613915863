/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentosCondicoesGeraisViagem } from '../models/DocumentosCondicoesGeraisViagem';
import type { DocumentosCondicoesGeraisViagemRequest } from '../models/DocumentosCondicoesGeraisViagemRequest';
import type { DocumentosPrivacidade } from '../models/DocumentosPrivacidade';
import type { DocumentosPrivacidadeRequest } from '../models/DocumentosPrivacidadeRequest';
import type { OutrosDocumentos } from '../models/OutrosDocumentos';
import type { OutrosDocumentosRequest } from '../models/OutrosDocumentosRequest';
import type { PatchedDocumentosCondicoesGeraisViagemRequest } from '../models/PatchedDocumentosCondicoesGeraisViagemRequest';
import type { PatchedDocumentosPrivacidadeRequest } from '../models/PatchedDocumentosPrivacidadeRequest';
import type { PatchedOutrosDocumentosRequest } from '../models/PatchedOutrosDocumentosRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DocsService {

    /**
     * @returns DocumentosCondicoesGeraisViagem
     * @throws ApiError
     */
    public static docsCondgeraisList(): CancelablePromise<Array<DocumentosCondicoesGeraisViagem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/docs/condgerais',
        });
    }

    /**
     * @returns DocumentosCondicoesGeraisViagem
     * @throws ApiError
     */
    public static docsCondgeraisCreate({
        formData,
    }: {
        formData: DocumentosCondicoesGeraisViagemRequest,
    }): CancelablePromise<DocumentosCondicoesGeraisViagem> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/docs/condgerais',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns DocumentosCondicoesGeraisViagem
     * @throws ApiError
     */
    public static docsCondgeraisRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this Documento Condições Gerais.
         */
        id: number,
    }): CancelablePromise<DocumentosCondicoesGeraisViagem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/docs/condgerais/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns DocumentosCondicoesGeraisViagem
     * @throws ApiError
     */
    public static docsCondgeraisUpdate({
        id,
        formData,
    }: {
        /**
         * A unique integer value identifying this Documento Condições Gerais.
         */
        id: number,
        formData: DocumentosCondicoesGeraisViagemRequest,
    }): CancelablePromise<DocumentosCondicoesGeraisViagem> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/docs/condgerais/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns DocumentosCondicoesGeraisViagem
     * @throws ApiError
     */
    public static docsCondgeraisPartialUpdate({
        id,
        formData,
    }: {
        /**
         * A unique integer value identifying this Documento Condições Gerais.
         */
        id: number,
        formData?: PatchedDocumentosCondicoesGeraisViagemRequest,
    }): CancelablePromise<DocumentosCondicoesGeraisViagem> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/docs/condgerais/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static docsCondgeraisDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this Documento Condições Gerais.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/docs/condgerais/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns OutrosDocumentos
     * @throws ApiError
     */
    public static docsOutrosList(): CancelablePromise<Array<OutrosDocumentos>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/docs/outros',
        });
    }

    /**
     * @returns OutrosDocumentos
     * @throws ApiError
     */
    public static docsOutrosCreate({
        formData,
    }: {
        formData: OutrosDocumentosRequest,
    }): CancelablePromise<OutrosDocumentos> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/docs/outros',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns OutrosDocumentos
     * @throws ApiError
     */
    public static docsOutrosRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this Outro Documento.
         */
        id: number,
    }): CancelablePromise<OutrosDocumentos> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/docs/outros/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns OutrosDocumentos
     * @throws ApiError
     */
    public static docsOutrosUpdate({
        id,
        formData,
    }: {
        /**
         * A unique integer value identifying this Outro Documento.
         */
        id: number,
        formData: OutrosDocumentosRequest,
    }): CancelablePromise<OutrosDocumentos> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/docs/outros/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns OutrosDocumentos
     * @throws ApiError
     */
    public static docsOutrosPartialUpdate({
        id,
        formData,
    }: {
        /**
         * A unique integer value identifying this Outro Documento.
         */
        id: number,
        formData?: PatchedOutrosDocumentosRequest,
    }): CancelablePromise<OutrosDocumentos> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/docs/outros/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static docsOutrosDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this Outro Documento.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/docs/outros/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns DocumentosPrivacidade
     * @throws ApiError
     */
    public static docsPrivacidadeList(): CancelablePromise<Array<DocumentosPrivacidade>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/docs/privacidade',
        });
    }

    /**
     * @returns DocumentosPrivacidade
     * @throws ApiError
     */
    public static docsPrivacidadeCreate({
        formData,
    }: {
        formData: DocumentosPrivacidadeRequest,
    }): CancelablePromise<DocumentosPrivacidade> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gestao/docs/privacidade',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns DocumentosPrivacidade
     * @throws ApiError
     */
    public static docsPrivacidadeRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this Documento Privacidade.
         */
        id: number,
    }): CancelablePromise<DocumentosPrivacidade> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gestao/docs/privacidade/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns DocumentosPrivacidade
     * @throws ApiError
     */
    public static docsPrivacidadeUpdate({
        id,
        formData,
    }: {
        /**
         * A unique integer value identifying this Documento Privacidade.
         */
        id: number,
        formData: DocumentosPrivacidadeRequest,
    }): CancelablePromise<DocumentosPrivacidade> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/gestao/docs/privacidade/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns DocumentosPrivacidade
     * @throws ApiError
     */
    public static docsPrivacidadePartialUpdate({
        id,
        formData,
    }: {
        /**
         * A unique integer value identifying this Documento Privacidade.
         */
        id: number,
        formData?: PatchedDocumentosPrivacidadeRequest,
    }): CancelablePromise<DocumentosPrivacidade> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/gestao/docs/privacidade/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static docsPrivacidadeDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this Documento Privacidade.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gestao/docs/privacidade/{id}',
            path: {
                'id': id,
            },
        });
    }

}
