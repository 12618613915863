import React, { useState } from 'react';
import {
  ComprasViagem,
  EuPagoEstadosReferencia,
  PagamentoService,
  RefsMultibanco,
  RefsMultibancoRequest,
} from '../../../../slidein_api';
import {
  ActionIcon,
  Badge,
  Button,
  Code,
  Group,
  LoadingOverlay,
  Modal,
  NumberInput,
  Popover,
  Stack,
  Table,
  Text,
  Title,
} from '@mantine/core';
import dayjs from 'dayjs';
import {
  IconCreditCard,
  IconCurrencyEuro,
  IconEye,
  IconFaceId,
  IconRefresh,
  IconTrashX,
  IconX,
} from '@tabler/icons-react';
import { msgAPIError } from '../../../helpers';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import { DatePickerInput } from '@mantine/dates';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';

function estadosColors(estado: EuPagoEstadosReferencia) {
  switch (estado) {
    case EuPagoEstadosReferencia.CANCELADA:
    case EuPagoEstadosReferencia.ERRO:
    case EuPagoEstadosReferencia.EXPIRADA:
      return 'red';
    case EuPagoEstadosReferencia.PAGA:
      return 'green';
    case EuPagoEstadosReferencia.PENDENTE:
      return 'lightgreen';
    default:
      return 'gray';
  }
}

export function RefMultibancoView({ viagem, refresh }: { viagem: ComprasViagem; refresh }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [newRefCloseVisible, setNewRefCloseVisible] = useState<boolean>(false);
  const refreshRef = (ref: RefsMultibanco) => {
    setLoading(true);
    PagamentoService.pagamentoRefRefreshStateRetrieve({ id: ref.id }).then(
      () => {
        refresh();
        notifications.show({
          title: 'Referência Actualizada!',
          message: `A referência ${ref.referencia} foi actualizada.`,
          withCloseButton: true,
          icon: <IconRefresh />,
          color: 'green',
        });
        setLoading(false);
      },
      (reason) => {
        msgAPIError(reason);
        setLoading(false);
      }
    );
  };

  const cancelRef = (ref) => {
    setLoading(true);
    PagamentoService.pagamentoRefInvalidateRefRetrieve({ id: ref.id }).then(
      () => {
        refresh();
        setLoading(false);
      },
      (reason) => {
        msgAPIError(reason);
        setLoading(false);
      }
    );
  };
  const createREFMBClose = (ref) => {
    setLoading(true);
    PagamentoService.pagamentoRefNewCloseCreate({
      requestBody: ref,
    }).then(
      () => {
        refresh();
        setNewRefCloseVisible(false);
        setLoading(false);
      },
      (reason) => {
        msgAPIError(reason);
        setLoading(false);
        setNewRefCloseVisible(false);
      }
    );
  };

  const refCloseForm = useForm<RefsMultibancoRequest>({
    initialValues: {
      viagem: null,
      data_inicio: null,
      data_fim: null,
    },
    validate: yupResolver(
      Yup.object({
        data_inicio: Yup.string().required('Obrigatório'),
        data_fim: Yup.string().required('Obrigatório'),
        valor: Yup.string().min(0).required('Obrigatório'),
      })
    ),
    transformValues: (values) => {
      const di = values.data_inicio ? new Date(values.data_inicio) : null;
      const df = values.data_fim ? new Date(values.data_fim) : null;
      return {
        ...values,
        viagem: viagem.id,
        data_inicio: di
          ? `${di.getFullYear()}-${(di.getMonth() + 1).toString().padStart(2, '0')}-${di
              .getDate()
              .toString()
              .padStart(2, '0')}`
          : '',
        data_fim: df
          ? `${df.getFullYear()}-${(df.getMonth() + 1).toString().padStart(2, '0')}-${df
              .getDate()
              .toString()
              .padStart(2, '0')}`
          : '',
      };
    },
  });

  return (
    <Stack>
      <LoadingOverlay visible={loading}></LoadingOverlay>
      <Group mt={'sm'}>
        <Title order={3}>Geral:</Title>{' '}
        <Button
          size={'xs'}
          leftIcon={<IconCreditCard />}
          variant={'outline'}
          color={'gray'}
          onClick={() => setNewRefCloseVisible(true)}>
          Gerar Nova referência Valor Fixo
        </Button>
      </Group>
      <Table>
        <thead>
          <tr>
            <th>Estado:</th>
            <th>Entidade/Referência/Valor</th>
            <th>Validade</th>
            <th>Data de Criação</th>
            <th>Data da última Actualização</th>
            <th>Tipo</th>
            <th>Identificador</th>
            <th>EuPago Resposta(JSON)</th>
            <th>Actualizar</th>
            <th>Cancelar</th>
          </tr>
        </thead>
        <tbody>
          {viagem?.refs_multibanco.map((ref) => (
            <tr key={ref.id}>
              <td>
                <Badge
                  variant={'outline'}
                  fullWidth={false}
                  color={estadosColors(ref.estado)}>
                  {ref.estado}
                </Badge>
              </td>
              <td>
                {ref.entidade} / {ref.referencia} / {ref.valor + '€'}
              </td>
              <td>{ref.data_inicio + ' a ' + ref.data_fim}</td>
              <td>{dayjs(ref.creation_date).locale('pt').format('YYYY-MM-DD HH:mm:ss')}</td>
              <td>{dayjs(ref.last_modification).locale('pt').format('YYYY-MM-DD HH:mm:ss')}</td>
              <td>{ref.nome}</td>
              <td>{ref.identificador}</td>
              <td>
                <Popover
                  position='bottom'
                  withArrow
                  shadow='md'>
                  <Popover.Target>
                    <IconEye />
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Code>{JSON.stringify(ref.ref_gen, null, 4)}</Code>
                  </Popover.Dropdown>
                </Popover>
              </td>
              <td>
                <Group>
                  <ActionIcon
                    variant={'outline'}
                    onClick={() => refreshRef(ref)}>
                    <IconRefresh></IconRefresh>
                  </ActionIcon>
                </Group>
              </td>
              <td>
                <ActionIcon
                  variant={'outline'}
                  onClick={() => {
                    modals.openConfirmModal({
                      title: 'Cancelar Referência!',
                      centered: true,
                      children: (
                        <Text size='sm'>
                          Confirma que deseja cancelar a Referência : {ref?.referencia} ?
                        </Text>
                      ),
                      labels: { confirm: 'Cancelar', cancel: 'Sair' },
                      confirmProps: { color: 'red', variant: 'outline', leftIcon: <IconTrashX /> },
                      cancelProps: { color: 'yellow', variant: 'outline', leftIcon: <IconX /> },
                      onConfirm: () => {
                        cancelRef(ref);
                      },
                    });
                  }}>
                  <IconTrashX></IconTrashX>
                </ActionIcon>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal
        withCloseButton={false}
        closeOnEscape={false}
        opened={newRefCloseVisible}
        onClose={() => {
          setNewRefCloseVisible(false);
        }}
        title={'Criar Referência Multibanco Valor Fixo'}>
        <form onSubmit={refCloseForm.onSubmit(createREFMBClose)}>
          <Stack>
            <DatePickerInput
              label='Data Inicio:'
              w={{ base: '100%', xs: '49%' }}
              locale={'pt'}
              firstDayOfWeek={1}
              dropdownType={'modal'}
              placeholder='Escolher Data'
              icon={<IconFaceId stroke={1.5} />}
              valueFormat='DD/MM/YYYY'
              {...refCloseForm.getInputProps('data_inicio')}
              value={
                refCloseForm.values.data_inicio
                  ? dayjs(refCloseForm.values.data_inicio).toDate()
                  : null
              }
            />
            <DatePickerInput
              label='Data Fim:'
              w={{ base: '100%', xs: '49%' }}
              locale={'pt'}
              firstDayOfWeek={1}
              dropdownType={'modal'}
              placeholder='Escolher Data'
              icon={<IconFaceId stroke={1.5} />}
              valueFormat='DD/MM/YYYY'
              {...refCloseForm.getInputProps('data_fim')}
              value={
                refCloseForm.values.data_fim ? dayjs(refCloseForm.values.data_fim).toDate() : null
              }
            />

            <NumberInput
              placeholder='Valor'
              label='Valor'
              precision={2}
              icon={<IconCurrencyEuro />}
              hideControls
              decimalSeparator=','
              thousandsSeparator='.'
              min={0}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              formatter={(value) =>
                value && !Number.isNaN(parseFloat(value))
                  ? // eslint-disable-next-line security/detect-unsafe-regex
                    `${value} €`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                  : ''
              }
              {...refCloseForm.getInputProps('valor')}
            />

            <Group
              position='right'
              mt='md'>
              <Button
                variant={'outline'}
                type='submit'>
                Criar Referência
              </Button>
              <Button
                type='button'
                onClick={() => {
                  setNewRefCloseVisible(false);
                }}>
                Cancelar
              </Button>
            </Group>
          </Stack>
        </form>
      </Modal>
    </Stack>
  );
}
